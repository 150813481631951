import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router';
import { useLoginContext } from '../hooks/login';
import '../theme/acercade.css';



const AcercaDe: React.FC = () => {
  const { loggedIn } = useLoginContext();

  //console.log(`rendering from loginPage with loggedIn=${loggedIn}`);
  if (!loggedIn) {
    return <Redirect to="/allivia/alliviaHome" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton text=""  color='primary' defaultHref="/" />
          </IonButtons>
          <IonTitle>Acerca de nosotros</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className='acerca'>
        <h1>Caregiver Solutions S.A. de C.V.</h1>
        <p className='caregiver'>Empresa dedicada a visibilizar la importancia de aquellas
          personas que cuidan a otras personas, por enfermedad,
          discapacidad o eventos esporádicos que se presentan por
          accidentes o eventos imprevistos pero incapacitantes y
          que al ser eventos no planeados, ni deseados, llega a
          afectarles de manera emocional, física y nutricional.</p>



        <h3>Misión</h3>

        <p>Dar a las personas que cuidan a pacientes familiares sin
          remuneración, herramientas para su trabajo que afecta
          su vida emocional, física y nutricional, para que ellos
          continúen su labor y logren el éxito que esperan en sus
          familiares sin sacrificar nada de su vida.</p>




        <h3>Visión</h3>

        <p>Ser considerados como la empresa que atendió la salud
          de manera diferente para hacerla integral, con un equipo
          multidisciplinario comprometido por las personas y sus
          familias, aún sin conocerlas.</p>
          </div>
      </IonContent>
    </IonPage>
  );
};

export default AcercaDe;
