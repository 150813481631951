import { SERVICE_ALLIVIA} from "../properties";
import {  DTOEstudio, UsuarioLogin } from "./AlliviaInterfaces";


export const leerEstudioById = async (usuario:UsuarioLogin,idEstudio:number):Promise<DTOEstudio> =>{
  const url = SERVICE_ALLIVIA+"/estudio/id/"+idEstudio;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerEstudios = async (usuario:UsuarioLogin,idPersona:number):Promise<Array<DTOEstudio>> =>{
  const url = SERVICE_ALLIVIA+"/estudio/"+idPersona;
  try{ 
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("estudios",JSON.stringify(json));
    console.log(json);    
    return json;
  }catch(evt){
    console.log("error",evt);
    let sigPeso :Array<DTOEstudio>=[];
    if(localStorage.estudios){
      sigPeso=JSON.parse(localStorage.estudios);        
    }
    return sigPeso
  }
}

export const guardarEstudio =async (usuario:UsuarioLogin,estudio:DTOEstudio):Promise<DTOEstudio> => {
  limpiarDocsAntesGuardar(estudio);
  const url = SERVICE_ALLIVIA+"/estudio";
  const response = await fetch(url,{
    body: JSON.stringify(estudio),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el estudio medico: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

const limpiarDocsAntesGuardar = (estudio :DTOEstudio) =>{
  if(estudio.docEstudioClinico){
    estudio.docEstudioClinico.fileStream=null;
  }  
}

