import { SERVICE_ALLIVIA} from "../properties";
import {  DTOTratamiento, UsuarioLogin } from "./AlliviaInterfaces";


export const leerTratamientoById = async (usuario:UsuarioLogin,idTratamiento:number):Promise<DTOTratamiento> =>{
  const url = SERVICE_ALLIVIA+"/tratamiento/id/"+idTratamiento;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerTratamientos = async (usuario:UsuarioLogin,idPersona:number):Promise<Array<DTOTratamiento>> =>{
  const url = SERVICE_ALLIVIA+"/tratamiento/"+idPersona; 
  try{ 
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("tratamientos",JSON.stringify(json));
    console.log(json);    
    return json;
  }catch(evt){
    console.log("error",evt);
    let trat :Array<DTOTratamiento>=[];
    if(localStorage.tratamientos){
      trat=JSON.parse(localStorage.tratamientos);        
    }
    return trat
  }
}

export const guardarTratamiento =async (usuario:UsuarioLogin,tratamiento:DTOTratamiento):Promise<DTOTratamiento> => {
  limpiarDocsAntesGuardar(tratamiento);
  const url = SERVICE_ALLIVIA+"/tratamiento";
  const response = await fetch(url,{
    body: JSON.stringify(tratamiento),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el tratamiento: "+response.statusText);
  }
  const json = await response.json();    
  return json;
}

const limpiarDocsAntesGuardar = (tratamiento :DTOTratamiento) =>{
  if(tratamiento.docTratamiento){
    tratamiento.docTratamiento.fileStream=null;
  }  
}

export const eliminarTratamiento = async (usuario:UsuarioLogin,idTratamiento:number):Promise<string> =>{
  const url = SERVICE_ALLIVIA+"/tratamiento/"+idTratamiento;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "DELETE"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.text();
  console.log(json);    
  return json;
}
