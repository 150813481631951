import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import '../theme/terminosyaviso.css';
import '../theme/eliminarCuenta.css';



const EliminarCuenta: React.FC = () => {

  //console.log(`rendering from loginPage with loggedIn=${loggedIn}`);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Eliminar Cuenta</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div id="aviso">
          <h1> Eliminar Cuenta</h1>
          <div className="aviso">
            <ul>
              <li>
                <span className="bold"> MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U
                  OPOSICIÓN (DERECHOS ARCO).    </span> Puede enviar un correo electrónico a <a href="mailto:contacto@allivia.com.mx">
                  contacto@allivia.com.mx,
                </a>  en cualquier momento, para ejercer sus Derechos de Acceso, Rectificación, Cancelación u Oposición
                (“Derechos ARCO”). Para ejercer los Derechos ARCO, usted (o su representante legal), deberá
                presentar la solicitud, identificándose con la siguiente documentación:
                I. Nombre del usuario o titular.
                II. Domicilio del usuario o titular u otro medio para comunicarle la respuesta a su solicitud.
                III. Documentos que acrediten su identidad (IFE/INE o pasaporte) y, en su caso, los documentos que
                acrediten la representación legal del solicitante.
                IV. Una descripción de la información / los datos sobre los que está tratando de ejercer sus derechos
                ARCO y los derechos que le gustaría ejercer.
                Si uno o más de los documentos mencionados anteriormente no están incluidos, y/o los documentos
                tienen errores u omisiones, la Empresa le notificará dentro de los 3 días hábiles posteriores a la recepción
                de la solicitud y le pedirá los documentos faltantes y/o las correcciones pertinentes; tendrá 5 días hábiles
                a partir de esa notificación para proporcionar la información actualizada, de lo contrario, la solicitud se
                entenderá como no presentada.
              </li>
            </ul>
            
          </div>
          <div className='div-centrar-img'>
          <img className='img-eliminarCuenta' src="./assets/eliminarCuenta.jpeg" >

</img>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EliminarCuenta;
