import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonImg,
  IonBackButton,
  IonLoading,
  IonButton,
  IonToast,
  IonModal,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/pagoAlliviaPremium.css';
import { guardarTarjetaCliente, leerPlan, leerSuscripcion, pagarSuscripcionPlan} from '../services/openpay.service';
import { DTOCard, DTOPlan, DTOSuscripcion } from '../services/OpenPayInterfaces';
import { guardarMembresia, leerDatosOpenPay, leerMembresiaByIdUser, validaSuscripcion } from '../services/membresia.service';
import { leerParametroByNombre } from '../services/parametro.service';
import { PARAM_MEMBRESIA_PAGO_ANUAL, PARAM_MEMBRESIA_PAGO_MENSUAL, PARAM_MEMBRESIA_PAGO_MSI, PARAM_OPENPAY_PLAN_ANUAL_ID, PARAM_OPENPAY_PLAN_MENSUAL_ID } from '../constantes';
import { Redirect } from 'react-router';
import { DTOMembresia, DTOOpenPay } from '../services/AlliviaInterfaces';
import dayjs from 'dayjs';


const PagoAlliviaPremium: React.FC = () => {
  const user = useLoginContext();
  const { parametros } = useParametros();
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [suscripcion, setSuscripcion] = useState<DTOSuscripcion>();
  const [modalCVV, setModalCvv] = useState(false);
  const [nombreTitular, setNombreTitular] = useState("");
  const [numeroTarjeta, setNumeroTarjeta] = useState("");
  const [mesE, setMesE] = useState("");
  const [anioE, setAnioE] = useState("");
  const [cvv, setCvv] = useState("");
  const [idPeriodoMembresia,setIdPeriodoMembresia] = useState(2);
  const [montoAnual, setMontoAnual] = useState("0.00");
  const [montoMensual, setMontoMensual] = useState("0.00");
  const [mesesMSI, setMesesMSI] = useState<Array<string>>([]);
  const [mensualidad, setMensualidad] = useState("1");
  const [idPlanOpenpay,setIdPlanOpenpay] = useState("");
  const [idPlanAnual,setIdPlanAnual] = useState("");
  const [idPlanMensual,setIdPlanMensual] = useState("");
  const [membresia,setMembresia]= useState<DTOMembresia>();
  const [planOpenPay,setPlanOpenPay]= useState<DTOPlan>({});
  const [datosOpenPay,setDatosOpenPay] = useState<DTOOpenPay>();
  const [errorMensaje,setErrorMensaje] = useState("");


  const validaPeriodoMembresia = async(idPeriodo:number) =>{
    if(idPeriodo === 1){
      setIdPlanOpenpay(idPlanMensual);
    }else{
      setIdPlanOpenpay(idPlanAnual);
    }    
    setIdPeriodoMembresia(idPeriodo);
  }

  const formatoFecha=(fecha:string):string=>{  
    return dayjs(fecha).format("DD/MM/YY");
  }

  const realizarPago = async () => {
    setEstatus({ loading: true, leer: false });    
    if(numeroTarjeta===null|| numeroTarjeta===''){
      setErrorMensaje("El número de tarjeta es requerido");
      setMsgToast({showToast:true,statusMessage:"El número de tarjeta es requerido"});
      setEstatus({ loading: false, leer: false });
      return;
    }

    if(numeroTarjeta.length!=16){
      setErrorMensaje("El número de tarjeta debe de ser de 16 digitos");
      setMsgToast({showToast:true,statusMessage:"El número de tarjeta debe de ser de 16 digitos"});
      setEstatus({ loading: false, leer: false });
      return;
    }

    if(mesE===null || mesE===''){
      setErrorMensaje("El mes de expiración es requerido");
      setMsgToast({showToast:true,statusMessage:"El mes de expiración es requerido"});
      setEstatus({ loading: false, leer: false });
      return;
    }

    if(anioE===null|| anioE===''){
      setErrorMensaje("El año de expiración es requerido");
      setMsgToast({showToast:true,statusMessage:"El año de expiración es requerido"});
      setEstatus({ loading: false, leer: false });
      return;
    }

    if(anioE.length>2){
      setErrorMensaje("El año de expiración debe ser 2 digitos");
      setMsgToast({showToast:true,statusMessage:"El año de expiración debe ser 2 digitos"});
      setEstatus({ loading: false, leer: false });
      return;
    }

    if(mesE.length>2||parseInt(mesE)>12){
      setErrorMensaje("El mes de expiración debe ser 2 digitos, valores de 1 a 12");
      setMsgToast({showToast:true,statusMessage:"El mes de expiración debe ser 2 digitos, valores de 1 a 12"});
      setEstatus({ loading: false, leer: false });
      return;
    }

    if(cvv===null|| cvv===''){
      setErrorMensaje("El codigo cvv es requerido");
      setMsgToast({showToast:true,statusMessage:"El codigo cvv es requerido"});
      setEstatus({ loading: false, leer: false });
      return;
    }


    //guardar membresia
    try{
    
      let memTemp = membresia;

      if (!memTemp.idTarjetaOpenPay) {
        let deviceInfo = window["generaDeviceSessionId"](true, datosOpenPay.openPayId, datosOpenPay.openPayPrivateKey);
        console.log("device info:", deviceInfo);
        let tarjeta: DTOCard = {
          card_number: numeroTarjeta,
          holder_name: nombreTitular,
          expiration_year: anioE,
          expiration_month: mesE,
          device_session_id: deviceInfo,
          cvv2: cvv,
        }
        console.log("send", tarjeta);
        await guardarTarjetaCliente(tarjeta, memTemp.idClienteOpenPay, datosOpenPay).then((trjt)=>{
          tarjeta=trjt;
        }).catch((err)=>{
          console.log(err)
          setErrorMensaje(err.message);
          throw new Error(err.message);
        });
        console.log("response", tarjeta);
        memTemp = { ...memTemp, idTarjetaOpenPay: tarjeta.id, estatusTarjetaOpenPay: "OK", deviceSessionId: deviceInfo }
        console.log("membresia guardar", memTemp);
        memTemp = await guardarMembresia(user, memTemp);
      }

      let temIdPlan = null;
      if(idPeriodoMembresia === 1){
        temIdPlan =idPlanMensual;
      }else{
        temIdPlan = idPlanAnual;
      } 

      if(memTemp.idTarjetaOpenPay!=null){
        let suscrip :DTOSuscripcion = {
          plan_id: temIdPlan,
          source_id: memTemp.idTarjetaOpenPay
        }   

        let res =null ;
        await pagarSuscripcionPlan(suscrip,datosOpenPay,memTemp.idClienteOpenPay).then((rmem)=>{
          res=rmem;
        }).catch((err)=>{
          console.log(err)
          setErrorMensaje(err.message);
          throw new Error(err.message);
        });
        if(res.id){     
          memTemp = {...memTemp,idPlan:idPeriodoMembresia+"",
          idSuscripcion:res.id,
          idPlanOpenpay:temIdPlan,
          estatusSuscripcion:res.status,
          periodo:res.current_period_number}
          memTemp = await guardarMembresia(user, memTemp);
          memTemp = await validaSuscripcion(user,res.transaction.id);
          setMembresia(memTemp);
          setMsgToast({showToast:true,statusMessage:"La suscripción al plan allivia premium se realizó correctamente"});                
          setSuscripcion(res);
        }
      }
    }catch(error){
      setMsgToast({showToast:true,statusMessage:"Error: "+error.message});
    }
    setEstatus({ loading: false, leer: false });
  }



  const leerDatosInicio = async () => {
    setEstatus({ loading: true, leer: false });
    //leer datos de usuario(cuidador)
    setNombreTitular(parametros.cuidador.nombreCompleto);
    let memTem :DTOMembresia= null;
    await leerMembresiaByIdUser(user).then((memb)=>{
      memTem=memb;
    });
    let datosOpenPayTem = await leerDatosOpenPay(user);
    if(!memTem.idSuscripcion){
      //leer datos de monto mes
      await leerParametroByNombre(user, PARAM_MEMBRESIA_PAGO_MENSUAL).then((param) => {
        setMontoMensual(param.valor);
      });
      //leer datos de monto anual
      await leerParametroByNombre(user, PARAM_MEMBRESIA_PAGO_ANUAL).then((param) => {
        setMontoAnual(param.valor);
      });    
      await leerParametroByNombre(user, PARAM_OPENPAY_PLAN_ANUAL_ID).then((param) => {
        setIdPlanAnual(param.valor);
      });
      await leerParametroByNombre(user, PARAM_OPENPAY_PLAN_MENSUAL_ID).then((param) => {
        setIdPlanMensual(param.valor);
        setIdPlanOpenpay(param.valor);
      });
      //leer las mensualidades
      await leerParametroByNombre(user,PARAM_MEMBRESIA_PAGO_MSI).then((param) =>{
        let val = param.valor.split(",");
        setMesesMSI(val);
      });
      setMensualidad("1");
    }else{
      let suscripTem:DTOSuscripcion = null;
      await leerSuscripcion(memTem.idClienteOpenPay,memTem.idSuscripcion,datosOpenPayTem).then((susc)=>{
        suscripTem=susc;
        setSuscripcion(susc);
        console.log("Suscripcion: ",susc);
      });

      await leerPlan(suscripTem.plan_id,datosOpenPayTem).then((plan)=>{
        setPlanOpenPay(plan);
        console.log("Suscripcion: ",plan);
      })
      
    }
    setDatosOpenPay(datosOpenPayTem);
    setMembresia(memTem);
    setEstatus({ loading: false, leer: false });
  }

  useEffect(() => {
    // setEstatus({ loading: true, leer: false });
    if (parametros) {
      leerDatosInicio();
    }
    // setEstatus({ loading: false, leer: false });
  }, [parametros]);




  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  if(!membresia){
    return (<IonLoading isOpen message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>);
  }

  // if (!user.loggedIn) {
  //   return <Redirect to="/login" />
  // }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons hidden={suscripcion!=null} slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Realizar Pago</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div hidden={suscripcion!=null}>          
            <div className='pago-panel'>
              <IonImg src="./assets/pago.png"></IonImg>
              <IonTitle className="pagar">Realizar pago</IonTitle>
            </div>
            <div id="contenedor_tarjetas">
              <div className="txt_titlospago">
                <p>
                   En apoyo a nuestros cuidadores, con tu pago anual,&nbsp;
                   <span className="naranja">
                    obtén dos meses ¡Completamente gratis!
                  </span>
                </p>
                <p>
                  Y si así lo deseas, puedes dividir tu pago anual en 6 mensualidades, sin intereses
                </p>
                <p> Aceptamos pago con tarjeta de débito y/o crédito</p>
              </div>
            <div className='panel-tarjetas-tipo'>
              <div className="">
                <IonImg src="./assets/tarj1.png"></IonImg>
              </div>
              <div className="">
                <IonImg src="./assets/tarj2.png"></IonImg>
              </div>
              
            </div>              
            </div>
            <form action="#" method="POST" id="payment-form">
              <input type="hidden" name="token_id" id="token_id" />
              <input type="hidden" name="use_card_points" id="use_card_points" value="false" />
              <div className="pymnt-itm card active">
                <div className="sctn-row">
                  <div className="sctn-col">
                    <div>
                      <label>Nombre del titular:</label>
                    </div>
                    <div>
                      <input type="text" value={nombreTitular} placeholder="Como aparece en la tarjeta" autoComplete='off' data-openpay-card="holder_name" onChange={(event)=>setNombreTitular(event.target.value)}/>
                    </div>
                  </div>
                  <div className="sctn-col">
                    <div>
                      <label>Número de tarjeta:</label>
                    </div>
                    <div>
                      <input type="number" min={1} max={16} value={numeroTarjeta}  autoComplete="off" data-openpay-card="card_number"  onChange={(event)=>setNumeroTarjeta(event.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="sctn-row">
                  <div className="col_fechas">
                    <div className="fechas_card">
                      <div>Fecha de exp. mes/año:</div>
                      <div className="datos_card">
                        <div className="mesyano">
                          <input type='number' placeholder="mm" max={12} maxLength={2} min={1} value={mesE} onChange={(event)=>setMesE(event.target.value)} data-openpay-card="expiration_month"/>
                        </div>&nbsp;
                        <div className="mesyano">
                          <input type="number" placeholder="aa" max={99} maxLength={2} min={1} value={anioE} onChange={(event)=>setAnioE(event.target.value)} data-openpay-card="expiration_year" />
                        </div>
                      </div>
                    </div>
                    <div className="fechas_card">
                      <div className="codigo">Código de seguridad:</div>
                      <div className="datos_card">
                        <div className="mesyano">
                          <input type="number" value={cvv} placeholder="3 ó 4 dígitos" autoComplete='off' data-openpay-card="cvv2"
                          onChange={(event)=>setCvv(event.target.value)}
                          />
                        </div>
                        <div className="cvv" onClick={() => setModalCvv(true)}>
                          ¡
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sctn-row">
                  <div className="tipo_mem">
                    <label>Membresia:</label>
                    <IonRadioGroup value={idPeriodoMembresia} onIonChange={(e) => {validaPeriodoMembresia(e.detail.value) }}>
                      <div className="membresia">
                        <div className='mensual-anual'>
                          <div className='mem-rad'>
                            <IonRadio mode="md"  value={1} ></IonRadio>
                          </div>                                               
                          <div className='tipo-pago'>
                            <p>Mensual ${montoMensual}</p>
                          </div>                          
                        </div>
                        <div className='mensual-anual'>
                          <div className='mem-rad'>                       
                            <IonRadio mode="md"  value={2} ></IonRadio>
                          </div>
                          <div className="tipo-pago">
                            <p className="naranja">Anual ${montoAnual}</p>
                          </div>
                        </div>
                      </div>
                    </IonRadioGroup>                                                                
                  </div>
                  <div hidden>
                      <IonGrid>
                        <IonRow>
                          <IonCol size='6'>
                            <IonLabel>Meses sin intereses</IonLabel>
                          </IonCol>
                          <IonCol size='6'>
                            <IonSelect value={mensualidad} onIonChange={(event)=>setMensualidad(event.detail.value)}>
                              {mesesMSI.map((mval)=>
                                <IonSelectOption key={mval} value={mval}>{mval}</IonSelectOption>
                              )}                        
                            </IonSelect>
                          </IonCol>
                        </IonRow>
                      </IonGrid>                    
                  </div>
                </div>
                <div>
                    <p className='membresia_pago'>
                      Recuerda que tu pago se realiza de manera segura, <span></span>
                       <span className="rosa">
                      porque tu tranquilidad, ¡También importa!
                        </span>
                    </p>              
                </div>
                <div className="openpay">
                  <div className="logo">                    
                      Transacción segura<br/>realizada vía:
                  </div>
                  <div className="img_log">
                    <IonImg src="./assets/openpay.png"></IonImg>
                  </div>
                </div>             
              </div>
            </form>
            <div hidden={errorMensaje===''} className='errormessage'>
              *{errorMensaje}
            </div> 
            <div className='panel-boton-pago'>
              <IonButton className='button-pago' expand='block' onClick={realizarPago}>Pagar</IonButton>            
            </div>            
          </div>
          <div hidden={suscripcion==null}>
              <div>
                <h1 className="apremium">allivia <span className="premiumm">Premium</span> </h1>
                <IonGrid className='datos-suscripcion'>
                  <IonRow>
                    <IonCol size='6'>
                      <IonTitle>
                       <p className="vigencia_suscri">Plan</p> 
                      </IonTitle>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{planOpenPay?planOpenPay.name:""}</IonText>
                    </IonCol>
                    <IonCol size='6'>
                      <IonTitle>
                         <p className="vigencia_suscri">
                          Fecha de suscripción
                        </p>
                      </IonTitle>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{suscripcion?formatoFecha(suscripcion.creation_date):""}</IonText>
                    </IonCol>
                    <IonCol size='6'>
                      <IonTitle>
                        <p className="vigencia_suscri">
                          Inicio de periodo
                        </p>
                      </IonTitle>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{suscripcion?formatoFecha(suscripcion.creation_date):""}</IonText>
                    </IonCol>
                    <IonCol size='6'>
                      <IonTitle>
                        <p className="vigencia_suscri">
                           Fin de periodo  
                        </p>
                      </IonTitle>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{suscripcion?formatoFecha(suscripcion.period_end_date):""}</IonText>
                    </IonCol>
                    <IonCol size='6'>
                      <IonTitle>
                       <p className="vigencia_suscri">
                           Número de periodo     
                        </p> 
                      </IonTitle>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{suscripcion?suscripcion.current_period_number:""}</IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>                             
                <IonCol>
                  <div className="info_baja">
                    <p>
                      En caso de querer darte de baja con un plan mensual o anual, por favor envía un correo a:&nbsp;
                       <a href="mailto:contacto@allivia" target="_blank">
                            contacto@allivia.com.mx                      
                      </a>  haciendo la solicitud al menos con 72 horas antes del vencimiento. De no ser así se renovará en automático.
                    </p>
                  </div>
                </IonCol>                
                <IonButton expand='block' href='/'>Inicio</IonButton>
              </div>
          </div> 
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
          <IonModal backdropDismiss={false} isOpen={modalCVV} className="ion-modal-cvv">
            <IonContent>
              <IonImg src="./assets/cvvEj.png" ></IonImg>
              <IonButton expand='block' onClick={() => setModalCvv(false)}>
                Cerrar
              </IonButton>
            </IonContent>
          </IonModal>
        </IonContent>       
      </IonPage>
    </>
  );
};

export default PagoAlliviaPremium;
