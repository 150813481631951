import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext } from '../hooks/login';
import '../theme/resetPassword.css';
import { EMAIL_VALID } from '../properties';
import { generarCodigoVerificacion, restablecerPassword } from '../services/usuario.service';
import { DTOActualizarPassword, UsuarioLogin } from '../services/AlliviaInterfaces';



const ResetPassword: React.FC = () => {
  const user = useLoginContext();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [codigo,setCodigo] = useState("");
  const [confirmPassword,setConfirmPassword] = useState("");
  const [mensajeError,setMensajeError] = useState("");
  const [tieneCodigo,setTieneCodigo] = useState(0);
  const [nombre,setNombre] = useState("");
  
  useEffect(()=>{    
    setEstatus({loading:false,leer:false});
  },[]);

  const handleYaTengoCodigo=async()=>{
    setEstatus({loading:true,leer:false});
    setTieneCodigo(1);
    setEstatus({loading:false,leer:false});
  }

  const handleActualizarContraseña=async()=>{
    setEstatus({loading:true,leer:false});
    if(email==null||email ===''){
      setMensajeError("Ingresa un correo electrónico");
      setMsgToast({ showToast: true, statusMessage: "Ingresa un correo electrónico" });      
      setEstatus({loading:false,leer:false});
      return;
    }
    if(!EMAIL_VALID.test(email)){
      setMensajeError("Ingresa un correo electrónico con formato válido");
      setMsgToast({ showToast: true, statusMessage: "Ingresa un correo electrónico con formato válido" });
      setEstatus({loading:false,leer:false});
      return;
    }
    if(codigo==null||codigo ===''){
      setMensajeError("Ingresa el código que se envió a tu correo elctrónico");
      setMsgToast({ showToast: true, statusMessage: "Ingresa el código que se envió a tu correo elctrónico" });      
      setEstatus({loading:false,leer:false});
      return;
    }
    if(codigo.length!=6){
      setMensajeError("El código de verificación debe de ser de 6 digitos");
      setMsgToast({ showToast: true, statusMessage: "El código de verificación debe de ser de 6 digitos" });      
      setEstatus({loading:false,leer:false});
      return;
    }
    if(password==null||password ===''){
      setMensajeError("Ingresa una contraseña");
      setMsgToast({ showToast: true, statusMessage: "Ingresa una contraseña" });      
      setEstatus({loading:false,leer:false});
      return;
    }
    if(confirmPassword==null||confirmPassword ===''){
      setMensajeError("Ingresa la confirmación de la contraseña");
      setMsgToast({ showToast: true, statusMessage: "Ingresa la confirmación de la contraseña" });      
      setEstatus({loading:false,leer:false});
      return;
    }
    if(password!=confirmPassword){
      setMensajeError("La contraseña es diferente");
      setMsgToast({ showToast: true, statusMessage: "La contraseña es diferente" });      
      setEstatus({loading:false,leer:false});
      return;
    }
    let resetPass :DTOActualizarPassword={username:email,codigo:codigo,password:password};
    let respuesta  = await restablecerPassword(resetPass).then((resp)=>{
      setNombre(resp);
      setTieneCodigo(2);
      setMsgToast({ showToast: true, statusMessage: "El password se actualizó correctamente" });  
    }).catch((error)=>{
      setMensajeError(error.message);
      setMsgToast({ showToast: true, statusMessage: error.message}); 
    });
    setEstatus({loading:false,leer:false});
  }
 

  const handleEnviarCodigoVerificacion =async()=>{    
    setEstatus({loading:true,leer:false});
    setMensajeError("");
    if(email==null||email ===''){
      setMensajeError("Ingresa un correo electrónico");
      setMsgToast({ showToast: true, statusMessage: "Ingresa un correo electrónico" });      
      setEstatus({loading:false,leer:false});
      return;
    }
    if(!EMAIL_VALID.test(email)){
      setMensajeError("Ingresa un correo electrónico con formato válido");
      setMsgToast({ showToast: true, statusMessage: "Ingresa un correo electrónico con formato válido" });
      setEstatus({loading:false,leer:false});
      return;
    }
    let us:UsuarioLogin = {loggedIn:false,username:email};
    let codigo = await generarCodigoVerificacion(us).catch((error)=>{
      setMensajeError("El correo que ingresaste no existe en nuestro sistema, por favor verificalo.");
    });
    console.log("codigo:",codigo);
    if(us!=null){
      setTieneCodigo(1);
      setMsgToast({ showToast: true, statusMessage: "El código de verificación fue enviado a tu correo electrónico" });
    }
    setEstatus({loading:false,leer:false});
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Restablecer contraseña</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className='reset-password'>
            <div hidden={tieneCodigo!=0}>
              <div className='mensaje 1'>
                <p>Por tu seguridad, debes de verificar tu cuenta</p>
                <p>Ingresa el correo electrónico con el que se registro tu cuenta</p>
                <p>Se enviará a tu correo un código temporal con el que podras restablecer tu contraseña</p>
              </div>
              <IonList lines='none' className='lista-campos'>
                <IonItem>
                  <IonLabel position="stacked">Correo electrónico</IonLabel>
                  <IonInput type='email' pattern='email' inputMode='email' required={true}
                  value={email} onIonChange={(event)=>setEmail(event.detail.value)} ></IonInput>
                </IonItem>
              </IonList>
              <div hidden={mensajeError===''} className='mensaje-error'>
                {mensajeError}
              </div>
              <IonButton onClick={handleEnviarCodigoVerificacion} expand='block'>Enviar código</IonButton>
              <IonButton onClick={handleYaTengoCodigo} color="rosa" expand='block'>Ya tengo un código</IonButton>
            </div>
            <div hidden={tieneCodigo!=1}>
            <IonList lines='none' className='lista-campos'>
                <IonItem>
                  <IonLabel position="stacked">Correo electrónico</IonLabel>
                  <IonInput type='email' pattern='email' inputMode='email' required={true}
                  value={email} onIonChange={(event)=>setEmail(event.detail.value)}></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Código de verificación </IonLabel>
                  <IonInput type='number' required={true}
                  value={codigo} onIonChange={(event)=>setCodigo(event.detail.value)}></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Nueva contraseña</IonLabel>
                  <IonInput type='password' pattern='password'  required={true}
                  value={password} onIonChange={(event)=>setPassword(event.detail.value)}></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Confirmar contraseña</IonLabel>
                  <IonInput type='password' pattern="password" required={true}
                  value={confirmPassword} onIonChange={(event)=>setConfirmPassword(event.detail.value)}></IonInput>
                </IonItem>
              </IonList>
              <div hidden={mensajeError===''} className='mensaje-error'>
                {mensajeError}
              </div>
              <IonButton onClick={handleActualizarContraseña} expand='block'>Actualizar contraseña</IonButton>
              <IonButton onClick={handleEnviarCodigoVerificacion} expand='block'>Reenviar código</IonButton>
              <div className='nota'>
                Nota: Si el correo no aparece en tu bandeja de entrada, revisa la bandeja de correo no deseado.
              </div>
            </div>
            <div hidden={tieneCodigo!=2} >
              ¡Hola {nombre}, tu contraseña se actualizó correctamente!              
            </div>
          </div>                              
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
        </IonContent>        
      </IonPage>
    </>
  );
};

export default ResetPassword;
