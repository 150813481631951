import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useContext } from 'react';
import { LoginContext } from '../hooks/login';
import { cerrarSesion } from '../services/login.service';
import { types } from '../types/types';


const SettingsPage: React.FC = () => {
  const {dispatch} = useContext(LoginContext);

  const handleCerrarSesion = async () => {
    try{
      cerrarSesion();
      const action = {
        type: types.logout,
        payload: {loggedIn:'false'}
      }
      dispatch(action);
    }catch(error){
      console.log("Error al cerar la sesión: ",error);
    }    
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings Page</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonButton color="medium" expand='block'
        onClick={handleCerrarSesion}>
        Logout
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
