import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToast,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { timeout } from 'rxjs';
import { useLoginContext } from '../hooks/login';
import { EMAIL_VALID } from '../properties';
import { registrarUsuario } from '../services/usuario.service';
import '../theme/registroUsuario.css';



const RegistroUsuario: React.FC = () => {
  const { loggedIn } = useLoginContext();
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [nombre, setNombre] = useState('');
  const [apPaterno, setApPaterno] = useState('');
  const [apMaterno, setApMaterno] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [status, setStatus] = useState({ loading: false, error: false });
  const [terminosCond,setTerminosCod] = useState(true);
  const [usuarioRegistrado,setUsuarioRegistrado]=useState(false);
  const [registroExitoso,setRegistroExitoso] = useState(false);



  const handleRegister = async () => {
    try {
      setStatus({ loading: true, error: false });
      if(campoVacio()){
        setMsgToast({showToast:true,statusMessage:"Por favor ingrese todos los datos obligatorios (*)"});
        setStatus({ loading: false, error: true });
        return;
      }

      if(!validaEmailCorrecto()){
        setMsgToast({showToast:true,statusMessage:"Por favor ingresa un correo con formato válido"});
        setStatus({ loading: false, error: true });
        return;
      }

      if(!validaPassword()){
        console.log("El password no es igual");
        setMsgToast({showToast:true,statusMessage:"Las contraseñas no son iguales"});
        setStatus({ loading: false, error: true });
        return;            
      }
     
      const usuario = {nombre:nombre,apPaterno:apPaterno,apMaterno:apMaterno,email:email,password:password};
      const usuarioResp = await registrarUsuario(usuario);
      console.log('usuario Resp: ',usuarioResp )
      if(usuarioResp.idUsuario ===null){
        setMsgToast({showToast:true,statusMessage:"Ocurrio un error en el registro, por favor intentar de nuevo"});
      }else{
        limpiarCampos();        
        setMsgToast({showToast:true,statusMessage:"¡Registro exitoso!"});
        setRegistroExitoso(true);
      }
      setStatus({ loading: false, error: false });            
    } catch (error) {
      setMsgToast({showToast:true,statusMessage:"¡Ocurrio un error en el registro, por favor intenta de nuevo!"});      
      setStatus({ loading: false, error: true });
      console.log('error', error);
    }
  }  

  const validaPassword = ()=>{
    var valor=false;
    if(passwordConfirm===password){
      valor=true;
    }
    return valor;
  }

  const validaEmailCorrecto=()=>{
    let regEmail = EMAIL_VALID;
    console.log(email+"=",regEmail.test(email));
    return regEmail.test(email);
  }

  function limpiarCampos(){
    setNombre('');
    setApPaterno('');
    setApMaterno('');
    setEmail('');
    setPassword('');
    setPasswordConfirm('');
  }
  
  const campoVacio = () =>{
    var valida = false;
    if(nombre===''){      
      valida=true;
    }
    if(apPaterno===''){
      valida=true;
    }    

    if(email===''){
      valida=true;
    }

    if(password===''){
      valida=true;
    }

    if(passwordConfirm===''){
      valida=true;
    }

    return valida;
  }

  const afterMessage = async ()=>{
    setMsgToast({showToast:false,statusMessage:''});
    if(registroExitoso){
      await timeout(1000);
      setUsuarioRegistrado(true);
    }
  }

  //console.log(`rendering from loginPage with loggedIn=${loggedIn}`);
  if (loggedIn) {
    return <Redirect to="/my/alliviaHome" />
  }
  if (usuarioRegistrado) {    
    return <Redirect to="/login" />
  }
  return (
    <IonPage>
      <IonHeader>
      <div className='login-header'>
            <div className='login_caregiver'>
              <img src="./assets/icon/caregiver.png" alt="caregiver" />
            </div>
            <div className="log_allivia">
              <img className="login-logo" src="./assets/icon/allivia.png" alt='allivia-icon'/>
            </div>
        
        </div>
      
      </IonHeader>
      <IonContent className="ion-padding">
        <IonText className='registro-aviso-text'>Por favor ingrese los datos solicitados</IonText>
        <IonGrid className='registro-form'>
          <IonRow>
            <IonCol size="12">
                <IonInput class="id_registro apellidos" type='text' inputMode='text' autocapitalize="sentences" placeholder="Nombre(s)*:" value={nombre} 
                  onIonChange={(event) => setNombre(event.detail.value)} />
            </IonCol>
            <IonCol size="12" >
                <IonInput class="id_registro apellidos" type='text' inputMode='text' autocapitalize="sentences" placeholder="Apellido Paterno*:" value={apPaterno}
                  onIonChange={(event) => setApPaterno(event.detail.value)} />
            </IonCol>
            <IonCol size="12" >
                <IonInput class="id_registro apellidos" type='text' inputMode='text' autocapitalize="sentences" placeholder="Apellido Materno:" value={apMaterno}
                  onIonChange={(event) => setApMaterno(event.detail.value)} />
            </IonCol>
            <IonCol size="12" >
                <IonInput class="id_registro" type='email' inputMode='email' pattern='email' placeholder="Correo electrónico*:" value={email}
                  onIonChange={(event) => setEmail(event.detail.value)} />
            </IonCol>
            <IonCol size="12" >
                <IonInput class="id_registro" type='password' pattern='password' placeholder="Contraseña*:" value={password}
                  onIonChange={(event) => setPassword(event.detail.value)} />
            </IonCol>
            <IonCol size="12">
                <IonInput class="id_registro" type='password' pattern='password' placeholder="Confirmar contraseña*:" value={passwordConfirm}
                  onIonChange={(event) => setPasswordConfirm(event.detail.value)} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">              
              <IonToggle className="centrar_toogle" color='success' checked={terminosCond} onIonChange={(event)=> setTerminosCod(event.detail.checked)}  >
              </IonToggle>              
            </IonCol>
            <IonCol className="registro_terminos" size='9'>
              <IonLabel className='login-terminoscondiciones-text' >Al ingresar acepto los términos y condiciones</IonLabel>
                <a className="resaltarbutton" href='https://caregiversolutions.com.mx/terminos-y-condiciones/' target={"_blank"} > Ver </a>
                <div>
                    <IonLabel className='login-terminoscondiciones-text'>
                  Si deseas consultar nuestro Aviso de privacidad puedes acceder en el siguiente enlace:  
                   </IonLabel>
                  <a className="resaltarbutton" href="https://caregiversolutions.com.mx/aviso-de-privacidad/" target={"_blank"}>
                      &nbsp;Consultar
                    </a>
                </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonButton disabled={!terminosCond} expand='block' onClick={handleRegister}>
                Registrar
              </IonButton>
            </IonCol>
          </IonRow>
          </IonGrid>
          <IonGrid>
          <IonRow>
            <IonCol id="nocuenta" size='12' className="text-center">             
                <IonLabel>¿Ya tienes cuenta?</IonLabel>                                  
            </IonCol>
            <IonCol>
              <IonButton className='txtcuenta' expand='block' fill='clear' routerLink='/login'>
                Ingresa aquí                
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" className='text-center'>
              <IonLabel >Visibilizar la importancia de aquellas personas que cuidan a otras personas</IonLabel>
            </IonCol>
          </IonRow>
          
        </IonGrid>                
        <IonLoading isOpen={status.loading} />
        <IonToast color="rosa"
          isOpen={msgToast.showToast}
          position='top'
          onDidDismiss={() => afterMessage()}
          message= {msgToast.statusMessage}
          duration={3000}
      />
      </IonContent>
    </IonPage>
  );
};

export default RegistroUsuario;
