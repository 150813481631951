import {  OPENPAY_SERV_CARGOS, OPENPAY_SERV_CLIENTES, OPENPAY_SERV_SUSCRIPCIONES, OPENPAY_SERV_TARJETAS} from "../constantes";
import { DTOOpenPay } from "./AlliviaInterfaces";
import { DTOCard, DTOCargo, DTOCliente, DTOPlan, DTOSuscripcion } from "./OpenPayInterfaces";


export const guardarCliente =async (cliente:DTOCliente,datosOpenPay:DTOOpenPay):Promise<DTOCliente> => {
  const url = datosOpenPay.openPayUrl+"/v1/"+datosOpenPay.openPayId+"/"+OPENPAY_SERV_CLIENTES;
  const response = await fetch(url,{
    body: JSON.stringify(cliente),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Basic "+btoa(datosOpenPay.openPayPrivateKey+":"),
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("El registro se realizo correctamente: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const guardarTarjeta =async (tarjeta:DTOCard,datosOpenPay:DTOOpenPay):Promise<DTOCard> => {
  const url = datosOpenPay.openPayUrl+"/v1/"+datosOpenPay.openPayId+"/"+OPENPAY_SERV_TARJETAS;
  const response = await fetch(url,{
    body: JSON.stringify(tarjeta),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Basic "+btoa(datosOpenPay.openPayPrivateKey+":"),
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("El registro se realizo correctamente: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const guardarTarjetaCliente =async (tarjeta:DTOCard,cutermerId:string,datosOpenPay:DTOOpenPay):Promise<DTOCard> => {
  const url = datosOpenPay.openPayUrl+"/v1/"+datosOpenPay.openPayId+"/customers/"+cutermerId+"/"+OPENPAY_SERV_TARJETAS;
  const response = await fetch(url,{
    body: JSON.stringify(tarjeta),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Basic "+btoa(datosOpenPay.openPayPrivateKey+":"),
    },
    method: "POST"
  });
  if(!response.ok){
    const js = await response.json();
    console.log("Er: ",js);
    let desc ="";
    console.log("error_code",js.error_code,js.description);
    if(js.error_code===1001){
      if(js.description==='cvv2 length must be 3 digits'){
        desc ="cvv2 la longitud debe ser de 3 digitos";
      }else if('card_number is required, expiration_year expiration_month is required'){
        desc ="el número de tarjeta es requerido, la fecha de expiración es requerida";
      }else if('card_number length is invalid'){
        desc ="la longitud del numero de tarjeta es invalido";
      }else if('expiration_year length must be 2 digits, expiratio…is invalid, valid expirations months are 01 to 12'){
        desc = 'el año de expiracion debe ser de 2 digitos,'
      }else{
        desc ="error al procesar la solicitud"
      }
    }else if(js.error_code===1003){
      if(js.description==="Card declined (k)"){
        desc ="Tarjeta declinada";
      }else{
        desc = "Tarjeta declinada";
      }
    } else if(js.error_code===2004){
      if(js.description==="The card number verification digit is invalid"){
        desc ="El número de tarjeta es inválido";
      }else{
        desc = js.description;
      }
    }else if(js.error_code===3001){
      if(js.description==='The card was declined by the bank'){
        desc ="La tarjeta fue declinada por el banco";
      }else{
        desc ="Error al procesar los datos de la tarjeta"
      }
    }else if(js.error_code===3002){
      if('The card has expired'){
        desc ="La tarjeta ha expirado";
      }else{
        desc ="Error al procesar los datos de la tarjeta"
      }
    }else if(js.error_code===3003){
      if('The card has expired'){
        desc ="La tarjeta ha expirado";
      }else{
        desc ="Error al procesar los datos de la tarjeta"
      }
    }else if(js.error_code===3004){
      if("The card was reported as stolen"){
        desc ="Tarjeta declinada";
      }else{
        desc ="Tarjeta declinada"
      }
    }
    console.log("desc",desc);
    throw new Error(desc);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerSuscripcion = async (customerId:string,idSuscripcion:string,datosOpenPay:DTOOpenPay):Promise<DTOSuscripcion> =>{
  const url = datosOpenPay.openPayUrl+"/v1/"+datosOpenPay.openPayId+"/customers/"+customerId+"/"+OPENPAY_SERV_SUSCRIPCIONES+"/"+idSuscripcion;
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Basic "+btoa(datosOpenPay.openPayPrivateKey+":"),
    },
    method: "get"
  });
  
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log("suscripcionws: ",json);    
  return json;
}

export const leerPlan = async (idSuscripcion:string,datosOpenPay:DTOOpenPay):Promise<DTOPlan> =>{
  const url = datosOpenPay.openPayUrl+"/v1/"+datosOpenPay.openPayId+"/plans/"+idSuscripcion;
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Basic "+btoa(datosOpenPay.openPayPrivateKey+":"),
    },
    method: "get"
  });
  
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log("suscripcionws: ",json);    
  return json;
}

export const pagarMembresiaOpenPay = async (cargo:DTOCargo,datosOpenPay:DTOOpenPay)=>{
  const url = datosOpenPay.openPayUrl+"/v1/"+datosOpenPay.openPayId+"/"+OPENPAY_SERV_CARGOS;
  const response = await fetch(url,{
    body: JSON.stringify(cargo),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Basic "+btoa(datosOpenPay.openPayPrivateKey+":"),
    },
    method: "POST"    
  });
  if(!response.ok){
    throw Error("El cargo se realizo correctamente: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){    
    return null
  }
}

export const pagarSuscripcionPlan= async (suscripcion:DTOSuscripcion,datosOpenPay:DTOOpenPay,customerId:string):Promise<DTOSuscripcion>=>{
  const url = datosOpenPay.openPayUrl+"/v1/"+datosOpenPay.openPayId+"/customers/"+customerId+"/"+OPENPAY_SERV_SUSCRIPCIONES;
  const response = await fetch(url,{
    body: JSON.stringify(suscripcion),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Basic "+btoa(datosOpenPay.openPayPrivateKey+":"),
    },
    method: "POST"    
  });
  if(!response.ok){
    const js = await response.json();
    console.log("Er: ",js);
    let desc ="";
    console.log("error_code",js.error_code,js.description);
    if(js.error_code===1018){
      if("The number of retries of charge is greater than allowed"){
        desc ="Tarjeta declinada";
      }else{
        desc ="Tarjeta declinada"
      }
    }
    if(js.error_code===3003){
      if("The card doesn't have sufficient funds"){
        desc ="Tarjeta declinada";
      }else{
        desc ="Tarjeta declinada"
      }
    }
    if(js.error_code===3004){
      if("The card was reported as stolen"){
        desc ="Tarjeta declinada";
      }else{
        desc ="Tarjeta declinada"
      }
    }
    console.log("desc",desc);
    throw new Error(desc);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){    
    return null
  }
}

export const pagarMembresiaOpenPayCliente = async (cargo:DTOCargo,datosOpenPay:DTOOpenPay,customerId:string)=>{
  const url = datosOpenPay.openPayUrl+"/v1/"+datosOpenPay.openPayId+"/customers/"+customerId+"/"+OPENPAY_SERV_CARGOS;
  const response = await fetch(url,{
    body: JSON.stringify(cargo),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Basic "+btoa(datosOpenPay.openPayPrivateKey+":"),
    },
    method: "POST"    
  });
  if(!response.ok){
    throw Error("El cargo se realizo correctamente: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){    
    return null
  }
}

export const pagoTerminal =async (cargo:DTOCargo,datosOpenPay:DTOOpenPay):Promise<DTOCargo> => {
  const url = datosOpenPay.openPayUrl+"/v1/"+datosOpenPay.openPayId+"/"+OPENPAY_SERV_CARGOS;
  const response = await fetch(url,{
    body: JSON.stringify(cargo),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Basic "+btoa(datosOpenPay.openPayPrivateKey+":"),
    },
    method: "POST"    
  });
  if(!response.ok){
    throw Error("El cargo se realizo correctamente: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){    
    return null
  }
}

