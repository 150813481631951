import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/cuestionarioTres.css';
import { Redirect } from 'react-router';
import { DTOCuestionario, DTOCuestionarioEvaluacion, DTOTestCuidador, DTOTestCuidadorRespuesta } from '../services/AlliviaInterfaces';
import { guardarTestCuidador, leerCuestionarioEvaluacion, leerCuestionarios } from '../services/cuestionario.service';
import { timeout } from 'rxjs';



const CuestionarioTres: React.FC = () => {
  const user = useLoginContext();
  const { parametros,setParametros} = useParametros();
  const idTest = 3;
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [cuestionario, setCuestionario] = useState<Array<DTOCuestionario>>([]);
  const [cuestionarioEvaluacion, setCuestionarioEvaluacion] = useState<Array<DTOCuestionarioEvaluacion>>([]);
  const [preguntaPendiente, setPreguntaPendiente] = useState(true);
  const [evaluacion, setEvaluacion] = useState({ puntos: null, descripcion: null,idCuestEvaluacion:null });
  const [idPanel,setIdPanel] = useState(1);
  const [puedeAvanzar, setPuedeAvanzar] = useState(false);
  const [testFinalizado,setTestFinalizado] =useState(false);
  const [regresar,setRegresar] =useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  
  const scrollToTop= () => {
      contentRef.current && contentRef.current.scrollToTop();
  };

  const leerDatosCuestionario = async () => {
    setEstatus({ loading: true, leer: false });
  
    await leerCuestionarios(idTest, user).then((cuest) => {
      //crear structura de respuestas        
      setCuestionario(cuest);
    });
    await leerCuestionarioEvaluacion(idTest, user).then((evalu) => {
      setCuestionarioEvaluacion(evalu);
    })
    setEstatus({ loading: false, leer: false });
  }

  const avanzarPanel=async () => {
    evaluarRespuestas(idPanel+1);
    let contarAnsw = 0;
    let total = 0;
    cuestionario.map((resp) => {
      console.log(resp.numPregunta + "==" + resp.valorRespuesta);
      if(idPanel===resp.idPanel){
        if(resp.idPanel<=idPanel){
          total = total+1;
        }
        if (resp.valorRespuesta!=null) {
          contarAnsw=contarAnsw+1;
        }    
      }  
    });
    if(contarAnsw==total){
      setIdPanel(idPanel+1);
    }
    scrollToTop();
  }

  const guardarCuestionario = async () => {
    setEstatus({ loading: true, leer: false });
    let date = new Date();
    let respuestas: Array<DTOTestCuidadorRespuesta> = [];
    cuestionario.map((question) => {
      let resp: DTOTestCuidadorRespuesta = {
        idCuestionario: question.idCuestionario,
        valorRespuesta: question.valorRespuesta,
      }
      respuestas.push(resp);
    });

    let testCuidador:DTOTestCuidador = {
      idTest : idTest,
      idCuidador: parametros.cuidador.idCuidador,
      puntos: evaluacion.puntos,
      idCuestEvaluacion: evaluacion.idCuestEvaluacion,   
      fechaEvaluacion: date.toISOString(),
      testCuidadorRespuestas : respuestas   
    }

    await guardarTestCuidador(testCuidador,user).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El test se guardo correctamente"});
      setParametros({...parametros,test2:testCuidador});
      setTestFinalizado(true);
    }).catch((error)=>{
      console.log("Error al guardar el test",error);
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el test por favor intente de nuevo"});

    });    
    setEstatus({ loading: false, leer: false });
  }

  const afterMessage = async ()=>{
    setMsgToast({showToast:false,statusMessage:''});
    if(testFinalizado){
      await timeout(1000);
      setRegresar(true);
    }
  }

  const evaluarRespuestas = async (idPanelValor:number) => {
    setEstatus({ loading: true, leer: false });
    let puntos = 0;
    let pendiente:boolean = false;
    let contaAnsw =0;
    let total =0;
    let temPuedeAvanzar:boolean=false;
    console.log("puntos:", pendiente,"parametres: ",parametros);
    cuestionario.map((resp) => {
      if(resp.idPanel<=idPanelValor){
        total = total+1;
      }
      console.log(resp.numPregunta + "==" + resp.valorRespuesta);
      if (resp.valorRespuesta!=null) {
        console.log("Entra==" + resp.valorRespuesta);
        puntos = puntos + resp.valorRespuesta;
        contaAnsw=contaAnsw+1;
      } else {
        console.log("Etrna true: ",resp.numPregunta);
        pendiente = true;
      }
    });
    console.log("puntos:",pendiente);
    if(idPanelValor<6 && contaAnsw>=total){
      temPuedeAvanzar=true;
    }

    setPuedeAvanzar(temPuedeAvanzar);  

    cuestionarioEvaluacion.map((evalu) => {
      console.log(evalu.minimo + "<=" + puntos + "&&" + puntos + "<=" + evalu.maximo);
      if (evalu.minimo <= puntos && puntos <= evalu.maximo) {
        setEvaluacion({ puntos: puntos, descripcion: evalu.descripcion,idCuestEvaluacion:evalu.idCuestEvaluacion });
      }
    });

    setPreguntaPendiente(pendiente);
    setEstatus({ loading: false, leer: false });
  }

  const regresarPanel=async()=>{
    if(idPanel>1 ){
      setIdPanel(idPanel-1);
    }    
    evaluarRespuestas(idPanel-1);
    scrollToTop();
  }

  useEffect(() => {
    leerDatosCuestionario().then(() => {
      console.log("Funcionando");
    });
    window.scrollTo(0, 0);
  }, []);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if(!parametros){
    return <Redirect to="/allivia/alliviaHome" />
  }

  if(regresar){
    return <Redirect to="/allivia/estadoEmocional" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Autovalore su situación</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent ref={contentRef} scrollEvents={true} >
          <div className='cuestionario-tres'>
            <div className='header' >
              <div className='numero'>
                <span>3</span>
              </div>
              <div className='titulo'>
                <span>¿Cuál es mi estado de ánimo?</span>
              </div>
            </div>
            <div className='contenido'>
              <div className='subtitulo'>
              Le dará información sobre si su estado de ánimo se ve afectado por su situación, y en especial si se siente triste, desanimado, desesperanzado…
              </div>
              <div className='instrucciones'>
                <span>Instrucción:</span> A continuación, aparecen varios grupos de afirmaciones. Por favor, lea con atención cada uno de ellos y señale cuál de las afirmaciones de cada grupo describe mejor sus sentimientos durante la ÚLTIMA SEMANA, INCLUIDO EL DÍA DE HOY. Seleccione el círculo con el número de la afirmación que haya elegido. Si dentro de un mismo grupo hay más de una afirmación que considere aplicable a su caso, márquela también. Asegúrese de haber leído todas las afirmaciones dentro de cada grupo antes de la elección
              </div>
              <div className='cuestionario'>
                {cuestionario ? cuestionario.map((preg) =>
                  preg.idPanel===idPanel?
                  <>
                    <div className='pregunta'>
                      <span className='categoria'>{preg.categoria}&nbsp;</span>
                    </div>
                    <IonRadioGroup value={preg.valorRespuesta} onIonChange={(event) => { preg.valorRespuesta = event.detail.value; evaluarRespuestas(idPanel) }}>
                      <div className='respuestas'>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={0} />
                          <div className='descripcion'>
                            {JSON.parse(preg.pregunta).a1}
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={1} />
                          <div className='descripcion'>
                          {JSON.parse(preg.pregunta).a2}
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={2} />
                          <div className='descripcion'>
                          {JSON.parse(preg.pregunta).a3}
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={3} />
                          <div className='descripcion'>
                          {JSON.parse(preg.pregunta).a4}
                          </div>
                        </div>                                               
                      </div>
                    </IonRadioGroup>
                  </>:null
                ) : null
                }
              </div>              
              <div hidden={idPanel<6} className='resultado'>
                <div className='panel-mensaje'>
                  <div className='puntaje'>
                    <span className='texto'>Tu puntuación fue:</span>
                    <span className='numero'>{evaluacion.puntos}</span>
                  </div>
                  <div className='nota'>
                    {evaluacion.descripcion}
                  </div>
                </div>
              </div>
              <span className="ir-arriba icon-arrow-up2"></span>
              <div className='boton-siguiente'>
              <IonButton expand='block' color="rosa" disabled={idPanel===1} onClick={regresarPanel}>Anterior</IonButton>
                <IonButton color="rosa" hidden={idPanel===6} disabled={!puedeAvanzar} expand="block"
                  onClick={() => { avanzarPanel();window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); }}>Preguntas siguientes</IonButton>
                <IonButton color="rosa" hidden={idPanel<6 || testFinalizado} expand="block" disabled={preguntaPendiente}
                  onClick={() => { guardarCuestionario() }}>Siguiente test</IonButton>
              </div>
            </div>
          </div>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => afterMessage()}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        </IonContent>
      </IonPage>
    </>
  );
};

export default CuestionarioTres;
