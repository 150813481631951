import { SERVICE_ALLIVIA} from "../properties";
import { DTOCorreo, UsuarioLogin } from "./AlliviaInterfaces";

export const enviarEmail = async (email:DTOCorreo,usuario:UsuarioLogin):Promise<DTOCorreo> =>{
  const url = SERVICE_ALLIVIA+"/correo/"; 
  const response = await fetch(url,{
    body: JSON.stringify(email),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

