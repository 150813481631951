import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonItem,
  IonImg,
  IonLabel,
  IonModal,
  IonButton,
  IonIcon,
  IonGrid,
  IonCol,
  IonRow,
  IonInput,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/soyPremium.css';
import { Redirect } from 'react-router';
import { closeOutline, trash} from 'ionicons/icons';
import { DTOCuidador, DTOSolicitudCompartirMembresia } from '../services/AlliviaInterfaces';
import { EMAIL_VALID } from '../properties';
import { leerCuidadorCompartir} from '../services/cuidador.service';
import { compartirMembresiaPremium, eliminarInvitadoMembresia, leerSolicitudCompartirMembresiaPremium } from '../services/membresia.service';



const SoyAlliviaPremium: React.FC = () => {
  const user = useLoginContext();
  const { parametros,setParametros } = useParametros();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [modalCorreoInvitar,setModalCorreoInvitar] = useState(false);
  const [modalConfirmacion,setModalConfirmacion] = useState(false);
  const [modalEliminar,setModalEliminar] = useState(false);
  const [cuidador,setcuidador] = useState<DTOCuidador>();
  const [invitadoRegistrado,setInvitadoRegistrado] = useState(false);
  const [invitadoEsPremium,setInvitadoEsPremium] = useState(false);
  const [correo,setCorreo] = useState("");
  const [nombre,setNombre] = useState("");
  const [solicitud,setSolicitud] = useState<DTOSolicitudCompartirMembresia>(null);

  const abrirModalEnviarCorreo = async() =>{
    setEstatus({loading:true,leer:false});
    setModalCorreoInvitar(true);
    setEstatus({loading:false,leer:false});
  }

  const leerDatos=async()=>{
    setEstatus({loading:true,leer:false});
    if(parametros!=null && parametros.cuidador){
      setcuidador(parametros.cuidador)
      let sol = await leerSolicitudCompartirMembresiaPremium(user);
      setSolicitud(sol);
    }    
    setEstatus({loading:false,leer:false});
  }

  const enviarInvitacionCompartirCuenta = async () => {
    setEstatus({loading:true,leer:false});
    // Valida formato correo
    let regEmail = EMAIL_VALID.test(correo);    
    if(!regEmail){
      console.log("formato de correo electrónico inconrrecto: ",correo);
      setEstatus({loading:false,leer:false});
      setMsgToast({showToast:true,statusMessage:"Formato de correo electrónico incorrecto"});
      return;
    }
    // valida que el correo no sea el mismo del usuario actual
    if(cuidador.email===correo){
      setEstatus({loading:false,leer:false});
      setMsgToast({showToast:true,statusMessage:"El correo no puede ser el mismo que el tuyo"});
      return;
    }
    let esRegistrado = false;
    let esPremium = false;
    // Valida existe paciente    
    let cuidCompartir = await leerCuidadorCompartir(correo,user);
    if(!cuidCompartir){
      esRegistrado=false;
    }
    // Valida si NO es paciente premium.
    if(cuidCompartir && !cuidCompartir.premium){
     esRegistrado=true;
     esPremium=false;
    }

    if(cuidCompartir && cuidCompartir.premium){
      esRegistrado=true;
      esPremium=true;
     }

    let idCuidadorInvi =null;
    if(cuidCompartir){
      idCuidadorInvi = cuidCompartir.idCuidador;
    }

    let solicitud:DTOSolicitudCompartirMembresia = {
      idCuidadorPremium: cuidador.idCuidador,
      idCuidadorInvitado: idCuidadorInvi,
      correo: correo,
      nombreCompleto:nombre,
      premium:esPremium,
      registrado:esRegistrado  
    }

    setInvitadoRegistrado(esRegistrado);
    setInvitadoEsPremium(esPremium);
    if(!esPremium){
      solicitud = await compartirMembresiaPremium(user,solicitud);
      console.log("solICITUD: ",solicitud);
      setSolicitud(solicitud);    
    }
    
    //Cierra modal correo
    setModalCorreoInvitar(false);
    //Abre modal de confirmación
    setModalConfirmacion(true);
    setEstatus({loading:false,leer:false});
  }

  const cerrarModal = async()=>{
    setCorreo("");
    setNombre("");
    setModalConfirmacion(false);
    setModalCorreoInvitar(false);
    if(!solicitud.registrado){
      setSolicitud(null);
    }
  }

  const aceptarEliminarInvitado = async () =>{
    setEstatus({loading:true,leer:false});
    let solicit  = await eliminarInvitadoMembresia(user);
    if(solicit.estatus === "ELIMINADO"){
      setMsgToast({showToast:true,statusMessage:"El invitado se eliminó correctamente"});
      setSolicitud(null);
      setParametros({...parametros,update:true});
    }
    setModalEliminar(false);
    setEstatus({loading:false,leer:false});
  }

  const cancelarEliminarInvitado = async () =>{
    setEstatus({loading:true,leer:false});
    setModalEliminar(false);
    setEstatus({loading:false,leer:false});
  }

  
  
  useEffect(()=>{    
    leerDatos();
  },[]);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  if(!cuidador){
    return (<IonLoading isOpen message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>);
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle className='soy-premium-titulo'>Soy
                <span className="apremium">
                  allivia
                </span>
                <span className="premiumm">
                  premium
                </span> 
              </IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className='soy-premium'>
          <div className='beneficios'>
            <h3>Beneficios exclusivos:</h3>
            <p>
              1. Extensión a 4 miembros para llevar un historial clínico.             
            </p>
            <p>
              2. Plan dúo para compartir la Información en dos dispositivos móviles.
            </p>
            <p>
              3. Plan de alimentación personalizado para el cuidador.
            </p>
            <p>
              4. Test de diagnóstico emocional para el cuidador.
            </p>
            <p>
              5. Accede al recordatorio de compra de medicamentos y sus posibles promociones.
            </p>
            <p>
              6. Tu información estará respaldada en la nube.
              
            </p>
          </div>   
          <div className='invitados-prem'>
            <div className="ip_titulo">
              <h6>Mi invitado Premium</h6>
            </div>            
            <div hidden={solicitud!=null} className='l-sin-invitados'>
              AÚN NO HAS INVITADO A NADIE
            </div>
            <div hidden={solicitud===null} className='l-invitados'>
              <p className='mensaje'>Has invitado a ser parte de tu membresía <span className='mensaje-p'>Premium</span> a:</p>
              <div className='invitado'>
                <div className='im-inv'>
                  AC
                </div>
                <div className='datos-inv'>
                  <div>{solicitud?solicitud.nombreCompleto:""}</div>
                  <div>INVITADO PREMIUM</div>
                  <div className='datos-estatus'>
                    <div className='estatus'>{solicitud && solicitud.idEstatus===0?"PENDIENTE DE ACEPTAR":(solicitud &&solicitud.idEstatus===1?"ACEPTADA":(solicitud &&solicitud.idEstatus===2?"RECHAZADA":""))}</div>                    
                  </div>
                </div>
                <div className='accion'>
                  <IonButton fill='clear' onClick={()=>setModalEliminar(true)}><IonIcon icon={trash}></IonIcon></IonButton>
                </div>
              </div>              
              <p>Ve a la pantalla de "Mis pacientes" para compartir algún paciente con tu invitado.</p>
            </div>
            <div hidden={solicitud!=null}  className="msj-inv-prem">
            Las personas que designes como invitados podrán visualizar a tu(s) paciente(s) y editar datos a través de la aplicación <span className='tallivia'>allivia </span><span className='tpremium'>PREMIUM</span>
            </div>
            <div hidden={solicitud!=null}  className='panel-agregar'>              
              <IonItem lines='none' onClick={()=>abrirModalEnviarCorreo()}>
                <IonImg src='./assets/hotList.png'></IonImg>
                <IonLabel>Agregar invitado premium</IonLabel>
              </IonItem>
            </div>    
          </div>                           
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
          <IonModal backdropDismiss={false} isOpen={modalCorreoInvitar} className="modal-correo-invitado" >
            <IonContent >
              <div className='header'>                
                <IonLabel>Correo de invitación</IonLabel>
                <IonButton fill='clear' onClick={()=>{cerrarModal();}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <p className='instrucciones'>
                  Ingresa el nombre y correo electrónico de tu invitado.
                </p>
                <p className='mensaje'><span className='w-importante'>IMPORTANTE: </span>Si tu invitado ya está registrado en la app allivia o es miembro PREMIUM, ingresa el correo asociado a su cuenta.
                </p>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='12'>
                        <IonLabel>Nombre(s):</IonLabel>
                      </IonCol>
                      <IonCol size='12'>
                        <IonInput type='text' inputMode='text' autocapitalize="sentences" value={nombre} onIonChange={(event)=>{setNombre(event.detail.value)}}></IonInput>
                      </IonCol>
                      <IonCol size='12'>
                        <IonLabel>Correo electrónico:</IonLabel>
                      </IonCol>
                      <IonCol size='12'>
                        <IonInput type='email' inputMode='email' autocapitalize='none' value={correo} onIonChange={(event)=>{setCorreo(event.detail.value)}} ></IonInput>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonButton expand='block' onClick={()=>{enviarInvitacionCompartirCuenta()}}>Continuar</IonButton>
                </div>
              </div>              
            </IonContent>
            </IonModal>      
            <IonModal backdropDismiss={false} isOpen={modalConfirmacion} className="modal-correo-confirmacion" >
            <IonContent >              
              <div className='contenido'>
                  <div hidden={!(invitadoRegistrado && !invitadoEsPremium)}>                    
                    <p className='usuario'>
                      {cuidador.nombreCompleto}
                    </p>
                    <p className='mensaje'>
                      ¡Listo! Hemos enviado tu invitación a {nombre.trim()}, para ser tu invitado <span className='w-premium'>PREMIUM</span>                  
                    </p>
                  </div> 
                  <div hidden={invitadoRegistrado}>
                    <p className='mensaje1'>
                      ¡Lo sentimos!
                    </p>
                    <p className='mensaje2'>
                      El usuario que deseas invitar aún no tiene cuenta, le hemos enviado una invitación para ser parte de la familia <span className='w-allivia'>allivia</span>.                  
                    </p>
                  </div>
                  <div hidden={!invitadoEsPremium}>
                    <p className='mensaje3'>
                      {cuidador.nombreCompleto}
                    </p>
                    <p className='mensaje4'>
                      El usuario que deseas invitar, ya es un usuario <span className='w-premium'>premium</span>.                      .                  
                    </p>
                  </div>
                  <div>                  
                    <IonButton expand='block' onClick={()=>cerrarModal()}>Finalizar</IonButton>
                  </div>
              </div>
            </IonContent>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={modalEliminar} className="modal-eliminar-invitado" >
            <IonContent >              
              <div className='contenido'>  
                <div className='imagen'>
                  <IonImg src='./assets/errorIcon.png'></IonImg>
                </div>
                <div className='mensaje1'>
                  Eliminar invitado premium
                </div>
                <div className='mensaje2'>
                  ¿Estás seguro que deseas eliminar a {solicitud!=null?solicitud.nombreCompleto:""} como tu invitado PREMIUM?
                </div>   
                <div className='mensaje3'>
                Ya no podra visualizar a los datos de tu Paciente compartido.
                </div>             
                <div className='botones'>
                  <div className='dl'>
                    <IonButton onClick={cancelarEliminarInvitado} fill='clear'>Cancelar</IonButton>
                  </div>
                  <div className='dr'>
                    <IonButton onClick={aceptarEliminarInvitado} fill='clear'>Aceptar</IonButton>
                  </div>
                  

                </div>
              </div>              
            </IonContent>
            </IonModal>   
        </IonContent>        
      </IonPage>
    </>
  );
};

export default SoyAlliviaPremium;
