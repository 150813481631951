import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonLabel,
  IonImg,
  IonButton,
  IonModal,
  IonInput,
  IonGrid,
  IonCol,
  IonRow,
  IonMenuToggle,
  IonRadioGroup,
  IonItem,
  IonRadio,
  IonToast,
  IonFabList,
  IonAvatar,
  IonLoading,
  IonSelectOption,
  IonSelect,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { addCircle, call, closeOutline,  home, menu, personCircle, search, shareSocial } from 'ionicons/icons';
import { useLoginContext, } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/miCalendarioCuidador.css';
import { Redirect } from 'react-router';
import Menu from './Menu';
import { Calendar, momentLocalizer,Views } from 'react-big-calendar'
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/es-mx'
import { DTOCuidador, DTOEvento } from '../services/AlliviaInterfaces';
import { guardarEvento, leerEventos } from '../services/evento.service';
import dayjs from 'dayjs';
import { leerCuidador } from '../services/cuidador.service';
import { emergencyCall, esWeb } from '../services/utils.service';
import { leerNotificaciones } from '../services/notificaciones.service';



const MiCalendarioCuidador: React.FC = () => {
  const user = useLoginContext();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [cuidador, setCuidador] = useState<DTOCuidador | undefined>(undefined);
  const [modalAgregarEvento, setModalAgregarEvento] = useState(false);
  const localizer = momentLocalizer(moment)
  const [evento, setEvento] = useState<DTOEvento>({});
  const [listaEventos,setListaEventos]= useState<Array<DTOEvento>>();
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [verRecordatorio, setVerRecordatorio] = useState("no");
  const [eventosCalendario,setEventosCalendario]=useState<Array<{}>>();
  const [horaTemp,setHoraTemp] = useState("");

  const handleGuardarEvento = async ()=>{
    setEstatus({loading:true,leer:false});
    console.log("Guardar cita medica: ",evento);        
    let temEvento ={...evento,mostrar:true,recordatorio:verRecordatorio==='si',hora:convertirHoraFecha(horaTemp)};         
    await guardarEvento(user,temEvento).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se guardó correctamente"});
      leerEventos(user,cuidador.idCuidador).then((leventos)=>{       
        actualizarEventosCalendario(leventos);
        setListaEventos(leventos);              
      }).finally(()=>{
        setEvento({idCuidador:cuidador.idCuidador});
        setHoraTemp("");
        setVerRecordatorio("no");        
      });
      sincronizaNotificaciones();
    });
    setModalAgregarEvento(false);
    setEstatus({loading:false,leer:false});
  }

  const scroleToTimeCustom =()=>{
    let mom = moment().set('hour',6);
    moment().set('minute', 0);
    moment().set('second', 0);
    moment().set('millisecond', 0);
    console.log(mom);
    return mom;
  }

  const eventStyleGetter = (event, start, end, isSelected)=> {
    console.log(event);
    var backgroundColor = event.color;
    var style = {
        backgroundColor: backgroundColor,
        color: "#FFFFFF"       
        /*borderRadius: '0px',
        opacity: 0.8,
        ,
        border: '0px',
        display: 'block',*/
    };
    return {
        style: style
    };
}

const convertirHora=(fecha:string):string=>{  
  return dayjs(fecha).format("HH:mm");;
}
  const actualizarEventosCalendario = (leventos:Array<DTOEvento>)=>{
    let events =[];
          leventos.map(
            (ev)=>{
              let background="#65c8d0"
              // if(ev.nombre==='Estudio'){
              //   background="#00FF00"                
              // }else if(ev.nombre==="Medicamento"){
              //   background="#ff0000"
              // }

              let fechaEvento = new Date(ev.fecha+"T"+ev.hora);
              
              events.push({
                start:fechaEvento,              
                end:fechaEvento,
                title: ev.descripcion,
                color:background,
                ...ev,
                hora:convertirHora(ev.fecha+"T"+ev.hora)             
              });
            }
          );
          setEventosCalendario(events);   
  }

  useEffect(() => {
    if (!cuidador) {     
      leerCuidador(user.username,user).then((valor)=>{
        leerEventos(user,valor.idCuidador).then((leventos)=>{          
          actualizarEventosCalendario(leventos);
          setListaEventos(leventos);              
        });
        setCuidador(valor);
        setEvento({idCuidador:valor.idCuidador});
      });
      setEstatus({loading:false,leer:false});
    }
  }, []);  

  const convertirHoraFecha =(t:string):string=>{  
    let d = ""+t+":00"
    return d;
  }

  const sincronizaNotificaciones=async ()=>{    
    await leerNotificaciones(user,cuidador.idCuidador)
  }

  const actualizaEvento = (valor:any)=>{
    setEvento({...evento,...valor});
  }
  
  const date = new  Date('2022-03-21T10:30:00');
  console.log(date);

   
  
  let formats = {
    dateFormat: 'DD',      
    dayFormat: (date, culture,localizer) =>
      localizer.format(date, 'dd', culture),
    weekdayFormat :(date,culture,localizer)=>
      localizer.format(date,'dd',culture),
    dayHeaderFormat: (date,culture,localizer)=>
      localizer.format(date,"dddd DD MMMM",culture),
    agendaDateFormat: (date,culture,localizer)=>
      localizer.format(date,"DD/MM/YY",culture),
    agendaHeaderFormat:({ start, end }, culture, local)=>
      localizer.format(start, 'DD/MM/YY', culture) + ' – ' + localizer.format(end, 'DD/MM/YY', culture)
  }

  

  const onEventEvent=(event)=>{
    console.log("Evento seleccionado",event);
    setHoraTemp(event.hora)
    setEvento(event);       
    setVerRecordatorio(event.recordatorio?"si":"no");
    setModalAgregarEvento(true);
  }
  

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
    <Menu/>
      <IonPage>
        <IonHeader>
        <IonToolbar>
      <IonButtons slot="start">
         <IonMenuToggle>
            <IonButton >
              <IonIcon size='large' color="primary" icon={menu}></IonIcon>
            </IonButton>
          </IonMenuToggle>          
        </IonButtons>
        <IonTitle  className="title_header">Mi Calendario</IonTitle>
        <IonButtons slot='end'>
          <IonButton href='allivia/perfilCuidador' >            
          { cuidador && cuidador.avatar ? 
            <>
              <IonAvatar className='homepage-avatar'>
                <img className='homepage-avatar' src={cuidador.avatar.fileStream}></img>
              </IonAvatar>
            </>                          
              :<IonIcon size='large' color="rosa" icon={personCircle} ></IonIcon>
            }   
          </IonButton>
        </IonButtons>
        </IonToolbar>  
        </IonHeader>
        <IonContent className="calendario-cuidador">          
          <div className='calendario-panel'>
            <div className='calendario-titulo' >
              <IonImg src='/assets/calendarioCuidador.png' />
              <p>Mi Calendario CUIDADOR   </p>             
            </div>
            <Calendar              
              localizer={localizer}
              formats={formats}
              defaultDate={new Date()}
              defaultView="agenda"
              events={eventosCalendario?eventosCalendario:[]}
              style={{ height: "65vh"}}
              popup={true}
              drilldownView="agenda"
              messages={{
                next: ">",
                today: "Hoy",
                previous: "<",
                month: "Mes",
                week: "Semana",
                day: "Día",
                date: "Fecha",
                time: "Hora",
                event: "Evento",
                showMore:function showMore(total) {
                  return '+' + total + 'más';
                }             
              }}
              onSelectEvent={onEventEvent}
              eventPropGetter={(eventStyleGetter)}
              scrollToTime = {scroleToTimeCustom()}

            />
          </div>
          <IonFab hidden={esWeb()} className='allivia-emergencia-call' vertical='bottom' horizontal='center' edge slot='fixed'>
            <IonFabButton color="emergencia">
              <IonIcon icon={call} />
              <IonLabel>911</IonLabel>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton color="success" onClick={()=>{emergencyCall();}}><IonIcon icon={call} /></IonFabButton>
            </IonFabList>
          </IonFab>
          <IonModal backdropDismiss={false} isOpen={modalAgregarEvento} className="ion-modal-calendario" >
            <IonContent>
              <div className='header'>
                <IonImg src='./assets/calendarioCuidadorAgregar.png'></IonImg>
                Agregar Evento
                <IonButton fill='clear' onClick={()=>{setModalAgregarEvento(false);}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/event1.png" />
                        <IonLabel>Evento:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >                       
                        <IonSelect className="lista_dato" value={evento.nombre}                        
                          okText="Aceptar" cancelText='Cancelar'
                          onIonChange={(event) => { actualizaEvento({nombre:event.detail.value})}}>
                          <IonSelectOption value="Cita médica" >Cita médica</IonSelectOption>
                          <IonSelectOption value="Otros" >Otros (Eventos Personales)</IonSelectOption>
                        </IonSelect>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/event2.png" />                     
                        <IonLabel>Descripción:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={evento.descripcion} 
                        onIonChange={(event)=>{actualizaEvento({descripcion:event.detail.value})}}></IonInput>
                      </IonCol>                      
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/event3.png" />
                        <IonLabel>Fecha:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="lista_dato" type='date' value={evento.fecha} 
                        onIonChange={(event)=>{actualizaEvento({fecha:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/event4.png" />
                        <IonLabel>Hora:</IonLabel>
                      </IonCol>                      
                      <IonCol size='6'>
                        <IonInput className="lista_dato" type='time' value={horaTemp}
                        onIonChange={(event)=>{setHoraTemp(event.detail.value)}}></IonInput>
                      </IonCol>              
                      <IonCol size='12'>
                        <IonLabel>Desea recibir un recordatorio de este evento:</IonLabel>                                               
                      </IonCol> 
                    </IonRow>
                    <IonRadioGroup color='success' value={verRecordatorio}
                      onIonChange={(event)=>{setVerRecordatorio(event.detail.value)}}>
                      <IonRow>                                           
                        <IonCol size='6' >
                          <IonItem lines='none'>
                          <IonLabel>Si</IonLabel>
                            <IonRadio mode="md"  slot="start" value="si"></IonRadio>
                          </IonItem>
                        </IonCol>
                        <IonCol size='6' >
                          <IonItem lines='none'>
                            <IonLabel>No</IonLabel>
                            <IonRadio mode="md"  slot="start" value="no"></IonRadio>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonRadioGroup>
                  </IonGrid>
                  <IonButton  className="guardar_orange" expand='block' onClick={() => { handleGuardarEvento()}} >Guardar
                   <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>              
            </IonContent>
          </IonModal>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
        />
        <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
        </IonContent>
        <div className='custom-tab-bar'>
          <IonButton fill='clear' routerLink='/'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' onClick={() => { setModalAgregarEvento(true); console.log("entrando") }}>
            <div>
              <IonIcon icon={search} />
              <IonLabel>Buscar</IonLabel>
            </div>
          </IonButton>
          <IonButton disabled={true} fill='clear' onClick={() => { setModalAgregarEvento(true); console.log("entrando") }}>
            <div>
              {/* <IonIcon icon={shareSocial} />
              <IonLabel>Compartir</IonLabel> */}
            </div>
          </IonButton>
          <IonButton fill='clear' onClick={() => { setModalAgregarEvento(true); console.log("entrando") }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Evento</IonLabel>
            </div>
          </IonButton>
        </div>
      </IonPage>
    </>
  );
};

export default MiCalendarioCuidador;
