import { SERVICE_ALLIVIA} from "../properties";
import { DTOMembresia, DTOOpenPay, DTOSolicitudCompartirMembresia, UsuarioLogin } from "./AlliviaInterfaces";
import { DTOCargo } from "./OpenPayInterfaces";


export const leerMembresiaByIdUser = async (usuario:UsuarioLogin):Promise<DTOMembresia> =>{
  const url = SERVICE_ALLIVIA+"/membresia/usuario/"+usuario.idUsuario;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const validaSuscripcion = async (usuario:UsuarioLogin,idSuscripcion:string):Promise<DTOMembresia> =>{
  const url = SERVICE_ALLIVIA+"/membresia/valida/suscripcion/usuario/"+usuario.idUsuario+"/transaccion/"+idSuscripcion;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const guardarMembresia =async (usuario:UsuarioLogin,membresia:DTOMembresia):Promise<DTOMembresia> => {
  const url = SERVICE_ALLIVIA+"/membresia";
  const response = await fetch(url,{
    body: JSON.stringify(membresia),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el estudio medico: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerDatosOpenPay = async (usuario:UsuarioLogin):Promise<DTOOpenPay> =>{
  const url = SERVICE_ALLIVIA+"/openPay/datos";  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const realizarPagoOpenPay= async (usuario:UsuarioLogin,sessionid:string):Promise<DTOCargo>=>{
  const url = SERVICE_ALLIVIA+"/membresia/pagar/usuario/"+usuario.idUsuario+"/"+sessionid;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });

  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const compartirMembresiaPremium =async (usuario:UsuarioLogin,solicitud:DTOSolicitudCompartirMembresia):Promise<DTOSolicitudCompartirMembresia> => {
  const url = SERVICE_ALLIVIA+"/membresia/compartir";
  const response = await fetch(url,{
    body: JSON.stringify(solicitud),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al compartir membresia: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerSolicitudCompartirMembresiaPremium =async (usuario:UsuarioLogin):Promise<DTOSolicitudCompartirMembresia> => {
  const url = SERVICE_ALLIVIA+"/membresia/solicitud/compartir/"+usuario.idUsuario;
  const response = await fetch(url,{    
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "Get"
  });
  if(!response.ok){
    throw Error("error al compartir membresia: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerSolicitudRecibidaMembresiaPremium =async (usuario:UsuarioLogin):Promise<DTOSolicitudCompartirMembresia> => {
  const url = SERVICE_ALLIVIA+"/membresia/solicitud/recibida/"+usuario.idUsuario;
  try{
    const response = await fetch(url,{    
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "Get"
    });
    if(!response.ok){
      throw Error("error al compartir membresia: "+response.statusText);
    }
  
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const aceptarSolicitudRecibidaMembresiaPremium =async (usuario:UsuarioLogin):Promise<DTOSolicitudCompartirMembresia> => {
  const url = SERVICE_ALLIVIA+"/membresia/solicitud/recibida/aceptar/"+usuario.idUsuario;
  const response = await fetch(url,{    
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "Get"
  });
  if(!response.ok){
    throw Error("error al compartir membresia: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const rechazarSolicitudRecibidaMembresiaPremium =async (usuario:UsuarioLogin):Promise<DTOSolicitudCompartirMembresia> => {
  const url = SERVICE_ALLIVIA+"/membresia/solicitud/recibida/rechazar/"+usuario.idUsuario;
  const response = await fetch(url,{    
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "Get"
  });
  if(!response.ok){
    throw Error("error al compartir membresia: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const eliminarInvitadoMembresia =async (usuario:UsuarioLogin):Promise<DTOSolicitudCompartirMembresia> => {
  const url = SERVICE_ALLIVIA+"/membresia/eliminar/invitado/"+usuario.idUsuario;
  const response = await fetch(url,{    
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "Delete"
  });
  if(!response.ok){
    throw Error("error al compartir membresia: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}



