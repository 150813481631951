import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonImg,
  IonButton,
  IonLabel,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonModal
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/hidratacion.css';
import { Redirect } from 'react-router';
import { leerPesoUltimo } from '../services/signosVitales.service';
import { guardarHidratacion, leerHidratacionHoy } from '../services/hidratacion.service';
import { DTOHidratacion, DTOHidratacionRegistro, DTOSignoPeso } from '../services/AlliviaInterfaces';
import { leerNotificaciones } from '../services/notificaciones.service';
import { VALOR_VASO } from '../constantes';

const Hidratacion: React.FC = () => {
  const user = useLoginContext();
  const {parametros} = useParametros();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [numLitros,setNumLitros] = useState(0.0);
  const [peso,setPeso]=useState(0);
  const [hidratacion,setHidratacion]  = useState<DTOHidratacion>();
  const [notificar,setNotificar] = useState("false");
  
  const [modalSabiasQue,setModalSabiasQue] = useState(false);
  

  const calcularLitros= async ()=>{
    setEstatus({loading:true,leer:false});
    let valorLitros=calculaFormulaLitros(peso)
    let tempHidrat={...hidratacion,cantidadAgua:valorLitros,peso:peso};
    await guardarHidratacion(user,tempHidrat);
    await leerHidratacionHoy(user,parametros.cuidador.idCuidador).then((hdri)=>{
     setHidratacion(hdri);
    });
    setNumLitros(valorLitros);
    setEstatus({loading:false,leer:false});
  }

  const calculaFormulaLitros = (peso:number)=>{
    if(peso>0){
      let litro = peso*35;
      litro = litro/1000;
      return parseFloat(litro.toFixed(1));
    }
    return 0;
  }

  const actualizarNotificaciones= async (noti:string)=>{
    setEstatus({loading:true,leer:false});
    console.log(noti+"!="+notificar);
    if(noti!=notificar){       
      let hid:DTOHidratacion = {...hidratacion,notificaciones:noti=="true"}
      await guardarHidratacion(user,hid);
      await leerHidratacionHoy(user,parametros.cuidador.idCuidador).then((hdri)=>{
        setHidratacion(hdri);
      });
        setNotificar(noti);
        if(noti==='true'){
          setMsgToast({showToast:true,statusMessage:"Recibiras recordatorios para beber agua "});
        }else{
          setMsgToast({showToast:true,statusMessage:"Se cancelaron los recordatorios "});
        }
    }
    sincronizaNotificaciones();
    setEstatus({loading:false,leer:false});  
  }

  const sincronizaNotificaciones=async ()=>{    
    await leerNotificaciones(user,parametros.cuidador.idCuidador)
  }



  const agregarConsumoAgua = async()=>{
    setEstatus({loading:true,leer:false});
    let registro:DTOHidratacionRegistro = {
      idHidratacion:hidratacion.idHidratacion,
      cantidad:VALOR_VASO,
    }
    hidratacion.hidratacionRegistros.push(registro);
    let resultado = await guardarHidratacion(user,hidratacion);
    if(resultado.idHidratacion!=null){        
      await leerHidratacionHoy(user,parametros.cuidador.idCuidador).then((hdri)=>{
        setHidratacion(hdri);
      });      
    }
    setEstatus({loading:false,leer:false});
  }
  

  const leerInicio = async() =>{
    setEstatus({loading:true,leer:false});
    // Leer datos de hidratacion
    let hidrat:DTOHidratacion=null;
    await leerHidratacionHoy(user,parametros.cuidador.idCuidador).then((hdri)=>{
      console.log(hdri);
      hidrat= hdri
    });

    // Leer datos de peso
    let pes:DTOSignoPeso = null
    await leerPesoUltimo(user,parametros.cuidador.idPersona).then((ps)=>{
      pes = ps;
    })

    let valorPeso = 0;
    let actualiza = false;
    if(hidrat&&pes){
      console.log(hidrat,pes);
      if(hidrat.fechaModificacion>(pes.fecha+"T"+pes.hora)){
        valorPeso=hidrat.peso;
      }else{
        valorPeso=pes.peso;
        actualiza=true;
      }
    }else if(hidrat){
      valorPeso=hidrat.peso;
    }else if(pes){
      valorPeso=pes.peso;
    }

    if(!hidrat||actualiza){
      let hidratNuevo:DTOHidratacion=null
      if(actualiza){
        hidratNuevo={...hidrat,
        peso: valorPeso,
        cantidadAgua:calculaFormulaLitros(valorPeso)
        } 
      }else{
        hidratNuevo={
          idCuidador:parametros.cuidador.idCuidador,
          peso: valorPeso,
          cantidadAgua:calculaFormulaLitros(valorPeso)
        }
      }

      let resultado = await guardarHidratacion(user,hidratNuevo);
      if(resultado.idHidratacion!=null){        
        await leerHidratacionHoy(user,parametros.cuidador.idCuidador).then((hdri)=>{
          console.log(hdri);
          hidrat= hdri
        });
        
      }
    }

    setHidratacion(hidrat);
    setNotificar(hidrat.notificaciones+"");
    if(valorPeso!=null){
      setPeso(valorPeso);
      setNumLitros(calculaFormulaLitros(valorPeso));
    }
    setEstatus({loading:false,leer:false});
  }
  
  
  useEffect(()=>{
    if(parametros){
      leerInicio();
    }
  },[parametros]);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if(!parametros){
    return <Redirect to="/" />
  }  

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Cuida de ti</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
        <div className='hidratacion'>
            <div className='header' >
              <div className='imagen'>
                <IonImg src='./assets/iconSituacionEmocional.png'/>
                <span>Hidratación</span>
              </div>
              <div className='titulo'>
                <h1>Hidratate correctamente</h1>
              </div>
            </div>
            <div className='contenido'>
              <div id="sabias_que">
                  <IonButton fill='clear' id="trigger-button" onClick={()=>setModalSabiasQue(true)}>
                    ¿Sabes por qué debes hidratarte correctamente? Da clic aquí  
                  </IonButton>                  
              </div>
              <div className='titulo'>
                ¿Cuál es la cantidad de agua que debes tomar al día?
              </div>
              <div className='mensaje'>
              <p>En <span className="apremium">allivia</span> te ayudamos a que estes hidratado correctamente según las necesidades de tu organismo.</p>
              <p>Utiliza la siguiene fórmula, para saber la cantidad de agua que debes consumir diariamente. Si así lo deseas puedes agregar el registro de agua diario para logar tu meta.</p>
              </div>
              <div className='formula'>
                <div className='titulo'>
                  Fórmula
                </div>
                <div className='operacion'>                    
                    <div className='opPeso'>
                      Peso (Kg):
                      <IonInput type='number' inputMode='numeric' value={peso} onIonChange={(event)=>{setPeso(parseFloat(event.detail.value))}}></IonInput>
                    </div>
                    <div  className='opConst'>
                    <IonLabel>x 35=</IonLabel>
                    </div>
                    <div  className='opResult'>
                        {numLitros}l
                      <IonImg src='./assets/botellita.png'></IonImg>
                    </div>                                       
                </div>                  
                <div className='boton-calcula'>
                  <IonButton onClick={()=>calcularLitros()}>
                    <IonLabel>Calcular</IonLabel>
                  </IonButton>
                </div>
              </div>              
            </div>
            <div className='boton-agregar'>
              <IonButton fill='clear' shape='round' routerLink='/allivia/consumoAgua' onClick={()=>{agregarConsumoAgua()}}>
                <IonImg src='./assets/agregarHidratacion.png'></IonImg>
              </IonButton>
            </div>
            <div hidden={!parametros}  className='pregunta'>
              <p>Desea recibir un recordatorio durante el día para la toma de agua:</p>
              <IonRadioGroup value={notificar} onIonChange={(e)=>{actualizarNotificaciones(e.detail.value)}}>
                <div>
                  <div>
                    <IonLabel>Si</IonLabel>
                    <IonRadio mode="md"  value="true" ></IonRadio>
                  </div>
                  <div>
                    <IonLabel>No</IonLabel>
                    <IonRadio mode="md"  value="false" ></IonRadio>
                  </div>
                </div>
              </IonRadioGroup>
            </div>
          </div>       
                          
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
          <IonModal backdropDismiss={false}  isOpen={modalSabiasQue} className="ion-modal-sabiasque">
            <IonContent>
              <p>
                Uno de los procesos que debe vigilarse siempre, es la cantidad de agua que se consume al día. 
              </p>
              <p>
                 Una Hidratación adecuada ayuda a mantener lubricados los músculos y articulaciones, ayuda a eliminar lo que no necesitas y la energía en tu cuerpo aumenta.              
              </p> 
              <p>
               <span className="apremium">allivia</span>   quiere acompañarte a hacer de la hidratación un hábito lo suficientemente sólido para mantener tu cuerpo sano. 
               <br/>
               <br/>
               <span className="rosa">
                    ¡Venga, pruébalo! 
                </span>
              </p>  
              <IonButton expand='block' onClick={()=>{setModalSabiasQue(false)}}>
                Cerrar
              </IonButton>
            </IonContent>
          </IonModal>
        </IonContent>
              
      </IonPage>
    </>
  );
};

export default Hidratacion;
