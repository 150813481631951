import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { logOut } from 'ionicons/icons';
import React, { useContext, useEffect, useState} from 'react';
import { LoginContext, useParametros } from '../hooks/login';
import { cerrarSesion } from '../services/login.service';
import { types } from '../types/types';
import '../theme/menu.css'
import { menuController } from '@ionic/core/components';


const Menu: React.FC = () => {
  const {dispatch} = useContext(LoginContext);
  const {parametros} = useParametros();
  const [premium,setPremium]=useState(false);
  const [premiumDuo,setPremiumDuo]=useState(false);
  const [invitacionPendiente,setInvitacionPendiente]=useState(false);


  const cerrarMenu = async () =>{
    menuController.toggle();
  }

  const handleCerrarSesion = async () => {
    try{
      cerrarSesion();
      const action = {
        type: types.logout,
        payload: {loggedIn:'false'}
      }
      dispatch(action);
    }catch(error){
      console.log("Error al cerar la sesión: ",error);
    }    
  }  

  useEffect(() =>{
    if(parametros && parametros.perfil){     
      setPremium(parametros.perfil.perfil==='PREMIUM');      
      setPremiumDuo(parametros.perfil.perfil==='PREMIUM_DUO');
    }
    if(parametros && parametros.invitacionPremium){     
      setInvitacionPendiente(parametros.invitacionPremium!=null?true:false);  
    }    
  },[parametros]);

  return (
    <>
      <IonMenu  className="allivia-menu" contentId="menuContent" >
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>Menú</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
        <IonList>
          <IonItem hidden={premium}  routerLink='/allivia/alliviaPremium' onClick={()=>cerrarMenu()}><IonLabel>Suscríbete<br/>allivia premium</IonLabel></IonItem> 
          <IonItem hidden={!premium} routerLink='/allivia/soyAlliviaPremium' onClick={()=>cerrarMenu()}><IonLabel>Soy allivia premium</IonLabel></IonItem> 
          <IonItem hidden={premium || !invitacionPendiente}  routerLink='/allivia/miInvitacionPremium' onClick={()=>cerrarMenu()}><IonLabel>Mi invitación PREMIUM</IonLabel></IonItem>
          <IonItem hidden={!(premium || premiumDuo)} routerLink='/allivia/misPacientes' onClick={()=>cerrarMenu()}>Mis pacientes</IonItem>
          {/* <IonItem hidden={!premium} routerLink='/allivia/promocionesFarmacia' onClick={()=>cerrarMenu()}><IonLabel>Promociones de Farmacia(s)</IonLabel></IonItem> */}
          <IonItem hidden={!premium} routerLink='/allivia/pagoAlliviaPremium' onClick={()=>cerrarMenu()}><IonLabel>Vigencia de membresia</IonLabel></IonItem>
          <IonItem hidden={!premium || true} routerLink='/allivia/blogAllivia' onClick={()=>cerrarMenu()}><IonLabel>Blog allivia</IonLabel></IonItem>
          <IonItem routerLink='/allivia/derechosCuidador' onClick={()=>cerrarMenu()}>Derechos del cuidador</IonItem>
          <IonItem routerLink='/allivia/acercaDe' onClick={()=>cerrarMenu()}>Acerca de nosotros</IonItem>
          {/* <IonItem routerLink='/allivia/configuracion' >Configuración</IonItem> */}
          <IonItem routerLink='/allivia/preguntasFrecuentes' onClick={()=>cerrarMenu()}>Preguntas frecuentes</IonItem>
          <IonItem routerLink='/allivia/buzonSugerencias' onClick={()=>cerrarMenu()}>Buzón de sugerencias</IonItem>
          <IonItem routerLink='/allivia/contacto' onClick={()=>cerrarMenu()}>Contacto y soporte</IonItem>
          <IonItem routerLink='/allivia/avisoPrivacidad' onClick={()=>cerrarMenu()}>Aviso de privacidad</IonItem>
          <IonItem routerLink='/allivia/terminosCondiciones' onClick={()=>cerrarMenu()}>Términos y condiciones</IonItem>          
          <IonItem routerLink='/allivia/cuentaUsuario' onClick={()=>cerrarMenu()}>Cuenta de Usuario</IonItem>
          {/* <IonItem hidden={parametros?parametros.cuidador.email != 'rcarpiogo300@msn.com':true} routerLink='/allivia/admin/dev' onClick={()=>cerrarMenu()}>Dev</IonItem> */}
          <IonItem onClick={handleCerrarSesion} >Cerrar sesión
            <IonIcon slot='end' icon={logOut} ></IonIcon>
          </IonItem>
        </IonList>
        <div className='allivia-version'>
          <span><b>Versión:</b> {parametros&&parametros.versionNumber?parametros.versionNumber:""}</span>
        </div>
        <div className='allivia-caregivers'>
          <span>by</span>
          <span>caregiver solutions</span>
        </div>
        </IonContent>
      </IonMenu>
      <IonRouterOutlet id="menuContent"></IonRouterOutlet>
    </>
  );
};

export default Menu;
