import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/metodoPago.css';
import { Redirect } from 'react-router';



const RespiracionMsg: React.FC = () => {
  const user = useLoginContext();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  
  useEffect(()=>{    
    setEstatus({loading:false,leer:false});
  },[]);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Respiración</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div>
            <div className="msg_respiracion">
              <p>
                Si en el día, hay algo que te molesta o te incomoda, vuelve a conectarte contigo, con la energía que te brinda, tu propio cuerpo a través de la respiración. 
              </p>
              <p>
               <span className="apremium">
                 allivia
              </span> <span> </span>te compaña con este pequeño ejercicio, por al menos un minuto, siéntete en confianza de utilizarlo las veces que quieras en el día. 
              </p>

              <p>
                Esto sólo es el comienzo para empezarte a cuidar.
              </p>

              
            </div>
            <IonButton className="respira" expand='block' routerLink='/allivia/respiracion'>
             Comenzar ejercicio de respiración
            </IonButton>
          </div>                              
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
        </IonContent>        
      </IonPage>
    </>
  );
};

export default RespiracionMsg;
