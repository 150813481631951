import { SERVICE_ALLIVIA} from "../properties";
import { DTOCatAfiliacionSocial, DTOCatAseguradora, DTOCatParentesco, DTOCatTipoDocumento, DTOCatTipoSangre, UsuarioLogin } from "./AlliviaInterfaces";


export const leerCatAfiliacionSocial = async (usuario:UsuarioLogin):Promise<Array<DTOCatAfiliacionSocial>> =>{
  const url = SERVICE_ALLIVIA+"/catAfiliacionSocial/activo";  
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("catAfiliacionSocial",JSON.stringify(json))
    //console.log("cat-afiliacion:",json);    
    return json;
  }catch(err){
    console.log("error",err);
    let catTAfiliacion :Array<DTOCatAfiliacionSocial>;
    if(localStorage.catAfiliacionSocial){
      catTAfiliacion=JSON.parse(localStorage.catAfiliacionSocial);        
    }
    return catTAfiliacion
  }

}

export const leerCatAseguradora = async (usuario:UsuarioLogin):Promise<Array<DTOCatAseguradora>> =>{
  const url = SERVICE_ALLIVIA+"/catAseguradora/activo";  
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("catAseguradora",JSON.stringify(json))
    //console.log("cat-aseguradora:",json);    
    return json;
  }catch(err){
    console.log("error",err);
    let catAseguradora :Array<DTOCatAseguradora>;
    if(localStorage.catAseguradora){
      catAseguradora=JSON.parse(localStorage.catAseguradora);        
    }
    return catAseguradora
  }
}

export const leerCatTipoDocumento= async (usuario:UsuarioLogin):Promise<Array<DTOCatTipoDocumento>> =>{
  const url = SERVICE_ALLIVIA+"/catTipoDocumento/activo";  
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("catTipoDocumento",JSON.stringify(json))
    //console.log("cat-t-documento:",json);    
    return json;
  }catch(err){
    console.log("error",err);
    let catTDocumento :Array<DTOCatTipoDocumento>;
    if(localStorage.catTipoDocumento){
      catTDocumento=JSON.parse(localStorage.catTipoDocumento);        
    }
    return catTDocumento
  }
}


export const leerCatTipoSangre = async (usuario:UsuarioLogin):Promise<Array<DTOCatTipoSangre>> =>{
  const url = SERVICE_ALLIVIA+"/catTipoSangre/activo";
  try{ 
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("catTipoSangre",JSON.stringify(json))
    //console.log("cat-tipoSangre:",json);    
    return json;
  }catch(err){
    console.log("error",err);
    let catTSangre :Array<DTOCatTipoSangre>;
    if(localStorage.catTipoSangre){
      catTSangre=JSON.parse(localStorage.catTipoSangre);        
    }
    return catTSangre
  }
}

export const leerCatParentesco = async (usuario:UsuarioLogin):Promise<Array<DTOCatParentesco>> =>{
    const url = SERVICE_ALLIVIA+"/catParentesco/activo";  
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("catParentesco",JSON.stringify(json));
    console.log("cat-parentesco:",json);    
    return json;
  }catch(err){
    console.log("error",err);
    let catTSangre :Array<DTOCatParentesco>;
    if(localStorage.catParentesco){
      catTSangre=JSON.parse(localStorage.catParentesco);        
    }
    return catTSangre
  }
}

