import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router';
import { useLoginContext } from '../hooks/login';
import '../theme/preguntasfrecuentes.css';



const PreguntasFrecuentes: React.FC = () => {
  const { loggedIn } = useLoginContext(); 

  //console.log(`rendering from loginPage with loggedIn=${loggedIn}`);
  if (!loggedIn) {
    return <Redirect to="/allivia/alliviaHome" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton text=""  color='primary' defaultHref="/"/>
          </IonButtons>
          <IonTitle>Preguntas Frecuentes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">        
        <div id="preguntas_fre">
            <div className='pregunta'>
              <h1>¿Qué es allivia?</h1>
              <p>
                Es una marca registrada por Caregiver Solutions, donde el principal punto de contacto es la app denominada 
                <span className="apremium">allivia,</span>que está destinada a resolver el principal problema de los cuidadores y que genera su mayor estrés, es decir, el no poder llevar un seguimiento adecuado de la patología del paciente, ordenar sus tratamientos y estudios que pueden ser tan diversos que llegan a generar depresión, ansiedad y que terminan por deteriorar el estado de ánimo del Cuidador. 
               <br /> <br />
               <span className="apremium"> allivia </span> es la app pensada para llevar un registro del paciente ordenado y sobre todo orientando al cuidador para que no olvide nada acerca del tratamiento y seguimiento de su paciente. Lo relevante es que el seguimiento del estado del cuidador, también será posible de registrar y llevar a lo largo del tiempo para tener un respaldo personal.
              </p>
            </div>
            <div className='pregunta'>
              <h1>¿Por qué se pretende visibilizar a los cuidadores de pacientes familiares?</h1>
              <p>
                Durante muchos años, los Cuidadores de pacientes familiares, han representado una fuerza laboral muy importante en la sociedad para dar seguimiento, continuidad y apoyo en el término de tratamientos y este apoyo ha sido prácticamente invisibilizado, dando por hecho, que el cuidado es una obligación familiar, quizás por un tema cultural, pero también por un tema de idiosincrasia, ya que el 85% de las personas que cuidan pacientes, son mujeres. 
                <br /> <br />
                Este trabajo no remunerado y llevado a cabo en muchos casos, de manera solitaria, ha provocado problemas de salud en los mismos cuidadores de tipo emocional, físico y nutricional, lo cual, debe ser tratado mientras la piramide poblacional sigue siendo considerada joven, ya que los cuidadores de hoy, serán los que probablemente deban ser cuidados mañana. No habrá quien ayude de manera natural y con la misma fuerza, si no logramos hacer conciencia en nuestra salud y en compartir el cuidado como un hábito familiar.  Es por ello que en Caregiver Solutions consideramos cambiar la dinámica, para hacer más sencillo el cuidado futuro desde hoy. 
              </p>
            </div>
            <div className='pregunta'>
              <h1>¿Para qué sirve la app?</h1>
              <p>
               <span className="apremium"> allivia </span>  es la app pensada para llevar un registro del paciente ordenado, pero sobre todo, pensado para facilitar el registro al cuidador, para que no olvide nada acerca del tratamiento y seguimiento de su paciente. 
                Lo relevante es que el seguimiento del estado del cuidador, también será posible de registrar y llevar a lo largo del tiempo para tener un respaldo personal.
                <br /> <br />
                Ambos podrán llevar el registro de recetas, citas, diagnósticos, fechas de compra de medicamentos, y documentos importantes que pueden tener al alcance de un click, para poder compartir con quien deseen, sobretodo en esos momentos de emergencia que en algún momento inesperado, se pueden presentar.  
              </p>
            </div>
            <div className='pregunta'>
              <h1>¿Por qué existe una versión premium?</h1>
              <p>
                Nuestro compromiso es mantener el uso de la app de manera gratuita, el hecho de hacer una versión premium surge de la necesidad de lograr un hábito en las familias mexicanas para llevar un control a nivel familiar de la salud de todos. Compartir la app entre dos miembros de la familia y algunos beneficios más de seguimiento, lo que hará que el costo premium sea realmente absorbido con un servicio integral por parte de &nbsp;
                <span className="apremium">allivia. </span>
                <br /> <br />
                Por otro lado, este costo permite continuar con los esfuerzos por llevar a los usuarios a tener ideas todo el tiempo, de cómo cuidarse y cuidar a los demás.
              </p>
            </div>
            <div className='pregunta'>
              <h1>¿Qué me garantiza que mis datos están protegidos?</h1>
              <p>
                <span className="apremium">allivia</span> no accede a la información personal, ya que se encuentra encriptada en el celular del usuario y cuenta con el respaldo de Caregiver Solutions, empresa sujeta a los más estrictos estándares de seguridad y privacidad que lleven a los usuarios a mantener el control de su información y sobre todo, con quien pueden compartirla.
                <br /> <br />
                Asimismo, informamos que lo que <span className="apremium">allivia</span> utiliza, son estadísticas generales de uso de la app, con el fin de siempre vigilar y monitorear mejoras para el usuario. 
                Te invitamos a revisar el Aviso de Privacidad y nuestros términos y condiciones.
              </p>
            </div>
            <div className='pregunta'>
              <h1>¿Cómo puedo hacer el pago?</h1>
              <p>
                Utilizamos la mejor tecnología para mantener tu privacidad protegida y es por ello que hemos elegido openpay, que te permite realizar los pagos en línea, con múltiples formas y opciones de pago. Recuerda que con tu plan anual ahorras dos meses en tu membresía.
              </p>
            </div>
            <div className='pregunta'>
              <h1>¿Cómo puedo facturar?</h1>
              <p>
                En el momento de hacer tu suscripción se te pedirán tus datos de facturación en caso de que decidas solicitarlo. Es importante mencionar que las facturas deben ser expedidas en el mismo mes en que se realiza el pago por instrucciones del SAT, por lo que te pedimos ingresar tus datos en el mismo momento. En caso de que haya algún error, debe ser corregido de la misma manera en el mes que se realizó el pago. 
              </p>
            </div>
            <div className='pregunta'>
              <h1>¿El cargo recurrente se puede modificar? </h1>
              <p>
                Si, la forma de pago la determinas tú y es importante para nosotros que esto no te genere ningún problema, por lo que el cambio es muy sencillo con openPay.
              </p>
            </div>
            <div className='pregunta'>
              <h1>¿Qué pasa con mis datos cuando dejo de pagar la versión premium?</h1>
              <p>
                Todos los datos que ingreses te pertenecen, por lo que si dejas de utilizar la versión premium, esos datos podrás recuperarlos y transmitirlos vía PDF, WhatsApp o E-mail. Antes de que se cumpla la fecha de terminación de la membresía, tendrás un recordatorio para realizar dicho movimiento.  
              </p>
            </div>
            <div className='pregunta'>
              <h1>¿Cuánto tiempo dura activa mi sesión?</h1>
              <p>
                Tu sesión durará iniciada 15 días abierta, una vez transcurrido este tiempo y por seguridad, deberás iniciar sesión nuevamente.
              </p>
            </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PreguntasFrecuentes;
