import {
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import { add, camera, cloudDownload, cloudUpload, document, pencil, personAdd, trash } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import { useLoginContext } from '../hooks/login';
import { URL_ALLIVIA_DOCS, URL_CURP } from '../properties';
import { DTOAlergia, DTOCatAfiliacionSocial, DTOCatAseguradora, DTOCatParentesco, DTOCatTipoSangre, DTOCuidador, DTODiagnostico, DTODocumento, DTOPaciente, DTOSeguroBeneficiario, FileTemporalDoc } from '../services/AlliviaInterfaces';
import { leerCatAfiliacionSocial, leerCatAseguradora, leerCatParentesco, leerCatTipoSangre } from '../services/catalogos.service';
import { leerCuidador } from '../services/cuidador.service';
import { guardarPaciente } from '../services/paciente.service';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { Camera, CameraResultType } from '@capacitor/camera';
import '../theme/nuevoPaciente.css';
import { abrirArchivoBrowser, consultaTipoImagen, eliminarDocumento, guardarDocumentos, leerArchivo, leerFileBlob, leerImagenDataURL } from '../services/utils.service';
import { IMG_AVATAR, IMG_CARNET, IMG_ESTUDIO_CLINICO, IMG_INE, IMG_PSGMM, IMG_P_SEGURO_VIDA, IMG_RECETA_MEDICA, IMG_VAC, MENSAJE_CARGA_ARCHIVOS } from '../constantes';

const initPaciente = (): DTOPaciente => {
  return { nombre: '' }
}

const initCuidador = (): DTOCuidador => {
  return { nombre: '' }
}

const NuevoPaciente: React.FC = () => {
  const user = useLoginContext();
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [paciente, setPaciente] = useState<DTOPaciente | undefined>(initPaciente());
  const [cuidador, setCuidador] = useState<DTOCuidador | undefined>(initCuidador());
  const [modalDiagnostico, setModalDiagnostico] = useState(false);
  const [alergiaDescripcion, setAlergiaDescripcion] = useState("");
  const [diagnosticoDescripcion, setDiagnosticoDescripcion] = useState("");
  const [catTipoSangre, setCatTipoSangre] = useState<Array<DTOCatTipoSangre> | undefined>();
  const [catParentesco, setCatParentesco] = useState<Array<DTOCatParentesco> | undefined>();
  const [catAfiliacionSocial, setCatAfiliacionSocial] = useState<Array<DTOCatAfiliacionSocial> | undefined>();
  const [catAseguradora, setCatAseguradora] = useState<Array<DTOCatAseguradora> | undefined>();
  const [tempBeneficiario, setTempBeneficiario] = useState<string>();
  const [tempPorcentaje, setTempPorcentaje] = useState<number>();
  const [diganosticoTemp, setDiagnosticoTem] = useState<DTODiagnostico>();
  const fileInputAvatarRef = useRef<HTMLInputElement>();
  const fileInputDocVacRef = useRef<HTMLInputElement>();
  const fileInputDocIneRef = useRef<HTMLInputElement>();
  const fileInputDocCarnetRef = useRef<HTMLInputElement>();
  const fileInputDocSegGMMRef = useRef<HTMLInputElement>();
  const fileInputDocSegVidaRef = useRef<HTMLInputElement>();
  const [fileAvatar, setFileAvatar] = useState<FileTemporalDoc>({ url: './assets/avatar.png' });
  const [fileDocVac, setFileDocVac] = useState<FileTemporalDoc>();
  const [fileDocCarnet, setFileDocCarnet] = useState<FileTemporalDoc>();
  const [fileDocIne, setFileDocIne] = useState<FileTemporalDoc>();
  const [fileDocSegGMM, setFileDocSegGMM] = useState<FileTemporalDoc>();
  const [fileDocSegVida, setFileDocSegVida] = useState<FileTemporalDoc>();
  const [subirIne, setSubirIne] = useState(false);
  const [subirVac, setSubirVac] = useState(false);
  const [subirCarnet, setSubirCarnet] = useState(false);
  const [subirGMM, setSubirGMM] = useState(false);
  const [subirSvida, setSubirSvida] = useState(false);
  const [imagenOptions] = useState({
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.Uri,
    promptLabelHeader: "Foto",
    promptLabelCancel: "Cancelar",
    promptLabelPhoto: "Galeria",
    promptLabelPicture: "Cámara"
  });
  const [vprevIne, setVprevIne] = useState("");
  const [vprevVac, setVprevVac] = useState("");
  const [vprevCarnet, setVprevCarnet] = useState("");
  const [vprevGMM, setVprevGMM] = useState("");
  const [vprevSvida, setVprevSvida] = useState("");
  const [alergiaTemp, setAlergiaTem] = useState<DTOAlergia>();
  const [modalAlergia, setModalAlergia] = useState(false);
  const [modalBeneficiarios, setModalBeneficiarios] = useState(false);
  const [porcentajeTotal, setPorcentajeTotal] = useState(0);

  const takePictureAvatar = async () => {
    const image = await Camera.getPhoto(imagenOptions);

    var imageUrl = image.webPath;
    console.log("imgAvatar", image);
    setFileAvatar({ url: imageUrl, fileName: "avatar." + image.format, mimeType: "image/" + image.format });
  };

  const takePicture = async (tipoImagen: number) => {
    setEstatus({ loading: true, leer: false });
    const image = await Camera.getPhoto(imagenOptions);

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    var imageUrl = image.webPath;

    // Can be set to the src of an image now
    // imageUrl;
    let { fileName } = consultaTipoImagen(tipoImagen);
    let fileDocIne = { url: imageUrl, fileName: fileName + image.format, mimeType: "image/" + image.format };
    switch (tipoImagen) {
      case IMG_INE:
        setFileDocIne(fileDocIne);
        setSubirIne(false);
        ; break;
      case IMG_P_SEGURO_VIDA:
        setFileDocSegVida(fileDocIne);
        setSubirSvida(false);
        ; break;
      case IMG_VAC:
        setFileDocVac(fileDocIne);
        setSubirVac(false);
        ; break;
      case IMG_AVATAR:
        ; break;
      case IMG_PSGMM:
        setFileDocSegGMM(fileDocIne);
        setSubirGMM(false);
        ; break;
      case IMG_CARNET:
        setFileDocCarnet(fileDocIne);
        setSubirCarnet(false);
        ; break;
      case IMG_ESTUDIO_CLINICO:
        ; break;
      case IMG_RECETA_MEDICA:        
        ; break;
    }  
    setEstatus({ loading: false, leer: false });  
    setMsgToast({ showToast: true, statusMessage: MENSAJE_CARGA_ARCHIVOS });    
  };  

  const handleEliminarDocVacuna = (doc: DTODocumento) => {
    setEstatus({ loading: true, leer: false });
    if (fileDocVac != null) {
      URL.revokeObjectURL(fileDocVac.url);
      setVprevVac("");
      setFileDocVac(null);
    } else {
      doc.estatus = 0;
      setPaciente(paciente);
    }
    fileInputDocVacRef.current.value ="";
    console.log("eliminar doc vacuna", paciente);
    setEstatus({ loading: false, leer: false });
  }

  const handleEliminarDocIne = (doc: DTODocumento) => {
    setEstatus({ loading: true, leer: false });
    if (fileDocIne != null) {
      URL.revokeObjectURL(fileDocIne.url);
      setVprevIne("");
      setFileDocIne(null);
    } else {
      doc.estatus = 0;
      setPaciente(paciente);
    }
    console.log(fileInputDocIneRef.current.value);
    fileInputDocIneRef.current.value ="";
    console.log("eliminar doc ine", paciente,URL.length);
    setEstatus({ loading: false, leer: false });
  }

  const handleEliminarDocCarnet = (doc: DTODocumento) => {
    setEstatus({ loading: true, leer: false });
    if (fileDocCarnet != null) {
      URL.revokeObjectURL(fileDocCarnet.url);
      setVprevCarnet("");
      setFileDocCarnet(null);
    } else {
      doc.estatus = 0;
      setPaciente(paciente);
    }
    fileInputDocCarnetRef.current.value ="";
    console.log("eliminar doc carnet", paciente);
    setEstatus({ loading: false, leer: false });
  }

  const handleEliminarDocSegGMM = (doc: DTODocumento) => {
    setEstatus({ loading: true, leer: false });
    if (fileDocSegGMM != null) {
      URL.revokeObjectURL(fileDocSegGMM.url);
      setVprevGMM("");
      setFileDocSegGMM(null);
    } else {
      doc.estatus = 0;
      setPaciente(paciente);
    }
    fileInputDocSegGMMRef.current.value ="";
    console.log("eliminar doc seguro GMM", paciente);
    setEstatus({ loading: false, leer: false });
  }

  const handleEliminarDocSegVida = (doc: DTODocumento) => {
    setEstatus({ loading: true, leer: false });
    if (fileDocSegVida != null) {
      URL.revokeObjectURL(fileDocSegVida.url);
      setVprevSvida("");
      setFileDocSegVida(null);
    } else {
      doc.estatus = 0;
      setPaciente(paciente);
    }
    fileInputDocSegVidaRef.current.value ="";
    console.log("eliminar doc seguro Vida", paciente);
    setEstatus({ loading: false, leer: false });
  }

  const abrirArchivo = async (doc: DTODocumento) => {
    let uri = await Filesystem.getUri({
      path: doc.urlDocumento,
      directory: Directory.Documents
    }
    );
    console.log("abir archivo uri:", uri);
    FileOpener.showOpenWithDialog(uri.uri, doc.mimeType).then().catch((e) => {
      console.log("error", e);
      abrirArchivoBrowser(doc).catch((e) => {
        setMsgToast({ showToast: true, statusMessage: "El documento no se encuentra en este dispositivo" });
      });
    });
  }

  const handleSeleccionarDocIne = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    console.log(event);
    setFileDocIne(await leerArchivo(event,IMG_INE));    
    setSubirIne(false);
    setEstatus({ loading: false, leer: false });
    setMsgToast({ showToast: true, statusMessage: MENSAJE_CARGA_ARCHIVOS });
  }

  const handleSeleccionarDocVacuna = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    setFileDocVac(await leerArchivo(event,IMG_VAC));
    setSubirVac(false);
    setEstatus({ loading: false, leer: false });
    setMsgToast({ showToast: true, statusMessage: MENSAJE_CARGA_ARCHIVOS });
  }

  const handleSeleccionarDocCarnet = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    setFileDocCarnet(await leerArchivo(event,IMG_CARNET));
    setSubirCarnet(false);
    setEstatus({ loading: false, leer: false });
    setMsgToast({ showToast: true, statusMessage: MENSAJE_CARGA_ARCHIVOS });
  }

  const handleSeleccionarDocSegGMM = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    setFileDocSegGMM(await leerArchivo(event,IMG_PSGMM));
    setSubirGMM(false);
    setEstatus({ loading: false, leer: false });    
    setMsgToast({ showToast: true, statusMessage: MENSAJE_CARGA_ARCHIVOS });
  }

  const handleSeleccionarDocSegVida = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    setFileDocSegVida(await leerArchivo(event,IMG_P_SEGURO_VIDA));
    setSubirSvida(false);
    setEstatus({ loading: false, leer: false });
    setMsgToast({ showToast: true, statusMessage: MENSAJE_CARGA_ARCHIVOS });
  }

  const handleEliminarDiagnostico = (diag: DTODiagnostico) => {
    setEstatus({ loading: true, leer: false });
    diag.estatus = 0;
    setPaciente(paciente)
    setEstatus({ loading: false, leer: false });
  }

  const handleActualizarDiagnostico = (diag: DTODiagnostico) => {
    setDiagnosticoTem(diag);
    setDiagnosticoDescripcion(diag.descripcion);
    setModalDiagnostico(true);
  }

  const handleEliminarBeneficiario = (benef: DTOSeguroBeneficiario) => {
    setEstatus({ loading: true, leer: false });
    benef.estatus = 0;
    validaPorcentajeTotal(paciente);
    setPaciente(paciente);
    setEstatus({ loading: false, leer: false });
  }


  const handelFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      const file = event.target.files.item(0);
      console.log(file);
      const pictureUrl = URL.createObjectURL(file);
      setFileAvatar({ url: pictureUrl, fileName: file.name, mimeType: file.type });
      console.log("created URL: ", pictureUrl);
    }
  }

  const handleGuardarPaciente = async () => {
    setEstatus({ loading: true, leer: false });
    try {
      let tempPaciente = { ...paciente };
      if(!tempPaciente.nombre||tempPaciente.nombre===null||tempPaciente.nombre===""){
        setMsgToast({ showToast: true, statusMessage: "Ingresa el nombre del paciente" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      if(!tempPaciente.apPaterno||tempPaciente.apPaterno===null||tempPaciente.apPaterno===""){
        setMsgToast({ showToast: true, statusMessage: "Ingresa el apellido paterno del paciente" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      if (fileAvatar != null && (fileAvatar.url.startsWith("blob") || fileAvatar.url.startsWith("http"))) {
        let blob = await leerFileBlob(fileAvatar.url);
        let array = await leerImagenDataURL(blob);

        let temAvatar: DTODocumento = paciente.avatar;
        if (!temAvatar) {
          temAvatar = {
            idPersona: paciente.idPersona,
            descripcion: "avatar",
            urlDocumento: fileAvatar.fileName,
            catTipoDocumento: { idTipoDoc: 4 },
            estatus: 1,
            remoto: true,
            fileStream: array,
            mimeType: fileAvatar.mimeType
          }
        } else {
          temAvatar = { ...temAvatar, fileStream: array, urlDocumento: fileAvatar.fileName, mimeType: fileAvatar.mimeType }
        }
        tempPaciente = { ...tempPaciente, avatar: temAvatar };
      }
      console.log("Guardar documento Vacuna");
      if (fileDocVac != null) {
        let temDocVacuna: DTODocumento = paciente.docVacuna;
        if (!temDocVacuna) {
          temDocVacuna = {
            idPersona: paciente.idPersona,
            descripcion: "vacuna",
            urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocVac.fileName,
            catTipoDocumento: { idTipoDoc: 3 },
            estatus: 1,
            remoto: false,
            mimeType: fileDocVac.mimeType
          }
        } else {
          temDocVacuna = { ...temDocVacuna, urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocVac.fileName, mimeType: fileDocVac.mimeType }
        }
        tempPaciente = { ...tempPaciente, docVacuna: temDocVacuna };

        await guardarDocumentos(fileDocVac);
        URL.revokeObjectURL(fileDocVac.url);
        setFileDocVac(null);
      }
      console.log("Guardar documento Ine");
      if (fileDocIne != null) {
        let temDocIne: DTODocumento = paciente.docIne;
        if (!temDocIne) {
          temDocIne = {
            idPersona: paciente.idPersona,
            descripcion: "Ine",
            urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocIne.fileName,
            catTipoDocumento: { idTipoDoc: 1 },
            estatus: 1,
            remoto: false,
            mimeType: fileDocIne.mimeType
          }
        } else {
          temDocIne = { ...temDocIne, urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocIne.fileName, mimeType: fileDocIne.mimeType }
        }
        tempPaciente = { ...tempPaciente, docIne: temDocIne };
        await guardarDocumentos(fileDocIne);
        URL.revokeObjectURL(fileDocIne.url);
        setFileDocIne(null);
      }
      console.log("Guardar documento Carnet");
      if (fileDocCarnet != null) {
        let temDocCarnet: DTODocumento = paciente.docCarnet;
        if (!temDocCarnet) {
          temDocCarnet = {
            idPersona: paciente.idPersona,
            descripcion: "Carnet",
            urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocCarnet.fileName,
            catTipoDocumento: { idTipoDoc: 6 },
            estatus: 1,
            remoto: false,
            mimeType: fileDocCarnet.mimeType
          }
        } else {
          temDocCarnet = { ...temDocCarnet, urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocCarnet.fileName, mimeType: fileDocCarnet.mimeType }
        }
        tempPaciente = { ...tempPaciente, docCarnet: temDocCarnet };
        await guardarDocumentos(fileDocCarnet);
        URL.revokeObjectURL(fileDocCarnet.url);
        setFileDocCarnet(null);
      }
      console.log("Guardar documento Seguro GMM");
      if (fileDocSegGMM != null) {
        let temDocSegGMM: DTODocumento = paciente.docSeguroGMM;
        if (!temDocSegGMM) {
          temDocSegGMM = {
            idPersona: paciente.idPersona,
            descripcion: "Seguro GMM",
            urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocSegGMM.fileName,
            catTipoDocumento: { idTipoDoc: 5 },
            estatus: 1,
            remoto: false,
            mimeType: fileDocSegGMM.mimeType
          }
        } else {
          temDocSegGMM = { ...temDocSegGMM, urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocSegGMM.fileName, mimeType: fileDocSegGMM.mimeType }
        }
        tempPaciente = { ...tempPaciente, docSeguroGMM: temDocSegGMM };
        await guardarDocumentos(fileDocSegGMM);
        URL.revokeObjectURL(fileDocSegGMM.url);
        setFileDocSegGMM(null);
      }
      console.log("Guardar documento Seguro Vida");
      if (fileDocSegVida != null) {
        let temDocSegVida: DTODocumento = paciente.docSeguroVida;
        if (!temDocSegVida) {
          temDocSegVida = {
            idPersona: paciente.idPersona,
            descripcion: "seguro Vida",
            urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocSegVida.fileName,
            catTipoDocumento: { idTipoDoc: 2 },
            estatus: 1,
            remoto: false,
            mimeType: fileDocSegVida.mimeType
          }
        } else {
          temDocSegVida = { ...temDocSegVida, urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDocSegVida.fileName, mimeType: fileDocSegVida.mimeType }
        }
        tempPaciente = { ...tempPaciente, docSeguroVida: temDocSegVida };
        await guardarDocumentos(fileDocSegVida);
        URL.revokeObjectURL(fileDocSegVida.url);
        setFileDocSegVida(null);
      }

      if (tempPaciente.docIne != null && tempPaciente.docIne.estatus === 0) {
        //eliminar archivo();
        await eliminarDocumento(tempPaciente.docIne);
      }

      if (tempPaciente.docVacuna != null && tempPaciente.docVacuna.estatus === 0) {
        //eliminar archivo();
        await eliminarDocumento(tempPaciente.docVacuna);
      }

      if (tempPaciente.docCarnet != null && tempPaciente.docCarnet.estatus === 0) {
        //eliminar archivo();
        await eliminarDocumento(tempPaciente.docCarnet);
      }

      if (tempPaciente.docSeguroGMM != null && tempPaciente.docSeguroGMM.estatus === 0) {
        //eliminar archivo();
        await eliminarDocumento(tempPaciente.docSeguroGMM);
      }

      if (tempPaciente.docSeguroVida != null && tempPaciente.docSeguroVida.estatus === 0) {
        //eliminar archivo();
        await eliminarDocumento(tempPaciente.docSeguroVida);
      }

      console.log("guardarPaciente: ", tempPaciente);
      agregarNuevoBeneficiario(paciente);
      if (tempPaciente.seguroVida && !tempPaciente.seguroVida.idCatAseguradora) {
        setMsgToast({ showToast: true, statusMessage: "Selecciona una aseguradora" });
        setEstatus({ loading: false, leer: false });
        return
      }
      const pacient = await guardarPaciente(user, tempPaciente);
      setPaciente(pacient);
      setMsgToast({ showToast: true, statusMessage: "Los datos de perfil se guardaron correctamente" });
    } catch (error) {
      setMsgToast({ showToast: true, statusMessage: "Ocurrio un error al guardar los datos" });
    };
    setEstatus({ loading: false, leer: false });
    console.log("boton mi cuidador");
  }


  useEffect(() => {
    const val = async () => {
      return await Promise.all([
        leerCuidador(user.username, user),
        leerCatTipoSangre(user),
        leerCatAfiliacionSocial(user),
        leerCatAseguradora(user),
        leerCatParentesco(user)
      ]);
    }

    if (estatus.leer === true) {
      val().then((result) => {
        setCuidador(result[0]);
        iniciarNuevoPaciente(cuidador.idCuidador);
        setCatTipoSangre(result[1]);
        setCatAfiliacionSocial(result[2]);
        setCatAseguradora(result[3]);
        setCatParentesco(result[4]);
        setEstatus({ loading: false, leer: false });
      });
    }
  }, [user, catTipoSangre, catAfiliacionSocial, catAseguradora, cuidador, estatus]);

  const iniciarNuevoPaciente = (idCuidador: number) => {
    let pacient: DTOPaciente = { idCuidador: idCuidador, estatus: 1 }
    setPaciente(pacient);
  }
  /**
   * Funcion helper para concatenar valores al objeto
   * @param valor 
   */
  const agregarValor = (valor: any) => {
    setPaciente({ ...paciente, ...valor });
  }

  const agregarDiagnostico = () => {
    if (diganosticoTemp != null) {
      let diagnosticos = paciente.diagnosticos;
      let diagnosticosTem: Array<DTODiagnostico> = [];
      diagnosticos.forEach((diag) => {
        if (diag.idDiagnostico === diganosticoTemp.idDiagnostico) {
          diagnosticosTem.push({ ...diganosticoTemp, descripcion: diagnosticoDescripcion });
        } else {
          diagnosticosTem.push(diag);
        }
      });
      console.log("diag", diagnosticos);
      console.log("actualizar: ", paciente);
      setPaciente({ ...paciente, diagnosticos: diagnosticosTem });
      setDiagnosticoTem(null);
    } else {
      let diag: DTODiagnostico = { idPersona: paciente.idPersona, descripcion: diagnosticoDescripcion, estatus: 1 }
      let diagnosticos = paciente.diagnosticos ? paciente.diagnosticos : Array<DTODiagnostico>();
      diagnosticos.push(diag);
      setPaciente({ ...paciente, diagnosticos: diagnosticos });
    }
    setModalDiagnostico(false);
    setDiagnosticoDescripcion('');
  }

  const agregarAlergia = async () => {
    setEstatus({ loading: true, leer: false });
    if (alergiaTemp) {
      let alergias = paciente.alergias;
      let alergTemp: Array<DTOAlergia> = [];
      alergias.forEach((alerg) => {
        if (alerg.idAlergia === alergiaTemp.idAlergia) {
          alergTemp.push({ ...alergiaTemp, alergia: alergiaDescripcion, estatus: 1 });
        } else {
          alergTemp.push(alerg);
        }
      });
      setPaciente({ ...paciente, alergias: alergTemp });
      setAlergiaTem(null);
      console.log("alergias", alergias);
    } else {
      let alerg: DTOAlergia = { idPersona: paciente.idPersona, alergia: alergiaDescripcion, estatus: 1 }
      let alergias = paciente.alergias ? paciente.alergias : Array<DTOAlergia>();
      alergias.push(alerg);
      setPaciente({ ...paciente, alergias: alergias });
      console.log("alergias", alergias);
    }
    setModalAlergia(false);
    setAlergiaDescripcion("");
    setEstatus({ loading: false, leer: false });
  }

  const handleEliminarAlergia = (alerg: DTOAlergia) => {
    setEstatus({ loading: true, leer: false });
    alerg.estatus = 0;
    setPaciente(paciente);
    setEstatus({ loading: false, leer: false });
  }

  const handleActualizarAlergia = async (alerg: DTOAlergia) => {
    console.log(alerg)
    setAlergiaTem(alerg);
    setAlergiaDescripcion(alerg.alergia);
    setModalAlergia(true);
  }

  const cancelarAlergia = async () => {
    setEstatus({ loading: true, leer: false });
    setAlergiaDescripcion("");
    setModalAlergia(false);
    setEstatus({ loading: false, leer: false });
  }

  const agregarSeguroVida = (valor: any) => {
    if (!valor) {
      return;
    }
    if (!(valor.idCatAseguradora || valor.numeroPoliza || valor.vigenciaPoliza || valor.titular)) {
      return
    }
    let segVida = paciente.seguroVida;
    if (!segVida) {
      segVida = { idPersona: paciente.idPersona, seguroBeneficiarios: [], estatus: 1 }
    }
    segVida = { ...segVida, ...valor };
    setPaciente({ ...paciente, seguroVida: segVida });
  }

  const validaPorcentaje = (porcentaje: number) => {
    let po = 0;
    if (paciente != null && paciente.seguroVida != null && paciente.seguroVida.seguroBeneficiarios != null) {
      paciente.seguroVida.seguroBeneficiarios.forEach((b) => {
        if (b.estatus != 0) {
          po = po + b.porcentaje;
        }
      });
    }
    if ((po + porcentaje) > 100) {
      setTempPorcentaje(null);
      setTempBeneficiario(null);
      setMsgToast({ showToast: true, statusMessage: "La suma de porcentajes es mayor al 100%" });
    } else {
      setTempPorcentaje(porcentaje);
    }
  }

  const validaPorcentajeTotal = (tpaci: DTOPaciente) => {
    let po = 0;
    if (tpaci != null && tpaci.seguroVida != null && tpaci.seguroVida.seguroBeneficiarios != null) {
      tpaci.seguroVida.seguroBeneficiarios.forEach((b) => {
        if (b.estatus != 0) {
          po = po + b.porcentaje;
        }
      });
    }
    setPorcentajeTotal(po);
  }

  const agregarNuevoBeneficiario = (pacient: DTOPaciente) => {
    let benef: DTOSeguroBeneficiario[] = [];
    if (!tempBeneficiario) {
      return;
    }
    if (!tempPorcentaje) {
      return;
    }
    //validar si existe un seguro de vida creado
    if (pacient.seguroVida) {
      //leer la lista de beneficiarios
      benef = pacient.seguroVida.seguroBeneficiarios;
      //agregar nuevo beneficiario      
    }
    let idSeguro = pacient.seguroVida ? pacient.seguroVida.idSeguro : null;
    benef.push({ beneficiario: tempBeneficiario, porcentaje: tempPorcentaje, estatus: 1, idSeguro: idSeguro });

    if (!paciente.seguroVida) {
      //Si no existe seguro de vida crear uno
      console.log("benef: ", benef);
      setPaciente({ ...pacient, seguroVida: { idPersona: pacient.idPersona, seguroBeneficiarios: benef } });
      console.log("patient: ", pacient);
    }
    //reiniciar la variable
    setTempBeneficiario(null);
    setTempPorcentaje(null);
  }
  const abrirModalBeneficiarios = async () => {
    setEstatus({ loading: true, leer: false });
    setModalBeneficiarios(true)
    setEstatus({ loading: false, leer: false });
  }

  const cancelarBeneficiarios = async () => {
    setEstatus({ loading: true, leer: false });
    setModalBeneficiarios(false);
    setEstatus({ loading: false, leer: false });
  }

  const agregarBeneficiarios = async () => {
    setEstatus({ loading: true, leer: false });
    try {
      let temPac = { ...paciente };
      let benef: DTOSeguroBeneficiario[] = [];
      if (!tempBeneficiario || tempBeneficiario === "") {
        setMsgToast({ showToast: true, statusMessage: "Ingresa un nombre de beneficiario" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      if (!tempPorcentaje) {
        setMsgToast({ showToast: true, statusMessage: "Selecciona un porcentaje" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      //validar si existe un seguro de vida creado
      if (!temPac.seguroVida) {
        //Si no existe seguro de vida crear uno    
        temPac = { ...temPac, seguroVida: { idPersona: temPac.idPersona, seguroBeneficiarios: benef } };
        benef = temPac.seguroVida.seguroBeneficiarios;
      } else {
        //leer la lista de beneficiarios
        benef = temPac.seguroVida.seguroBeneficiarios;
        //agregar nuevo beneficiario      
      }
      benef.push({ beneficiario: tempBeneficiario, porcentaje: tempPorcentaje, estatus: 1, idSeguro: temPac.seguroVida.idSeguro });
      //reiniciar la variable
      validaPorcentajeTotal(temPac);
      setPaciente({ ...temPac });
      setTempBeneficiario(null);
      setTempPorcentaje(null);
      setModalBeneficiarios(false);
    } catch (error) {
      setMsgToast({ showToast: true, statusMessage: "Ocurrio un error al agregar el beneficiario, por favor intenta de nuevo" });
    }
    setEstatus({ loading: false, leer: false });
  }

  if (!user.loggedIn) {
    return <Redirect to="/" />
  }
  if (paciente.idPaciente) {
    return <Redirect to="/" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton text=""  color='primary' defaultHref="/" />
          </IonButtons>
          <IonTitle className="title_header" >Mi paciente</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className='panel-paciente'>
          <p>Por favor ingrese todos los datos solicitados y utilice el
            <span> </span>
            <span className="naranjanew">
              botón naranja para guardar su información&nbsp;&nbsp;
            </span>
            <img className="icon_small" src='./assets/savesmall.png'></img>
          </p>
          <div >
            <IonAvatar className='avatar'>
              <img src={fileAvatar.url} alt='avatar' >
              </img>
              <IonButton className="btn-avatar" size='small' shape='round' onClick={() => takePictureAvatar()} >
                <IonIcon icon={camera} ></IonIcon>
              </IonButton>
            </IonAvatar>
            <input type='file' className="btn-avatar" accept='image/*' hidden onChange={handelFileChange} ref={fileInputAvatarRef}>

            </input>
          </div>
        </div>
        <div className='panel-paciente-datos'>
          <div className='panel-paciente-form'>
            <IonAccordionGroup>
              <IonAccordion value="dGenerales">
                <IonItem slot="header" >
                  <IonLabel>Datos Generales</IonLabel>
                </IonItem>
                <IonGrid slot='content'>
                  <IonRow >
                    <IonCol size='6'>
                      <IonLabel position='floating'>Nombre:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput className="capitalizar" placeholder="Nombre" type="text" inputMode='text' autocapitalize="words" value={paciente.nombre}
                        onIonChange={(event) => agregarValor({ nombre: event.detail.value })}>
                      </IonInput>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Apellido Paterno:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput className="capitalizar" placeholder="Ap Paterno" type="text" inputMode='text' autocapitalize="words" value={paciente.apPaterno}
                        onIonChange={(event) => agregarValor({ apPaterno: event.detail.value })}>
                      </IonInput>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Apellido Materno:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput className="capitalizar" placeholder="Ap Materno" type="text" inputMode='text' autocapitalize="words" value={paciente.apMaterno}
                        onIonChange={(event) => agregarValor({ apMaterno: event.detail.value })}>
                      </IonInput>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Parentesco:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonSelect placeholder='Seleccionar parentesco' value={paciente.idParentesco}
                        onIonChange={(event) => agregarValor({ idParentesco: event.detail.value })}>
                        {
                          catParentesco ? catParentesco.map((pare) => {
                            return <>
                              <IonSelectOption key={pare.idParentesco} value={pare.idParentesco}>{pare.parentesco}</IonSelectOption>
                            </>
                          }) : null
                        }
                      </IonSelect>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Fecha de nacimiento:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonInput type="date" value={paciente.fechaNacimiento}
                        onIonChange={(event) => agregarValor({ fechaNacimiento: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Sexo:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonSelect placeholder="Seleccionar su Sexo" value={paciente.idSexo}
                        onIonChange={(event) => agregarValor({ idSexo: event.detail.value })}>
                        <IonSelectOption value={1}>Femenino</IonSelectOption>
                        <IonSelectOption value={2}>Masculino</IonSelectOption>
                      </IonSelect>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>CURP:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonInput className='curp' type="text" value={paciente.curp}
                        onIonChange={(event) => agregarValor({ curp: event.detail.value })} />
                      <IonButton className='link-curp' fill="clear" href={URL_CURP} target='_blank' >Descarga su CURP actualizada</IonButton>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>En caso de emergencia llamar al número:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="tel" value={paciente.telefonoEmergencia}
                        onIonChange={(event) => agregarValor({ telefonoEmergencia: event.detail.value })} />
                    </IonCol>
                    <IonCol>
                      <div hidden={paciente.docIne != null && paciente.docIne.estatus === 0} className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={fileDocIne ? (fileDocIne.mimeType.endsWith("pdf") ? "./assets/pdfIcon.png" : fileDocIne.url) : vprevIne} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirIne || paciente.docIne != null || fileDocIne != null} shape='round' color='rosa' onClick={() => setSubirIne(true)}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirIne || fileDocIne != null} shape='round' color='rosa' onClick={() => takePicture(IMG_INE)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirIne || fileDocIne != null} shape='round' color='rosa' onClick={() => fileInputDocIneRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!paciente.docIne} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docIne) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!paciente.docIne && !fileDocIne} shape='round' color='rosa' onClick={() => { handleEliminarDocIne(paciente.docIne) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>INE</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocIne} ref={fileInputDocIneRef}></input>
                      <IonButton onClick={handleGuardarPaciente} fill='clear' className="recordar_save">
                        <div className='recordar-guardar' >
                          <span className="naranja">
                            Por favor guarde su información
                          </span>
                          <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                        </div>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
              <IonAccordion value="dDatosMedicos">
                <IonItem slot="header" >
                  <IonLabel>Datos médicos importantes</IonLabel>
                </IonItem>
                <IonGrid slot='content'>
                  <IonRow>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Tipo de sangre:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonSelect placeholder='Tipo de sangre' okText='Aceptar' cancelText='Cancelar' value={paciente.idTipoSangre}
                        onIonChange={(event) => { agregarValor({ idTipoSangre: event.detail.value }) }} >
                        {catTipoSangre ? catTipoSangre.map((cts) => {
                          return <IonSelectOption key={cts.idTipoSangre} value={cts.idTipoSangre}>{cts.descripcion}</IonSelectOption>
                        }) : null
                        }
                      </IonSelect>
                    </IonCol>
                  </IonRow>
                  <IonRow className='panel-diagnosticos'>
                    <IonCol size='12'>
                      <IonLabel position='floating'>Alergias:</IonLabel>
                    </IonCol>
                    {
                      paciente && paciente.alergias ? paciente.alergias.map((aler) =>
                        aler.estatus > 0 ?
                          <>
                            <IonCol size="10" size-md="9">
                              <IonInput className="capitalizar" type="text" inputMode='text' disabled={true} value={aler.alergia} />
                            </IonCol>
                            <IonCol className='panel-acciones' size='2' sizeSm='3'>
                              <IonButton hidden={!aler.idAlergia} fill='clear' size='small' shape='round' onClick={() => { handleActualizarAlergia(aler) }}><IonIcon color='rosa' icon={pencil} /></IonButton>
                              <IonButton fill='clear' size='small' shape='round' onClick={() => { handleEliminarAlergia(aler) }} ><IonIcon color='rosa' icon={trash} /></IonButton>
                            </IonCol>
                          </> : <></>
                      ) : <></>
                    }
                    <IonCol size='12' className='panel-add-diag'>
                      <IonButton color="rosa" size='small' shape='round' onClick={() => { setModalAlergia(true); }}>
                        <IonIcon icon={add} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow className='panel-diagnosticos'>
                    <IonCol size='12' >
                      <IonLabel position='floating'>Diagnósticos médicos de importancia para tí:</IonLabel>
                    </IonCol>
                    {paciente && paciente.diagnosticos ? paciente.diagnosticos.map((diagnost) =>
                      diagnost.estatus > 0 ?
                        <>
                          <IonCol size='10' sizeSm='9'>
                            <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" key={diagnost.idDiagnostico} disabled={true} value={diagnost.descripcion} />
                          </IonCol>
                          <IonCol className='panel-acciones' size='2' sizeSm='3'>
                            <IonButton hidden={!diagnost.idDiagnostico} fill='clear' size='small' shape='round' onClick={() => { handleActualizarDiagnostico(diagnost); }}><IonIcon color='rosa' icon={pencil} /></IonButton>
                            <IonButton fill='clear' size='small' shape='round' onClick={() => { handleEliminarDiagnostico(diagnost); }} ><IonIcon color='rosa' icon={trash} /></IonButton>
                          </IonCol>
                        </>
                        : null
                    ) : null}
                    <IonCol size='12' className='panel-add-diag'>
                      <IonButton color="rosa" size='small' shape='round' onClick={() => { setModalDiagnostico(true); }}>
                        <IonIcon icon={add} />
                      </IonButton>
                    </IonCol>
                    <IonButton onClick={handleGuardarPaciente} fill='clear' className="recordar_save">
                      <div className='recordar-guardar' >
                        <span className="naranja">
                          Por favor guarde su información
                        </span>
                        <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                      </div>
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
              <IonAccordion value="dSeguroSalud">
                <IonItem slot="header" >
                  <IonLabel>Datos de Seguros de Salud</IonLabel>
                </IonItem>
                <IonGrid slot='content'>
                  <IonRow>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Tipo de Afiliación Social:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonSelect placeholder="Seleccione su afiliación" okText='Aceptar' cancelText='Cancelar'
                        value={paciente.idAfiliacion}
                        onIonChange={(event) => agregarValor({ idAfiliacion: event.detail.value })}>
                        {catAfiliacionSocial ? catAfiliacionSocial.map((catAS) => {
                          return <IonSelectOption key={catAS.idAfiliacionSocial} value={catAS.idAfiliacionSocial} >{catAS.descripcion}</IonSelectOption>
                        }) : null
                        }
                      </IonSelect>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Número de seguridad social y/o afiliación:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="text" value={paciente.nuAfiliacion}
                        onIonChange={(event) => agregarValor({ nuAfiliacion: event.detail.value })} />
                    </IonCol>
                    <IonCol size='12'>
                      <p className='ds-titulo'>¿Cuenta con Seguro de Gastos Médicos Mayores?</p>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Aseguradora:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonSelect placeholder="Seleccione su aseguradora"
                        okText='Aceptar' cancelText='Cancelar'
                        value={paciente.idAseguradora}
                        onIonChange={(event) => agregarValor({ idAseguradora: event.detail.value })}
                      >
                        {catAseguradora ? catAseguradora.map((catAsg) => {
                          return <IonSelectOption key={catAsg.idAseguradora} value={catAsg.idAseguradora} >{catAsg.descripcion}</IonSelectOption>
                        }) : null
                        }
                      </IonSelect>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Número de póliza:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="text" value={paciente.numeroPolizaGMM}
                        onIonChange={(event) => agregarValor({ numeroPolizaGMM: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Vigencia de póliza:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="date" value={paciente.vigenciaPoliza}
                        onIonChange={(event) => agregarValor({ vigenciaPoliza: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>En caso de emergencia trasladarme a:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={paciente.hospitalUrgencias}
                        onIonChange={(event) => agregarValor({ hospitalUrgencias: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Teléfono de su hospital:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="tel" inputMode='tel' value={paciente.telefonoHospital}
                        onIonChange={(event) => agregarValor({ telefonoHospital: event.detail.value })} />
                    </IonCol>
                    <IonCol size='12'>
                      <div hidden={paciente.docVacuna != null && paciente.docVacuna.estatus === 0} className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={fileDocVac ? (fileDocVac.mimeType.endsWith("pdf") ? "./assets/pdfIcon.png" : fileDocVac.url) : vprevVac} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirVac || paciente.docVacuna != null || fileDocVac != null} shape='round' color='rosa' onClick={() => setSubirVac(true)}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirVac || fileDocVac != null} shape='round' color='rosa' onClick={() => takePicture(IMG_VAC)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirVac || fileDocVac != null} shape='round' color='rosa' onClick={() => fileInputDocVacRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!paciente.docVacuna} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docVacuna) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!paciente.docVacuna && !fileDocVac} shape='round' color='rosa' onClick={() => { handleEliminarDocVacuna(paciente.docVacuna) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>Certificado de vacunación COVID-19</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocVacuna} ref={fileInputDocVacRef}></input>
                    </IonCol>
                    <IonCol size='12'>
                      <div hidden={paciente.docCarnet != null && paciente.docCarnet.estatus === 0} className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={fileDocCarnet ? (fileDocCarnet.mimeType.endsWith("pdf") ? "./assets/pdfIcon.png" : fileDocCarnet.url) : vprevCarnet} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirCarnet || paciente.docCarnet != null || fileDocCarnet != null} shape='round' color='rosa' onClick={() => setSubirCarnet(true)}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirCarnet || fileDocCarnet != null} shape='round' color='rosa' onClick={() => takePicture(IMG_CARNET)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirCarnet || fileDocCarnet != null} shape='round' color='rosa' onClick={() => fileInputDocCarnetRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!paciente.docCarnet} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docCarnet) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!paciente.docCarnet && !fileDocCarnet} shape='round' color='rosa' onClick={() => { handleEliminarDocCarnet(paciente.docCarnet) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>CARNET</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocCarnet} ref={fileInputDocCarnetRef}></input>
                    </IonCol>
                    <IonCol size='12'>
                      <div hidden={paciente.docSeguroGMM != null && paciente.docSeguroGMM.estatus === 0} className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={fileDocSegGMM ? (fileDocSegGMM.mimeType.endsWith("pdf") ? "./assets/pdfIcon.png" : fileDocSegGMM.url) : vprevGMM} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirGMM || paciente.docSeguroGMM != null || fileDocSegGMM != null} shape='round' color='rosa' onClick={() => setSubirGMM(true)}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirGMM || fileDocSegGMM != null} shape='round' color='rosa' onClick={() => takePicture(IMG_PSGMM)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirGMM || fileDocSegGMM != null} shape='round' color='rosa' onClick={() => fileInputDocSegGMMRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!paciente.docSeguroGMM} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docSeguroGMM) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!paciente.docSeguroGMM && !fileDocSegGMM} shape='round' color='rosa' onClick={() => { handleEliminarDocSegGMM(paciente.docSeguroGMM) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>Póliza de Seguro de Gastos Médicos Mayores</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocSegGMM} ref={fileInputDocSegGMMRef}></input>
                    </IonCol>
                    <IonButton onClick={handleGuardarPaciente} fill='clear' className="recordar_save">
                      <div className='recordar-guardar' >
                        <span className="naranja">
                          Por favor guarde su información
                        </span>
                        <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                      </div>
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
              <IonAccordion value="dSeguroVida">
                <IonItem slot="header" >
                  <IonLabel>Datos de Seguros de Vida</IonLabel>
                </IonItem>
                <IonGrid slot='content'>
                  <IonRow>
                    <IonCol size='12'>
                      <p className='ds-aviso'>Sí cuenta con Seguro de Vida, por favor ingrese los siguientes datos:</p>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Aseguradora:*</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonSelect placeholder="Seleccione su aseguradora" okText='Aceptar' cancelText='Cancelar'
                        value={paciente.seguroVida ? paciente.seguroVida.idCatAseguradora : null}
                        onIonChange={(event) => event.detail.value ? agregarSeguroVida({ idCatAseguradora: event.detail.value }) : {}}>
                        {catAseguradora ? catAseguradora.map((catAsg) => {
                          return <IonSelectOption key={catAsg.idAseguradora} value={catAsg.idAseguradora} >{catAsg.descripcion}</IonSelectOption>
                        }) : null
                        }
                      </IonSelect>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Número de póliza:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="text"
                        value={paciente.seguroVida ? paciente.seguroVida.numeroPoliza : null}
                        onIonChange={(event) => event.detail.value ? agregarSeguroVida({ numeroPoliza: event.detail.value }) : {}} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Vigencia Póliza:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="date"
                        value={paciente.seguroVida ? paciente.seguroVida.vigenciaPoliza : null}
                        onIonChange={(event) => agregarSeguroVida({ vigenciaPoliza: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Titular:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words"
                        value={paciente.seguroVida ? paciente.seguroVida.titular : null}
                        onIonChange={(event) => event.detail.value ? agregarSeguroVida({ titular: event.detail.value }) : {}} />
                    </IonCol>
                  </IonRow>
                  <IonRow className='panel-benef'>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Beneficiarios</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Porcentaje</IonLabel>
                    </IonCol>
                    {paciente && paciente.seguroVida && paciente.seguroVida.seguroBeneficiarios ? paciente.seguroVida.seguroBeneficiarios.map((benefic) =>
                      benefic.estatus > 0 ?
                        <>
                          <IonCol size='6'>
                            <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" key={benefic.idBeneficiario} disabled value={benefic.beneficiario}></IonInput>
                          </IonCol>
                          <IonCol size='4'>
                            <IonInput disabled value={benefic.porcentaje}></IonInput>
                          </IonCol>
                          <IonCol className='panel-acciones' size='2'>
                            <IonButton fill='clear' size='small' shape='round' onClick={() => { handleEliminarBeneficiario(benefic); }}>
                              <IonIcon color='rosa' icon={trash}></IonIcon>
                            </IonButton>
                          </IonCol>
                        </> : 
                        <>                          
                        </>
                    ) : <></>}
                    <IonCol className='hide-benef' hidden={porcentajeTotal >0} size='6'>
                        <IonInput disabled></IonInput>
                      </IonCol>
                      <IonCol className='hide-benef' hidden={porcentajeTotal >0} size='6'>
                        <IonInput disabled></IonInput>
                      </IonCol>
                    <IonCol className='panel-add-benef' size='12'>
                      <IonButton hidden={porcentajeTotal >= 100} fill='clear' onClick={() => { abrirModalBeneficiarios() }}>
                        <IonIcon color='rosa' icon={personAdd}></IonIcon>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <div hidden={paciente.docSeguroVida != null && paciente.docSeguroVida.estatus === 0} className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={fileDocSegVida ? (fileDocSegVida.mimeType.endsWith("pdf") ? "./assets/pdfIcon.png" : fileDocSegVida.url) : vprevSvida} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirSvida || paciente.docSeguroVida != null || fileDocSegVida != null} shape='round' color='rosa' onClick={() => setSubirSvida(true)}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirSvida || fileDocSegVida != null} shape='round' color='rosa' onClick={() => takePicture(IMG_P_SEGURO_VIDA)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirSvida || fileDocSegVida != null} shape='round' color='rosa' onClick={() => fileInputDocSegVidaRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!paciente.docSeguroVida} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docSeguroVida) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!paciente.docSeguroVida && !fileDocSegVida} shape='round' color='rosa' onClick={() => { handleEliminarDocSegVida(paciente.docSeguroVida) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>Póliza de Seguro de Vida</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocSegVida} ref={fileInputDocSegVidaRef}></input>
                    </IonCol>
                    <IonButton onClick={handleGuardarPaciente} fill='clear' className="recordar_save">
                      <div className='recordar-guardar' >
                        <span className="naranja">
                          Por favor guarde su información
                        </span>
                        <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                      </div>
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
            </IonAccordionGroup>
          </div>
          {/* <IonButton expand="block" onClick={handleGuardarPaciente}><IonLabel>Guardar</IonLabel></IonButton> */}

        </div>
        <IonToast color='rosa'
          isOpen={msgToast.showToast}
          onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
          message={msgToast.statusMessage}
          duration={3000}
        />
        <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        <IonModal backdropDismiss={false} isOpen={modalDiagnostico} className="ion-modal-diagnosticos" >
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>Escribe un nuevo diagnóstico médico de importancia para ti:</IonLabel>
                  <IonInput className="capitalizar" value={diagnosticoDescripcion} type="text" inputMode='text' autocapitalize="words"
                    onIonChange={(event) => { setDiagnosticoDescripcion(event.detail.value) }}>
                  </IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand='block' onClick={() => { setModalDiagnostico(false) }}>
                    <IonLabel>Cancelar</IonLabel>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton expand='block' onClick={() => { agregarDiagnostico() }} >
                    <IonLabel>Registrar</IonLabel>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonModal backdropDismiss={false} isOpen={modalAlergia} className="ion-modal-alergia" >
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>Alergia:</IonLabel>
                  <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="word" value={alergiaDescripcion}
                    onIonChange={(event) => { setAlergiaDescripcion(event.detail.value) }}>
                  </IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand='block' onClick={() => { cancelarAlergia() }}>
                    <IonLabel>Cancelar</IonLabel>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton expand='block' onClick={() => { agregarAlergia() }} >
                    <IonLabel>{alergiaTemp ? 'Actualizar' : 'Guardar'}</IonLabel>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonModal backdropDismiss={false} isOpen={modalBeneficiarios} className="ion-modal-beneficiarios" >
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>Beneficiario:</IonLabel>
                  <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={tempBeneficiario}
                    onIonChange={(event) => setTempBeneficiario(event.detail.value)} />
                </IonCol>
                <IonCol>
                  <IonLabel>Porcentaje:</IonLabel>
                  <IonSelect placeholder="Seleccione su aseguradora" value={tempPorcentaje}
                    onIonChange={(event) => validaPorcentaje(event.detail.value)}>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 5} value={5}>5%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 10} value={10}>10%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 15} value={15}>15%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 20} value={20}>20%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 25} value={25}>25%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 30} value={30}>30%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 35} value={35}>35%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 40} value={40}>40%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 45} value={45}>45%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 50} value={50}>50%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 55} value={55}>55%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 60} value={60}>60%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 65} value={65}>65%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 70} value={70}>70%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 75} value={75}>75%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 80} value={80}>80%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 85} value={85}>85%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 90} value={90}>90%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 95} value={95}>95%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 100} value={100}>100%</IonSelectOption>
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand='block' onClick={() => { cancelarBeneficiarios() }}>
                    <IonLabel>Cancelar</IonLabel>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton expand='block' onClick={() => { agregarBeneficiarios() }} >
                    <IonLabel>Guardar</IonLabel>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonContent>
      <IonFab vertical="bottom" horizontal="end" title='Guardar' >
        <IonFabButton className='saveFabButton' onClick={handleGuardarPaciente}>
          <IonImg src='./assets/saveIcon.png' />
        </IonFabButton>
      </IonFab>
    </IonPage>
  );
};

export default NuevoPaciente;
