import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonLoading,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import { sync } from 'ionicons/icons';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { useLoginContext } from '../hooks/login';
import { enviarEmail } from '../services/correo.service';
import '../theme/sugerenciascontacto.css';



const BuzonSugerencias: React.FC = () => {
  const user = useLoginContext();
  const { loggedIn } = useLoginContext();
  const [status, setStatus] = useState({ loading: false, error: false });
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [ email, setEmail ] = useState<string>();
  const [ nombre, setNombre ] = useState<string>();
  const [ sugerencia, setSugerencia ] = useState<string>();

  const enviarCorreo = async () => {   
    setStatus({ loading: true, error: false });   
    let correo = {
      para:email,
      nombre:nombre,
      contenido:sugerencia
    }

    console.log("correo enviado: ",correo);

    let respuesta = await enviarEmail(correo,user);
    if(respuesta!=null){
      setMsgToast({showToast:true,statusMessage:"Gracias por tu mensaje, lo atenderemos a la brevedad. Allivia,Porque tú cuidado, ¡También importa!"});
    }else{
      setMsgToast({showToast:true,statusMessage:"Ocurrió un error al enviar el correo, por favor intenta de nuevo"});
    }
    setStatus({ loading: false, error: false });   
  }

  //console.log(`rendering from loginPage with loggedIn=${loggedIn}`);
  if (!loggedIn) {
    return <Redirect to="/allivia/alliviaHome" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton text=""  color='primary' defaultHref="/"/>
          </IonButtons>
          <IonTitle>Buzón de Sugerencias</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">  
        <div id="contenedor_sugerencias">
              <div id="titulo">
                  <div className="titular">
                    <div id="icon">
                      <img src="./assets/buzon.png" />
                    </div>
                    <div id="txtsuger">
                     <h2>Buzón de sugerencias</h2>
                    </div>
                  </div>
              </div> 
              <div id="txt">
                <p>
                  Agradecemos el tiempo que se toma 
                <br />
                  para enviarnos sugerencias sobre <span className="bold">allivia</span>
                </p>
              </div> 
              <div id="formulario">
                    <form action="#" method="post">
                        <div className="inputs face">
                            <IonInput autocapitalize="words" type="text" name="person" placeholder="Nombre completo" required
                            value={nombre}
                            onIonChange={(event) => setNombre(event.detail.value)}></IonInput>
                           
                        </div>
                        <div className="inputs mail">
                            <IonInput type="text" name="mail" placeholder='Correo electrónico' pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}" required
                            value={email}
                            onIonChange={(event) => setEmail(event.detail.value)}></IonInput>
                         
                        </div>
                        <div id="txtarea">
                          <IonTextarea placeholder="Por favor escriba aquí sus sugerencias" name="mensaje"  required
                            value={sugerencia} rows={20}
                            onIonChange={(event) => setSugerencia(event.detail.value)}>
                            </IonTextarea>
                        </div>
                        <IonButton onClick={enviarCorreo}  className="enviar"><IonLabel>Enviar</IonLabel></IonButton>                        
                    </form>

              </div>
        </div>  
        <IonLoading isOpen={status.loading} />   
        <IonToast color="rosa"
          isOpen={msgToast.showToast}
          onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
          message= {msgToast.statusMessage}
          duration={10000}
          position={'middle'}
      /> 
      </IonContent>
    </IonPage>
  );
};

export default BuzonSugerencias;
