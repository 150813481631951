import {
  IonApp, setupIonicReact,
} from '@ionic/react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import React, { useReducer, useState } from 'react';
import AppTabs from './AppTabs';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import RegistroUsuario from './pages/RegistroUsuario';
import { LoginContext } from './hooks/login';
import { authReducer } from './reducers/authReducer';
import ResetPassword from './pages/ResetPassword';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
import { AppVersion } from '@awesome-cordova-plugins/app-version';
import { VERSION_WEB } from './constantes';
import MenuWeb from './pages/MenuWeb';
import EliminarCuenta from './pages/EliminarCuenta';


const init = () => {
  return localStorage.usuarioLogin ? { loading: false, loggedIn: true, ...JSON.parse(localStorage.usuarioLogin) } : { loggedIn: false };
}



ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);

//Codigo para la version >6
setupIonicReact();

const App: React.FC = () => {
  const [user, dispatch] = useReducer(authReducer, {}, init);
  const [parametros, setParametros] = useState<{ DTOPaciente?, versionNumber?}>();

  const leerVersionSystema = async () => {
    if (!parametros || !parametros.versionNumber) {
      await AppVersion.getVersionNumber().then(version => {
        setParametros({ ...parametros, versionNumber: version });
      }).catch(e => {
        setParametros({ ...parametros, versionNumber: VERSION_WEB });
      });
    }
  }

  leerVersionSystema();

  return (
    <IonApp>
      <LoginContext.Provider value={{ user, dispatch, parametros, setParametros }}>
        <IonReactRouter>
          {/*<IonRouterOutlet>*/}
          <MenuWeb />
          <Switch>
            <Route exact path="/eliminarCuenta" >
              <EliminarCuenta />
            </Route>
            <Route exact path="/login" >
              <LoginPage />
            </Route>
            <Route exact path="/registroUsuario" >
              <RegistroUsuario />
            </Route>
            <Route exact path="/resetPassword" >
              <ResetPassword />
            </Route>
            <Route path="/allivia" >
              <AppTabs />
            </Route>
            <Redirect exact path='/' to="/allivia/alliviaHome" />
            <Redirect exact path="/eliminarCuenta" to="/eliminarCuenta" />
            <Route>
              <NotFoundPage />
            </Route>
            {/*</IonRouterOutlet>*/}
          </Switch>
        </IonReactRouter>
      </LoginContext.Provider>
    </IonApp >
  );
};

export default App;
