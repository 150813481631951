import { types } from '../types/types';
/*
    {
        uid: 'jagdfjahdsf127362718',
        name: 'Fernando'
    }

*/
export const authReducer = ( state = {loggedIn:false}, action ):{loggedIn:boolean,token?:string,username?:string,idUsuario?:number} => {
    console.log("vivo");
    switch ( action.type ) {
        case types.login:
            console.log("estoy en el reducer login");
            return {                
                token: action.payload.token,
                username: action.payload.username,
                loggedIn: action.payload.loggedIn,
                idUsuario: action.payload.idUsuario,
            }

        case types.logout:
                return {loggedIn: false }
    
        default:{            
            return state;
        }
    }

}