import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/cuestionarioCuatro.css';
import { Redirect } from 'react-router';
import { DTOCuestionario, DTOCuestionarioEvaluacion, DTOTestCuidador, DTOTestCuidadorRespuesta } from '../services/AlliviaInterfaces';
import { guardarTestCuidador, leerCuestionarioEvaluacion, leerCuestionarios } from '../services/cuestionario.service';
import { timeout } from 'rxjs';



const CuestionarioCuatro: React.FC = () => {
  const user = useLoginContext();
  const { parametros,setParametros } = useParametros();
  const idTest = 4;
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [cuestionario, setCuestionario] = useState<Array<DTOCuestionario>>([]);
  const [cuestionarioEvaluacion, setCuestionarioEvaluacion] = useState<Array<DTOCuestionarioEvaluacion>>([]);
  const [preguntaPendiente, setPreguntaPendiente] = useState(true);
  const [evaluacion, setEvaluacion] = useState({ puntos: null, descripcion: null,idCuestEvaluacion:null });
  const [testFinalizado,setTestFinalizado] =useState(false);
  const [regresar,setRegresar] =useState(false);

  const leerDatosCuestionario = async () => {
    setEstatus({ loading: true, leer: false });
    // await leerCuidador(user.username, user).then((valor) => {
    //   setCuidador(valor);
    // }).finally(() => {
    // });
    await leerCuestionarios(idTest, user).then((cuest) => {
      //crear structura de respuestas        
      setCuestionario(cuest);
    });
    await leerCuestionarioEvaluacion(idTest, user).then((evalu) => {
      setCuestionarioEvaluacion(evalu);
    })
    setEstatus({ loading: false, leer: false });
  }

  const guardarCuestionario = async () => {
    setEstatus({ loading: true, leer: false });
    let date = new Date();
    let respuestas: Array<DTOTestCuidadorRespuesta> = [];
    cuestionario.map((question) => {
      let resp: DTOTestCuidadorRespuesta = {
        idCuestionario: question.idCuestionario,
        valorRespuesta: question.valorRespuesta,
      }
      respuestas.push(resp);
    });

    let testCuidador:DTOTestCuidador = {
      idTest : idTest,
      idCuidador: parametros.cuidador.idCuidador,
      puntos: evaluacion.puntos,
      idCuestEvaluacion: evaluacion.idCuestEvaluacion,   
      fechaEvaluacion: date.toISOString(),
      testCuidadorRespuestas : respuestas   
    }

    await guardarTestCuidador(testCuidador,user).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El test se guardo correctamente"});
      setParametros({...parametros,test1:testCuidador});
      setTestFinalizado(true);
    }).catch((error)=>{
      console.log("Error al guardar el test",error);
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el test por favor intente de nuevo"});

    });    
    setEstatus({ loading: false, leer: false });
  }

  const afterMessage = async ()=>{
    setMsgToast({showToast:false,statusMessage:''});
    if(testFinalizado){
      await timeout(1000);
      setRegresar(true);
    }
  }

  const evaluarRespuestas = async () => {
    setEstatus({ loading: true, leer: false });
    let puntos = 0;
    let pendiente:boolean = false;
    console.log("puntos:", pendiente,"parametres: ",parametros);
    cuestionario.map((resp) => {
      console.log(resp.numPregunta + "==" + resp.valorRespuesta);
      if (resp.valorRespuesta!=null) {
        console.log("Entra==" + resp.valorRespuesta);
        let val = resp.valorRespuesta;
        if(resp.numPregunta===1 ||
           resp.numPregunta===2 ||
           resp.numPregunta===3 ||
           resp.numPregunta===6 ||
           resp.numPregunta===7 ){
          val = 3-resp.valorRespuesta
        }
        puntos = puntos + val;
      } else {
        console.log("Etrna true: ",resp.numPregunta);
        pendiente = true;
      }
    });
    console.log("puntos:",pendiente,puntos);

    cuestionarioEvaluacion.map((evalu) => {
      console.log(evalu.minimo + "<=" + puntos + "&&" + puntos + "<=" + evalu.maximo);
      if (evalu.minimo <= puntos && puntos <= evalu.maximo) {
        setEvaluacion({ puntos: puntos, descripcion: evalu.descripcion,idCuestEvaluacion:evalu.idCuestEvaluacion });
      }
    });

    setPreguntaPendiente(pendiente);
    setEstatus({ loading: false, leer: false });
  }

  useEffect(() => {
    leerDatosCuestionario().then(() => {
      console.log("Funcionando");
    });
  }, []);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if(!parametros){
    return <Redirect to="/allivia/alliviaHome" />
  }

  if(regresar){
    return <Redirect to="/allivia/estadoEmocional" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Autovalore su situación</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent >
          <div className='cuestionario-cuatro'>
            <div className='header' >
              <div className='numero'>
                <span>1</span>
              </div>
              <div className='titulo'>
                <span>¿Cuál es mi nivel de tensión?</span>
              </div>
            </div>
            <div className='contenido'>
              <div className='subtitulo'>
              Le dará información sobre el grado de tensión y ansiedad que está experimentando en su vida.
              </div>
              <div className='instrucciones'>
                <span>Instrucción:</span> Lea cada frase y selecciona el círculo con la respuesta que más se ajusta a cómo se sintió durante la SEMANA PASADA. No piense mucho las respuestas. Lo más seguro es que si responde deprisa sus respuestas podrán reflejar mejor cómo se encontraba usted durante la semana pasada.
              </div>
              <div className='cuestionario'>
                {cuestionario ? cuestionario.map((preg) =>
                  <>
                    <div className='pregunta'>
                      <span className='categoria'>{preg.categoria}&nbsp;</span>
                      {preg.pregunta}
                    </div>
                    <IonRadioGroup value={preg.valorRespuesta} onIonChange={(event) => { preg.valorRespuesta = event.detail.value; evaluarRespuestas() }}>
                      <div className='respuestas'>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={0} />
                          <div className='descripcion'>
                            Todos los días
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={1} />
                          <div className='descripcion'>
                            Muchas veces
                          </div>
                        </div>
                        <div className='resp-radio3'>
                          <IonRadio mode="md"  value={2} />                          
                          <div className='descripcion3'>
                            A veces
                          </div>
                        </div>
                        <div className='resp-radio3'>
                          <IonRadio mode="md"  value={3} />                          
                          <div className='descripcion3'>
                            Nunca
                          </div>
                        </div>                        
                      </div>
                    </IonRadioGroup>
                  </>
                ) : null
                }
              </div>
              <div className='resultado'>
                <div className='panel-mensaje'>
                  <div className='puntaje'>
                    <span className='texto'>Tu puntuación fue:</span>
                    <span className='numero'>{evaluacion.puntos}</span>
                  </div>
                  <div className='nota'>
                    {evaluacion.descripcion}
                  </div>
                </div>
              </div>
              <div className='boton-siguiente'>
                <IonButton color='rosa' expand='block' hidden={preguntaPendiente||testFinalizado} 
                
                  onClick={() => { guardarCuestionario() }}>
                    <div className='muy-bien'>
                      <h4>¡Muy bien!</h4>
                      <p>
                        Finalizaste los test
                       <br/>
                        Primer paso para auto reconocerte.
                      </p>
                    </div>
                  </IonButton>
              </div>
            </div>
          </div>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => afterMessage()}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        </IonContent>
      </IonPage>
    </>
  );
};

export default CuestionarioCuatro;
