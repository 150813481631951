/* Descomentar para android */
import { LocalNotifications } from "@capacitor/local-notifications";
/* Desomentar para ios*/
//import { LocalNotifications } from "@awesome-cordova-plugins/local-notifications";
import { SERVICE_ALLIVIA} from "../properties";
import { UsuarioLogin } from "./AlliviaInterfaces";
import { eliminarTodosDocumentos, esWeb } from "./utils.service";

export const getTest = async () => {  
  const url = SERVICE_ALLIVIA+"/test";
  try{
    const res = await fetch(url);           
    if (!res.ok) {
      throw Error(res.statusText)
    }           
    const {response} = await res.json();                     
    console.log('data:'+response);      
    return response;
  }catch(error){
    return null;
  }
}
/**
 * El usuario se authentica con usuario y contraseña
 * @param username usuario 
 * @param password password
 * @returns token
 */
export const signInWithUserAndPassword = async (username,password) =>{
  const url = SERVICE_ALLIVIA+"/authenticate";
  const data = { username: username, password: password};
  const response = await fetch(url,{
    body: JSON.stringify(data),
    headers:{
      "Content-Type":"application/json",
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error(response.statusText);
  }
  const json = await response.json();  
  localStorage.setItem("usuarioLogin",JSON.stringify(json));
  return json;
}

/**
 * Función que valida el token consultando con el webservice.
 * @param authToken 
 * @returns Regresa el usuario login
 */
export const validarToken =async (authToken)=>{
  const url = SERVICE_ALLIVIA+"/validaToken";
  const token = authToken;
  const data = {token:token};
  console.log("validaTokent",token);
  
  try{
    let response = await fetch(url,{
        body: JSON.stringify(data),
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer "+token,
        },
        method: "POST"
    });

    if(!response.ok){
      throw Error(response.statusText);
    }
    const json = await response.json();
    localStorage.setItem("usuarioLogin",JSON.stringify(json));

    return json;
  }catch(error){
    const test = await getTest();    
    return test?undefined:token;
  }
  
}

export async function  cerrarSesion (){
  /* Descomentar para android*/
   let notPending = await LocalNotifications.getPending();  

  /* Descomentar para ios */  
  // try{
  //   await LocalNotifications.cancelAll();
  //   await LocalNotifications.clearAll();  
  // }catch(e){
  //   console.log("error notificaicones",e);
  // };

  /* Descomentar para android */
      console.log("cantidad notificaciones: ",notPending.notifications.length);
      if(notPending && notPending.notifications && notPending.notifications.length>0){
        console.log("Elimina notificaciones");
        await LocalNotifications.cancel(notPending);
      } 
      
  //let perfil = JSON.parse(localStorage.getItem("perfilUsuario"));
  localStorage.clear();
  //Solamente elimina los documentos cuendo es en ambiente web
  if(esWeb()){
    await eliminarTodosDocumentos();
  }    
}

export const onLoginStateChange = () =>{
  var authToken = localStorage.usuarioLogin;
  if(!authToken){
    return null;
  }    
  var token = JSON.parse(authToken);  
  return token;
}

export const eliminaUserWithPassword = async (usuario:UsuarioLogin,password) =>{
  const url = SERVICE_ALLIVIA+"/eliminaUsuario";
  const data = { username: usuario.username, password: password};
  const response = await fetch(url,{
    body: JSON.stringify(data),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });  

  if(!response.ok){
    throw Error(response.statusText);
  }
  const json = await response.json();  
  localStorage.setItem("usuarioLogin",JSON.stringify(json));
  return json;
}

