import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonMenuToggle,
  IonButton,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonListHeader,
  IonList,
  IonItem,
  IonImg,
  IonAvatar,
  IonFabList,
  IonCol,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { call,personCircle,home,   menu, search} from 'ionicons/icons';
import { useLoginContext, useParametros, } from '../hooks/login';
import '../theme/menuPaciente.css';
import Menu from './Menu';
import { Redirect } from 'react-router';
import {  DTOPaciente } from '../services/AlliviaInterfaces';
import { emergencyCall, esWeb } from '../services/utils.service';

const MenuPaciente: React.FC = () => {  
  const user = useLoginContext();
  const {parametros} = useParametros();
  const [paciente, setPaciente] = useState<DTOPaciente | undefined>({});
  const [cuidador, setCuidador] = useState<DTOPaciente | undefined>({});
  
  useEffect(() =>{
    if(parametros){
      setPaciente(parametros.paciente);
      setCuidador(parametros.cuidador);
    }
  },[parametros]);
  


  if(!parametros){
    return <Redirect to="/" />
  }

  
  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
    <Menu/>
    <IonPage>          
      <IonHeader>
        <IonToolbar>
      <IonButtons slot="start">
         <IonMenuToggle>
            <IonButton >
              <IonIcon size='large' color="primary" icon={menu}></IonIcon>
            </IonButton>
          </IonMenuToggle>          
        </IonButtons>
        <IonTitle className='home-title'>allivia</IonTitle>
        <IonButtons slot='end'>
          <IonButton href='allivia/perfilCuidador' >            
          { cuidador && cuidador.avatar ? 
            <>
              <IonAvatar className='homepage-avatar'>
                <img className='homepage-avatar' src={cuidador.avatar.fileStream}></img>
              </IonAvatar>
            </>                          
              :<IonIcon size='large' color="rosa" icon={personCircle} ></IonIcon>
            }   
          </IonButton>
        </IonButtons>
        </IonToolbar>        
      </IonHeader>
      <IonContent className="menu-paciente">
      <IonList lines='none' className='pleca-tratamientos'>
        <IonListHeader>
          <div id="contenedor_datos">
            <div className="icon_px">
                <IonImg src="./assets/px.png"/>
            </div>
            <div className="datos_px">
                 <div id='title_paciente'>
                   <span className='header-nombre'>{paciente?paciente.nombreCompleto:null}</span>
                 </div>
                 <div id="title_parentesco">
                  <span className='header-parentesco'>{paciente?paciente.parentesco:null}</span>
                 </div>
            </div>
          </div>
        </IonListHeader>     
         <div id="textos_allivia">
                <p>
                 <span className="apremium">allivia</span>  es una app que te ayudará a tener tu información y la de tu paciente actualizada, de tal manera que en casos de emergencia o seguimiento médico, tú puedas tener todo a la mano. 
                </p>
         </div>  
         <div id="contenedor_opciones">
        <IonItem className="fila_menu" routerLink='/allivia/informacionPaciente'>
            <IonImg src={paciente && paciente.idSexo===1?'./assets/informacionPersonalF.png':'./assets/informacionPersonal.png'} />
            
          <IonLabel className="titulos_menu">
            Información personal
          </IonLabel>
        </IonItem>
        <IonItem className="fila_menu" routerLink='/allivia/signosVitales'>
            <IonImg src="./assets/signosVitales.png" />
          <IonLabel className="titulos_menu">
            Monitoreo de Signos Vitales
          </IonLabel>
        </IonItem>
        <IonItem className="fila_menu" routerLink='/allivia/estudiosClinicos'>
            <IonImg src="./assets/analisisClinicos.png" />
          <IonLabel className="titulos_menu">
              Estudios o análisis clínicos
          </IonLabel>
        </IonItem>
        <IonItem className="fila_menu" routerLink='/allivia/tratamientos'>
            <IonImg src="./assets/tratamiento.png" />
          <IonLabel className="titulos_menu">
            Tratamientos
          </IonLabel>
        </IonItem>
        <IonItem className="fila_menu" routerLink='/allivia/citaMedica'>
            <IonImg src={paciente && paciente.idSexo===1?'./assets/citaMedicaF.png':'./assets/citaMedica.png'} />
          <IonLabel className="titulos_menu">
            Cita médica
          </IonLabel>
        </IonItem>
        </div>   
      </IonList>  
                    
      <IonFab hidden={esWeb()} className='allivia-emergencia-call' vertical='bottom' horizontal='center' edge slot='fixed'>
          <IonFabButton className="menu_llamada" color="emergencia">
            <div id="911">
              <IonIcon icon={call}/>
             <IonLabel>911</IonLabel>       
            </div>         
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton color="success" onClick={()=>{emergencyCall();}}><IonIcon icon={call} /></IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>  
         
      {/* <IonTabBar className="menuinferior" slot="bottom" color='primary'>
            <IonTabButton tab='home' href='/' >
              <IonIcon icon={home}/>
              <IonLabel>Inicio</IonLabel>
            </IonTabButton>
            <IonTabButton className='tab-right' >
              <IonIcon icon={search}/>
              <IonLabel>Persona a cuidar</IonLabel>
            </IonTabButton>            
      </IonTabBar> */}
      
      <div className='custom-tab-bar'>
          <IonButton fill='clear' routerLink='/allivia/alliviaHome'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' routerLink='/allivia/buscar'>
            <div>
              <IonIcon icon={search} />
              <IonLabel>Buscar</IonLabel>
            </div>
          </IonButton>          
        </div>
    </IonPage>
    </>
  );
};
export default MenuPaciente;
