import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonImg,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonIcon,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/misPacientes.css';
import { Redirect } from 'react-router';
import { leerCuidador } from '../services/cuidador.service';
import { DTOCuidador, DTOPaciente } from '../services/AlliviaInterfaces';
import { closeOutline } from 'ionicons/icons';
import { leerPacientesCompartidos, solicitudAceptarPacienteCompartido, solicitudRechazarPacienteCompartido } from '../services/paciente.service';



const MisPacientes: React.FC = () => {
  const user = useLoginContext();
  const {parametros} = useParametros();
  const [cuidador, setCuidador] = useState<DTOCuidador | undefined>(undefined);
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [tienePacientesCompartidos, setTienePacientesCompartidos] = useState(0);
  const [modalAceptarSolicitud, setModalAceptarSolicitud] = useState(false);
  const [pacienteSeleccionado, setPacienteSeleccionado] = useState<DTOPaciente>();
  const [pacientesCompartidos, setPacientesCompartidos] = useState<Array<DTOPaciente> | undefined>([]);
  const [esPremiumDuo,setEsPremiumDuo] = useState(false);

  const leerPacCompartidos = async () => {
    setEstatus({ loading: true, leer: true });
    await leerPacientesCompartidos(cuidador.idCuidador, user).then((invit) => {
      setPacientesCompartidos(invit);
    }
    );
    //solo es la confirmación del envió del paciente    
    setEstatus({ loading: false, leer: true });
  }

  const verDetallesPaciente = async (pac: DTOPaciente) => {
    if (pac.aceptaPaciente === 0) {
      setPacienteSeleccionado(pac);
      setModalAceptarSolicitud(true);
    }
  }

  const aceptarSolicitud = async () => {
    setEstatus({ loading: true, leer: true })
    await solicitudAceptarPacienteCompartido(pacienteSeleccionado.idPaciente, user)
      .then(() => {
        setMsgToast({ showToast: true, statusMessage: "Solicitud aceptada" });
      }).catch((error) => {
        console.log("Error al aceptar la solicitud");
        setMsgToast({ showToast: true, statusMessage: "Ocurrio un error al aceptar la solicitud por favor intenta nuevamente" });
      });
    setTienePacientesCompartidos(0);
    await leerDatosCuidadorPacientes();
    setPacienteSeleccionado(null);
    setModalAceptarSolicitud(false);
    setEstatus({ loading: false, leer: true })

  }

  const leerDatosCuidadorPacientes = async () => {
    return leerCuidador(user.username, user).then((valor) => {
      leerPacientesCompartidos(valor.idCuidador, user).then((invit) => {
        setTienePacientesCompartidos(invit.length)
        setPacientesCompartidos(invit);
      }
      );
      setCuidador(valor);
    })
  }

  const rechazarSolicitud = async () => {
    setEstatus({ loading: true, leer: true })
    await solicitudRechazarPacienteCompartido(pacienteSeleccionado.idPaciente, user)
      .then(() => {
        setMsgToast({ showToast: true, statusMessage: "Solicitud rechazada" });
      }).catch((error) => {
        console.log("Error al aceptar la solicitud");
        setMsgToast({ showToast: true, statusMessage: "Ocurrio un error al rechazar la solicitud por favor intenta nuevamente" });
      });
    setTienePacientesCompartidos(0);
    await leerDatosCuidadorPacientes();
    setPacienteSeleccionado(null);
    setModalAceptarSolicitud(false);
    setEstatus({ loading: false, leer: true })
  }

  const leerDatos = async () => {
    setEstatus({ loading: true, leer: false });
    if(parametros!=null){
      setEsPremiumDuo(parametros.premiumDuo);
      setTienePacientesCompartidos(0);
      await leerDatosCuidadorPacientes();
    }
    setEstatus({ loading: false, leer: false });
  }

  useEffect(() => {
    leerDatos();
  }, []);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }


  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Mis Pacientes</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding mispacientes">
          <div>
            <h1 className='titulo'>Mis pacientes</h1>
          </div>
          <IonList className='lista-mispacientes' lines="none">
            {cuidador ? cuidador.pacientes.map((pac) =>
              !pac.idCuidadorPropietario ? <>
                <IonItem className='item-paciente' >
                  <IonAvatar>
                    <IonImg src={!pac.avatar ? "./assets/pacienteAvatar.png" : pac.avatar.fileStream}></IonImg>
                  </IonAvatar>
                  <div className='paciente'>
                    <div className='paciente-datos'>
                      <div className='nombre-completo'>
                        <IonLabel>{pac.nombreCompleto}</IonLabel>
                      </div>
                      <div className='edad'>
                        <IonLabel>{pac.edad}</IonLabel>
                      </div>
                    </div>
                    <div className='parentesco'>
                      <IonLabel>|{pac.parentesco}</IonLabel>
                    </div>
                  </div>
                </IonItem>

              </>
                : <></>) : null

            }
          </IonList>
          <div id="pacientes_compartidos" hidden={tienePacientesCompartidos === 0}>
            <h1 className='titulocompartidos'>Mis pacientes compartidos</h1>
          </div>
          <IonList hidden={tienePacientesCompartidos === 0} className='lista-mispacientes' lines="none">
            {pacientesCompartidos ? pacientesCompartidos.map((pac) =>
              pac.idCuidadorPropietario ?
                <>
                  <IonItem id="f_compartidos" onClick={() => { verDetallesPaciente(pac) }} >
                    <IonAvatar>
                      <IonImg src={!pac.avatar ? "./assets/pacienteAvatar.png" : pac.avatar.fileStream}></IonImg>
                    </IonAvatar>
                    <div className='mi-paciente-compartido'>
                      <div className='paciente'>
                        <div className='paciente-datos'>
                          <div className='nombre-completo'>
                            <IonLabel>{pac.nombreCompleto} |</IonLabel>
                          </div>
                          <div className='edad'>
                            <IonLabel> {pac.edad}</IonLabel>
                          </div>
                        </div>
                        <div className='parentesco'>
                          <IonLabel>{pac.parentesco}</IonLabel>
                        </div>
                      </div>
                      <div className='solicitud'>
                        <IonLabel>{pac.aceptaPaciente === 0 ? "Solicitud pendiente" : (pac.aceptaPaciente === 1 ? "Solicitud aceptada" : "Solicitud Rechazada")}</IonLabel>
                      </div>
                    </div>
                  </IonItem>
                </>
                : <></>) : null
            }
          </IonList>
          <div hidden={esPremiumDuo}  id="compartir_paciente">
            <h6>Si deseas compartir algún paciente, da clic en el siguiente botón:</h6>
            <IonButton id="btn_compartir" expand='block' routerLink='/allivia/accesoCompartido' >Acceso Compartido</IonButton>
            
          </div>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
          <IonModal backdropDismiss={false} isOpen={modalAceptarSolicitud} className="ion-modal-aceptar-paciente" >
              <IonContent>
                <div className='contenido'>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='10'>
                        <h4>Aceptar solicitud</h4>
                      </IonCol>
                      <IonCol size='2'>
                        <IonButton fill='clear' onClick={() => {setModalAceptarSolicitud(false) }}>
                          <IonIcon icon={closeOutline}  ></IonIcon>
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='12'>
                        <p>Sí aceptas la solicitud podrás ver y modificar la información del paciente</p>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <div className='card-paciente'>
                    <div className='avatar'>
                      <IonAvatar>
                        <IonImg src={pacienteSeleccionado && !pacienteSeleccionado.avatar ? "./assets/pacienteAvatar.png" : (pacienteSeleccionado ? pacienteSeleccionado.avatar.fileStream : "")}></IonImg>
                      </IonAvatar>
                    </div>
                    <div className='paciente'>
                      <div className='paciente-datos'>
                        <div className='nombre-completo'>
                          <IonLabel>{pacienteSeleccionado ? pacienteSeleccionado.nombreCompleto : ""} |</IonLabel>
                        </div>
                        <div className='edad'>
                          <IonLabel> {pacienteSeleccionado ? pacienteSeleccionado.edad : ""}&nbsp;&nbsp;</IonLabel>
                        </div>
                      </div>
                      <div className='parentesco'>
                        <IonLabel>{pacienteSeleccionado ? pacienteSeleccionado.parentesco : ""}</IonLabel>
                      </div>
                    </div>
                  </div>
                  <IonGrid hidden={pacienteSeleccionado && pacienteSeleccionado.aceptaPaciente === 2}>
                    <IonRow>
                      <IonCol size='6'>
                        <IonButton expand='block'
                          onClick={() => { aceptarSolicitud() }} >Aceptar</IonButton>
                      </IonCol>
                      <IonCol size='6'>
                        <IonButton expand='block'
                          onClick={() => { rechazarSolicitud() }} >Rechazar</IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </IonContent>
            </IonModal>
        </IonContent>
      </IonPage>
    </>
  );
};

export default MisPacientes;
