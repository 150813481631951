import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonLabel,
  IonItem,
  IonBackButton,
  IonLoading,
  IonInput,
  IonButton,
  IonAvatar,
  IonToast,
  IonRow,
  IonCol,
  IonSelectOption,
  IonSelect,
  IonAccordion,
  IonGrid,
  IonAccordionGroup,
  IonFabList,
  IonModal,
  IonImg,
  NavContext,
} from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { add, call, camera, cloudDownload, cloudUpload, document, downloadOutline, home, pencil, personAdd, save, search, trash } from 'ionicons/icons';
import { useLoginContext, useParametros, } from '../hooks/login';
import '../theme/informacionPaciente.css';
import { Redirect } from 'react-router';
import { DTOCatAfiliacionSocial, DTOCatAseguradora, DTOCatTipoSangre, DTODiagnostico, DTODocumento, DTOPaciente, DTOCatParentesco, DTOSeguroBeneficiario, FileTemporalDoc, DTOAlergia } from '../services/AlliviaInterfaces';
import { abrirArchivoBrowser, cargarPreview, consultaTipoImagen, eliminarDocumento, emergencyCall, esWeb, guardarDocumentos, leerArchivoDescarga, leerFileBlob, leerImagenDataURL } from '../services/utils.service';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { Camera, CameraResultType } from '@capacitor/camera';
import { URL_ALLIVIA_DOCS, URL_CURP } from '../properties';
import { guardarPaciente } from '../services/paciente.service';
import { leerCatAfiliacionSocial, leerCatAseguradora, leerCatParentesco, leerCatTipoSangre } from '../services/catalogos.service';
import { generarReporteInformacionPaciente } from '../services/reportes.service';
import { IMG_AVATAR, IMG_CARNET, IMG_ESTUDIO_CLINICO, IMG_INE, IMG_PSGMM, IMG_P_SEGURO_VIDA, IMG_RECETA_MEDICA, IMG_VAC, MENSAJE_CARGA_ARCHIVOS, MSJ_CARGA_DOCUMENTOS } from '../constantes';
import { eliminarDocumentosGluster, guardarDocumentosGluster } from '../services/gluster.service';

const InformacionPaciente: React.FC = () => {
  const user = useLoginContext();
  const { parametros, setParametros } = useParametros();
  const [paciente, setPaciente] = useState<DTOPaciente | undefined>();
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [modalDiagnostico, setModalDiagnostico] = useState(false);
  const [alergiaDescripcion, setAlergiaDescripcion] = useState("");
  const [diagnosticoDescripcion, setDiagnosticoDescripcion] = useState("");
  const [catTipoSangre, setCatTipoSangre] = useState<Array<DTOCatTipoSangre> | undefined>();
  const [catParentesco, setCatParentesco] = useState<Array<DTOCatParentesco> | undefined>();
  const [catAfiliacionSocial, setCatAfiliacionSocial] = useState<Array<DTOCatAfiliacionSocial> | undefined>();
  const [catAseguradora, setCatAseguradora] = useState<Array<DTOCatAseguradora> | undefined>();
  const [tempBeneficiario, setTempBeneficiario] = useState<string>();
  const [tempPorcentaje, setTempPorcentaje] = useState<number>();
  const fileInputAvatarRef = useRef<HTMLInputElement>();
  const fileInputDocVacRef = useRef<HTMLInputElement>();
  const fileInputDocIneRef = useRef<HTMLInputElement>();
  const fileInputDocCarnetRef = useRef<HTMLInputElement>();
  const fileInputDocSegGMMRef = useRef<HTMLInputElement>();
  const fileInputDocSegVidaRef = useRef<HTMLInputElement>();
  const [fileAvatar, setFileAvatar] = useState<FileTemporalDoc>({ url: './assets/avatar.png' });
  const [diganosticoTemp, setDiagnosticoTem] = useState<DTODiagnostico>();
  const [alergiaTemp, setAlergiaTem] = useState<DTOAlergia>();
  const [benefiTem, setBenefiTem] = useState<DTOSeguroBeneficiario>();  
  const [imagenOptions] = useState({
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.Uri,
    promptLabelHeader: "Foto",
    promptLabelCancel: "Cancelar",
    promptLabelPhoto: "Galeria",
    promptLabelPicture: "Cámara"
  });
  const [vprevIne, setVprevIne] = useState("");
  const [vprevVac, setVprevVac] = useState("");
  const [vprevCarnet, setVprevCarnet] = useState("");
  const [vprevGMM, setVprevGMM] = useState("");
  const [vprevSvida, setVprevSvida] = useState("");
  const [modalAlergia, setModalAlergia] = useState(false);
  const [modalBeneficiarios, setModalBeneficiarios] = useState(false);
  const [porcentajeTotal, setPorcentajeTotal] = useState(0);
  const [hayCambios,setHayCambios]  = useState(false);
  const [pacienteOriginal, setPacienteOriginal] = useState("");
  const [modalGuardar, setModalGuardar] = useState(false);
  const [back,setBack] = useState(false);
  const {goBack,navigate} = useContext(NavContext);



  const descargarReporteInformacionPaciente = async () => {
    setEstatus({ loading: true, leer: false });
    console.log("Iniciando descarga");
    let docu = await generarReporteInformacionPaciente(user, paciente.idCuidador, paciente.idPersona);
    if(esWeb()){
      console.log(docu.fileStream);      
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='" +docu.fileStream+ "'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }

    // let fileTransfer: FileTransferObject = FileTransfer.create();
    //Se obtiene la Uri del dispositivo
    // let uriSave = await Filesystem.getUri({
    //   path: URL_ALLIVIA_DOCS,
    //   directory: Directory.Documents
    // }
    // );
    // console.log("save uri archivo uri:", uriSave);
    //Se descarga el archivo
    // await fileTransfer.download(docu.fileStream, uriSave.uri + "/" + docu.urlDocumento).then(() => {
    //   docu.urlDocumento = URL_ALLIVIA_DOCS + "/" + docu.urlDocumento;
    //   abrirArchivo(docu);
    // }).catch((e) => { console.log("Error:", e) });
    setEstatus({loading:false,leer:false});
    await guardarDocumentos(docu).then(()=>{
      docu.urlDocumento = URL_ALLIVIA_DOCS + "/" + docu.urlDocumento;
      abrirArchivo(docu);
    }).catch((e)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un erro al descargar el reporte, por favor intenta de nuevo"});
    });
  }

  const takePictureAvatar = async () => {
    try{
      const image = await Camera.getPhoto(imagenOptions);
      var imageUrl = image.webPath;
      let temp:FileTemporalDoc = { url: imageUrl, fileName: "avatar." + image.format, mimeType: "image/" + image.format };
      await handleGuardarPacienteW(temp);            
    }catch(err){
      console.log("error al cargar imagen avatar", err,err.message);
      setMsgToast({showToast:true,statusMessage:"Error al subir la imagen, por favor intenta de nuevo"});
    }
  };

  const takePicture = async (tipoImagen: number) => {
    setEstatus({ loading: true, leer: false });
    try{
      const image = await Camera.getPhoto(imagenOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      var imageUrl = image.webPath;

      // Can be set to the src of an image now
      // imageUrl;
      let { fileName, descripcion } = consultaTipoImagen(tipoImagen);
      let fileDocIne = { url: imageUrl, fileName: fileName + image.format, mimeType: "image/" + image.format };
      await saveDocumentos(fileDocIne, descripcion, tipoImagen);
    }catch(err){
      console.log("Error al guardar la imagen");
    }
    setEstatus({ loading: false, leer: false });
  };

  const saveDocumentos = async (fileDoc: FileTemporalDoc, descripcion: string, tipoImagen: number) => {
    let tempPaciente = { ...paciente };
    if (fileDoc != null) {
      let temDocIne = seleccionaDocumentoExiste(tipoImagen);
      if (!temDocIne) {
        temDocIne = {
          idPersona: paciente.idPersona,
          descripcion: descripcion,
          urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDoc.fileName,
          nombre:fileDoc.fileName,
          catTipoDocumento: { idTipoDoc: tipoImagen },
          estatus: 1,
          remoto: false,
          mimeType: fileDoc.mimeType,
          gluster:0,
        }
      } else {
        temDocIne = { ...temDocIne, urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDoc.fileName, mimeType: fileDoc.mimeType }
      }
          
      await guardarDocumentos(fileDoc);      
      if(parametros&&(parametros.premium||parametros.premiumDuo)){
        //syncronizar imagen en el servidor
        temDocIne ={...temDocIne,fileStream:fileDoc.fileStream,gluster:1};
        await guardarDocumentosGluster(user,temDocIne);
        temDocIne.fileStream=null;        
      }
      tempPaciente = { ...tempPaciente, docIne: temDocIne };
      URL.revokeObjectURL(fileDoc.url);
    }
    const pacient = await guardarPaciente(user, tempPaciente);
    setPaciente(pacient);
    setParametros({ ...parametros, paciente: pacient })

    switch (tipoImagen) {
      case IMG_INE:
        cargarPreview(user,pacient.docIne,parametros).then((tdoc) => {
          setVprevIne(tdoc);
        });
        ; break;
      case IMG_P_SEGURO_VIDA:
        cargarPreview(user,pacient.docSeguroVida,parametros).then((tdoc) => {
          setVprevSvida(tdoc);
        });
        ; break;
      case IMG_VAC:
        cargarPreview(user,pacient.docVacuna,parametros).then((tdoc) => {
          setVprevVac(tdoc);
        });
        ; break;
      case IMG_AVATAR:
        ; break;
      case IMG_PSGMM:
        cargarPreview(user,pacient.docSeguroGMM,parametros).then((tdoc) => {
          setVprevGMM(tdoc);
        });
        ; break;
      case IMG_CARNET:
        cargarPreview(user,pacient.docCarnet,parametros).then((tdoc) => {
          setVprevCarnet(tdoc);
        });
        ; break;
      case IMG_ESTUDIO_CLINICO:
        ; break;
      case IMG_RECETA_MEDICA:
        ; break;
    }
  }

  const seleccionaDocumentoExiste = (tipoImagen: number): DTODocumento => {
    let temDoc: DTODocumento = {};
    switch (tipoImagen) {
      case IMG_INE:
        temDoc = paciente.docIne;
        ; break;
      case IMG_P_SEGURO_VIDA:
        temDoc = paciente.docSeguroVida;
        ; break;
      case IMG_VAC:
        temDoc = paciente.docVacuna;
        ; break;
      case IMG_AVATAR:
        ; break;
      case IMG_PSGMM:
        temDoc = paciente.docSeguroGMM;
        ; break;
      case IMG_CARNET:
        temDoc = paciente.docCarnet;
        ; break;
      case IMG_ESTUDIO_CLINICO:
        ; break;
      case IMG_RECETA_MEDICA:
        ; break;
    }
    return temDoc;
  }  

  const handleEliminarDoc = async (doc: DTODocumento) => {
    setEstatus({ loading: true, leer: false });
    try {
      doc.estatus = 0;
      console.log(paciente);
      let temPaciente = { ...paciente };
      await eliminarDocumento(doc);
      try{
        await eliminarDocumentosGluster(user,doc);
      }catch(err){      
        console.log("error al eliminar los documentos del gluster,",err);
      }
      temPaciente = await guardarPaciente(user, temPaciente);
      setPaciente(temPaciente);

      switch (doc.catTipoDocumento.idTipoDoc) {
        case IMG_INE:
          setVprevIne("");
          fileInputDocIneRef.current.value ="";
          ; break;
        case IMG_P_SEGURO_VIDA:
          setVprevSvida("");
          fileInputDocSegVidaRef.current.value ="";
          ; break;
        case IMG_VAC:
          setVprevVac("");
          fileInputDocVacRef.current.value ="";
          ; break;
        case IMG_AVATAR:
          ; break;
        case IMG_PSGMM:
          setVprevGMM("");
          fileInputDocSegGMMRef.current.value ="";
          ; break;
        case IMG_CARNET:
          setVprevCarnet("");
          fileInputDocCarnetRef.current.value ="";
          ; break;
        case IMG_ESTUDIO_CLINICO:
          ; break;
        case IMG_RECETA_MEDICA:
          ; break;
      }
      setMsgToast({ showToast: true, statusMessage: "El documento se eliminó correctamente" });
    } catch (error) {
      setMsgToast({ showToast: true, statusMessage: "Ocurrió un error al guardar los datos" });
    };
    setEstatus({ loading: false, leer: false });
  }
  

  const abrirArchivo = async (doc: DTODocumento) => {

    if(esWeb()){         
      let docTemp = await leerArchivoDescarga(doc);
      console.log(docTemp);
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='"+docTemp+"'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }

    let uri = await Filesystem.getUri({
      path: doc.urlDocumento,
      directory: Directory.Documents
    }
    );
    console.log("abir archivo uri:", uri);
    await FileOpener.showOpenWithDialog(uri.uri, doc.mimeType).then().catch((e) => {
      console.log("error", e);
      abrirArchivoBrowser(doc).catch((e) => {
        setMsgToast({ showToast: true, statusMessage: "El documento no se encuentra en este dispositivo" });
      });
    });
  }

  const handleSeleccionarDocIne = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event);
    let { descripcion } = consultaTipoImagen(IMG_INE);
    await saveDocumentos(fileDoc, descripcion, IMG_INE)
    .catch((e)=>{
      console.log("error al subir imagenes",e);
      setEstatus({ loading: false, leer: false })
    });
    setEstatus({ loading: false, leer: false });
  }

  const handleSeleccionarDocVacuna = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event);
    let { descripcion } = consultaTipoImagen(IMG_VAC);
    saveDocumentos(fileDoc, descripcion, IMG_VAC);
    setEstatus({ loading: false, leer: false });
  }

  const handleSeleccionarDocCarnet = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event);
    let { descripcion } = consultaTipoImagen(IMG_CARNET);
    await saveDocumentos(fileDoc, descripcion, IMG_CARNET)
    .catch(()=>{setEstatus({ loading: false, leer: false })});
    setEstatus({ loading: false, leer: false });
  }

  const handleSeleccionarDocSegGMM = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event);
    let { descripcion } = consultaTipoImagen(IMG_PSGMM);
    await saveDocumentos(fileDoc, descripcion, IMG_PSGMM)
    .catch(()=>{setEstatus({ loading: false, leer: false })});
    setEstatus({ loading: false, leer: false });
  }

  const handleSeleccionarDocSegVida = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event);
    let { descripcion } = consultaTipoImagen(IMG_P_SEGURO_VIDA);
    await saveDocumentos(fileDoc, descripcion, IMG_P_SEGURO_VIDA)
    .catch(()=>{setEstatus({ loading: false, leer: false })});
    setEstatus({ loading: false, leer: false });
  }

  const leerArchivo = async (event: React.ChangeEvent<HTMLInputElement>): Promise<FileTemporalDoc> => {
    if (event.target.files.length > 0) {
      const file = event.target.files.item(0);
      const temUrl = { url: URL.createObjectURL(file), fileName: file.name, mimeType: file.type };
      console.log("created URL: ", temUrl);
      return temUrl;
    }
  }



  const handleEliminarDiagnostico = (diag: DTODiagnostico) => {
    setEstatus({ loading: true, leer: false });
    diag.estatus = 0;
    setPaciente(paciente);
    validaCambiosGuardar(paciente);
    setEstatus({ loading: false, leer: false });
  }

  const handleActualizarDiagnostico = (diag: DTODiagnostico) => {
    setDiagnosticoTem(diag);
    setDiagnosticoDescripcion(diag.descripcion);
    setModalDiagnostico(true);
  }

  const handleEliminarBeneficiario = (benef: DTOSeguroBeneficiario) => {
    setEstatus({ loading: true, leer: false });
    benef.estatus = 0;
    validaPorcentajeTotal(paciente);
    setPaciente(paciente);
    validaCambiosGuardar(paciente);
    setEstatus({ loading: false, leer: false });
  }

  const handleActualizarBeneficiario = (benef: DTOSeguroBeneficiario) => {
    setBenefiTem(benef);
    //setDiagnosticoDescripcion(diag.descripcion);    
  }

  const handelFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      const file = event.target.files.item(0);
      console.log(file);
      const pictureUrl = URL.createObjectURL(file);
      setFileAvatar({ url: pictureUrl, fileName: file.name, mimeType: file.type });
      console.log("created URL: ", pictureUrl);
    }
  }
  
  const handleGuardarPaciente = async () => {
    handleGuardarPacienteW(fileAvatar);
  }

  const handleGuardarPacienteW = async (fileAvatar:FileTemporalDoc) => {    
    setEstatus({ loading: true, leer: false });
    try {
      let tempPaciente = { ...paciente };
      if(!tempPaciente.nombre||tempPaciente.nombre===null||tempPaciente.nombre===""){
        setMsgToast({ showToast: true, statusMessage: "Ingresa el nombre del paciente" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      if(!tempPaciente.apPaterno||tempPaciente.apPaterno===null||tempPaciente.apPaterno===""){
        setMsgToast({ showToast: true, statusMessage: "Ingresa el apellido paterno del paciente" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      if (fileAvatar != null && (fileAvatar.url.startsWith("blob") || fileAvatar.url.startsWith("http") || fileAvatar.url.startsWith("capacitor"))) {
        let blob = await leerFileBlob(fileAvatar.url);
        let array = await leerImagenDataURL(blob);

        let temAvatar: DTODocumento = paciente.avatar;
        if (!temAvatar) {
          temAvatar = {
            idPersona: paciente.idPersona,
            descripcion: "avatar",
            urlDocumento: fileAvatar.fileName,
            nombre:fileAvatar.fileName,
            catTipoDocumento: { idTipoDoc: 4 },
            estatus: 1,
            remoto: true,
            fileStream: array,
            mimeType: fileAvatar.mimeType,
            gluster:0
          }
        } else {
          temAvatar = { ...temAvatar, fileStream: array, urlDocumento: fileAvatar.fileName, mimeType: fileAvatar.mimeType }
        }
        tempPaciente = { ...tempPaciente, avatar: temAvatar };
      }

      console.log("guardarPaciente: ", tempPaciente);
      agregarNuevoBeneficiario(paciente);
      if (tempPaciente.seguroVida && !tempPaciente.seguroVida.idCatAseguradora) {
        setMsgToast({ showToast: true, statusMessage: "Selecciona una aseguradora" });
        setEstatus({ loading: false, leer: false });
        return
      }
      const pacient = await guardarPaciente(user, tempPaciente);
      setPaciente(pacient);
      setPacienteOriginal(JSON.stringify(pacient));
      setHayCambios(false);
      setParametros({ ...parametros, paciente: pacient })
      setMsgToast({ showToast: true, statusMessage: "Los datos de perfil se guardaron correctamente" });
    } catch (error) {
      console.log("guardarPaciente: ", error);
      setMsgToast({ showToast: true, statusMessage: "Ocurrió un error al guardar los datos" });
    };
    setEstatus({ loading: false, leer: false });
    console.log("boton mi cuidador");
  }

  useEffect(() => {
    console.log("Entrando a useefect menuPaciente: ");
    if (parametros) {
      validaPorcentajeTotal(parametros.paciente);
      if (parametros.paciente.avatar && parametros.paciente.avatar.fileStream) {
        setFileAvatar({ url: parametros.paciente.avatar.fileStream + "" });
      }
      if (parametros.paciente.docIne) {
        cargarPreview(user,parametros.paciente.docIne,parametros).then((tdoc) => {
          setVprevIne(tdoc);
        });
      }
      if (parametros.paciente.docCarnet) {
        cargarPreview(user,parametros.paciente.docCarnet,parametros).then((tdoc) => {
          setVprevCarnet(tdoc);
        });
      }
      if (parametros.paciente.docSeguroGMM) {
        cargarPreview(user,parametros.paciente.docSeguroGMM,parametros).then((tdoc) => {
          setVprevGMM(tdoc);
        });
      }
      if (parametros.paciente.docSeguroVida) {
        cargarPreview(user,parametros.paciente.docSeguroVida,parametros).then((tdoc) => {
          setVprevSvida(tdoc);
        });
      }
      if (parametros.paciente.docVacuna) {
        cargarPreview(user,parametros.paciente.docVacuna,parametros).then((tdoc) => {
          setVprevVac(tdoc);
        });
      }
      setPaciente(parametros.paciente);
      setPacienteOriginal(JSON.stringify(parametros.paciente));
    }
  }, [parametros]);

  useEffect(() => {
    const val = async () => {
      return await Promise.all([
        leerCatTipoSangre(user),
        leerCatAfiliacionSocial(user),
        leerCatAseguradora(user),
        leerCatParentesco(user)
      ]);
    }
    //if(estatus.leer===true){            
    val().then((result) => {
      setCatTipoSangre(result[0]);
      setCatAfiliacionSocial(result[1]);
      setCatAseguradora(result[2]);
      setCatParentesco(result[3]);
      setEstatus({ loading: false, leer: false });
    });
    //}
  }, []);

  /**
   * Funcion helper para concatenar valores al objeto
   * @param valor 
   */
  const agregarValor = (valor: any) => {
    let tempPac= { ...paciente, ...valor };
    setPaciente(tempPac);
    validaCambiosGuardar(tempPac);
  }

  const agregarDiagnostico = async() => {
    setEstatus({ loading: true, leer: false });
    if (diganosticoTemp != null) {
      let diagnosticos = paciente.diagnosticos;
      let diagnosticosTem: Array<DTODiagnostico> = [];
      diagnosticos.map((diag) => {
        if (diag.idDiagnostico === diganosticoTemp.idDiagnostico) {
          diagnosticosTem.push({ ...diganosticoTemp, descripcion: diagnosticoDescripcion });
        } else {
          diagnosticosTem.push(diag);
        }
      });
      console.log("diag", diagnosticos);
      console.log("actualizar: ", paciente);
      let temPac = { ...paciente, diagnosticos: diagnosticosTem };
      setPaciente(temPac);
      await validaCambiosGuardar(temPac);
      setDiagnosticoTem(null);
    } else {
      let diag: DTODiagnostico = { idPersona: paciente.idPersona, descripcion: diagnosticoDescripcion, estatus: 1 }
      let diagnosticos = paciente.diagnosticos ? paciente.diagnosticos : Array<DTODiagnostico>();
      diagnosticos.push(diag);
      let temPac ={ ...paciente, diagnosticos: diagnosticos };
      setPaciente(temPac);
      await validaCambiosGuardar(temPac);
    }
    setModalDiagnostico(false);
    setDiagnosticoDescripcion('');
    setEstatus({ loading: false, leer: false });
  }

  const agregarAlergia = async () => {
    setEstatus({ loading: true, leer: false });
    if (alergiaTemp) {
      let alergias = paciente.alergias;
      let alergTemp: Array<DTOAlergia> = [];
      alergias.forEach((alerg) => {
        if (alerg.idAlergia === alergiaTemp.idAlergia) {
          alergTemp.push({ ...alergiaTemp, alergia: alergiaDescripcion, estatus: 1 });
        } else {
          alergTemp.push(alerg);
        }
      });
      let tempPac = { ...paciente, alergias: alergTemp };
      setPaciente(tempPac);
      await validaCambiosGuardar(tempPac);
      setAlergiaTem(null);
      console.log("alergias", alergias);
    } else {
      let alerg: DTOAlergia = { idPersona: paciente.idPersona, alergia: alergiaDescripcion, estatus: 1 }
      let alergias = paciente.alergias;
      alergias.push(alerg);
      let temPac={ ...paciente, alergias: alergias };
      setPaciente(temPac);
      await validaCambiosGuardar(temPac);
      console.log("alergias", alergias);
    }
    setModalAlergia(false);
    setAlergiaDescripcion("");
    setEstatus({ loading: false, leer: false });
  }

  const handleEliminarAlergia = (alerg: DTOAlergia) => {
    setEstatus({ loading: true, leer: false });
    alerg.estatus = 0;
    setPaciente(paciente);
    validaCambiosGuardar(paciente);
    setEstatus({ loading: false, leer: false });
  }

  const handleActualizarAlergia = async (alerg: DTOAlergia) => {
    console.log(alerg)
    setAlergiaTem(alerg);
    setAlergiaDescripcion(alerg.alergia);
    setModalAlergia(true);
  }

  const cancelarAlergia = async () => {
    setEstatus({ loading: true, leer: false });
    setAlergiaDescripcion("");
    setModalAlergia(false);
    setEstatus({ loading: false, leer: false });
  }

  const agregarSeguroVida = async(valor: any) => {
    if (!valor) {
      return;
    }
    if (!(valor.idCatAseguradora || valor.numeroPoliza || valor.vigenciaPoliza || valor.titular)) {
      return
    }
    let segVida = paciente.seguroVida;
    if (!segVida) {
      segVida = { idPersona: paciente.idPersona, seguroBeneficiarios: [], estatus: 1 }
    }
    segVida = { ...segVida, ...valor };
    let temPac = { ...paciente, seguroVida: segVida };    
    setPaciente(temPac);
    await validaCambiosGuardar(temPac);
  }

  const validaPorcentaje = (porcentaje: number) => {
    let po = 0;
    if (paciente != null && paciente.seguroVida != null && paciente.seguroVida.seguroBeneficiarios != null) {
      paciente.seguroVida.seguroBeneficiarios.map((b) => {
        if (b.estatus != 0) {
          po = po + b.porcentaje;
        }
      });
    }
    if ((po + porcentaje) > 100) {
      setTempPorcentaje(null);
      setTempBeneficiario(null);
      setMsgToast({ showToast: true, statusMessage: "La suma de porcentajes es mayor al 100%" });
    } else {
      setTempPorcentaje(porcentaje);
    }
  }

  const validaPorcentajeTotal = (tpaci: DTOPaciente) => {
    let po = 0;
    if (tpaci != null && tpaci.seguroVida != null && tpaci.seguroVida.seguroBeneficiarios != null) {
      tpaci.seguroVida.seguroBeneficiarios.forEach((b) => {
        if (b.estatus != 0) {
          po = po + b.porcentaje;
        }
      });
    }
    setPorcentajeTotal(po);
  }

  const agregarNuevoBeneficiario = (pacient: DTOPaciente) => {
    let benef: DTOSeguroBeneficiario[] = [];
    if (!tempBeneficiario) {
      return;
    }
    if (!tempPorcentaje) {
      return;
    }
    //validar si existe un seguro de vida creado
    if (pacient.seguroVida) {
      //leer la lista de beneficiarios
      benef = pacient.seguroVida.seguroBeneficiarios;
      //agregar nuevo beneficiario      
    }
    let idSeguro = pacient.seguroVida ? pacient.seguroVida.idSeguro : null;
    benef.push({ beneficiario: tempBeneficiario, porcentaje: tempPorcentaje, estatus: 1, idSeguro: idSeguro });


    if (!paciente.seguroVida) {
      //Si no existe seguro de vida crear uno
      console.log("benef: ", benef);
      setPaciente({ ...pacient, seguroVida: { idPersona: pacient.idPersona, seguroBeneficiarios: benef } });
      console.log("patient: ", pacient);
    }
    //reiniciar la variable
    setTempBeneficiario(null);
    setTempPorcentaje(null);
  }

  const abrirModalBeneficiarios = async () => {
    setEstatus({ loading: true, leer: false });
    setModalBeneficiarios(true)
    setEstatus({ loading: false, leer: false });
  }

  const cancelarBeneficiarios = async () => {
    setEstatus({ loading: true, leer: false });
    setModalBeneficiarios(false);
    setEstatus({ loading: false, leer: false });
  }

  const agregarBeneficiarios = async () => {
    setEstatus({ loading: true, leer: false });
    try {
      let temPac = { ...paciente };
      let benef: DTOSeguroBeneficiario[] = [];
      if (!tempBeneficiario || tempBeneficiario === "") {
        setMsgToast({ showToast: true, statusMessage: "Ingresa un nombre de beneficiario" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      if (!tempPorcentaje) {
        setMsgToast({ showToast: true, statusMessage: "Selecciona un porcentaje" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      //validar si existe un seguro de vida creado
      if (!temPac.seguroVida) {
        //Si no existe seguro de vida crear uno    
        temPac = { ...temPac, seguroVida: { idPersona: temPac.idPersona, seguroBeneficiarios: benef } };
        benef = temPac.seguroVida.seguroBeneficiarios;
      } else {
        //leer la lista de beneficiarios
        benef = temPac.seguroVida.seguroBeneficiarios;
        //agregar nuevo beneficiario      
      }
      benef.push({ beneficiario: tempBeneficiario, porcentaje: tempPorcentaje, estatus: 1, idSeguro: temPac.seguroVida.idSeguro });
      //reiniciar la variable
      validaPorcentajeTotal(temPac);
      setPaciente({ ...temPac });
      setTempBeneficiario(null);
      setTempPorcentaje(null);
      setModalBeneficiarios(false);
    } catch (error) {
      setMsgToast({ showToast: true, statusMessage: "Ocurrió un error al agregar el beneficiario, por favor intenta de nuevo" });
    }
    setEstatus({ loading: false, leer: false });
  }

  const validaCambiosGuardar=async (pacTemp:DTOPaciente) =>{
    console.log(JSON.stringify(pacTemp),pacienteOriginal);
    setHayCambios(JSON.stringify(pacTemp)!=pacienteOriginal);
  }

  const handleAbrirModalGuardar = async(b:boolean) =>{
    setBack(b);
    setModalGuardar(true);
  }

  const handleAceptarGuardar=async()=>{
    await handleGuardarPaciente();
    setModalGuardar(false);
    if(back){
      goBack();
    }else{
      navigate('/');
    }
  }

  const handleCancelarGuardar= async()=>{
    setHayCambios(false);
    setModalGuardar(false);   
    if(back){
      goBack();
    }else{
      navigate('/');
    }
  }

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  if (!paciente) {
    return (<IonLoading isOpen message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />);
  }

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons hidden={hayCambios} slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonButtons hidden={!hayCambios} slot='start'>
              <IonButton color="naranja" onClick={()=>handleAbrirModalGuardar(true)}  ><IonIcon icon={save}></IonIcon></IonButton>
            </IonButtons>
            <IonTitle className="title_header">Información Personal</IonTitle>
            <IonButtons slot='end'>
              <IonButton color='primary' onClick={() => { descargarReporteInformacionPaciente() }} >
                <IonIcon size='large' icon={downloadOutline} ></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className="textos_personal">
            <p>
              Los datos que se llenan a partir del ingreso a la app, no son registrados por <span className="apremium">allivia</span> o ninguno de sus administradores, por lo que tu información está segura.
            </p>
          </div>
          
          <div className='panel-paciente'>
            <p className="textos_personal">Por favor ingrese todos los datos solicitados y utilice el
              <span> </span>
              <span className="naranjanew">
                botón para guardar su información&nbsp; &nbsp;
              </span>
              <img className="icon_small" src='./assets/savesmall.png'></img>
            </p>
            <div className="contenedor_avatar">
              <IonAvatar className='avatar'>
                <img src={fileAvatar.url} alt='avatar' >
                </img>
                <IonButton className="btn-avatar" size='small' shape='round' onClick={() => takePictureAvatar()}>
                  <IonIcon icon={camera} ></IonIcon>
                </IonButton>
              </IonAvatar>
              <input type='file' className="btn-avatar" accept='image/*' hidden onChange={handelFileChange} ref={fileInputAvatarRef}>

              </input>
            </div>
          </div>
          <div className='panel-paciente-datos'>
            <div className='panel-paciente-form'>
              <IonAccordionGroup>
                <IonAccordion value="dGenerales">
                  <IonItem slot="header" >
                    <IonLabel>Datos Generales</IonLabel>
                  </IonItem>
                  <IonGrid slot='content'>
                    <IonRow >
                      <IonCol size='6'>
                        <IonLabel position='floating'>Nombre(s):</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" placeholder="Nombre" type="text" inputMode='text' autocapitalize="words" value={paciente.nombre}
                          onIonChange={(event) => agregarValor({ nombre: event.detail.value })}>
                        </IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Apellido Paterno:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" placeholder="Ap Paterno" type="text" inputMode='text' autocapitalize="words" value={paciente.apPaterno}
                          onIonChange={(event) => agregarValor({ apPaterno: event.detail.value })}>
                        </IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Apellido Materno:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" placeholder="Ap Materno" type="text" inputMode='text' autocapitalize="words" value={paciente.apMaterno}
                          onIonChange={(event) => agregarValor({ apMaterno: event.detail.value })}>
                        </IonInput>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size='6'>
                        <IonLabel  position='floating'>Parentesco:</IonLabel>
                      </IonCol>
                      <IonCol size="6" size-md="4">
                        <IonSelect className="listas_datos" placeholder='Seleccione parentesco' okText='Aceptar' cancelText='Cancelar' value={catParentesco ? paciente.idParentesco : null}
                          onIonChange={(event) => agregarValor({ idParentesco: event.detail.value })}>
                         {
                            catParentesco ? catParentesco.map((pare) => {
                              return <>
                                <IonSelectOption key={pare.idParentesco} value={pare.idParentesco}>{pare.parentesco}</IonSelectOption>
                              </>
                            }) : null
                          }
                        </IonSelect>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Fecha de nacimiento:</IonLabel>
                      </IonCol>
                      <IonCol size="6" size-md="4">
                        <IonInput type="date" value={paciente.fechaNacimiento}
                          onIonChange={(event) => agregarValor({ fechaNacimiento: event.detail.value })} />
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Sexo:</IonLabel>
                      </IonCol>
                      <IonCol size="6" size-md="4">
                        <IonSelect className="listas_datos" placeholder="Seleccione su Sexo" value={paciente.idSexo}
                          onIonChange={(event) => agregarValor({ idSexo: event.detail.value })}>
                          <IonSelectOption value={1}>Femenino</IonSelectOption>
                          <IonSelectOption value={2}>Masculino</IonSelectOption>
                        </IonSelect>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>CURP:</IonLabel>
                      </IonCol>
                      <IonCol size="6" size-md="4">
                        <IonInput className='curp' type="text" value={paciente.curp} autocapitalize="on"
                          onIonChange={(event) => agregarValor({ curp: event.detail.value })} />
                        <IonButton className='link-curp' fill="clear" href={URL_CURP} target='_blank' >
                          Consulta tu CURP

                        </IonButton>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>En caso de emergencia comunicarse al número:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="tel" value={paciente.telefonoEmergencia}
                          onIonChange={(event) => agregarValor({ telefonoEmergencia: event.detail.value })} />
                      </IonCol>
                      <IonCol>
                        <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                          <div className='upload-panel'>
                            <div className='upload-preview'>
                              <img src={vprevIne} alt="">
                              </img>
                            </div>
                            <div className='upload-panel-buttons'>                                                           
                              <IonButton className="photo" hidden={paciente.docIne!=null} shape='round' color='rosa' onClick={() => takePicture(IMG_INE)}>
                                <IonIcon icon={camera} />
                              </IonButton>
                              <IonButton hidden={paciente.docIne!=null} shape='round' color='rosa' onClick={() => fileInputDocIneRef.current.click()}>
                                <IonIcon icon={document} />
                              </IonButton>
                              <IonButton hidden={!paciente.docIne} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docIne) }}>
                                <IonIcon icon={cloudDownload} />
                              </IonButton>
                              <IonButton hidden={!paciente.docIne} shape='round' color='rosa' onClick={() => { handleEliminarDoc(paciente.docIne) }}>
                                <IonIcon icon={trash} />
                              </IonButton>
                            </div>
                            <IonLabel>INE</IonLabel>
                          </div>
                          <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocIne} ref={fileInputDocIneRef}></input>
                          </div>
                        <div className='msj-doc-noPremium' hidden={!(esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo))}>
                          <span>{MSJ_CARGA_DOCUMENTOS}</span>
                        </div>
                        <div>
                          <p className="textos_descriptivos">
                            Te sugerimos subir tu INE por ambos lados en un archivo PDF
                          </p>
                        </div>
                        <IonButton onClick={handleGuardarPaciente} fill='clear' className="recordar_save">
                          <div className='recordar-guardar' >
                            <span className="naranja">
                              Por favor guarde su información
                            </span>
                            <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                          </div>
                        </IonButton>
                        
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonAccordion>
                <IonAccordion value="dDatosMedicos">
                  <IonItem slot="header" >
                    <IonLabel>Datos médicos importantes</IonLabel>
                  </IonItem>
                  <IonGrid slot='content'>
                    <IonRow>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Tipo de sangre:</IonLabel>
                      </IonCol>
                      <IonCol size="6" size-md="4">
                        <IonSelect className="listas_datos" placeholder='Tipo de sangre' okText='Aceptar' cancelText='Cancelar' value={catTipoSangre ? paciente.idTipoSangre : null}
                          onIonChange={(event) => { agregarValor({ idTipoSangre: event.detail.value }) }} >
                          {catTipoSangre ? catTipoSangre.map((cts) => {
                            return <IonSelectOption key={cts.idTipoSangre} value={cts.idTipoSangre}>{cts.descripcion}</IonSelectOption>
                          }) : null
                          }
                        </IonSelect>
                      </IonCol>
                    </IonRow>
                    <IonRow className='panel-diagnosticos'>
                      <IonCol size='12'>
                        <IonLabel position='floating'>Alergias:</IonLabel>
                      </IonCol>
                      {
                        paciente && paciente.alergias ? paciente.alergias.map((aler) =>
                          aler.estatus > 0 ?
                            <>
                              <IonCol size="10" size-md="9">
                                <IonInput className="capitalizar allivia-input-disabled" type="text" inputMode='text' disabled={true} value={aler.alergia} />
                              </IonCol>
                              <IonCol className='panel-acciones' size='2' sizeSm='3'>
                                <IonButton hidden={!aler.idAlergia} fill='clear' size='small' shape='round' onClick={() => { handleActualizarAlergia(aler) }}><IonIcon color='rosa' icon={pencil} /></IonButton>
                                <IonButton fill='clear' size='small' shape='round' onClick={() => { handleEliminarAlergia(aler) }} ><IonIcon color='rosa' icon={trash} /></IonButton>
                              </IonCol>
                            </> : <></>
                        ) : <></>
                      }
                      <IonCol size='12' className='panel-add-diag'>
                        <IonButton color="rosa" size='small' shape='round' onClick={() => { setModalAlergia(true); }}>
                          <IonIcon icon={add} />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    <IonRow className='panel-diagnosticos'>
                      <IonCol size='12' >
                        <IonLabel position='floating'>Diagnósticos médicos de importancia para tí:</IonLabel>
                      </IonCol>
                      {paciente && paciente.diagnosticos ? paciente.diagnosticos.map((diagnost) =>
                        diagnost.estatus > 0 ?
                          <>
                            <IonCol size='10' sizeSm='9'>
                              <IonInput className="capitalizar allivia-input-disabled" key={diagnost.idDiagnostico} disabled={true} value={diagnost.descripcion} />
                            </IonCol>
                            <IonCol className='panel-acciones' size='2' sizeSm='3'>
                              <IonButton hidden={!diagnost.idDiagnostico} fill='clear' size='small' shape='round' onClick={() => { handleActualizarDiagnostico(diagnost); }}><IonIcon color='rosa' icon={pencil} /></IonButton>
                              <IonButton fill='clear' size='small' shape='round' onClick={() => { handleEliminarDiagnostico(diagnost); }} ><IonIcon color='rosa' icon={trash} /></IonButton>
                            </IonCol>

                          </>
                          : null
                      ) : null}
                      <IonCol size='12' className='panel-add-diag'>
                        <IonButton color="rosa" size='small' shape='round' onClick={() => { setModalDiagnostico(true); }}>
                          <IonIcon icon={add} />
                        </IonButton>
                      </IonCol>
                      <IonButton onClick={handleGuardarPaciente} fill='clear' className="recordar_save">
                        <div className='recordar-guardar' >
                          <span className="naranja">
                            Por favor guarde su información
                          </span>
                          <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                        </div>
                      </IonButton>
                    </IonRow>
                  </IonGrid>
                </IonAccordion>
                <IonAccordion value="dSeguroSalud">
                  <IonItem slot="header" >
                    <IonLabel>Datos de Seguros de Salud</IonLabel>
                  </IonItem>
                  <IonGrid slot='content'>
                    <IonRow>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Tipo de Afiliación Social:</IonLabel>
                      </IonCol>
                      <IonCol size="6" size-md="4">
                        <IonSelect className="listas_datos" placeholder="Seleccione su afiliación" okText='Aceptar' cancelText='Cancelar'
                          value={catAfiliacionSocial ? paciente.idAfiliacion : null}
                          onIonChange={(event) => agregarValor({ idAfiliacion: event.detail.value })}>
                          {catAfiliacionSocial ? catAfiliacionSocial.map((catAS) => {
                            return <IonSelectOption key={catAS.idAfiliacionSocial} value={catAS.idAfiliacionSocial} >{catAS.descripcion}</IonSelectOption>
                          }) : null
                          }
                        </IonSelect>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Número de seguridad social y/o afiliación:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="text" value={paciente.nuAfiliacion}
                          onIonChange={(event) => agregarValor({ nuAfiliacion: event.detail.value })} />
                      </IonCol>
                      <IonCol size='12'>
                        <p className='ds-titulo'>¿Cuenta con Seguro de Gastos Médicos Mayores?</p>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Aseguradora:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonSelect className="listas_datos" placeholder="Seleccione su aseguradora"
                          okText='Aceptar' cancelText='Cancelar'
                          value={catAseguradora ? paciente.idAseguradora : null}
                          onIonChange={(event) => agregarValor({ idAseguradora: event.detail.value })}
                        >
                          {catAseguradora ? catAseguradora.map((catAsg) => {
                            return <IonSelectOption key={catAsg.idAseguradora} value={catAsg.idAseguradora} >{catAsg.descripcion}</IonSelectOption>
                          }) : null
                          }
                        </IonSelect>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Número de póliza:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="text" value={paciente.numeroPolizaGMM}
                          onIonChange={(event) => agregarValor({ numeroPolizaGMM: event.detail.value })} />
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Vigencia de póliza:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="date" value={paciente.vigenciaPoliza}
                          onIonChange={(event) => agregarValor({ vigenciaPoliza: event.detail.value })} />
                      </IonCol>
                          <hr className="linea_urgencias"></hr>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Clínica de Urgencias preferida (pública o privada):</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text'  autocapitalize="words" value={paciente.hospitalUrgencias}
                          onIonChange={(event) => agregarValor({ hospitalUrgencias: event.detail.value })} />
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Teléfono de su hospital:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="tel" value={paciente.telefonoHospital}
                          onIonChange={(event) => agregarValor({ telefonoHospital: event.detail.value })} />
                      </IonCol>
                      <IonCol size='12'>
                      <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                        <div className='upload-panel'>
                          <div className='upload-preview'>
                            <img src={vprevVac}>
                            </img>
                          </div>
                          <div className='upload-panel-buttons'>                           
                            <IonButton className="photo" hidden={paciente.docVacuna != null} shape='round' color='rosa' onClick={() => takePicture(IMG_VAC)}>
                              <IonIcon icon={camera} />
                            </IonButton>
                            <IonButton hidden={paciente.docVacuna != null} shape='round' color='rosa' onClick={() => fileInputDocVacRef.current.click()}>
                              <IonIcon icon={document} />
                            </IonButton>
                            <IonButton hidden={!paciente.docVacuna} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docVacuna) }}>
                              <IonIcon icon={cloudDownload} />
                            </IonButton>
                            <IonButton hidden={!paciente.docVacuna} shape='round' color='rosa' onClick={() => { handleEliminarDoc(paciente.docVacuna) }}>
                              <IonIcon icon={trash} />
                            </IonButton>
                          </div>
                          <IonLabel>Certificado de vacunación COVID-19</IonLabel>
                        </div>
                        <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocVacuna} ref={fileInputDocVacRef}></input>
                        </div>                        
                      </IonCol>                      
                      <IonCol size='12'>
                      <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                        <div className='upload-panel'>
                          <div className='upload-preview'>
                            <img src={vprevCarnet}>
                            </img>
                          </div>
                          <div className='upload-panel-buttons'>                            
                            <IonButton className="photo" hidden={paciente.docCarnet != null} shape='round' color='rosa' onClick={() => takePicture(IMG_CARNET)}>
                              <IonIcon icon={camera} />
                            </IonButton>
                            <IonButton hidden={paciente.docCarnet != null} shape='round' color='rosa' onClick={() => fileInputDocCarnetRef.current.click()}>
                              <IonIcon icon={document} />
                            </IonButton>
                            <IonButton hidden={!paciente.docCarnet} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docCarnet) }}>
                              <IonIcon icon={cloudDownload} />
                            </IonButton>
                            <IonButton hidden={!paciente.docCarnet} shape='round' color='rosa' onClick={() => { handleEliminarDoc(paciente.docCarnet) }}>
                              <IonIcon icon={trash} />
                            </IonButton>
                          </div>
                          <IonLabel>CARNET</IonLabel>
                        </div>
                        <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocCarnet} ref={fileInputDocCarnetRef}></input>
                        </div> 
                        <div>
                          <p className="textos_descriptivos">
                            Te sugerimos subir tu carnet con la vigencia en un archivo PDF
                          </p>  
                        </div>         
                      </IonCol>
                      <IonCol size='12'>
                      <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                        <div className='upload-panel'>
                          <div className='upload-preview'>
                            <img src={vprevGMM}>
                            </img>
                          </div>
                          <div className='upload-panel-buttons'>                            
                            <IonButton className="photo" hidden={paciente.docSeguroGMM != null} shape='round' color='rosa' onClick={() => takePicture(IMG_PSGMM)}>
                              <IonIcon icon={camera} />
                            </IonButton>
                            <IonButton hidden={paciente.docSeguroGMM != null} shape='round' color='rosa' onClick={() => fileInputDocSegGMMRef.current.click()}>
                              <IonIcon icon={document} />
                            </IonButton>
                            <IonButton hidden={!paciente.docSeguroGMM} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docSeguroGMM) }}>
                              <IonIcon icon={cloudDownload} />
                            </IonButton>
                            <IonButton hidden={!paciente.docSeguroGMM} shape='round' color='rosa' onClick={() => { handleEliminarDoc(paciente.docSeguroGMM) }}>
                              <IonIcon icon={trash} />
                            </IonButton>
                          </div>
                          <IonLabel>Póliza de Seguro de Gastos Médicos Mayores</IonLabel>
                        </div>
                        <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocSegGMM} ref={fileInputDocSegGMMRef}></input>
                        </div>
                        <div className='msj-doc-noPremium' hidden={!(esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo))}>
                          <span>{MSJ_CARGA_DOCUMENTOS}</span>
                        </div>
                      </IonCol>
                      
                      <IonButton onClick={handleGuardarPaciente} fill='clear' className="recordar_save">
                        <div className='recordar-guardar' >
                          <span className="naranja">
                            Por favor guarde su información
                          </span>
                          <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                        </div>
                      </IonButton>
                    </IonRow>
                  </IonGrid>
                </IonAccordion>
                <IonAccordion value="dSeguroVida">
                  <IonItem slot="header" >
                    <IonLabel>Datos de Seguros de Vida</IonLabel>
                  </IonItem>
                  <IonGrid slot='content'>
                    <IonRow>
                      <IonCol size='12'>
                        <p className='ds-aviso'>Sí cuenta con Seguro de Vida, por favor ingrese los siguientes datos:</p>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Aseguradora:*</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonSelect className="listas_datos" placeholder="Seleccione su aseguradora" okText='Aceptar' cancelText='Cancelar'
                          value={catAseguradora && paciente.seguroVida ? paciente.seguroVida.idCatAseguradora : null}
                          onIonChange={(event) => event.detail.value ? agregarSeguroVida({ idCatAseguradora: event.detail.value }) : {}}>
                          {catAseguradora ? catAseguradora.map((catAsg) => {
                            return <IonSelectOption key={catAsg.idAseguradora} value={catAsg.idAseguradora} >{catAsg.descripcion}</IonSelectOption>
                          }) : null
                          }
                        </IonSelect>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Número de póliza:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="text"
                          value={paciente.seguroVida ? paciente.seguroVida.numeroPoliza : null}
                          onIonChange={(event) => event.detail.value ? agregarSeguroVida({ numeroPoliza: event.detail.value }) : {}} />
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Vigencia Póliza:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="date"
                          value={paciente.seguroVida ? paciente.seguroVida.vigenciaPoliza : null}
                          onIonChange={(event) => agregarSeguroVida({ vigenciaPoliza: event.detail.value })} />
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Titular:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words"
                          value={paciente.seguroVida ? paciente.seguroVida.titular : null}
                          onIonChange={(event) => event.detail.value ? agregarSeguroVida({ titular: event.detail.value }) : {}} />
                      </IonCol>
                    </IonRow>
                    <IonRow className='panel-benef'>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Beneficiarios</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonLabel position='floating'>Porcentaje</IonLabel>
                      </IonCol>
                      {paciente && paciente.seguroVida && paciente.seguroVida.seguroBeneficiarios ? paciente.seguroVida.seguroBeneficiarios.map((benefic) =>
                        benefic.estatus > 0 ?
                          <>
                            <IonCol size='6'>
                              <IonInput  className="capitalizar allivia-input-disabled" id="benef" type="text" inputMode='text' autocapitalize="words" key={benefic.idBeneficiario} disabled value={benefic.beneficiario}></IonInput>
                            </IonCol>
                            <IonCol size='4'>
                              <IonInput className='allivia-input-disabled' disabled value={benefic.porcentaje}></IonInput>
                            </IonCol>
                            <IonCol className='panel-acciones' size='2'>
                              <IonButton hidden fill='clear' size='small' shape='round' onClick={() => { handleActualizarBeneficiario(benefic); }}>
                                <IonIcon color='rosa' icon={pencil}></IonIcon>
                              </IonButton>
                              <IonButton fill='clear' size='small' shape='round' onClick={() => { handleEliminarBeneficiario(benefic); }}>
                                <IonIcon color='rosa' icon={trash}></IonIcon>
                              </IonButton>
                            </IonCol>
                          </> :   <></>                        
                      ) : <></>}
                      <IonCol className='hide-benef' hidden={porcentajeTotal >0} size='6'>
                        <IonInput disabled></IonInput>
                      </IonCol>
                      <IonCol className='hide-benef' hidden={porcentajeTotal >0} size='6'>
                        <IonInput disabled></IonInput>
                      </IonCol>
                      <IonCol className='panel-add-benef' size='12'>
                        <IonButton hidden={porcentajeTotal >= 100} fill='clear' onClick={() => { abrirModalBeneficiarios() }}>
                          <IonIcon color='rosa' icon={personAdd}></IonIcon>
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                      <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                        <div className='upload-panel'>
                          <div className='upload-preview'>
                            <img src={vprevSvida} alt="">
                            </img>
                          </div>
                          <div className='upload-panel-buttons'>                            
                            <IonButton className="photo" hidden={paciente.docSeguroVida != null} shape='round' color='rosa' onClick={() => takePicture(IMG_P_SEGURO_VIDA)}>
                              <IonIcon icon={camera} />
                            </IonButton>
                            <IonButton hidden={paciente.docSeguroVida != null} shape='round' color='rosa' onClick={() => fileInputDocSegVidaRef.current.click()}>
                              <IonIcon icon={document} />
                            </IonButton>
                            <IonButton hidden={!paciente.docSeguroVida} shape='round' color='rosa' onClick={() => { abrirArchivo(paciente.docSeguroVida) }}>
                              <IonIcon icon={cloudDownload} />
                            </IonButton>
                            <IonButton hidden={!paciente.docSeguroVida} shape='round' color='rosa' onClick={() => { handleEliminarDoc(paciente.docSeguroVida) }}>
                              <IonIcon icon={trash} />
                            </IonButton>
                          </div>
                          <IonLabel>Póliza de Seguro de Vida</IonLabel>
                        </div>
                        <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocSegVida} ref={fileInputDocSegVidaRef}></input>
                        </div>
                        <div className='msj-doc-noPremium' hidden={!(esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo))}>
                          <span>{MSJ_CARGA_DOCUMENTOS}</span>
                        </div>
                      </IonCol>
                      <IonButton onClick={handleGuardarPaciente} fill='clear' className="recordar_save">
                        <div className='recordar-guardar' >
                          <span className="naranja">
                            Por favor guarde su información
                          </span>
                          <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                        </div>
                      </IonButton>
                      <div id="aviso_privacidad">
                          <p>
                            <a href='https://allivia.com.mx/assets/pdf/aviso_privacidad.pdf' target={"_blank"}>
                                Descargar aviso de privacidad
                            </a>
                          </p>
                        </div>
                    </IonRow>
                     
                  </IonGrid>
                </IonAccordion>
              </IonAccordionGroup>
            </div>
            {/* <IonButton expand="block" onClick={handleGuardarPaciente}><IonLabel>Guardar</IonLabel></IonButton> */}
          </div>
          <IonToast color='rosa'
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
          <IonModal backdropDismiss={false} isOpen={modalDiagnostico} className="ion-modal-diagnosticos" >
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel>Escribe un nuevo diagnóstico médico de importancia para ti:</IonLabel>
                    <IonInput className="capitalizar" value={diagnosticoDescripcion} type="text" inputMode='text' autocapitalize="sentences"
                      onIonChange={(event) => { setDiagnosticoDescripcion(event.detail.value) }}>
                    </IonInput>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton expand='block' onClick={() => { setModalDiagnostico(false) }}>
                      <IonLabel>Cancelar</IonLabel>
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton expand='block' onClick={() => { agregarDiagnostico() }} >
                      <IonLabel>Registrar</IonLabel>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={modalAlergia} className="ion-modal-alergia" >
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel>Alergia:</IonLabel>
                    <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="word" value={alergiaDescripcion}
                      onIonChange={(event) => { setAlergiaDescripcion(event.detail.value) }}>
                    </IonInput>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton expand='block' onClick={() => { cancelarAlergia() }}>
                      <IonLabel>Cancelar</IonLabel>
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton expand='block' onClick={() => { agregarAlergia() }} >
                      <IonLabel>{alergiaTemp ? 'Actualizar' : 'Guardar'}</IonLabel>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={modalGuardar} className="ion-modal-cambios-singuardar" >
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>¿Deseas guardar los cambios de tú perfil, antes de salir?</IonLabel>                  
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand='block' onClick={() => { handleCancelarGuardar() }}>
                    <IonLabel>Cancelar</IonLabel>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton expand='block' onClick={() => { handleAceptarGuardar() }} >
                    <IonLabel>Guardar</IonLabel>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
          <IonModal backdropDismiss={false} isOpen={modalBeneficiarios} className="ion-modal-beneficiarios" >
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel>Beneficiario:</IonLabel>
                    <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={tempBeneficiario}
                      onIonChange={(event) => setTempBeneficiario(event.detail.value)} />
                  </IonCol>
                  <IonCol>
                    <IonLabel>Porcentaje:</IonLabel>
                    <IonSelect className="listas_datos" placeholder="Seleccione su aseguradora" value={tempPorcentaje}
                      onIonChange={(event) => validaPorcentaje(event.detail.value)}>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 5} value={5}>5%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 10} value={10}>10%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 15} value={15}>15%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 20} value={20}>20%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 25} value={25}>25%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 30} value={30}>30%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 35} value={35}>35%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 40} value={40}>40%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 45} value={45}>45%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 50} value={50}>50%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 55} value={55}>55%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 60} value={60}>60%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 65} value={65}>65%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 70} value={70}>70%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 75} value={75}>75%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 80} value={80}>80%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 85} value={85}>85%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 90} value={90}>90%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 95} value={95}>95%</IonSelectOption>
                      <IonSelectOption disabled={(100 - porcentajeTotal) < 100} value={100}>100%</IonSelectOption>
                    </IonSelect>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton expand='block' onClick={() => { cancelarBeneficiarios() }}>
                      <IonLabel>Cancelar</IonLabel>
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton expand='block' onClick={() => { agregarBeneficiarios() }} >
                      <IonLabel>Guardar</IonLabel>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
          <IonFab className="fixed_save" vertical="bottom" horizontal="end" title='Guardar' slot='fixed'>
            <IonFabButton className='saveFabButton' onClick={handleGuardarPaciente}>
              <IonImg src='./assets/saveIcon.png' />
            </IonFabButton>
          </IonFab>
          <IonFab hidden={esWeb()} className='allivia-emergencia-call' vertical='bottom' horizontal='center' edge slot='fixed'>
            <IonFabButton color="emergencia">
              <div>
                <IonIcon icon={call} />
                <IonLabel>911</IonLabel>
              </div>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton color="success" onClick={() => { emergencyCall(); }}><IonIcon icon={call} /></IonFabButton>
            </IonFabList>
          </IonFab>
        </IonContent>        
        <div className='custom-tab-bar'>
          <IonButton hidden={!hayCambios} fill='clear' onClick={()=>handleAbrirModalGuardar(false)}>
            <div>
              <IonIcon icon={save} />
              <IonLabel>Guardar</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={hayCambios} fill='clear' routerLink='/'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear'>
            <div>
              <IonIcon icon={search} />
              <IonLabel>Persona a cuidar</IonLabel>
            </div>
          </IonButton>
        </div>
      </IonPage>
    </>
  );
};

export default InformacionPaciente;
