import { SERVICE_ALLIVIA} from "../properties";
import { DTOCatAfiliacionSocial, DTOCatAseguradora, DTOCatParentesco, DTOCatTipoDocumento, DTOCatTipoSangre, DTOResultadoBusqueda, UsuarioLogin } from "./AlliviaInterfaces";


export const funcionBuscar = async (query:string):Promise<Array<DTOResultadoBusqueda>> =>{
  let lres = listaResultados().filter(res=>res.resultado.toUpperCase().includes(query.toUpperCase()));
  console.log(lres);    
  return lres;
}


const listaResultados = ():Array<DTOResultadoBusqueda>=>{
  let lr = Array<DTOResultadoBusqueda>();
  
  lr.push({resultado:"Inicio", url:'/allivia/alliviaHome'});
  lr.push({resultado:"Acerca de", url:'/allivia/acercaDe'});
  lr.push({resultado:"Nuevo paciente", url:'/allivia/nuevoPaciente'});
  lr.push({resultado:"Menú paciente", url:'/allivia/menuPaciente'});
  lr.push({resultado:"Información paciente", url:'/allivia/informacionPaciente'});
  lr.push({resultado:"Signos Vitales", url:'/allivia/signosVitales'});
  lr.push({resultado:"Estudios clínicos", url:'/allivia/estudiosClinicos'});
  lr.push({resultado:"Tratamientos", url:'/allivia/tratamientos'});
  lr.push({resultado:"Medicamentos", url:'/allivia/medicamentos'});
  lr.push({resultado:"Mi calendario cuidador", url:'/allivia/miCalendarioCuidador'});
  lr.push({resultado:"Cíta médica", url:'/allivia/citaMedica'});
  lr.push({resultado:"Perfil cuidador", url:'/allivia/perfilCuidador'});
  lr.push({resultado:"Derechos del cuidador", url:'/allivia/derechosCuidador'});
  lr.push({resultado:"Buzón de sugerencias", url:'/allivia/buzonSugerencias'});
  lr.push({resultado:"Aviso privacidad", url:'/allivia/avisoPrivacidad'});
  lr.push({resultado:"Configuración", url:'/allivia/configuracion'});
  lr.push({resultado:"Contacto", url:'/allivia/contacto'});
  lr.push({resultado:"Preguntas frecuentes", url:'/allivia/preguntasFrecuentes'});
  lr.push({resultado:"Términos y condiciones", url:'/allivia/terminosCondiciones'});
  lr.push({resultado:"Allivia premium", url:'/allivia/alliviaPremium'});
  lr.push({resultado:"Pago allivia premium", url:'/allivia/pagoAlliviaPremium'});
  lr.push({resultado:"Mis pacientes", url:'/allivia/misPacientes'});
  lr.push({resultado:"Acceso compartido", url:'/allivia/accesoCompartido'});
  lr.push({resultado:"Soy allivia premium", url:'/allivia/soyAlliviaPremium'});
  lr.push({resultado:"Estado emocional", url:'/allivia/estadoEmocional'});
  lr.push({resultado:"Estado nutricional", url:'/allivia/estadoNutricional'});
  lr.push({resultado:"Respiración", url:'/allivia/respiracion'});
  lr.push({resultado:"Hidratación", url:'/allivia/hidratación'});  
  lr.push({resultado:"Promociones farmacia", url:'/allivia/promocionesFarmacia'});   

  let result = lr.sort((a,b)=>a.resultado.localeCompare(b.resultado))
  return result;
}

