import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router';
import { useLoginContext } from '../hooks/login';
import '../theme/terminosyaviso.css';



const AvisoPrivacidad: React.FC = () => {
  const { loggedIn } = useLoginContext(); 

  //console.log(`rendering from loginPage with loggedIn=${loggedIn}`);
  if (!loggedIn) {
    return <Redirect to="/allivia/alliviaHome" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton text=""  color='primary' defaultHref="/"/>
          </IonButtons>
          <IonTitle>Aviso de Privacidad</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">  
      <div id="aviso">
        <h1> AVISO DE PRIVACIDAD</h1>
        <p>
            A los Usuarios (como se definen posteriormente), les informamos que el siguiente Aviso de Privacidad,
            les es aplicable por el simple uso o acceso a cualquiera de las páginas, aplicaciones web y móviles,
            softwares y, aplicaciones en general, que integran el Portal de:  
            <a href="https://caregiversolutions.com.mx">
              https://caregiversolutions.com.mx
            </a> 
            (en adelante y, conjunta e indistintamente, el "Portal"), por lo que entenderemos que lo acepta y acuerda en
            obligarse en su cumplimiento. En caso de que no esté de acuerdo con el Aviso de Privacidad
            y/o con los Términos y Condiciones a su disposición, deberá abstenerse de acceder o
            utilizar el Portal.
            El Usuario, entendido como aquella persona que realiza el uso o accede, mediante equipo de cómputo y/o
            cualquier equipo de comunicación o dispositivo, al Portal (en adelante el “Usuario”), acepta plena y sin
            reserva todas y cada una de las disposiciones incluidas en el presente Aviso de Privacidad.
          </p>
          <div className="aviso">
            <ul>
              <li>
               <span className="bold"> RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES. </span>   
                Para CAREGIVER SOLUTIONS
                  SA DE CV (en adelante la “Empresa”) la seguridad de los Usuarios es nuestra prioridad, por lo que
                  protegemos sus datos personales mediante el uso, aplicación y mantenimiento de altas medidas de
                  seguridad técnicas, físicas y administrativas.
                  Como Usuario, usted tiene la oportunidad de escoger entre una amplia gama de productos y servicios a
                  través de nuestro Portal, sabiendo que sus datos personales estarán protegidos y serán tratados de
                  manera confidencial. Les informamos que el RESPONSABLE de recabar y dar tratamiento y/o utilizar los
                  datos personales que el Usuario proporcione a través del Portal, es la Empresa, así como sus subsidiarias,
                  asociadas, sociedades controladoras y afiliadas.
                  DOMICILIO DEL RESPONSABLE. Para efectos del presente aviso de privacidad, la Empresa señala,
                  individualmente, como su domicilio, el ubicado en Municipio Libre 111, Ampliación Sinatel, Iztapalapa,
                  Ciudad de México, C. P. 09470
                  DATOS PERSONALES QUE PUEDEN SER RECOLECTADOS. Los datos personales que la Empresa
                  puede recopilar del Usuario al utilizar el Portal y/o contratar nuestros servicios y productos, son los
                  siguientes:
                  I. Nombre
                  II. Teléfono
                  III. Correo electrónico
                  IV. Parentesco o relación con el paciente
                  V. Fecha de nacimiento
                  VI. Sexo
                  Serán considerados como datos personales sensibles aquellos así dispuestos por la Ley Federal de
                  Protección de Datos en Posesión de los Particulares, que de forma enunciativa más no limitativa, son los
                  datos que forman parte de la intimidad del personal y cuya divulgación pone en riesgo o da origen a
                  discriminación por aspectos de origen racial o étnico, estado de salud, información genética, creencias
                  religiosas, filosóficas o morales, afiliación sindical, opiniones políticas y preferencias sexuales.

              </li>
              <li>
                  <span className="bold"> FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES. </span> Los datos personales que la
                  Empresa recabe serán utilizados para atender las siguientes finalidades:
                  I. Garantizar la calidad de nuestros servicios, Contar con un medio de contacto personalizado, Mantener
                  contacto inmediato en caso de atención en emergencias, Si cuentas con tu membresía, tendremos
                  contacto contigo para hacerte llegar información sobre tus beneficios
                  II. Para integrar expedientes, bases de datos y sistemas necesarios para llevar a cabo las operaciones y
                  servicios correspondientes; (ii) Para reclamar la entrega de premios y/o promociones; (iii) Para llevar a
                  cabo análisis internos;
                  III. De manera adicional, se podrán utilizar sus datos personales para las siguientes finalidades
                  secundarias: (i) Mercadotecnia, publicidad y prospección comercial; (ii) Ofrecerle, en su caso, otros
                  productos y servicios propios de la Empresa o de cualquiera de sus afiliadas, subsidiarias, sociedades
                  controladoras, asociadas, comisionistas o sociedades; (iii) Remitirle promociones de otros bienes,
                  servicios y/o productos; (iv) Para realizar análisis estadísticos, de generación de modelos de
                  información y/o perfiles de comportamiento actual y predictivo y, (v) Para participar en encuestas,
                  sorteos y promociones.
                  OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS. La Empresa tiene
                  implementadas medidas de seguridad administrativas, técnicas y físicas para proteger sus datos
                  personales, mismas que igualmente exigimos sean cumplidas por los proveedores de servicios que
                  contratamos. Usted podrá limitar el uso o divulgación de sus datos personales enviando un correo
                  electrónico a contacto@allivia.com.mx indicándonos en el cuerpo del correo su nombre completo y que
                  dato desea que no sea divulgado, de proceder su solicitud, se le registrará en el listado de exclusión.
              </li>
              <li>
                 <span className="bold"> MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U
                OPOSICIÓN (DERECHOS ARCO).    </span> Puede enviar un correo electrónico a <a href="mailto:contacto@allivia.com.mx">
                  contacto@allivia.com.mx,
                  </a>  en cualquier momento, para ejercer sus Derechos de Acceso, Rectificación, Cancelación u Oposición
                (“Derechos ARCO”). Para ejercer los Derechos ARCO, usted (o su representante legal), deberá
                presentar la solicitud, identificándose con la siguiente documentación:
                I. Nombre del usuario o titular.
                II. Domicilio del usuario o titular u otro medio para comunicarle la respuesta a su solicitud.
                III. Documentos que acrediten su identidad (IFE/INE o pasaporte) y, en su caso, los documentos que
                acrediten la representación legal del solicitante.
                IV. Una descripción de la información / los datos sobre los que está tratando de ejercer sus derechos
                ARCO y los derechos que le gustaría ejercer.
                Si uno o más de los documentos mencionados anteriormente no están incluidos, y/o los documentos
                tienen errores u omisiones, la Empresa le notificará dentro de los 3 días hábiles posteriores a la recepción
                de la solicitud y le pedirá los documentos faltantes y/o las correcciones pertinentes; tendrá 5 días hábiles
                a partir de esa notificación para proporcionar la información actualizada, de lo contrario, la solicitud se
                entenderá como no presentada.
              </li>
              <li>
                  <span className="bold"> TRANSFERENCIA DE DATOS PERSONALES.   </span> La Empresa podrá divulgar sus datos personales a
                  aquellos terceros que, en virtud de los servicios y productos ofrecidos, necesiten conocerlos para cumplir
                  cabalmente con los mismos.
                  Asimismo, la Empresa puede divulgar su información a las autoridades competentes en términos de la
                  legislación aplicable; cualquier transferencia de sus datos personales sin consentimiento se realizará de
                  acuerdo con el Artículo 37 de la LFPDPPP.
              </li>
              <li>
                <span className="bold"> WEB BEACONS.</span>
                La Empresa, podrá o no, utilizar tecnologías de seguimiento tales como web beacons,
                similares a las cookies, para recabar datos sobre sus visitas en el Portal; éstas son pequeñas imágenes
                electrónicas incrustadas en el contenido web o mensajes de correo electrónico, las cuales no se
                encuentran normalmente visibles para los Usuarios y que nos permiten generar contenidos casi
                personalizados para ofrecerle una mejor experiencia cuando utilice nuestro Portal.
                En caso de no estar de acuerdo con cualquiera de las condiciones aquí establecidas, el Usuario siempre
                podrá cambiar la configuración de su navegador.
              </li>
              <li>
                <span className="bold"> MODIFICACIONES AL AVISO DE PRIVACIDAD. </span>  
                El presente aviso de privacidad puede sufrir
                modificaciones, cambios o actualizaciones derivadas, entre otras cosas, por nuevos requerimientos
                legales; necesidades propias de la Empresa, por los productos o servicios que ofrecemos; por nuestras
                prácticas de privacidad; por cambios en nuestro modelo de negocio, o por otras causas.
                Cualquier modificación al presente aviso de privacidad le será notificada a través de cualquiera de los
                siguientes medios: un comunicado por escrito enviado a su domicilio; por el correo electrónico que señale;
                un mensaje a su teléfono móvil; un mensaje dado a conocer a través del Portal o de cualquier medio
                electrónico que utilice.
              </li>
            </ul>
          </div>
     </div>     
      </IonContent>
    </IonPage>
  );
};

export default AvisoPrivacidad;
