import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonLabel,
  IonListHeader,
  IonList,
  IonImg,
  IonBackButton,
  IonLoading,
  IonButton,
  IonModal,
  IonInput,
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonFabList,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { addCircle, call, camera, closeOutline, cloudDownload, cloudUpload, document, downloadOutline, home, pencil, search, shareSocial, trash } from 'ionicons/icons';
import { useLoginContext, useParametros, } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/medicamentos.css';
import { Redirect } from 'react-router';
import { DTODocumento, DTOEvento, DTOMedicamento, DTOPaciente, DTOTratamiento, FileTemporalDoc } from '../services/AlliviaInterfaces';
import { guardarTratamiento, leerTratamientoById } from '../services/tratamiento.service';
import { abrirArchivoBrowser, cargarPreview, eliminarDocumento, emergencyCall, esWeb, guardarDocumentos, leerArchivoDescarga, leerFileBlob, leerImagenDataURL } from '../services/utils.service';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { URL_ALLIVIA_DOCS } from '../properties';
import { Camera, CameraResultType } from '@capacitor/camera';
import dayjs from 'dayjs';
import { generarReporteMedicamento } from '../services/reportes.service';
import { Share } from '@capacitor/share';
import { leerNotificaciones } from '../services/notificaciones.service';
import { eliminarDocumentosGluster, guardarDocumentosGluster } from '../services/gluster.service';
import { MSJ_CARGA_DOCUMENTOS } from '../constantes';






const Medicamentos: React.FC = () => {
  const user = useLoginContext();
  const { parametros,setParametros } = useParametros();
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [modalAgregarMedicamento, setModalAgregarMedicamento] = useState(false);
  const [paciente, setPaciente] = useState<DTOPaciente | undefined>();
  const [tratamientoMed, setTratamientoMed] = useState<DTOTratamiento>();
  const [medicamento, setMedicamento] = useState<DTOMedicamento>({});
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [horaTemp, setHoraTemp] = useState("");
  const [verEvento, setVerEvento] = useState("no");
  const [verRecordatorio, setVerRecordatorio] = useState("no");
  const fileInputDocTratamientoRef = useRef<HTMLInputElement>();
  const [fileTratamiento, setFileTratamiento] = useState<FileTemporalDoc>();
  const [subirTratamiento, setSubirTratamiento] = useState(false);
  const [imagenOptions] = useState({
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.Uri,
    promptLabelHeader: "Foto",
    promptLabelCancel: "Cancelar",
    promptLabelPhoto: "Galeria",
    promptLabelPicture: "Cámara"
  });
  const [vprevReceta, setVprevReceta] = useState("");

  const descargarReporteMedicamentos = async () => {
    setEstatus({ loading: true, leer: false });
    console.log("Iniciando descarga");
    let docu = await generarReporteMedicamento(user, paciente.idPersona, tratamientoMed.idTratamiento,paciente.idCuidador);
    if(esWeb()){
      console.log(docu.fileStream);      
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='" +docu.fileStream+ "'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }
    setEstatus({loading:false,leer:false});
    await guardarDocumentos(docu).then(()=>{
      docu.urlDocumento = URL_ALLIVIA_DOCS + "/" + docu.urlDocumento;
      abrirArchivo(docu);
    }).catch((e)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un erro al descargar el reporte, por favor intenta de nuevo"});
    });
  }


  const compartirArchivo = async () => {
    setEstatus({ loading: true, leer: false });
    let docu = await generarReporteMedicamento(user, paciente.idPersona, tratamientoMed.idTratamiento,paciente.idCuidador);

    console.log(docu.fileStream);

    // let fileTransfer: FileTransferObject = FileTransfer.create();
    //Se obtiene la Uri del dispositivo
    let uriSave = await Filesystem.getUri({
      path: URL_ALLIVIA_DOCS,
      directory: Directory.Documents
    }
    );
    console.log("save uri archivo uri:", uriSave);
    await guardarDocumentos(docu).then(()=>{      
    }).catch((e)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un erro al descargar el reporte, por favor intenta de nuevo"});
    });

    await Share.share({
      title: docu.descripcion,
      text: 'Compartir',
      url: "file://" + uriSave.uri + "/" + docu.urlDocumento,
      dialogTitle: 'Compartir',
    }).catch((error)=>{
      console.log(error);
    });
    setEstatus({ loading: false, leer: false });
  }


  const takePictureTratamiento = async () => {
    const image = await Camera.getPhoto(imagenOptions);
    let date = dayjs(new Date()).format("YYYYMMDDHHmmss");
    var imageUrl = image.webPath;
    console.log("imgEM", imageUrl);
    let fileT = { url: imageUrl, fileName: "Receta" + date + "." + image.format, mimeType: "image/" + image.format };
    let tratmTem = { ...tratamientoMed };
    console.log("Guardar documento Tratamiento");
    if (fileT != null) {
      let temDocTr: DTODocumento = tratamientoMed.docTratamiento;
      if (!temDocTr) {
        temDocTr = {
          idPersona: paciente.idPersona,
          descripcion: "Receta Medica",
          urlDocumento: URL_ALLIVIA_DOCS + "/" + fileT.fileName,
          nombre:fileT.fileName,
          catTipoDocumento: { idTipoDoc: 8 },
          estatus: 1,
          remoto: false,
          mimeType: fileT.mimeType,
          gluster:0,
        }
      } else {
        temDocTr = { ...temDocTr, urlDocumento: URL_ALLIVIA_DOCS + "/" + fileT.fileName, mimeType: fileT.mimeType }
      }
      if(parametros&&(parametros.premium||parametros.premiumDuo)){
        //syncronizar imagen en el servidor
        let blob = await leerFileBlob(fileT.url);
        let urlData = await leerImagenDataURL(blob);
        temDocTr ={...temDocTr,fileStream:urlData,gluster:1};
        guardarDocumentosGluster(user,temDocTr);
        temDocTr.fileStream=null;
      }
      tratmTem = { ...tratmTem, docTratamiento: temDocTr };
      await guardarDocumentos(fileT);
      URL.revokeObjectURL(fileT.url);
      setFileTratamiento(null);
      setSubirTratamiento(false);
    }

    await guardarTratamiento(user, tratmTem).then(() => {
      setMsgToast({ showToast: true, statusMessage: "El documento se guardo correctamente" });
      leerTratamientoById(user, tratamientoMed.idTratamiento).then((tratam) => {        
        setTratamientoMed(tratam);
        cargarPreview(user,tratam.docTratamiento,parametros).then((tdoc) => {
          setVprevReceta(tdoc);
        });
        setParametros({...parametros,tratamiento:tratam});
      });
    });

  };

  const handleEliminarDocTratamiento = async (doc: DTODocumento) => {
    setEstatus({ loading: true, leer: false });
    if (fileTratamiento != null) {
      URL.revokeObjectURL(fileTratamiento.url);
      setFileTratamiento(null);
    } else {
      doc.estatus = 0;
    }
    console.log("eliminar doc Receta medica", tratamientoMed);
    if (tratamientoMed.docTratamiento != null && tratamientoMed.docTratamiento.estatus === 0) {
      //eliminar archivo();
      await eliminarDocumento(tratamientoMed.docTratamiento);
      try{
        await eliminarDocumentosGluster(user,doc);
      }catch(err){      
        console.log("error al eliminar los documentos del gluster,",err);
      }
    }
    guardarTratamiento(user, tratamientoMed).then(() => {
      setMsgToast({ showToast: true, statusMessage: "El documento se guardo correctamente" });
      leerTratamientoById(user, tratamientoMed.idTratamiento).then((tratam) => {
        setTratamientoMed(tratam);
      });
    });
    setVprevReceta("");
    fileInputDocTratamientoRef.current.value = "";
    setEstatus({ loading: false, leer: false });
    setSubirTratamiento(false);
  }

  const handleSeleccionarDocTratamiento = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileT = await leerArchivo(event);
    let tratmTem = { ...tratamientoMed };
    console.log("Guardar documento Tratamiento");
    if (fileT != null) {
      let temDocTr: DTODocumento = tratamientoMed.docTratamiento;
      if (!temDocTr) {
        temDocTr = {
          idPersona: paciente.idPersona,
          descripcion: "Receta Medica",
          urlDocumento: URL_ALLIVIA_DOCS + "/" + fileT.fileName,
          nombre:fileT.fileName,
          catTipoDocumento: { idTipoDoc: 8 },
          estatus: 1,
          remoto: false,
          mimeType: fileT.mimeType,
          gluster:0,
        }
      } else {
        temDocTr = { ...temDocTr, urlDocumento: URL_ALLIVIA_DOCS + "/" + fileT.fileName, mimeType: fileT.mimeType }
      }
      if(parametros&&(parametros.premium||parametros.premiumDuo)){
        //syncronizar imagen en el servidor
        temDocTr ={...temDocTr,gluster:1};
      }
      tratmTem = { ...tratmTem, docTratamiento: temDocTr };            
      await guardarDocumentos(fileT);
      if(parametros&&(parametros.premium||parametros.premiumDuo)){
        //syncronizar imagen en el servidor
        temDocTr ={...temDocTr,fileStream:fileT.fileStream,gluster:1};
        await guardarDocumentosGluster(user,temDocTr);
        temDocTr.fileStream=null;
      }   
      URL.revokeObjectURL(fileT.url);
      setFileTratamiento(null);
      setSubirTratamiento(false);
      await cargarPreview(user,tratmTem.docTratamiento,parametros).then((tdoc) => {
        setVprevReceta(tdoc);
      });
    }


    guardarTratamiento(user, tratmTem).then(() => {
      setMsgToast({ showToast: true, statusMessage: "El documento se guardo correctamente" });
      leerTratamientoById(user, tratamientoMed.idTratamiento).then((tratam) => {
        if(parametros&&(parametros.premium||parametros.premiumDuo)){
          //syncronizar imagen en el servidor
          let temDocTr ={...tratam.docTratamiento,fileStream:fileT.fileStream,gluster:1};
          guardarDocumentosGluster(user,temDocTr);          
        }
        setTratamientoMed(tratam);
        setParametros({...parametros,tratamiento:tratam});
      });
    });
  }

  const leerArchivo = async (event: React.ChangeEvent<HTMLInputElement>): Promise<FileTemporalDoc> => {
    let sdate = dayjs(new Date()).format("YYYYMMDDHHmmss");
    if (event.target.files.length > 0) {
      const file = event.target.files.item(0);
      let name = file.name.replace(".", sdate+".");
      const temUrl = { url: URL.createObjectURL(file), fileName: name, mimeType: file.type };
      console.log("created URL: ", temUrl);
      return temUrl;
    }
  }

  const abrirArchivo = async (doc: DTODocumento) => {
    if(esWeb()){         
      let docTemp = await leerArchivoDescarga(doc);
      console.log(docTemp);
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='"+docTemp+"'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }

    let uri = await Filesystem.getUri({
      path: doc.urlDocumento,
      directory: Directory.Documents
    }
    );
    console.log("abir archivo uri:", uri);
    FileOpener.showOpenWithDialog(uri.uri, doc.mimeType).then().catch((e) => {
      console.log("error", e);
      abrirArchivoBrowser(doc).catch((e) => {
        setMsgToast({ showToast: true, statusMessage: "El documento no se encuentra en este dispositivo" });
      });
    });
  }

  const handleEditarMedicamento = async (med: DTOMedicamento) => {
    setMedicamento(med);
    setVerRecordatorio("no");
    med.eventos.map((ev) => {
      if (ev.recordatorio === true) {
        setVerRecordatorio("si");
      }
    });
    setHoraTemp(med.horaInicio);
    setModalAgregarMedicamento(true);
  }

  const handleEliminarMedicamento = async (med: DTOMedicamento) => {
    med = { ...med, estatus: 0 }
    console.log("Editar medicamento,", med);
    let tratmTem = tratamientoMed;

    let listaMedicamentos = tratmTem.medicamentos;
    let listaTem: Array<DTOMedicamento> = [];
    listaMedicamentos.map((vMed) => {
      if (vMed.idMedicamento === med.idMedicamento) {
        listaTem.push(med);
      } else {
        listaTem.push(vMed);
      }
    });

    listaMedicamentos = listaTem;
    tratmTem = { ...tratmTem, medicamentos: listaMedicamentos };
    console.log("Actualizar tratamiento: ", tratmTem);
    guardarTratamiento(user, tratmTem).then(() => {
      setMsgToast({ showToast: true, statusMessage: "El registro se guardo correctamente" });
      setMedicamento({ idTratamiento: tratamientoMed.idTratamiento, estatus: 1, periodo: 1 });
      setVerRecordatorio("no");
      setHoraTemp("");
      leerTratamientoById(user, tratamientoMed.idTratamiento).then((tratam) => {
        setTratamientoMed(tratam);
      });
    });
  }


  const handleGuardarMedicamento = async () => {
    console.log("med: ",medicamento);
    if(!medicamento.medicamento || medicamento.medicamento===null || medicamento.medicamento===''){
      setMsgToast({ showToast: true, statusMessage: "Por favor ingresa un nombre de medicamento" });
      return;
    }
    if(!medicamento.dosis || medicamento.dosis===null || medicamento.dosis===''){
      setMsgToast({ showToast: true, statusMessage: "Por favor ingresa el valor de la dósis" });
      return;
    }
    if(!medicamento.duracion || medicamento.duracion===null || medicamento.duracion===''){
      setMsgToast({ showToast: true, statusMessage: "Por favor ingresa el valor de la duración" });
      return;
    }
    //Validar el tamaño de la duración menor a 5 años
    let dias_duracion = 0;
    switch(medicamento.periodo){
      case 1: dias_duracion = parseInt(medicamento.duracion) * 1; break;
      case 2: dias_duracion = parseInt(medicamento.duracion) * 7; break;
      case 3: dias_duracion = parseInt(medicamento.duracion) * 14; break;
      case 4: dias_duracion = parseInt(medicamento.duracion) * 30; break;
    }
    
    let max_duracion = 5*365;    
    if(dias_duracion>max_duracion){
      setMsgToast({ showToast: true, statusMessage: "Por favor ingresa una duración más pequeña" });
      return;
    }
    if(!medicamento.intervaloToma || medicamento.intervaloToma===null || medicamento.intervaloToma===''){
      setMsgToast({ showToast: true, statusMessage: "Por favor ingresa el valor de tomar cada" });
      return;
    }

    

    if(!medicamento.fechaInicio || medicamento.fechaInicio===null || medicamento.fechaInicio===''){
      setMsgToast({ showToast: true, statusMessage: "Por favor ingresa la fecha de inicio de toma" });
      return;
    }

    if(!horaTemp || horaTemp===null || horaTemp===''){
      setMsgToast({ showToast: true, statusMessage: "Por favor ingresa la hora de inicio de toma" });
      return;
    }

    setEstatus({ loading: true, leer: false });
    let tratmTem = tratamientoMed;
    let tevento: DTOEvento = null;
    //Se debe de crear una serie de eventos de acuerdo con cada toma y calcular los eventos
    // if(verEvento==="si"){
    //   tevento={
    //     idPersona:tratamientoMed.idPersona,
    //     nombre:"Medicamento",
    //     descripcion:medicamento.medicamento,
    //     fecha: estudio.fechaEstudio,
    //     hora: estudio.fechaEstudio,
    //     mostrar: true,
    //     recordatorio: verRecordatorio==="si",        
    //   }
    // }

    let teMed: DTOMedicamento = { ...medicamento, horaInicio: convertirHoraFecha(horaTemp), verRecordatorio: verRecordatorio === "si" };
    let listaMedicamentos = tratmTem.medicamentos;
    let listaTem: Array<DTOMedicamento> = [];
    listaMedicamentos.map((vMed) => {
      if (vMed.idMedicamento === teMed.idMedicamento) {
        listaTem.push(teMed);
      } else {
        listaTem.push(vMed);
      }
    });

    listaMedicamentos = listaTem;

    if (!teMed.idMedicamento) {
      listaMedicamentos.push(teMed);
    }
    tratmTem = { ...tratmTem, medicamentos: listaMedicamentos };

    console.log("Actualizar tratamiento: ", tratmTem);
    await guardarTratamiento(user, tratmTem).then(() => {
      setMsgToast({ showToast: true, statusMessage: "El registro se guardo correctamente" });
      setMedicamento({ idTratamiento: tratamientoMed.idTratamiento, estatus: 1, periodo: 1 });
      setVerRecordatorio("no");
      setHoraTemp("");
      leerTratamientoById(user, tratamientoMed.idTratamiento).then((tratam) => {
        setTratamientoMed(tratam);
        setParametros({...parametros,tratamiento:tratam});
      });
      sincronizaNotificaciones();      
    }).catch((error)=>{
      setMsgToast({ showToast: true, statusMessage: "Error al guardar el medicamento, por favor intenta de nuevo" });
    });
    setModalAgregarMedicamento(false);
    setEstatus({ loading: false, leer: false });
  }

  const limpiarDatos = () => {
    let dateActual = new Date();
    let sdate = dayjs(dateActual).format("YYYY-MM-DD")
    let sfecha = dayjs(dateActual).format("HH:mm");
    setMedicamento({ idTratamiento: tratamientoMed.idTratamiento, estatus: 1, periodo: 1,fechaInicio:sdate});
    setVerRecordatorio("no");
    setHoraTemp(sfecha);
  };

  const convertirHoraFecha = (t: string): string => {
    let d = t + ":00"
    return d;
  }

  const validaPeriodo = (idPeriodo: number): string => {
    let valor;
    switch (idPeriodo) {
      case 1: valor = "Día(s)"; break;
      case 2: valor = "Semana(s)"; break;
      case 3: valor = "Quincena(s)"; break;
      case 4: valor = "Mes(es)"; break;
    }
    return valor;
  }

  const actualizaMedicamento = (valor: any) => {
    console.log("Entrando: ", valor);
    if(valor.duracion!=null && valor.duracion.length>4){
      valor = {duracion:valor.duracion.substring(0,4)}      
    }
    setMedicamento({ ...medicamento, ...valor });
  }

  const sincronizaNotificaciones=async ()=>{    
    await leerNotificaciones(user,parametros.cuidador.idCuidador);
  }

  useEffect(() => {
    console.log("Entrando a useefect menuPaciente: ");
    if (parametros) {
      setPaciente(parametros.paciente);
      setEstatus({ loading: false, leer: false });
    }
  }, [parametros]);

  const leerDatosMedicamentos = async () =>{
    setEstatus({ loading: true, leer: false });
    setMedicamento({ idTratamiento: parametros.tratamiento.idTratamiento, estatus: 1, periodo: 1 });
        setTratamientoMed(parametros.tratamiento);
        console.log("Receta dir:", parametros.tratamiento.docTratamiento);
        if (parametros.tratamiento.docTratamiento) {
          await cargarPreview(user,parametros.tratamiento.docTratamiento,parametros).then((tdoc) => {
            setVprevReceta(tdoc);
          });
        }
        setEstatus({ loading: false, leer: false });
  }

  useEffect(() => {
    if (parametros) {
      if (parametros.tratamiento) {
        leerDatosMedicamentos();
      }
    }
  }, []);



  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  if (!paciente) {
    return (<IonLoading isOpen />);
  }

  if (!parametros.tratamiento) {
    return (<IonLoading isOpen />);
  }

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle className="title_header">Medicamentos</IonTitle>
            <IonButtons slot='end'>
              <IonButton color='primary' onClick={() => { descargarReporteMedicamentos() }} >
                <IonIcon size='large' icon={downloadOutline} ></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="medicamento">
          <IonList lines='none' className='pleca-tratamientos'>
            <IonListHeader>
               <div id="contenedor_datos">
                <div className="icon_px">
                    <IonImg src="./assets/px.png"/>
                </div>
                <div className="datos_px">
                    <div id='title_paciente'>
                      <span className='header-nombre'>{paciente?paciente.nombreCompleto:null}</span>
                    </div>
                    <div id="title_parentesco">
                      <span className='header-parentesco'>{paciente?paciente.parentesco:null}</span>
                    </div>
                </div>
          </div>
            </IonListHeader>
          </IonList>
          <div className='medicamento-panel'>
            <div className='medicamento-titulo' >
              <div className='pastilla-medicamento'>
                <IonImg src={`/assets/pastilla${!tratamientoMed.color ? "Rosa" : tratamientoMed.color}.png`}></IonImg>
                <span>{tratamientoMed.diagnostico}</span>
              </div>
              <p className="titulo_pantalla"> Medicamentos</p>
            </div>
            <div className='panel-diagnostico' >
              <IonGrid>
                <IonRow>
                  <IonCol size='12'>
                    <IonImg src='/assets/med1.png'></IonImg>
                    <IonLabel>Diagnóstico médico (DX):&nbsp;</IonLabel>
                    <span>{tratamientoMed.diagnostico}</span>
                  </IonCol>
                  <IonCol size='12'>
                    <IonImg src='/assets/med2.png'></IonImg>
                    <IonLabel>Médico:&nbsp;</IonLabel>
                    <span className="capitalizar">{tratamientoMed.medico}</span>
                  </IonCol>
                  <IonCol size='12'>
                    <IonImg src='/assets/med3.png'></IonImg>
                    <IonLabel>Especialidad:&nbsp;</IonLabel>
                    <span>{tratamientoMed.especialidad}</span>
                  </IonCol>
                  <IonCol size='12'>
                    <IonImg src='/assets/med4.png'></IonImg>
                    <IonLabel>Teléfono del médico:&nbsp;</IonLabel>
                    <span>{tratamientoMed.telefonoMedico}</span>
                  </IonCol>
                  <IonCol size='12'>
                    <IonImg src='/assets/med5.png'></IonImg>
                    <IonLabel>Clínica u hospital:&nbsp;</IonLabel>
                    <span className="capitalizar">{tratamientoMed.clinica}</span>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div hidden={tratamientoMed && tratamientoMed.medicamentos.length>0} className='mensaje-sin-registros'>
             <span className="naranjanew">
              Por favor ingrese los médicamentos correspondientes a su tratamiento
              </span>
            </div>
            <div className='medicamento-datos' >
              <table>
                <thead>
                  <tr className="titulos_medicina">
                    <th>Nombre</th>
                    <th>Dósis</th>
                    <th>Duración</th>
                    <th>Tomar cada</th>
                    <th>Editar</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tratamientoMed && tratamientoMed.medicamentos ? tratamientoMed.medicamentos.map((lmed) => {
                      return <>
                        <tr key={lmed.idMedicamento}>
                          <td className="capitalizar">{lmed.medicamento}</td>
                          <td >{lmed.dosis}</td>
                          <td >{lmed.duracion} {validaPeriodo(lmed.periodo)}</td>
                          <td >c/{lmed.intervaloToma}h</td>
                          <td className='panel-acciones'>
                            <IonButton fill='clear' size='small' onClick={() => { handleEditarMedicamento(lmed) }} ><IonIcon color="rosa" icon={pencil}></IonIcon></IonButton>
                            <IonButton fill='clear' size='small' onClick={() => { handleEliminarMedicamento(lmed) }}><IonIcon color="rosa" icon={trash}></IonIcon></IonButton>
                          </td>
                        </tr>
                      </>
                    }) : null
                  }
                </tbody>
              </table>
            </div>
            <div className='panel-documentos'>
            <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
              <div className='upload-panel'>
                <div className='upload-preview'>
                  <img src={vprevReceta} alt="">
                  </img>
                </div>
                <div className='upload-panel-buttons'>
                  <IonButton hidden={subirTratamiento || tratamientoMed.docTratamiento != null || fileTratamiento != null} shape='round' color='rosa' onClick={() => setSubirTratamiento(true)}>
                    <IonIcon icon={cloudUpload} />
                  </IonButton>
                  <IonButton hidden={!subirTratamiento || fileTratamiento != null} shape='round' color='rosa' onClick={() => takePictureTratamiento()}>
                    <IonIcon icon={camera} />
                  </IonButton>
                  <IonButton hidden={!subirTratamiento || fileTratamiento != null} shape='round' color='rosa' onClick={() => fileInputDocTratamientoRef.current.click()}>
                    <IonIcon icon={document} />
                  </IonButton>
                  <IonButton hidden={!tratamientoMed.docTratamiento} shape='round' color='rosa' onClick={() => { abrirArchivo(tratamientoMed.docTratamiento) }}>
                    <IonIcon icon={cloudDownload} />
                  </IonButton>
                  <IonButton hidden={!tratamientoMed.docTratamiento && !fileTratamiento} shape='round' color='rosa' onClick={() => { handleEliminarDocTratamiento(tratamientoMed.docTratamiento) }}>
                    <IonIcon icon={trash} />
                  </IonButton>
                </div>
                <IonLabel>Receta médica</IonLabel>
                <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocTratamiento} ref={fileInputDocTratamientoRef}></input>
              </div>
              </div>
              <div className='msj-doc-noPremium' hidden={!(esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo))}>
                          <span>{MSJ_CARGA_DOCUMENTOS}</span>
                        </div>
            </div>
          </div>
          <IonFab hidden={esWeb()} className='allivia-emergencia-call' vertical='bottom' horizontal='center' edge slot='fixed'>
            <IonFabButton color="emergencia" routerLink='/my/alliviaHome/add'>
              <div>
                <IonIcon icon={call} />
                <IonLabel>911</IonLabel>
              </div>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton color="success" onClick={() => { emergencyCall(); }}><IonIcon icon={call} /></IonFabButton>
            </IonFabList>
          </IonFab>
          <IonModal backdropDismiss={false} isOpen={modalAgregarMedicamento} className="ion-modal-medicamento" >
            <IonContent>
              <div className='header'>
                <IonImg src='./assets/agregarMedicamento.png'></IonImg>
                Agregar Medicamento
                <IonButton fill='clear' onClick={() => { setModalAgregarMedicamento(false); limpiarDatos(); }}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='12' >
                        <IonImg className='img-icon' src="./assets/regMed0.png" />
                        <IonLabel className='indicaciones'>Indicaciones:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/regMed1.png" />
                        <IonLabel>Medicamento:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={medicamento.medicamento} placeholder='Comercial / Genérico'
                          onIonChange={(event) => { actualizaMedicamento({ medicamento: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/regMed2.png" />
                        <IonLabel>Dósis:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="text" inputMode='text' autocapitalize="sentences" placeholder='mg/ml/ui/...'
                          value={medicamento.dosis}
                          onIonChange={(event) => actualizaMedicamento({ dosis: event.detail.value })}>
                        </IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/regMed3.png" />
                        <IonLabel>Periodo:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonSelect className="listas_datos" value={medicamento.periodo}
                          okText="Aceptar" cancelText='Cancelar'
                          onIonChange={(event) => { actualizaMedicamento({ periodo: event.detail.value }) }}>
                          <IonSelectOption value={1} >Diario</IonSelectOption>
                          <IonSelectOption value={2} >Semanal</IonSelectOption>
                          <IonSelectOption value={3} >Quincenal</IonSelectOption>
                          <IonSelectOption value={4} >Mensual</IonSelectOption>
                        </IonSelect>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/regMed3.png" />
                        <IonLabel>Duración ({validaPeriodo(medicamento.periodo)}):</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='number' value={medicamento.duracion}
                          onIonChange={(event) => { actualizaMedicamento({ duracion: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/regMed4.png" />
                        <IonLabel>Fecha de inicio:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='date' value={medicamento.fechaInicio}
                          onIonChange={(event) => { actualizaMedicamento({ fechaInicio: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/regMed5.png" />
                        <IonLabel>Tomar cada:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonSelect className="listas_datos" okText='Aceptar' cancelText='Cancelar' title='Agregar'
                          value={medicamento.intervaloToma}
                          onIonChange={(event) => actualizaMedicamento({ intervaloToma: event.detail.value })}>
                          <IonSelectOption value={1} >1 hora</IonSelectOption>
                          <IonSelectOption value={2} >2 horas</IonSelectOption>
                          <IonSelectOption value={3} >3 horas</IonSelectOption>
                          <IonSelectOption value={4} >4 horas</IonSelectOption>
                          <IonSelectOption value={5} >5 horas</IonSelectOption>
                          <IonSelectOption value={6} >6 horas</IonSelectOption>
                          <IonSelectOption value={7} >7 horas</IonSelectOption>
                          <IonSelectOption value={8} >8 horas</IonSelectOption>
                          <IonSelectOption value={9} >9 horas</IonSelectOption>
                          <IonSelectOption value={10} >10 horas</IonSelectOption>
                          <IonSelectOption value={11} >11 horas</IonSelectOption>
                          <IonSelectOption value={12} >12 horas</IonSelectOption>
                          <IonSelectOption value={24} >24 horas</IonSelectOption>
                          <IonSelectOption value={168} >07 días</IonSelectOption>
                          <IonSelectOption value={360} >15 días</IonSelectOption>
                          <IonSelectOption value={504} >21 días</IonSelectOption>
                          <IonSelectOption value={720} >30 días</IonSelectOption>
                        </IonSelect>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/regMed6.png" />
                        <IonLabel>Hora de la primera toma:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='time' value={horaTemp}
                          onIonChange={(event) => { setHoraTemp(event.detail.value) }}></IonInput>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size='12'>
                        <IonLabel>Desea recibir una notificación de este evento</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRadioGroup value={verRecordatorio}
                      onIonChange={(event) => { setVerRecordatorio(event.detail.value) }} >
                      <IonRow>
                        <IonCol size='6' >
                          <IonItem lines='none'>
                            <IonLabel>Si</IonLabel>
                            <IonRadio mode="md"  slot="start" value="si"></IonRadio>
                          </IonItem>
                        </IonCol>
                        <IonCol size='6' >
                          <IonItem lines='none'>
                            <IonLabel>No</IonLabel>
                            <IonRadio mode="md"  slot="start" value="no"></IonRadio>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonRadioGroup>
                  </IonGrid>
                  <IonButton className="guardar_orange" expand='block' onClick={() => { handleGuardarMedicamento(); }} >Guardar
                    <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>
            </IonContent>
          </IonModal>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        </IonContent>
        <div className='custom-tab-bar'>
          <IonButton fill='clear' routerLink='/allivia/alliviaHome'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' routerLink='/allivia/buscar'>
            <div>
              <IonIcon icon={search} />
              <IonLabel>Buscar</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={esWeb()} fill='clear' onClick={() => { compartirArchivo() }}>
            <div>
              <IonIcon icon={shareSocial} />
              <IonLabel>Compartir</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={!esWeb()} fill='clear' onClick={() => { descargarReporteMedicamentos() }}>
            <div>
              <IonIcon icon={downloadOutline} />
              <IonLabel>Descargar</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' onClick={() => { limpiarDatos(); setModalAgregarMedicamento(true); console.log("entrando") }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Medicamento</IonLabel>
            </div>
          </IonButton>
        </div>
      </IonPage>
    </>
  );
};

export default Medicamentos;
