import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonToggle,
  IonList,
  IonItem,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { call } from 'ionicons/icons';
import '../theme/loginPage.css';
import { signInWithUserAndPassword } from '../services/login.service';
import { types } from '../types/types';
import { LoginContext, useParametros } from '../hooks/login';
import { emergencyCall, esWeb } from '../services/utils.service';


const LoginPage: React.FC = () => {
  const {user,dispatch} = useContext(LoginContext);
  const {parametros} = useParametros();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState({ loading: false, error: false });  
  const [terminosCond,setTerminosCod] = useState(true);
  const handleLogin = async () => {
    try{
      const user = await signInWithUserAndPassword(email,password);
      const action = {
        type: types.login,
        payload: {loggedIn:'true',token:user.token,username:user.username,idUsuario:user.idUsuario}
      }
      dispatch(action);
    }catch(error){      
      setStatus({ loading: false, error: true });
      console.log(error);
    }    
  }   

  const validaPublicidad = async () =>{
    if (parametros &&  parametros.publicidad){
      let banner = parametros.publicidad;;
      await banner.hide();
    }
  }

  useEffect(() =>{    
    validaPublicidad();
  },[]); 
  

  if (user.loggedIn) {
    return <Redirect to="/allivia/alliviaHome" />
  }
  return (
    <IonPage className='login-page'>
      <IonContent className="ion-padding" >
        <div className='login-header'>
            <div className='login_caregiver'>
              <img src="./assets/icon/caregiver.png" alt="caregiver" />
            </div>
            <div className="log_allivia">
              <img className="login-logo" src="./assets/icon/allivia.png" alt='allivia-icon'/>
            </div>
        
        </div>
        <IonGrid>
          <IonRow className='login-grid'>
            <IonCol className="fila_login" size='12'>
              <IonLabel className="labels_forms" style={{ color: '#878787' }} position='stacked' >Correo electrónico:</IonLabel>
              <IonInput className="input_forms" type='email' inputMode='email' pattern='email' value={email}
                onIonChange={(event) => setEmail(event.detail.value)} />
            </IonCol>
            <IonCol className="fila_login" size='12'>
              <IonLabel className="labels_forms" style={{ color: '#878787' }} position='stacked' >Contraseña:</IonLabel>
              <IonInput type='password' value={password}
                onIonChange={(event) => setPassword(event.detail.value)} />
            </IonCol>
            <IonRow>            
          </IonRow>
            {status.error
              &&
              <IonCol className="fila_login" size='12'>
                <IonText color="danger">Usuario y/o contraseña incorrectos</IonText>
              </IonCol>
            }
            <IonCol className="fila_login" size='12'>
              <IonButton id='botonIniciarSesion' expand='block' onClick={handleLogin}>
                Ingresar               
              </IonButton>
            </IonCol>
            <IonCol className="fila_login" size='12'>
              <IonButton className='restablecer' expand='block' color="medium" routerLink='/resetPassword'>
                Olvide mi contraseña              
              </IonButton>
            </IonCol>
          </IonRow>
           <IonRow>
            <IonCol className="fila_login" size='12' id="nocuenta">             
                <IonLabel>¿No tienes cuenta?</IonLabel>                                  
            </IonCol>
            <IonCol>
              <IonButton className='txtcuenta' expand='block' fill='clear' routerLink='/registroUsuario'>
                Registrate aquí
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="fila_login" size="3">              
              <IonToggle color='success' checked={terminosCond} onIonChange={(event)=> setTerminosCod(event.detail.checked)}  >
              </IonToggle>              
            </IonCol>
            <IonCol size='9'>
              <IonLabel className='login-terminoscondiciones-text' >Al ingresar acepto los términos y condiciones</IonLabel>
                <a className="resaltarbutton" href='https://caregiversolutions.com.mx/terminos-y-condiciones/' target={"_blank"} > Ver </a>
                <div>
                    <IonLabel className='login-terminoscondiciones-text'>
                  Si deseas consultar nuestro Aviso de privacidad puedes acceder en el siguiente enlace
                   </IonLabel>
                  <a className="resaltarbutton" href="https://caregiversolutions.com.mx/aviso-de-privacidad/" target={"_blank"}>
                    &nbsp; Consultar
                    </a>
                </div>
            </IonCol>
          </IonRow>
          <IonRow></IonRow>
          <IonRow>
            <div className='text-center'>
              <p>
                Visibilicemos la importancia de aquellas personas que cuidan a otras personas
              </p>
            </div>
          </IonRow>          
        </IonGrid>
        <IonLoading isOpen={status.loading} />
      </IonContent>
      <IonList hidden={esWeb()}>
        <IonItemSliding>
          <IonItemOptions side="start">
            <IonItemOption color="success" onClick={()=>{emergencyCall();}}>
              <IonLabel>Llamar</IonLabel>
            </IonItemOption>          
          </IonItemOptions>
          <IonItem  color="emergencia" className="login-emergencia-text">
            <div>
              <IonIcon className='tel' size='large'  icon={call}></IonIcon>
              <IonLabel>911<br/>Emergencia</IonLabel>
            </div>      
            
          </IonItem>        
        </IonItemSliding>
      </IonList>
      {/* <IonButton className="login-emergencia-text" color="emergencia" expand="full">
        <IonIcon size='large'  icon={call}></IonIcon>911<br/>Emergencia</IonButton>       */}
      
      <div className="login-version" >
        <IonLabel >Versión: {parametros&&parametros.versionNumber?parametros.versionNumber:""}</IonLabel>
      </div>
      {/* <a href="tel:5527034131">Clicka para llamar</a> */}

    </IonPage>
  );
};

export default LoginPage;
