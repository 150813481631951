import { DTODocumento, FileTemporalDoc, UsuarioLogin } from "./AlliviaInterfaces";
import { Filesystem,Directory,Encoding} from '@capacitor/filesystem';
// import { Browser } from '@capacitor/browser';
import { File } from '@awesome-cordova-plugins/file/';
import { URL_ALLIVIA_DOCS } from "../properties";
import { CallNumber } from '@awesome-cordova-plugins/call-number';
import { IMG_AVATAR, IMG_CARNET, IMG_ESTUDIO_CLINICO, IMG_INE, IMG_PSGMM, IMG_P_SEGURO_VIDA, IMG_RECETA_MEDICA, IMG_VAC} from "../constantes";
import dayjs from "dayjs";
import { getPlatforms, isPlatform } from "@ionic/react";
import { guardarDocumentosGluster, leerDocumentosGluster } from "./gluster.service";

/**
 * Realiza la carga de las imagenes o documentos que tengan vista previa
 * @param doc 
 * @returns 
 */
export const cargarPreview= async (user:UsuarioLogin,doc:DTODocumento,param:{premium?,premiumDuo?}):Promise<string>=>{    
  // valida que exista una imagen.
  if(doc==null){
    return null;
  }  
  let imgRead = "";

  let temimg = null;
  // Lee la imagen en carpeta del dispositivo
  try{
    temimg = await Filesystem.readFile({
      path:"allivia/"+doc.nombre,
      directory: Directory.Documents,
      encoding: Encoding.UTF8
    })
  }catch(err){
    console.log(err);
  };

  console.log("gluster-1",doc,temimg);
  // Valida si existe la imagen
  if(temimg && temimg!=null){  
    // Se parsea la imagen  
    imgRead = JSON.parse(temimg.data);    
  }else if(doc.gluster===1){
    //descargar la imagen desde el servidor.
    try{
      let docTem = await leerDocumentosGluster(user,doc);
      imgRead = docTem.fileStream;
      doc.fileStream=imgRead;
      await guardarDocumentos(doc);
    }catch(err){
      console.log(err);
    }
  }

  
  // Si la imagen no existe en el gluster
  if(doc.gluster===0&&temimg!=null){
    // Si el usuario es premium o premium duo
    if(param&&(param.premium||param.premiumDuo)){
      // Guardar imagen del dispositivo en el gluster remoto
      let temDocIne ={...doc,fileStream:temimg.data,gluster:1};
      console.log("tempDocGlusger:",temDocIne);    
      await guardarDocumentosGluster(user,temDocIne);        
    }
  }

  if(doc.mimeType==='application/pdf' || doc.mimeType==="application/PDF"){
    return "./assets/pdfIcon.png";
  } 

  if(!esWeb()&&imgRead===null){
    await Filesystem.readFile({
      path: doc.urlDocumento,
      directory: Directory.Documents      }      
    ).then((uri)=>{
      imgRead = "data:"+doc.mimeType+";base64,"+uri.data;
    }).catch((e)=>{
      console.log("Error file",e);
      imgRead = "";
    });
  }

  return imgRead;
}
/**
 * 
 * @param urlImagen 
 * @returns 
 */
export const leerFileBlob = async (urlImagen):Promise<Blob>=>{
  let response = await fetch(urlImagen);
  let blob = await response.blob();
  return blob;
}

/**
 * Valida la plataforma en la que se encuentra funcionando
 * @returns si es ambiente web
 */
export const esWeb= ():boolean=>{
  let platWeb= isPlatform("desktop")|| isPlatform("mobileweb");       
  return platWeb;
}

export const verPlataformas=():string=>{
  return JSON.stringify(getPlatforms());
}

/**
 * Convierte el blob a imagen en base64
 * @param blob 
 * @returns 
 */
export const leerImagenDataURL =(blob) =>{
    return new Promise((resolve, reject) => {
          let reader = new FileReader();  
          reader.onload = () => {
            resolve(reader.result);
          };  
          reader.onerror = reject;  
          reader.readAsDataURL(blob);
        })
  }

  export const leerFileDataBinaryString =(blob) =>{
    return new Promise((resolve, reject) => {
          let reader = new FileReader();  
          reader.onload = () => {
            resolve(reader.result);
          };  
          reader.onerror = reject;  
          reader.readAsBinaryString(blob);
        })
  }

  export const leerFileArrayBurffer=(blob) =>{
    return new Promise((resolve, reject) => {
          let reader = new FileReader();  
          reader.onload = () => {
            resolve(reader.result);
          };  
          reader.onerror = reject;  
          reader.readAsArrayBuffer(blob);
        })
  }

  export const leerFileText=(blob) =>{
    return new Promise((resolve, reject) => {
          let reader = new FileReader();  
          reader.onload = () => {
            resolve(reader.result);
          };  
          reader.onerror = reject;  
          reader.readAsText(blob,"UTF-8");
        })
  }


  export const leerImagen = (doc:DTODocumento) :string  =>{
    Filesystem.readFile({
      path: doc.urlDocumento,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    }).then((fileBlob)=>{
      const byteCharacters = fileBlob.data//atob(fileBlob.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      } 
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: 'image/png'});
      //let f = window.URL.createObjectURL(blob);
      leerImagenDataURL(blob).then((f)=>{
        console.log("url:",f);   
      return f;
      });      
    }).catch(()=>{
      return "";
    });    
    return "";
  }

  export const abrirArchivoBrowser = async(doc:DTODocumento)=>{
    let fileBlob = await Filesystem.readFile({
      path: doc.urlDocumento,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    });    
    if(fileBlob){
      //let decode = fileBlob.data.substring(fileBlob.data.indexOf(",")+1,fileBlob.data.length);
      const byteCharacters = fileBlob.data//atob(fileBlob.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      } 
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      let f = window.URL.createObjectURL(blob);
      //Browser.open({url:f});
    }
  }

  // export const guardarDocumentos= async(fileDoc:FileTemporalDoc)=>{  
  //   guardarDocumentosLog(null,fileDoc)
  // }

  export const guardarDocumentos= async(fileDoc:FileTemporalDoc)=>{  
    //lee archivo y lo convierte a blob
    let urlDoc = fileDoc.url?fileDoc.url:fileDoc.fileStream;

    let blob = await leerFileBlob(urlDoc);
    //genera una url temporal, con el blob   
    //let array = await leerImagenDataURL(blob);
    //Se descarga el archivo de la url temporal 
    // let fileTransfer:FileTransferObject = FileTransfer.create();

    
    let urlData=null;
    if(fileDoc.url){
      urlData = await leerImagenDataURL(blob);
    }else{
      urlData=fileDoc.fileStream;
    }

    let archivoData = JSON.stringify(urlData);

    let nombreArchivo = fileDoc.fileName?fileDoc.fileName:fileDoc.nombre;
    if(!esWeb()){
      await validaExisteDirectorio("allivia");
    }
    await Filesystem.writeFile({
      path:'allivia/'+nombreArchivo,
      data: archivoData,
      directory: Directory.Documents,
      encoding: Encoding.UTF8
    })
  
    fileDoc.fileStream=urlData; 
    
    if(!esWeb()){
      //Se obtiene la Uri del dispositivo
      let uriSave = await Filesystem.getUri({
        path: URL_ALLIVIA_DOCS,
        directory: Directory.Documents}      
      );
      console.log("save uri archivo uri:",uriSave.uri,File.externalDataDirectory);

      //valida si existe el directorio
      await validaExisteDirectorio(uriSave.uri);
      
      
      //Se descarga el archivo
      
      await File.checkFile(uriSave.uri,nombreArchivo).then(val=>{
        console.log("valor: ",val);
        if(val){
          Filesystem.deleteFile({
            path: URL_ALLIVIA_DOCS+"/"+nombreArchivo,
            directory:Directory.Documents
          }).catch((e)=>{
            console.log("Error al eliminar el documento",e.message);
          });
        }
      }).catch(error=>{
        console.log("checkfile:",error.message);
      });
      console.log("FileName",uriSave.uri,nombreArchivo);    
      await File.writeExistingFile(uriSave.uri,nombreArchivo,blob);
    }
      
  }

  const validaExisteDirectorio = async(uri:string) =>{
    //valida si existe el directorio
    await File.checkDir(uri,"").then((exist)=>{
      if(!exist){
        File.createDir(uri,"",true);
      }
    }).catch((e)=>{    
      Filesystem.mkdir({
        path: URL_ALLIVIA_DOCS,
        directory: Directory.Documents,recursive:true}      
      ).catch((e)=>{
          console.log("error, no se pudo crear el directorio",e.message);
        });
        console.log("Error al validar el directorio",e.message);
      }
    );  
  }

  export const guardarArchivos = async (url,blob) => {  
    console.log("archivos",url,blob);
    await Filesystem.writeFile({
      path: url,
      data: blob,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
      recursive:true
    });
  };

  export const eliminarDocumento=async(doc:DTODocumento)=>{

    await Filesystem.deleteFile({
      path: "allivia/"+doc.nombre,
      directory:Directory.Documents
    }).catch(()=>{
      console.log("Error al eliminar el documento");
    });
    if(!esWeb()){
      await Filesystem.deleteFile({
        path: doc.urlDocumento,
        directory:Directory.Documents
      }).catch(()=>{
        console.log("Error al eliminar el documento");
      }); 
    }
  }

  export const eliminarTodosDocumentos=async () => {
    await Filesystem.rmdir({
      path: "allivia",
      directory:Directory.Documents,  
      recursive: true    
    }).catch(()=>{
      console.log("Error al eliminar el documento");
    });
  }

  export const emergencyCall = ()=>{
    //CallNumber.callNumber("5527034131",true);
    CallNumber.callNumber("911",true)
    .then(res => console.log('Launch dialer!',res))
    .catch(err => console.log('Error launching dialer', err));
  }

  export const consultaTipoImagen = (tipoImagen: number): { fileName: string, descripcion: string } => {
    let sdate = dayjs(new Date()).format("YYYYMMDDHHmmss")
    let fileName = ""
    let descripcion = "";
    switch (tipoImagen) {
      case IMG_INE:
        fileName = "ine"+sdate+"."
        descripcion = "INE"
          ; break;
      case IMG_P_SEGURO_VIDA:
        fileName = "SeguroVida"+sdate+"."
        descripcion = "Seguro Vida";
        ; break;
      case IMG_VAC:
        fileName = "CertificadoVacuna."
        descripcion = "Certificado Vacuna"
          ; break;
      case IMG_AVATAR:
        fileName = "avatar"+sdate+"."
        descripcion = "Avatar"
          ; break;
      case IMG_PSGMM:
        fileName = "SeguroGMM"+sdate+"."
        descripcion = "Seguro GMM"
          ; break;
      case IMG_CARNET:
        fileName = "Carnet"+sdate+"."
        descripcion = "Carnet"
          ; break;
      case IMG_ESTUDIO_CLINICO:
        fileName = "estudio"+sdate+"."
        descripcion = "Estudio Clinico"
          ; break;
      case IMG_RECETA_MEDICA:
        fileName = "receta"+sdate+"."
        descripcion = "Receta Medica"
          ; break;
    }
    return { fileName: fileName, descripcion: descripcion };
  }

  export const leerArchivoDescarga= async (doc:DTODocumento):Promise<string>=>{        
    
    let imgRead = "";
    let temimg = null;
    try{
      temimg = await Filesystem.readFile({
        path:"allivia/"+doc.nombre,
        directory: Directory.Documents,
        encoding: Encoding.UTF8
      })
    }catch(err){
      console.log(err);
    };
    
    if(temimg && temimg!=null){    
      imgRead = JSON.parse(temimg.data);
      console.log(imgRead);   
    }
  
    return imgRead;
  }
  

  export const leerArchivo = async (event: React.ChangeEvent<HTMLInputElement>,tipoImagen:number): Promise<FileTemporalDoc> => {
    if (event.target.files.length > 0) {
      const file = event.target.files.item(0);
      let { fileName } = consultaTipoImagen(tipoImagen);
      let name = file.name.substring(file.name.lastIndexOf("."),file.name.length)
      const temUrl = { url: URL.createObjectURL(file), fileName: fileName+name, mimeType: file.type };
      console.log("created URL: ", temUrl);
      return temUrl;
    }
  }

  // const abrirArchivo = async(doc:DTODocumento)=>{
  //   let uri = await Filesystem.getUri({
  //     path: doc.urlDocumento,
  //     directory: Directory.Documents}      
  //   );
  //   console.log("abir archivo uri:",uri);
    // let fileBlob = await Filesystem.readFile({
    //   path: doc.urlDocumento,
    //   directory: Directory.Documents,
    //   encoding: Encoding.UTF8,
    // });
    // console.log(fileBlob.data);
    // let decode = fileBlob.data.substring(fileBlob.data.indexOf(",")+1,fileBlob.data.length);
    // const byteCharacters = atob(decode);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], {type: 'application/pdf'});

    

    // var byteString = fileBlob.data;
    // var ab = new ArrayBuffer(byteString.length);
    // var ia = new Uint8Array(ab);
    // for (var i = 0; i < byteString.length; i++) {
    //     ia[i] = byteString.charCodeAt(i);
    // }
    // var bb = new Blob([ab]);    
    // let f = window.URL.createObjectURL(blob);
    // console.log("url_crateated:",f);
    //setURLDownload(f);
    
    //await Browser.open({url:"https://www.diputados.gob.mx/LeyesBiblio/pdf/CPEUM.pdf"});
    
    // let vale=(await File.createDir(File.documentsDirectory,"allivia/test",true)).name;
    // console.log(vale);
    
    // FileOpener.showOpenWithDialog(uri.uri, 'application/pdf')
    // .then(() => console.log('File is opened'))
    // .catch(e => console.log('Error opening file', e));;

    // let canOpen = await checkCanOPenUrl();
    // await openPortfolioPage();
  // }

  // const checkCanOPenUrl = async () =>{
  //   const { value } = await AppLauncher.canOpenUrl({ url: 'com.getcapacitor.myapp' });
  //   console.log('Can open url: ', value);
  // }

  // const openPortfolioPage = async () => {
  //   await AppLauncher.openUrl({ url: 'com.getcapacitor.myapp://page?id=portfolio' });
  // };

  // if(fileCertVac.url.startsWith('blob:')){
  //   let blob = await leerFileBlob(fileCertVac.url);
  //   console.log("vacuna: ",fileCertVac,blob)   
  //   let binary = await leerFileDataBinaryString(blob);
            
  //   let array = await leerImagenDataURL(blob);      
    
  //   let temDocVacuna:DTODocumento=cuidador.docVacuna;
  //   if(!temDocVacuna){
  //     temDocVacuna={
  //       idPersona:cuidador.idPersona,
  //       descripcion:"vacuna",
  //       urlDocumento: URL_ALLIVIA_DOCS+"/"+fileCertVac.fileName,
  //       catTipoDocumento:{idTipoDoc:3},
  //       estatus : 1,
  //       remoto:false,            
  //     }
  //   }else{
  //     temDocVacuna={...temDocVacuna,urlDocumento:URL_ALLIVIA_DOCS+"/"+fileCertVac.fileName}
  //   }
  //   tempCuiddador = {...cuidador,docVacuna:temDocVacuna};
  //   //guardarArchivos(temDocVacuna.urlDocumento,binary);
  //   let fileTransfer:FileTransferObject = FileTransfer.create();
  //   let uriSave = await Filesystem.getUri({
  //     path: URL_ALLIVIA_DOCS,
  //     directory: Directory.Documents}      
  //   );
  //   console.log("save uri archivo uri:",uriSave);
  //   fileTransfer.download(array+"",uriSave.uri+"/"+fileCertVac.fileName);
  // }