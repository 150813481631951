import { SERVICE_ALLIVIA} from "../properties";
import { DTOPaciente, DTOSolicitudCompartir, UsuarioLogin } from "./AlliviaInterfaces";





export const leerPacientes = async (idCuidador:number,idPersona:number, usuario:UsuarioLogin):Promise<DTOPaciente> =>{
  const url = SERVICE_ALLIVIA+"/paciente/"+idCuidador+"/"+idPersona;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerPacientesPropietario = async (idCuidadorPropetario:number,idCuidadorCompartido:number, usuario:UsuarioLogin):Promise<Array<DTOPaciente>> =>{
  const url = SERVICE_ALLIVIA+"/paciente/propietario/"+idCuidadorPropetario+"/"+idCuidadorCompartido;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const solicitudEliminarPacienteCompartido = async (idPaciente:Array<number>, usuario:UsuarioLogin):Promise<string> =>{
  const url = SERVICE_ALLIVIA+"/paciente/eliminar/compartido/"+idPaciente;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "DELETE"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const solicitudAceptarPacienteCompartido = async (idPaciente:number, usuario:UsuarioLogin):Promise<string> =>{
  const url = SERVICE_ALLIVIA+"/paciente/aceptar/compartido/"+idPaciente;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const solicitudRechazarPacienteCompartido = async (idPaciente:number, usuario:UsuarioLogin):Promise<string> =>{
  const url = SERVICE_ALLIVIA+"/paciente/rechazar/compartido/"+idPaciente;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerPacientesCompartidos = async (idCuidadorCompartido:number, usuario:UsuarioLogin):Promise<Array<DTOPaciente>> =>{
  const url = SERVICE_ALLIVIA+"/paciente/compartidos/"+idCuidadorCompartido;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerPaciente = async (idPaciente:number, usuario:UsuarioLogin):Promise<DTOPaciente> =>{
  const url = SERVICE_ALLIVIA+"/paciente/"+idPaciente;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const guardarPaciente =async (usuario,paciente:DTOPaciente):Promise<DTOPaciente> => {
  const url = SERVICE_ALLIVIA+"/paciente";
  limpiarDocsAntesGuardar(paciente);
  const response = await fetch(url,{
    body: JSON.stringify(paciente),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar paciente:"+response.statusText);
  }
  const json = await response.json();    
  return json;
}

const limpiarDocsAntesGuardar = (pac :DTOPaciente) =>{
  if(pac.docIne){
    pac.docIne.fileStream=null;
  }
  if(pac.docVacuna){
    pac.docVacuna.fileStream=null;
  }
  if(pac.docCarnet){
    pac.docCarnet.fileStream=null;
  }
  if(pac.docSeguroGMM){
    pac.docSeguroGMM.fileStream=null;
  }
  if(pac.docSeguroVida){
    pac.docSeguroVida.fileStream=null;
  }
}

export const solicitaCompartirPaciente = async (usuario,solicitud:DTOSolicitudCompartir):Promise<DTOSolicitudCompartir> => {
  const url = SERVICE_ALLIVIA+"/paciente/solicitudCompartir";
  const response = await fetch(url,{
    body: JSON.stringify(solicitud),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error 2"+response.statusText);
  }
  const json = await response.json();    
  return json;
}

export const eliminarPaciente = async (idPaciente:number, usuario:UsuarioLogin):Promise<string> =>{
  const url = SERVICE_ALLIVIA+"/paciente/"+idPaciente;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "DELETE"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);  
  return json;
}
