import { SERVICE_ALLIVIA} from "../properties";
import { DTOCuestionario, DTOCuestionarioEvaluacion, DTOTestCuidador, UsuarioLogin } from "./AlliviaInterfaces";


export const leerCuestionarios = async (idTest:number,usuario:UsuarioLogin):Promise<Array<DTOCuestionario>> =>{
  const url = SERVICE_ALLIVIA+"/cuestionario/test/"+idTest;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}


export const leerCuestionarioEvaluacion = async (idTest:number,usuario:UsuarioLogin):Promise<Array<DTOCuestionarioEvaluacion>> =>{
  const url = SERVICE_ALLIVIA+"/cuestionarioEvaluacion/test/"+idTest;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerTestCuidador = async (idCuidador:number,usuario:UsuarioLogin):Promise<Array<DTOTestCuidador>> =>{
  const url = SERVICE_ALLIVIA+"/test/cuidador/"+idCuidador;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const guardarTestCuidador =async (testCuidador:DTOTestCuidador,usuario:UsuarioLogin):Promise<DTOTestCuidador> => {
  const url = SERVICE_ALLIVIA+"/test/cuidador";
  const response = await fetch(url,{
    body: JSON.stringify(testCuidador),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el estudio medico: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const guardarTestNutricion =async (testCuidador:DTOTestCuidador,usuario:UsuarioLogin):Promise<DTOTestCuidador> => {
  const url = SERVICE_ALLIVIA+"/test/nutricion/cuidador";
  const response = await fetch(url,{
    body: JSON.stringify(testCuidador),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el plan nutricional: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

