import { SERVICE_ALLIVIA} from "../properties";
import {DTODocumento, UsuarioLogin } from "./AlliviaInterfaces";


export const generarReporteSignosVitales = async (usuario:UsuarioLogin,idPersona:number,idCuidador:number,idReporte:number):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/reporte/signos/persona/"+idPersona+"/cuidador/"+idCuidador+"/reporte/"+idReporte;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const generarReporteEstudioClinico = async (usuario:UsuarioLogin,idPersona:number,idCuidador:number):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/reporte/estudioClinico/persona/"+idPersona+"/"+idCuidador;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const generarReporteCitaMedica = async (usuario:UsuarioLogin,idPersona:number,idCuidador:number):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/reporte/citaMedica/persona/"+idPersona+"/"+idCuidador;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const generarReporteTratamiento = async (usuario:UsuarioLogin,idPersona:number,idCuidador:number):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/reporte/tratamiento/persona/"+idPersona+"/"+idCuidador;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const generarReporteMedicamento = async (usuario:UsuarioLogin,idPersona:number,idTratamiento:number,idCuidador:number):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/reporte/medicamento/tratamiento/"+idPersona+"/"+idTratamiento+"/"+idCuidador;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const generarReporteInformacionPaciente = async (usuario:UsuarioLogin,idCuidador:number,idPersona:number):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/reporte/informacionPersonal/cuidador/persona/"+idCuidador+"/"+idPersona;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const generarReporteTestCuidador= async (usuario:UsuarioLogin,idCuidador:number):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/reporte/testCuidador/cuidador/"+idCuidador;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

