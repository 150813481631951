import {
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  NavContext,
} from '@ionic/react';
import { add, camera, cloudDownload, cloudUpload, pencil, personAdd, trash, document, save } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import { useLoginContext, useParametros } from '../hooks/login';
import { URL_ALLIVIA_DOCS, URL_CURP } from '../properties';
import { DTOAlergia, DTOCatAfiliacionSocial, DTOCatAseguradora, DTOCatTipoSangre, DTOCuidador, DTODiagnostico, DTODocumento, DTOSeguroBeneficiario, FileTemporalDoc } from '../services/AlliviaInterfaces';
import { leerCatAfiliacionSocial, leerCatAseguradora, leerCatTipoSangre } from '../services/catalogos.service';
import { guardarCuidador, leerCuidador } from '../services/cuidador.service';
import { abrirArchivoBrowser, cargarPreview, consultaTipoImagen, eliminarDocumento, esWeb, guardarDocumentos, leerArchivo, leerFileBlob, leerImagenDataURL, } from '../services/utils.service';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { Camera, CameraResultType } from '@capacitor/camera';
import '../theme/perfilCuidador.css';
import { IMG_AVATAR, IMG_CARNET, IMG_ESTUDIO_CLINICO, IMG_INE, IMG_PSGMM, IMG_P_SEGURO_VIDA, IMG_RECETA_MEDICA, IMG_VAC, MENSAJE_CARGA_ARCHIVOS, MSJ_CARGA_DOCUMENTOS } from '../constantes';
import { eliminarDocumentosGluster, guardarDocumentosGluster } from '../services/gluster.service';


const initCuidador = (): DTOCuidador => {
  return { nombre: '' }
}


const PerfilCuidador: React.FC = () => {
  const user = useLoginContext();
  const { parametros,setParametros } = useParametros();
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [cuidador, setCuidador] = useState<DTOCuidador | undefined>(initCuidador());
  const [modalDiagnostico, setModalDiagnostico] = useState(false);
  const [alergiaDescripcion, setAlergiaDescripcion] = useState("");
  const [diagnosticoDescripcion, setDiagnosticoDescripcion] = useState("");
  const [catTipoSangre, setCatTipoSangre] = useState<Array<DTOCatTipoSangre> | undefined>();
  const [catAfiliacionSocial, setCatAfiliacionSocial] = useState<Array<DTOCatAfiliacionSocial> | undefined>();
  const [catAseguradora, setCatAseguradora] = useState<Array<DTOCatAseguradora> | undefined>();
  const [tempBeneficiario, setTempBeneficiario] = useState<string>();
  const [tempPorcentaje, setTempPorcentaje] = useState<number>();
  const fileInputAvatarRef = useRef<HTMLInputElement>();
  const fileInputDocVacRef = useRef<HTMLInputElement>();
  const fileInputDocIneRef = useRef<HTMLInputElement>();
  const fileInputDocCarnetRef = useRef<HTMLInputElement>();
  const fileInputDocSegGMMRef = useRef<HTMLInputElement>();
  const fileInputDocSegVidaRef = useRef<HTMLInputElement>();
  const [fileAvatar, setFileAvatar] = useState<FileTemporalDoc>({ url: './assets/avatar.png' });
  const [diganosticoTemp, setDiagnosticoTem] = useState<DTODiagnostico>();
  const [alergiaTemp, setAlergiaTem] = useState<DTOAlergia>();
  const [subirIne, setSubirIne] = useState(false);
  const [subirVac, setSubirVac] = useState(false);
  const [subirCarnet, setSubirCarnet] = useState(false);
  const [subirGMM, setSubirGMM] = useState(false);
  const [subirSvida, setSubirSvida] = useState(false);
  const [imagenOptions] = useState({
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.Uri,
    promptLabelHeader: "Foto",
    promptLabelCancel: "Cancelar",
    promptLabelPhoto: "Galeria",
    promptLabelPicture: "Cámara"
  });
  const [vprevIne, setVprevIne] = useState("");
  const [vprevVac, setVprevVac] = useState("");
  const [vprevCarnet, setVprevCarnet] = useState("");
  const [vprevGMM, setVprevGMM] = useState("");
  const [vprevSvida, setVprevSvida] = useState("");
  const [modalAlergia, setModalAlergia] = useState(false);
  const [modalBeneficiarios, setModalBeneficiarios] = useState(false);
  const [porcentajeTotal, setPorcentajeTotal] = useState(0);
  const [hayCambios,setHayCambios]  = useState(false);
  const [cuidadorOriginal, setCuidadorOriginal] = useState("");
  const [modalGuardar, setModalGuardar] = useState(false);
  const {goBack} = useContext(NavContext);

  const takePictureAvatar = async () => {
    try{
      const image = await Camera.getPhoto(imagenOptions);
      var imageUrl = image.webPath;
      let temp:FileTemporalDoc = { url: imageUrl, fileName: "avatar." + image.format, mimeType: "image/" + image.format };
      //setFileAvatar({ url: imageUrl, fileName: "avatar." + image.format, mimeType: "image/" + image.format });
      await handleGuardarCuidadorw(temp);  
    }catch(err){
      console.log("error al cargar imagen avatar", err,err.message);
      setMsgToast({showToast:true,statusMessage:"Error al subir la imagen, por favor intenta de nuevo"});
    }
  };

  const takePicture = async (tipoImagen: number) => {
    setEstatus({ loading: true, leer: false });
    const image = await Camera.getPhoto(imagenOptions);
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    var imageUrl = image.webPath;
    // Can be set to the src of an image now
    // imageUrl;
    
    let { fileName, descripcion } = consultaTipoImagen(tipoImagen);
    let fileDocIne = { url: imageUrl, fileName: fileName + image.format, mimeType: "image/" + image.format };
    await saveDocumentos(fileDocIne, descripcion, tipoImagen);
    setEstatus({ loading: false, leer: false });
  };

  const saveDocumentos = async (fileDoc: FileTemporalDoc, descripcion: string, tipoImagen: number) => {
    let tempCuiddador = { ...cuidador };
    if (fileDoc != null) {
      let temDocIne = seleccionaDocumentoExiste(tipoImagen);
      if (!temDocIne) {
        temDocIne = {
          idPersona: cuidador.idPersona,
          descripcion: descripcion,
          urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDoc.fileName,
          catTipoDocumento: { idTipoDoc: tipoImagen },
          nombre:fileDoc.fileName,
          estatus: 1,
          remoto: false,
          mimeType: fileDoc.mimeType,
          gluster:0,
        }
      } else {
        temDocIne = { ...temDocIne, urlDocumento: URL_ALLIVIA_DOCS + "/" + fileDoc.fileName, mimeType: fileDoc.mimeType }
      }      
      await guardarDocumentos(fileDoc);
      console.log(parametros);
      if(parametros&&(parametros.premium||parametros.premiumDuo)){
        //syncronizar imagen en el servidor
        temDocIne ={...temDocIne,fileStream:fileDoc.fileStream,gluster:1};
        await guardarDocumentosGluster(user,temDocIne);
        temDocIne.fileStream=null;        
      }
      tempCuiddador = { ...tempCuiddador, docIne: temDocIne };
      URL.revokeObjectURL(fileDoc.url);
    }
    let cuida = await guardarCuidador(user, tempCuiddador);
    setCuidador(cuida);
    resetValores();

    switch (tipoImagen) {
      case IMG_INE:
        cargarPreview(user,cuida.docIne,parametros).then((tdoc) => {
          setVprevIne(tdoc);
        });
        ; break;
      case IMG_P_SEGURO_VIDA:
        cargarPreview(user,cuida.docSeguroVida,parametros).then((tdoc) => {
          setVprevSvida(tdoc);
        });
        ; break;
      case IMG_VAC:
        cargarPreview(user,cuida.docVacuna,parametros).then((tdoc) => {
          setVprevVac(tdoc);
        });
        ; break;
      case IMG_AVATAR:
        ; break;
      case IMG_PSGMM:
        cargarPreview(user,cuida.docSeguroGMM,parametros).then((tdoc) => {
          setVprevGMM(tdoc);
        });
        ; break;
      case IMG_CARNET:
        cargarPreview(user,cuida.docCarnet,parametros).then((tdoc) => {
          setVprevCarnet(tdoc);
        });
        ; break;
      case IMG_ESTUDIO_CLINICO:
        ; break;
      case IMG_RECETA_MEDICA:
        ; break;
    }
  }

  const seleccionaDocumentoExiste = (tipoImagen: number): DTODocumento => {
    let temDoc: DTODocumento = {};
    switch (tipoImagen) {
      case IMG_INE:
        temDoc = cuidador.docIne;
        ; break;
      case IMG_P_SEGURO_VIDA:
        temDoc = cuidador.docSeguroVida;
        ; break;
      case IMG_VAC:
        temDoc = cuidador.docVacuna;
        ; break;
      case IMG_AVATAR:
        ; break;
      case IMG_PSGMM:
        temDoc = cuidador.docSeguroGMM;
        ; break;
      case IMG_CARNET:
        temDoc = cuidador.docCarnet;
        ; break;
      case IMG_ESTUDIO_CLINICO:
        ; break;
      case IMG_RECETA_MEDICA:
        ; break;
    }
    return temDoc;
  }

  const resetValores = () => {
    setSubirIne(false);
    setSubirVac(false);
    setSubirCarnet(false);
    setSubirSvida(false);
    setSubirGMM(false);
  }

  const handleEliminarDoc = async (doc: DTODocumento) => {
    setEstatus({ loading: true, leer: false });
    try {
      doc.estatus = 0;
      console.log(cuidador);
      let temCuidador = { ...cuidador };
      await eliminarDocumento(doc);
      try{
        await eliminarDocumentosGluster(user,doc);
      }catch(err){      
        console.log("error al eliminar los documentos del gluster,",err);
      }
      temCuidador = await guardarCuidador(user, temCuidador);
      setCuidador(temCuidador);

      switch (doc.catTipoDocumento.idTipoDoc) {
        case IMG_INE:
          setVprevIne("");
          fileInputDocIneRef.current.value ="";
          ; break;
        case IMG_P_SEGURO_VIDA:
          setVprevSvida("");
          fileInputDocSegVidaRef.current.value ="";
          ; break;
        case IMG_VAC:
          setVprevVac("");
          fileInputDocVacRef.current.value ="";
          ; break;
        case IMG_AVATAR:
          ; break;
        case IMG_PSGMM:
          setVprevGMM("");
          fileInputDocSegGMMRef.current.value ="";
          ; break;
        case IMG_CARNET:
          setVprevCarnet("");
          fileInputDocCarnetRef.current.value ="";
          ; break;
        case IMG_ESTUDIO_CLINICO:
          ; break;
        case IMG_RECETA_MEDICA:
          ; break;
      }
      setMsgToast({ showToast: true, statusMessage: "El documento se elmino correctamente" });
    } catch (error) {
      setMsgToast({ showToast: true, statusMessage: "Ocurrio un error al guardar los datos" });
    };
    setEstatus({ loading: false, leer: false });
  }




  const abrirArchivo = async (doc: DTODocumento) => {
    let uri = await Filesystem.getUri({
      path: doc.urlDocumento,
      directory: Directory.Documents
    }
    );
    console.log("abir archivo uri:", uri);
    FileOpener.showOpenWithDialog(uri.uri, doc.mimeType).then().catch((e) => {
      console.log("error", e);
      abrirArchivoBrowser(doc).catch((e) => {
        setMsgToast({ showToast: true, statusMessage: "El documento no se encuentra en este dispositivo" });
      });
    });
  }


  const handleSeleccionarDocIne = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event,IMG_INE);
    let { descripcion } = consultaTipoImagen(IMG_INE);
    await saveDocumentos(fileDoc, descripcion, IMG_INE)
    .catch(()=>{setEstatus({ loading: false, leer: false })});
    setEstatus({ loading: false, leer: false });
    //setMsgToast({ showToast: true, statusMessage: MENSAJE_CARGA_ARCHIVOS });
  }
  const handleSeleccionarDocVacuna = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event,IMG_VAC);
    let { descripcion } = consultaTipoImagen(IMG_VAC);
    await saveDocumentos(fileDoc, descripcion, IMG_VAC)
    .catch(()=>{setEstatus({ loading: false, leer: false })});
    setEstatus({ loading: false, leer: false });
  }

  const handleSeleccionarDocCarnet = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event,IMG_CARNET);
    let { descripcion } = consultaTipoImagen(IMG_CARNET);
    await saveDocumentos(fileDoc, descripcion, IMG_CARNET)
    .catch(()=>{setEstatus({ loading: false, leer: false })});
    setEstatus({ loading: false, leer: false });
  }

  const handleSeleccionarDocSegGMM = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event,IMG_PSGMM);
    let { descripcion } = consultaTipoImagen(IMG_PSGMM);
    await saveDocumentos(fileDoc, descripcion, IMG_PSGMM)
    .catch(()=>{setEstatus({ loading: false, leer: false })});
    setEstatus({ loading: false, leer: false });
  }

  const handleSeleccionarDocSegVida = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstatus({ loading: true, leer: false });
    let fileDoc = await leerArchivo(event,IMG_P_SEGURO_VIDA);
    let { descripcion } = consultaTipoImagen(IMG_P_SEGURO_VIDA);
    await saveDocumentos(fileDoc, descripcion, IMG_P_SEGURO_VIDA)
    .catch(()=>{setEstatus({ loading: false, leer: false })});
    setEstatus({ loading: false, leer: false });
  }  



  const handleEliminarDiagnostico = (diag: DTODiagnostico) => {
    setEstatus({ loading: true, leer: false });
    diag.estatus = 0;
    setCuidador(cuidador);
    validaCambiosGuardar(cuidador);
    setEstatus({ loading: false, leer: false });
  }

  const handleActualizarDiagnostico = (diag: DTODiagnostico) => {
    setDiagnosticoTem(diag);
    setDiagnosticoDescripcion(diag.descripcion);
    setModalDiagnostico(true);
  }

  const handleEliminarBeneficiario = (benef: DTOSeguroBeneficiario) => {
    setEstatus({ loading: true, leer: false });
    benef.estatus = 0;
    validaPorcentajeTotal(cuidador);
    setCuidador(cuidador);
    validaCambiosGuardar(cuidador);
    setEstatus({ loading: false, leer: false });
  }

  const handelFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      const file = event.target.files.item(0);
      console.log(file);
      const pictureUrl = URL.createObjectURL(file);
      setFileAvatar({ url: pictureUrl, fileName: file.name, mimeType: file.type });
      console.log("created URL: ", pictureUrl);
    }
  }

  const handleGuardarCuidador = async () => {
    handleGuardarCuidadorw(fileAvatar);
  }

  const handleGuardarCuidadorw = async (fileAvatar:FileTemporalDoc) => {
    setEstatus({ loading: true, leer: false });
    try {
      let tempCuiddador = { ...cuidador };
      if(!tempCuiddador.nombre||tempCuiddador.nombre===null||tempCuiddador.nombre===""){
        setMsgToast({ showToast: true, statusMessage: "Ingresa el nombre del paciente" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      if(!tempCuiddador.apPaterno||tempCuiddador.apPaterno===null||tempCuiddador.apPaterno===""){
        setMsgToast({ showToast: true, statusMessage: "Ingresa el apellido paterno del paciente" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      if (fileAvatar != null && (fileAvatar.url.startsWith("blob") || fileAvatar.url.startsWith("http") || fileAvatar.url.startsWith("capacitor"))) {
        let blob = await leerFileBlob(fileAvatar.url);
        let array = await leerImagenDataURL(blob);

        let temAvatar: DTODocumento = cuidador.avatar;
        if (!temAvatar) {
          temAvatar = {
            idPersona: cuidador.idPersona,
            descripcion: "avatar",
            urlDocumento: fileAvatar.fileName,
            nombre:fileAvatar.fileName,
            catTipoDocumento: { idTipoDoc: 4 },
            estatus: 1,
            remoto: true,
            fileStream: array,
            mimeType: fileAvatar.mimeType,
            gluster:0
          }
        } else {
          temAvatar = { ...temAvatar, fileStream: array, urlDocumento: fileAvatar.fileName, mimeType: fileAvatar.mimeType }
        }
        tempCuiddador = { ...tempCuiddador, avatar: temAvatar };
      }

      console.log("guardarcuidador: ", tempCuiddador);
      agregarNuevoBeneficiario(tempCuiddador);
      if (tempCuiddador.seguroVida && !tempCuiddador.seguroVida.idCatAseguradora) {
        setMsgToast({ showToast: true, statusMessage: "Selecciona una aseguradora" });
        setEstatus({ loading: false, leer: false });
        return
      }

      let cuida = await guardarCuidador(user, tempCuiddador);
      setCuidador(cuida);
      setCuidadorOriginal(JSON.stringify(cuida));      
      setHayCambios(false);
      setParametros({...parametros,cuidador:cuida});
      setMsgToast({ showToast: true, statusMessage: "Los datos de perfil se guardaron correctamente" });
    } catch (error) {
      setMsgToast({ showToast: true, statusMessage: "Ocurrio un error al guardar los datos" });
    };
    setEstatus({ loading: false, leer: true });
  }


  useEffect(() => {
    const val = async () => {
      return await Promise.all([
        leerCuidador(user.username, user),
        leerCatTipoSangre(user),
        leerCatAfiliacionSocial(user),
        leerCatAseguradora(user),
      ]);

    }

    if (estatus.leer === true) {
      val().then((result) => {
        validaPorcentajeTotal(result[0]);
        if (result[0].avatar && result[0].avatar.fileStream) {
          setFileAvatar({ url: result[0].avatar.fileStream + "" });
        }
        setCuidadorOriginal(JSON.stringify(result[0]));
        if (result[0].docIne) {
          cargarPreview(user,result[0].docIne,parametros).then((tdoc) => {
            setVprevIne(tdoc);
          });
        }
        if (result[0].docCarnet) {
          cargarPreview(user,result[0].docCarnet,parametros).then((tdoc) => {
            setVprevCarnet(tdoc);
          });
        }
        if (result[0].docSeguroGMM) {
          cargarPreview(user,result[0].docSeguroGMM,parametros).then((tdoc) => {
            setVprevGMM(tdoc);
          });
        }
        if (result[0].docSeguroVida) {
          cargarPreview(user,result[0].docSeguroVida,parametros).then((tdoc) => {
            setVprevSvida(tdoc);
          });
        }
        if (result[0].docVacuna) {
          cargarPreview(user,result[0].docVacuna,parametros).then((tdoc) => {
            setVprevVac(tdoc);
          });
        }
        setCuidador(result[0]);
        setCatTipoSangre(result[1]);
        setCatAfiliacionSocial(result[2]);
        setCatAseguradora(result[3]);
        setEstatus({ loading: false, leer: false });
      });
    }
  }, [user, catTipoSangre, catAfiliacionSocial, catAseguradora, cuidador, estatus]);

  const agregarValor = async(valor: any) => {
    let cuidTemp = { ...cuidador, ...valor }
    setCuidador(cuidTemp);
    validaCambiosGuardar(cuidTemp);
  }


  const agregarDiagnostico = async () => {
    setEstatus({ loading: true, leer: false });
    if (diganosticoTemp) {
      let diagnosticos = cuidador.diagnosticos;
      let diagnosticosTem: Array<DTODiagnostico> = [];
      diagnosticos.forEach((diag) => {
        if (diag.idDiagnostico === diganosticoTemp.idDiagnostico) {
          diagnosticosTem.push({ ...diganosticoTemp, descripcion: diagnosticoDescripcion });
        } else {
          diagnosticosTem.push(diag);
        }
      });
      let tempCuid={ ...cuidador, diagnosticos: diagnosticosTem };
      setCuidador(tempCuid);
      await validaCambiosGuardar(tempCuid);
      setDiagnosticoTem(null);
    } else {
      let diag: DTODiagnostico = { idPersona: cuidador.idPersona, descripcion: diagnosticoDescripcion, estatus: 1 }
      let diagnosticos = cuidador.diagnosticos;
      diagnosticos.push(diag);
      let tempCuid={ ...cuidador, diagnosticos: diagnosticos };
      setCuidador(tempCuid);
      await validaCambiosGuardar(tempCuid);
    }
    setModalDiagnostico(false);
    setDiagnosticoDescripcion('');
    setEstatus({ loading: false, leer: false });
  }

  const agregarAlergia = async () => {
    setEstatus({ loading: true, leer: false });
    if (alergiaTemp) {
      let alergias = cuidador.alergias;
      let alergTemp: Array<DTOAlergia> = [];
      alergias.forEach((alerg) => {
        if (alerg.idAlergia === alergiaTemp.idAlergia) {
          alergTemp.push({ ...alergiaTemp, alergia: alergiaDescripcion, estatus: 1 });
        } else {
          alergTemp.push(alerg);
        }
      });
      let tempCuid = { ...cuidador, alergias: alergTemp };
      setCuidador(tempCuid);
      await validaCambiosGuardar(tempCuid);
      setAlergiaTem(null);
    } else {
      let alerg: DTOAlergia = { idPersona: cuidador.idPersona, alergia: alergiaDescripcion, estatus: 1 }
      let alergias = cuidador.alergias;
      alergias.push(alerg);
      let tempCuid = { ...cuidador, alergias: alergias };
      setCuidador(tempCuid);
      await validaCambiosGuardar(tempCuid);
    }
    setModalAlergia(false);
    setAlergiaDescripcion("");
    setEstatus({ loading: false, leer: false });
  }

  const handleEliminarAlergia = (alerg: DTOAlergia) => {
    setEstatus({ loading: true, leer: false });
    alerg.estatus = 0;
    setCuidador(cuidador);
    validaCambiosGuardar(cuidador);
    setEstatus({ loading: false, leer: false });
  }

  const handleActualizarAlergia = async (alerg: DTOAlergia) => {
    console.log(alerg)
    setAlergiaTem(alerg);
    setAlergiaDescripcion(alerg.alergia);
    setModalAlergia(true);
  }

  const cancelarDiagnostico = async () => {
    setEstatus({ loading: true, leer: false });
    setDiagnosticoDescripcion("");
    setModalDiagnostico(false);
    setEstatus({ loading: false, leer: false });
  }

  const cancelarAlergia = async () => {
    setEstatus({ loading: true, leer: false });
    setAlergiaDescripcion("");
    setModalAlergia(false);
    setEstatus({ loading: false, leer: false });
  }

  const agregarSeguroVida = async (valor: any) => {
    if (!(valor.idCatAseguradora || valor.numeroPoliza || valor.vigenciaPoliza || valor.titular)) {
      return
    }
    let segVida = cuidador.seguroVida;
    if (!segVida) {
      segVida = { idPersona: cuidador.idPersona, seguroBeneficiarios: [], estatus: 1 }
    }
    segVida = { ...segVida, ...valor };
    let tempCuid={ ...cuidador, seguroVida: segVida };
    setCuidador(tempCuid);
    await validaCambiosGuardar(tempCuid);
    console.log("Se agrego el seguro de vida: ", cuidador);
  }


  const validaPorcentaje = (porcentaje: number) => {
    let po = 0;
    if (cuidador != null && cuidador.seguroVida != null && cuidador.seguroVida.seguroBeneficiarios != null) {
      cuidador.seguroVida.seguroBeneficiarios.forEach((b) => {
        if (b.estatus != 0) {
          po = po + b.porcentaje;
        }
      });
    }
    if ((po + porcentaje) > 100) {
      setMsgToast({ showToast: true, statusMessage: "La suma de porcentajes es mayor al 100%" });
    } else {
      setTempPorcentaje(porcentaje);
    }
  }

  const validaPorcentajeTotal = (tcuid: DTOCuidador) => {
    let po = 0;
    if (tcuid != null && tcuid.seguroVida != null && tcuid.seguroVida.seguroBeneficiarios != null) {
      tcuid.seguroVida.seguroBeneficiarios.forEach((b) => {
        if (b.estatus != 0) {
          po = po + b.porcentaje;
        }
      });
    }
    console.log("porcentajeTotal:", po);
    setPorcentajeTotal(po);
    // return (po + tempPorcentaje) >= 100
  }

  const agregarNuevoBeneficiario = (cuid: DTOCuidador) => {
    let benef: DTOSeguroBeneficiario[] = [];
    if (!tempBeneficiario) {
      return;
    }
    if (!tempPorcentaje) {
      return;
    }
    //validar si existe un seguro de vida creado
    if (!cuid.seguroVida) {
      //Si no existe seguro de vida crear uno     
      setCuidador({ ...cuid, seguroVida: { idPersona: cuid.idPersona, seguroBeneficiarios: benef } });
    } else {
      //leer la lista de beneficiarios
      benef = cuidador.seguroVida.seguroBeneficiarios;
      //agregar nuevo beneficiario      
    }
    benef.push({ beneficiario: tempBeneficiario, porcentaje: tempPorcentaje, estatus: 1, idSeguro: cuidador.seguroVida.idSeguro });
    //reiniciar la variable
    setTempBeneficiario(null);
    setTempPorcentaje(null);
  }

  const abrirModalBeneficiarios = async () => {
    setEstatus({ loading: true, leer: false });
    setModalBeneficiarios(true)
    setEstatus({ loading: false, leer: false });
  }

  const cancelarBeneficiarios = async () => {
    setEstatus({ loading: true, leer: false });
    setModalBeneficiarios(false);
    setEstatus({ loading: false, leer: false });
  }

  const agregarBeneficiarios = async () => {
    setEstatus({ loading: true, leer: false });
    try {
      let temCuid = { ...cuidador };
      let benef: DTOSeguroBeneficiario[] = [];
      if (!tempBeneficiario || tempBeneficiario === "") {
        setMsgToast({ showToast: true, statusMessage: "Ingresa un nombre de beneficiario" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      if (!tempPorcentaje) {
        setMsgToast({ showToast: true, statusMessage: "Selecciona un porcentaje" });
        setEstatus({ loading: false, leer: false });
        return;
      }
      //validar si existe un seguro de vida creado
      if (!temCuid.seguroVida) {
        //Si no existe seguro de vida crear uno    
        temCuid = { ...temCuid, seguroVida: { idPersona: temCuid.idPersona, seguroBeneficiarios: benef } };
        benef = temCuid.seguroVida.seguroBeneficiarios;
      } else {
        //leer la lista de beneficiarios
        benef = temCuid.seguroVida.seguroBeneficiarios;
        //agregar nuevo beneficiario      
      }
      benef.push({ beneficiario: tempBeneficiario, porcentaje: tempPorcentaje, estatus: 1, idSeguro: temCuid.seguroVida.idSeguro });
      //reiniciar la variable
      validaPorcentajeTotal(temCuid);
      setCuidador({ ...temCuid });
      validaCambiosGuardar({...temCuid});
      setTempBeneficiario(null);
      setTempPorcentaje(null);
      setModalBeneficiarios(false);
    } catch (error) {
      setMsgToast({ showToast: true, statusMessage: "Ocurrio un error al agregar el beneficiario, por favor intenta de nuevo" });
    }
    setEstatus({ loading: false, leer: false });
  }

  const validaCambiosGuardar=async (cuidTemp:DTOCuidador) =>{
    console.log(JSON.stringify(cuidTemp),cuidadorOriginal);
    setHayCambios(JSON.stringify(cuidTemp)!=cuidadorOriginal);
  }

  const handleAbrirModalGuardar = async() =>{
    setModalGuardar(true);
  }

  const handleAceptarGuardar=async()=>{
    await handleGuardarCuidador();
    setModalGuardar(false);
    //const elemtn = document.getElementById('my-element');
    goBack();
  }

  const handleCancelarGuardar= async()=>{
    setHayCambios(false);
    setModalGuardar(false);   
    goBack(); 
  }


  //console.log(`rendering from loginPage with loggedIn=${user.loggedIn}`);
  if (!user.loggedIn) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  // if (!cuidador) {
  //   return (<IonLoading isOpen message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />);
  // }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons hidden={hayCambios} slot='start'>
            <IonBackButton text=""   color='primary' defaultHref="/" />            
          </IonButtons>
          <IonButtons hidden={!hayCambios} slot='start'>
            <IonButton color="naranja" onClick={handleAbrirModalGuardar}  ><IonIcon icon={save}></IonIcon></IonButton>
          </IonButtons>
          <IonTitle className="titulos_interiores">Mi perfil de cuidador</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div>
            <p>
              Los datos que se llenan a partir del ingreso a la app, no son registrados por <span className="apremium">allivia</span> o ninguno de sus administradores, por lo que tu información está segura.
            </p>
        </div>
        <div className='panel-cuidador_perfil'>
          <p>Por favor ingrese todos los datos solicitados y utilice el
            <span> </span>
            <span className="naranjanew">
              botón naranja para guardar su información&nbsp;&nbsp;
            </span>
            <img className="icon_small" src='./assets/savesmall.png'></img>
          </p>
          <div id="avatar_perfil">
            <IonAvatar className='avatar'>
              <img src={fileAvatar.url} alt='avatar' >
              </img>
              <IonButton className="btn-avatar" size='small' shape='round' onClick={() => takePictureAvatar()}>
                <IonIcon className="camera" icon={camera} ></IonIcon>
              </IonButton>
            </IonAvatar>
            <input type='file' className="btn-avatar" accept='image/*' hidden onChange={handelFileChange} ref={fileInputAvatarRef}>

            </input>
          </div>
          <IonInput className="nombreCompleto allivia-input-disabled" disabled placeholder="Nombre completo" value={cuidador.nombreCompleto} >
          </IonInput>
        </div>
        <div className='panel-cuidador-datos'>
          <div className='panel-cuidador-form'>
            <IonAccordionGroup>
              <IonAccordion className="grupo_datos" value="dGenerales">
                <IonItem slot="header" >
                  <IonLabel className="title_datos">Datos Generales</IonLabel>
                </IonItem>
                <IonGrid slot='content'>
                  <IonRow>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Fecha de nacimiento:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonInput type="date" value={cuidador.fechaNacimiento}
                        onIonChange={(event) => { agregarValor({ fechaNacimiento: event.detail.value }) }} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Sexo:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonSelect className="listas_datos" placeholder="Seleccionar su Sexo" value={cuidador.idSexo}
                        onIonChange={(event) => agregarValor({ idSexo: event.detail.value })}>
                        <IonSelectOption value={1}>Femenino</IonSelectOption>
                        <IonSelectOption value={2}>Masculino</IonSelectOption>
                      </IonSelect>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>CURP:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="2">
                      <IonInput className='curp' type="text" value={cuidador.curp}
                        onIonChange={(event) => agregarValor({ curp: event.detail.value })} />
                      <IonButton className='link-curp' fill="clear" href={URL_CURP} target='_blank' >Descarga tu CURP actualizado</IonButton>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>En caso de emergencia llamar al:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type='tel' inputMode='tel' value={cuidador.telefonoEmergencia}
                        onIonChange={(event) => agregarValor({ telefonoEmergencia: event.detail.value })} />
                    </IonCol>
                    <IonCol>
                    <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                      <div className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={vprevIne} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirIne || cuidador.docIne != null} shape='round' color='rosa' onClick={() => setSubirIne(true)}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirIne} shape='round' color='rosa' onClick={() => takePicture(IMG_INE)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirIne} shape='round' color='rosa' onClick={() => fileInputDocIneRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docIne} shape='round' color='rosa' onClick={() => { abrirArchivo(cuidador.docIne) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docIne} shape='round' color='rosa' onClick={() => { handleEliminarDoc(cuidador.docIne) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>INE</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocIne} ref={fileInputDocIneRef}></input>
                      </div>
                      <div className='msj-doc-noPremium' hidden={!(esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo))}>
                          <span>{MSJ_CARGA_DOCUMENTOS}</span>
                        </div>
                        <div>
                          <p className="textos_descriptivos">
                            Te sugerimos subir tu INE por ambos lados en un archivo PDF
                          </p>
                        </div>
                      <IonButton onClick={handleGuardarCuidador} fill='clear' className="recordar_save">
                        <div className='recordar-guardar' >
                          <span className="naranja">
                            Por favor guarde su información
                          </span>
                          <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                        </div>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
              <IonAccordion className="grupo_datos" value="tamanio">
                <IonItem slot="header">
                  <IonLabel className="title_datos">Datos Médicos Importantes</IonLabel>
                </IonItem>
                <IonGrid slot='content'>
                  <IonRow>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Tipo de sangre:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonSelect className="listas_datos" placeholder='Tipo de sangre' okText='Aceptar' cancelText='Cancelar' value={cuidador.idTipoSangre}
                        onIonChange={(event) => { agregarValor({ idTipoSangre: event.detail.value }) }} >
                        {catTipoSangre ? catTipoSangre.map((cts) => {
                          return <IonSelectOption key={cts.idTipoSangre} value={cts.idTipoSangre}>{cts.descripcion}</IonSelectOption>
                        }) : null

                        }
                      </IonSelect>
                    </IonCol>
                  </IonRow>
                  <IonRow className='panel-diagnosticos'>
                    <IonCol size='12'>
                      <IonLabel position='floating'>Alergias:</IonLabel>
                    </IonCol>
                    {
                      cuidador && cuidador.alergias ? cuidador.alergias.map((aler) =>
                        aler.estatus > 0 ?
                          <>
                            <IonCol size="10" size-md="9">
                              <IonInput className="capitalizar allivia-input-disabled" type="text" inputMode='text' disabled={true} value={aler.alergia} />
                            </IonCol>
                            <IonCol className='panel-acciones' size='2' sizeSm='3'>
                              <IonButton hidden={!aler.idAlergia} fill='clear' size='small' shape='round' onClick={() => { handleActualizarAlergia(aler) }}><IonIcon color='rosa' icon={pencil} /></IonButton>
                              <IonButton fill='clear' size='small' shape='round' onClick={() => { handleEliminarAlergia(aler) }} ><IonIcon color='rosa' icon={trash} /></IonButton>
                            </IonCol>
                          </> : <></>
                      ) : <></>
                    }
                    <IonCol size='12' className='panel-add-diag'>
                      <IonButton color="rosa" size='small' shape='round' onClick={() => { setModalAlergia(true); }}>
                        <IonIcon icon={add} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow className='panel-diagnosticos'>
                    <IonCol size='12'>
                      <IonLabel position='floating'>Diagnósticos médicos de importancia para tí:</IonLabel>
                    </IonCol>
                    {cuidador && cuidador.diagnosticos ? cuidador.diagnosticos.map((diagnost) =>
                      diagnost.estatus > 0 ?
                        <>
                          <IonCol size='10' sizeSm='9'>
                            <IonInput className="capitalizar allivia-input-disabled" type="text" inputMode='text' autocapitalize="words" key={diagnost.idDiagnostico} disabled={true} value={diagnost.descripcion} />
                          </IonCol>
                          <IonCol className='panel-acciones' size='2' sizeSm='3'>
                            <IonButton hidden={!diagnost.idDiagnostico} fill='clear' size='small' shape='round' onClick={() => { handleActualizarDiagnostico(diagnost); }}><IonIcon color='rosa' icon={pencil} /></IonButton>
                            <IonButton fill='clear' size='small' shape='round' onClick={() => { handleEliminarDiagnostico(diagnost); }} ><IonIcon color='rosa' icon={trash} /></IonButton>
                          </IonCol>
                        </>
                        : null
                    ) : null}
                    <IonCol size='12' className='panel-add-diag'>
                      <IonButton color="rosa" size='small' shape='round' onClick={() => { setModalDiagnostico(true); }}>
                        <IonIcon icon={add} />
                      </IonButton>
                    </IonCol>
                    <IonButton onClick={handleGuardarCuidador} fill='clear' className="recordar_save">
                      <div className='recordar-guardar' >
                        <span className="naranja">
                          Por favor guarde su información
                        </span>
                        <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                      </div>
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
              <IonAccordion className="grupo_datos" value="dSeguroSalud">
                <IonItem slot="header" >
                  <IonLabel className="title_datos">Datos Seguro de Salud</IonLabel>
                </IonItem>
                <IonGrid slot='content'>
                  <IonRow>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Tipo de afiliación social:</IonLabel>
                    </IonCol>
                    <IonCol size="6" size-md="4">
                      <IonSelect className="listas_datos" placeholder="Seleccione su afiliación" okText='Aceptar' cancelText='Cancelar'
                        value={cuidador.idAfiliacion}
                        onIonChange={(event) => agregarValor({ idAfiliacion: event.detail.value })}>
                        {catAfiliacionSocial ? catAfiliacionSocial.map((catAS) => {
                          return <IonSelectOption key={catAS.idAfiliacionSocial} value={catAS.idAfiliacionSocial} >{catAS.descripcion}</IonSelectOption>
                        }) : null
                        }
                      </IonSelect>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Número de seguridad social y/o afiliación:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="text" value={cuidador.nuAfiliacion}
                        onIonChange={(event) => agregarValor({ nuAfiliacion: event.detail.value })} />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size='12'>
                      <p className='ds-titulo'>¿Cuenta con Seguro de Gastos Médicos Mayores?</p>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Aseguradora:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonSelect className="listas_datos" placeholder="Seleccione su aseguradora"
                        okText='Aceptar' cancelText='Cancelar'
                        value={cuidador.idAseguradora}
                        onIonChange={(event) => agregarValor({ idAseguradora: event.detail.value })}
                      >
                        {catAseguradora ? catAseguradora.map((catAsg) => {
                          return <IonSelectOption key={catAsg.idAseguradora} value={catAsg.idAseguradora} >{catAsg.descripcion}</IonSelectOption>
                        }) : null
                        }
                      </IonSelect>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Número de póliza:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="text" value={cuidador.numeroPolizaGMM}
                        onIonChange={(event) => agregarValor({ numeroPolizaGMM: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Vigencia de póliza:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="date" value={cuidador.vigenciaPoliza}
                        onIonChange={(event) => agregarValor({ vigenciaPoliza: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>En caso de emergencia trasladarme a:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="text" inputMode='text' autocapitalize="words" value={cuidador.hospitalUrgencias}
                        onIonChange={(event) => agregarValor({ hospitalUrgencias: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Teléfono de su hospital:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type='tel' inputMode='tel' value={cuidador.telefonoHospital}
                        onIonChange={(event) => agregarValor({ telefonoHospital: event.detail.value })} />
                    </IonCol>
                    <IonCol size='12'>
                    <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                      <div className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={vprevVac} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirVac || cuidador.docVacuna != null} shape='round' color='rosa' onClick={() => { setSubirVac(true) }}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirVac} shape='round' color='rosa' onClick={() => takePicture(IMG_VAC)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirVac} shape='round' color='rosa' onClick={() => fileInputDocVacRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docVacuna} shape='round' color='rosa' onClick={() => { abrirArchivo(cuidador.docVacuna) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docVacuna} shape='round' color='rosa' onClick={() => { handleEliminarDoc(cuidador.docVacuna) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>Certificado de vacunación COVID-19</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocVacuna} ref={fileInputDocVacRef}></input>
                      </div>
                    </IonCol>
                    <IonCol size='12'>
                    <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                      <div className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={vprevCarnet} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirCarnet || cuidador.docCarnet != null} shape='round' color='rosa' onClick={() => setSubirCarnet(true)}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirCarnet} shape='round' color='rosa' onClick={() => takePicture(IMG_CARNET)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirCarnet} shape='round' color='rosa' onClick={() => fileInputDocCarnetRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docCarnet} shape='round' color='rosa' onClick={() => { abrirArchivo(cuidador.docCarnet) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docCarnet} shape='round' color='rosa' onClick={() => { handleEliminarDoc(cuidador.docCarnet) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>CARNET</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocCarnet} ref={fileInputDocCarnetRef}></input>
                      </div>
                      <div>
                        <p className="textos_descriptivos">
                            Te sugerimos subir tu carnet con la vigencia en un archivo PDF
                       </p> 
                      </div>
                        
                    </IonCol>
                    <IonCol size='12'>
                    <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                      <div className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={vprevGMM} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirGMM || cuidador.docSeguroGMM != null} shape='round' color='rosa' onClick={() => setSubirGMM(true)}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirGMM} shape='round' color='rosa' onClick={() => takePicture(IMG_PSGMM)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirGMM} shape='round' color='rosa' onClick={() => fileInputDocSegGMMRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docSeguroGMM} shape='round' color='rosa' onClick={() => { abrirArchivo(cuidador.docSeguroGMM) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docSeguroGMM} shape='round' color='rosa' onClick={() => { handleEliminarDoc(cuidador.docSeguroGMM) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>Póliza de Seguro de Gastos Médicos Mayores</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocSegGMM} ref={fileInputDocSegGMMRef}></input>
                      </div>
                      <div className='msj-doc-noPremium' hidden={!(esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo))}>
                          <span>{MSJ_CARGA_DOCUMENTOS}</span>
                        </div>
                    </IonCol>
                    <IonButton onClick={handleGuardarCuidador} fill='clear' className="recordar_save">
                      <div className='recordar-guardar' >
                        <span className="naranja">
                          Por favor guarde su información
                        </span>
                        <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                      </div>
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
              <IonAccordion className="grupo_datos" value="dSeguroVida">
                <IonItem slot="header" >
                  <IonLabel className="title_datos">Datos Seguro de Vida</IonLabel>
                </IonItem>
                <IonGrid slot='content'>
                  <IonRow>
                    <IonCol size='12'>
                      <p className='ds-aviso'>Sí cuenta con Seguro de Vida, por favor ingrese los siguientes datos:</p>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Aseguradora:*</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonSelect className="listas_datos" placeholder="Seleccione su aseguradora" okText='Aceptar' cancelText='Cancelar'
                        value={cuidador.seguroVida ? cuidador.seguroVida.idCatAseguradora : null}
                        onIonChange={(event) => agregarSeguroVida({ idCatAseguradora: event.detail.value })}>
                        {catAseguradora ? catAseguradora.map((catAsg) => {
                          return <IonSelectOption key={catAsg.idAseguradora} value={catAsg.idAseguradora} >{catAsg.descripcion}</IonSelectOption>
                        }) : null
                        }
                      </IonSelect>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Número de póliza:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="text"
                        value={cuidador.seguroVida ? cuidador.seguroVida.numeroPoliza : null}
                        onIonChange={(event) => agregarSeguroVida({ numeroPoliza: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Vigencia Póliza:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="date"
                        value={cuidador.seguroVida ? cuidador.seguroVida.vigenciaPoliza : null}
                        onIonChange={(event) => agregarSeguroVida({ vigenciaPoliza: event.detail.value })} />
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Titular:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonInput type="text" inputMode='text' autocapitalize="words"
                        value={cuidador.seguroVida ? cuidador.seguroVida.titular : null}
                        onIonChange={(event) => agregarSeguroVida({ titular: event.detail.value })} />
                    </IonCol>
                  </IonRow>
                  <IonRow className='panel-benef'>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Beneficiario(s)</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel position='floating'>Porcentaje</IonLabel>
                    </IonCol>
                    {cuidador && cuidador.seguroVida && cuidador.seguroVida.seguroBeneficiarios ? cuidador.seguroVida.seguroBeneficiarios.map((benefic) =>
                      benefic.estatus > 0 ?
                        <>
                          <IonCol size='6'>
                            <IonInput className="capitalizar allivia-input-disabled" type="text" inputMode='text' autocapitalize="words" key={benefic.idBeneficiario} disabled value={benefic.beneficiario}></IonInput>
                          </IonCol>
                          <IonCol size='4'>
                            <IonInput className="capitalizar allivia-input-disabled" disabled value={benefic.porcentaje}></IonInput>
                          </IonCol>
                          <IonCol className='panel-acciones' size='2'>
                            <IonButton fill='clear' size='small' shape='round' onClick={() => { handleEliminarBeneficiario(benefic); }}>
                              <IonIcon color='rosa' icon={trash}></IonIcon>
                            </IonButton>
                          </IonCol>
                        </> : 
                        <>
                        </>
                    ) : <></>}
                    <IonCol className='hide-benef' hidden={porcentajeTotal >0} size='6'>
                        <IonInput disabled></IonInput>
                      </IonCol>
                      <IonCol className='hide-benef' hidden={porcentajeTotal >0} size='6'>
                        <IonInput disabled></IonInput>
                      </IonCol>
                    <IonCol className='panel-add-benef' size='12'>
                      <IonButton hidden={porcentajeTotal >= 100} fill='clear' onClick={() => { abrirModalBeneficiarios() }}>
                        <IonIcon color='rosa' icon={personAdd}></IonIcon>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                      <div className='upload-panel'>
                        <div className='upload-preview'>
                          <img src={vprevSvida} alt="">
                          </img>
                        </div>
                        <div className='upload-panel-buttons'>
                          <IonButton hidden={subirSvida || cuidador.docSeguroVida != null} shape='round' color='rosa' onClick={() => setSubirSvida(true)}>
                            <IonIcon icon={cloudUpload} />
                          </IonButton>
                          <IonButton hidden={!subirSvida} shape='round' color='rosa' onClick={() => takePicture(IMG_P_SEGURO_VIDA)}>
                            <IonIcon icon={camera} />
                          </IonButton>
                          <IonButton hidden={!subirSvida} shape='round' color='rosa' onClick={() => fileInputDocSegVidaRef.current.click()}>
                            <IonIcon icon={document} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docSeguroVida} shape='round' color='rosa' onClick={() => { abrirArchivo(cuidador.docSeguroVida) }}>
                            <IonIcon icon={cloudDownload} />
                          </IonButton>
                          <IonButton hidden={!cuidador.docSeguroVida} shape='round' color='rosa' onClick={() => { handleEliminarDoc(cuidador.docSeguroVida) }}>
                            <IonIcon icon={trash} />
                          </IonButton>
                        </div>
                        <IonLabel>Póliza de Seguro de Vida</IonLabel>
                      </div>
                      <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocSegVida} ref={fileInputDocSegVidaRef}></input>
                      </div>
                      <div className='msj-doc-noPremium' hidden={!(esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo))}>
                          <span>{MSJ_CARGA_DOCUMENTOS}</span>
                        </div>
                    </IonCol>
                    <IonButton onClick={handleGuardarCuidador} fill='clear' className="recordar_save">
                      <div className='recordar-guardar' >
                        <span className="naranja">
                          Por favor guarde su información
                        </span>
                        <IonImg className="ico_small" src='./assets/savesmall.png'></IonImg>
                      </div>
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
            </IonAccordionGroup>
          </div>
          {/* <IonButton expand="block" onClick={handleGuardarCuidador}><IonLabel>Guardar</IonLabel></IonButton> */}

        </div>
        <IonToast color='rosa'
          isOpen={msgToast.showToast}
          onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
          message={msgToast.statusMessage}
          duration={3000}
        />
        <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        <IonModal backdropDismiss={false} isOpen={modalDiagnostico} className="ion-modal-diagnosticos" >
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>Escribe un nuevo diagnóstico médico de importancia para ti:</IonLabel>
                  <IonInput type="text" inputMode='text' autocapitalize="words" value={diagnosticoDescripcion}
                    onIonChange={(event) => { setDiagnosticoDescripcion(event.detail.value) }}>
                  </IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand='block' onClick={() => { cancelarDiagnostico() }}>
                    <IonLabel>Cancelar</IonLabel>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton expand='block' onClick={() => { agregarDiagnostico() }} >
                    <IonLabel>{diganosticoTemp ? 'Actualizar' : 'Guardar'}</IonLabel>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonModal backdropDismiss={false} isOpen={modalAlergia} className="ion-modal-alergia" >
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>Alergia:</IonLabel>
                  <IonInput type="text" inputMode='text' autocapitalize="word" value={alergiaDescripcion}
                    onIonChange={(event) => { setAlergiaDescripcion(event.detail.value) }}>
                  </IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand='block' onClick={() => { cancelarAlergia() }}>
                    <IonLabel>Cancelar</IonLabel>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton expand='block' onClick={() => { agregarAlergia() }} >
                    <IonLabel>{alergiaTemp ? 'Actualizar' : 'Guardar'}</IonLabel>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonModal backdropDismiss={false} isOpen={modalGuardar} className="ion-modal-cambios-singuardar" >
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>¿Deseas guardar los cambios de tú perfil, antes de salir?</IonLabel>                  
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand='block' onClick={() => { handleCancelarGuardar() }}>
                    <IonLabel>Cancelar</IonLabel>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton expand='block' onClick={() => { handleAceptarGuardar() }} >
                    <IonLabel>Guardar</IonLabel>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonModal backdropDismiss={false} isOpen={modalBeneficiarios} className="ion-modal-beneficiarios" >
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>Beneficiario:</IonLabel>
                  <IonInput type="text" inputMode='text' autocapitalize="words" value={tempBeneficiario}
                    onIonChange={(event) => setTempBeneficiario(event.detail.value)} />
                </IonCol>
                <IonCol>
                  <IonLabel>Porcentaje:</IonLabel>
                  <IonSelect className="listas_datos" placeholder="Seleccione su aseguradora" value={tempPorcentaje}
                    onIonChange={(event) => validaPorcentaje(event.detail.value)}>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 5} value={5}>5%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 10} value={10}>10%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 15} value={15}>15%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 20} value={20}>20%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 25} value={25}>25%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 30} value={30}>30%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 35} value={35}>35%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 40} value={40}>40%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 45} value={45}>45%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 50} value={50}>50%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 55} value={55}>55%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 60} value={60}>60%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 65} value={65}>65%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 70} value={70}>70%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 75} value={75}>75%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 80} value={80}>80%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 85} value={85}>85%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 90} value={90}>90%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 95} value={95}>95%</IonSelectOption>
                    <IonSelectOption disabled={(100 - porcentajeTotal) < 100} value={100}>100%</IonSelectOption>
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand='block' onClick={() => { cancelarBeneficiarios() }}>
                    <IonLabel>Cancelar</IonLabel>
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton expand='block' onClick={() => { agregarBeneficiarios() }} >
                    <IonLabel>Guardar</IonLabel>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonFab vertical="bottom" horizontal="end" title='Guardar' slot='fixed'>
          <IonFabButton className='saveFabButton' onClick={handleGuardarCuidador}>
            <IonImg src='./assets/saveIcon.png' />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default PerfilCuidador;
