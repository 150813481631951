import { SERVICE_ALLIVIA} from "../properties";
import {  DTOCitaMedica, UsuarioLogin } from "./AlliviaInterfaces";


export const leerCitaMedicaById = async (usuario:UsuarioLogin,idCitaMedica:number):Promise<DTOCitaMedica> =>{
  const url = SERVICE_ALLIVIA+"/citaMedica/id/"+idCitaMedica;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerCitaMedicas = async (usuario:UsuarioLogin,idPersona:number):Promise<Array<DTOCitaMedica>> =>{
  const url = SERVICE_ALLIVIA+"/citaMedica/"+idPersona;  
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("citaMedica",JSON.stringify(json));
    console.log(json);    
    return json;
  }catch(evt){
    console.log("error",evt);
    let citaMed :Array<DTOCitaMedica>=[];
    if(localStorage.citaMedica){
      citaMed=JSON.parse(localStorage.citaMedica);        
    }
    return citaMed
  }
}

export const guardarCitaMedica =async (usuario:UsuarioLogin,estudio:DTOCitaMedica):Promise<DTOCitaMedica> => {
  const url = SERVICE_ALLIVIA+"/citaMedica";
  const response = await fetch(url,{
    body: JSON.stringify(estudio),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar la cita medica: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

