import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonLabel,
  IonListHeader,
  IonList,
  IonImg,
  IonBackButton,
  IonLoading,
  IonButton,
  IonSlides,
  IonSlide,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonModal,
  IonToast,
  IonFabList,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { addCircle, call,closeOutline,downloadOutline,home, pencil, search, shareSocial, trash} from 'ionicons/icons';
import { useLoginContext, useParametros, } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/signosVitales.css';
import { Redirect } from 'react-router';
import {  DTODocumento, DTOGraphValues, DTOPaciente, DTOSignoEstatura, DTOSignoGlucosa, DTOSignoOxigeno, DTOSignoPeso, DTOSignoPresionArterial, DTOSignoTemperatura } from '../services/AlliviaInterfaces';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { guardarEstatura, guardarGlucosa, guardarOxigeno, guardarPeso, guardarPresionArterial, guardarTemperatura, leerEstaturas, leerGlucosas, leerOxigenoss, leerPesos, leerPresionArterials, leerTemperatura } from '../services/signosVitales.service';
import { emergencyCall, esWeb, guardarDocumentos } from '../services/utils.service';
import dayjs from 'dayjs';
import { generarReporteSignosVitales } from '../services/reportes.service';
import { abrirArchivoBrowser} from '../services/utils.service';
import { Filesystem,Directory} from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { Share } from '@capacitor/share';

import { URL_ALLIVIA_DOCS } from '../properties';


const SignosVitales: React.FC = () => {  
  const user = useLoginContext();
  const {parametros} = useParametros();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [paciente, setPaciente] = useState<DTOPaciente | undefined>();
  const [modalAgregarSigno, setModalAgregarSigno] = useState(false);
  const [peso, setPeso] = useState<DTOSignoPeso>({});
  const [estatura, setEstatura] = useState<DTOSignoEstatura>({});
  const [temperatura, setTemperatura] = useState<DTOSignoTemperatura>({});
  const [oxigeno, setOxigeno] = useState<DTOSignoOxigeno>({});
  const [glucosa, setGlucosa] = useState<DTOSignoGlucosa>({});
  const [presion, setPresion] = useState<DTOSignoPresionArterial>({});
  const [horaTemp,setHoraTemp] = useState("");
  const [lgPesos,setLgPesos]= useState<Array<DTOGraphValues>>([]);
  const [lgEstatura,setLgEstatura]= useState<Array<DTOGraphValues>>([]);
  const [lgTemperatura,setLgTemperatura]= useState<Array<DTOGraphValues>>([]);
  const [lgOxigeno,setLgOxigeno]= useState<Array<DTOGraphValues>>([]);
  const [lgGlucosa,setLgGlucosa]= useState<Array<DTOGraphValues>>([]);
  const [lgPresion,setLgPresion]= useState<Array<DTOGraphValues>>([]);
  const [listaPesos,setListaPesos]= useState<Array<DTOSignoPeso>>();
  const [listaEstaturas,setListaEstaturas]= useState<Array<DTOSignoEstatura>>();
  const [listaTemperaturas,setListaTemperaturas]= useState<Array<DTOSignoTemperatura>>();
  const [listaOxigeno,setListaOxigeno]= useState<Array<DTOSignoOxigeno>>();
  const [listaGlucosa,setListaGlucosa]= useState<Array<DTOSignoGlucosa>>();
  const [listaPresion,setListaPresion]= useState<Array<DTOSignoPresionArterial>>();
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [indexSlide,setIndexSlide] = useState(0);
  const signosSlide = useRef(null);

  const descargarArchivoSignosVitales = async ()=>{
    setEstatus({ loading: true, leer: false });
    console.log("Iniciando descarga");
    let docu = await generarReporteSignosVitales(user,paciente.idPersona,paciente.idCuidador,indexSlide);
    if(esWeb()){
      console.log(docu.fileStream);      
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='" +docu.fileStream+ "'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }
    setEstatus({loading:false,leer:false});
    await guardarDocumentos(docu).then(()=>{
      docu.urlDocumento = URL_ALLIVIA_DOCS + "/" + docu.urlDocumento;
      abrirArchivo(docu);
    }).catch((e)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un erro al descargar el archivo de signos vitales, por favor intenta de nuevo"});
    });
    
  }
  

  const compartirArchivo = async()=>{
    setEstatus({loading:true,leer:false});  
    let docu = await generarReporteSignosVitales(user,paciente.idPersona,paciente.idCuidador,indexSlide);

    console.log(docu.fileStream);

    // let fileTransfer:FileTransferObject = FileTransfer.create();
    //Se obtiene la Uri del dispositivo
    let uriSave = await Filesystem.getUri({
      path: URL_ALLIVIA_DOCS,
      directory: Directory.Documents}      
    );
    console.log("save uri archivo uri:",uriSave);
    await guardarDocumentos(docu).then(()=>{      
    })
    setEstatus({loading:false,leer:false}); 
    await Share.share({
      title: docu.descripcion,
      text: 'Compartir',
      url: "file://"+uriSave.uri+"/"+docu.urlDocumento,
      dialogTitle: 'Compartir',
    }).catch((error)=>{
      console.log("error",error);
    });
  }

  const abrirArchivo = async(doc:DTODocumento)=>{
    let uri = await Filesystem.getUri({
      path: doc.urlDocumento,
      directory: Directory.Documents}      
    );
    console.log("abir archivo uri:",uri);    
    FileOpener.showOpenWithDialog(uri.uri, doc.mimeType).then().catch((e)=>{
      abrirArchivoBrowser(doc).catch((e)=>{
        setMsgToast({showToast:true,statusMessage:"El documento no se encuentra en este dispositivo"});
      });
    });
  }

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  const convertirHora=(fecha:string):string=>{  
    return dayjs(fecha).format("HH:mm");
  }
  const convertirFecha=(fecha:string):string=>{
    return dayjs(fecha).format("DD/MM/YY");
  }

  const handleEditarPeso= async(speso :DTOSignoPeso)=>{ 
    setPeso(speso);
    setHoraTemp(convertirHora(speso.fecha+"T"+speso.hora));
    setModalAgregarSigno(true);
  };

  const handleEliminarPeso=async (speso:DTOSignoPeso)=>{
    setEstatus({loading:true,leer:false});  
    let ptem ={...speso,estatus:0};            
    await guardarPeso(user,ptem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se elimino correctamente"});      
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al eliminar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosPeso();
    await limpiarDatos();
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});  
  }

  const handleEditarEstatura= async (sestatura :DTOSignoEstatura)=>{    
    setEstatura(sestatura);
    setHoraTemp(convertirHora(sestatura.fecha+"T"+sestatura.hora));
    setModalAgregarSigno(true);
  };

  const handleEliminarEstatura= async (sestatura:DTOSignoEstatura)=>{
    setEstatus({loading:true,leer:false});  
    let ptem ={...sestatura,estatus:0};            
    await guardarEstatura(user,ptem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se elimino correctamente"});      
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al eliminar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosEstatura();
    await limpiarDatos();
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});  
  }

  const handleEditarTemperatura= async (stemperatura :DTOSignoTemperatura)=>{    
    setTemperatura(stemperatura);
    setHoraTemp(convertirHora(stemperatura.fecha+"T"+stemperatura.hora));
    setModalAgregarSigno(true);
  };

  const handleEliminarTemperatura= async (stemperatura:DTOSignoTemperatura)=>{
    setEstatus({loading:true,leer:false});  
    let ptem ={...stemperatura,estatus:0};            
    await guardarTemperatura(user,ptem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se elimino correctamente"});      
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al eliminar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosTemperatura();
    await limpiarDatos();      
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});  
  }

  const handleEditarOxigeno= async (soxigeno :DTOSignoOxigeno)=>{    
    setOxigeno(soxigeno);
    setHoraTemp(convertirHora(soxigeno.fecha+"T"+soxigeno.hora));
    setModalAgregarSigno(true);
  };

  const handleEliminarOxigeno= async (soxigeno:DTOSignoOxigeno)=>{
    setEstatus({loading:true,leer:false});  
    let ptem ={...soxigeno,estatus:0};            
    await guardarOxigeno(user,ptem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se elimino correctamente"});
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al eliminar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosOxigenacion();
    await limpiarDatos(); 
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});  
  }

  const handleEditarGlucosa= async (sglucosa :DTOSignoGlucosa)=>{    
    setGlucosa(sglucosa);
    setHoraTemp(convertirHora(sglucosa.fecha+"T"+sglucosa.hora));
    setModalAgregarSigno(true);
  };

  const handleEliminarGlucosa= async (sglucosa:DTOSignoGlucosa)=>{
    setEstatus({loading:true,leer:false});  
    let ptem ={...sglucosa,estatus:0};            
    await guardarGlucosa(user,ptem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se elimino correctamente"});      
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al eliminar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosGlucosa();
    await limpiarDatos(); 
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});
  }

  const handleEditarPresion=async(spresion :DTOSignoPresionArterial)=>{    
    setPresion(spresion);
    setHoraTemp(convertirHora(spresion.fecha+"T"+spresion.hora));
    setModalAgregarSigno(true);
  };

  const handleEliminarPresion=async (spresion:DTOSignoPresionArterial)=>{
    setEstatus({loading:true,leer:false});
    let ptem ={...spresion,estatus:0};            
    await guardarPresionArterial(user,ptem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se elimino correctamente"});
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al eliminar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosPresion();
    await limpiarDatos();      
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});
  }

  const handleGuardarPeso = async()=>{     
    setEstatus({loading:true,leer:false});      
    let ptem ={...peso,hora:convertirHoraFecha(horaTemp)};            
    await guardarPeso(user,ptem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se guardo correctamente"});      
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosPeso();
    await limpiarDatos();
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});
  }

  const handleGuardarEstatura = async ()=>{   
    setEstatus({loading:true,leer:false});
    let etem ={...estatura,hora:convertirHoraFecha(horaTemp)};
    await guardarEstatura(user,etem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se guardo correctamente"});      
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosEstatura();
    await limpiarDatos();
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});
  }

  const handleGuardarTemperatura = async()=>{   
    setEstatus({loading:true,leer:false});   
    let ttem ={...temperatura,hora:convertirHoraFecha(horaTemp)};
    await guardarTemperatura(user,ttem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se guardo correctamente"});
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosTemperatura();
    await limpiarDatos();
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});
  }

  const handleGuardarOxigeno = async()=>{ 
    setEstatus({loading:true,leer:false});     
    let otem ={...oxigeno,hora:convertirHoraFecha(horaTemp)};
    await guardarOxigeno(user,otem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se guardo correctamente"});
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosOxigenacion();
    await limpiarDatos();
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});
  }

  const handleGuardarGlucosa = async()=>{    
    setEstatus({loading:true,leer:false});  
    let gtem ={...glucosa,hora:convertirHoraFecha(horaTemp)};
    await guardarGlucosa(user,gtem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se guardo correctamente"});
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosGlucosa();
    await limpiarDatos();
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});
  }

  const handleGuardarPresion = async()=>{      
    setEstatus({loading:true,leer:false});
    let ptem ={...presion,hora:convertirHoraFecha(horaTemp)};
    await guardarPresionArterial(user,ptem).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se guardo correctamente"});
    }).catch((eror)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el registro, por favor inteta de nuevo"});
    });
    await obtenerDatosPresion();
    await limpiarDatos();
    setModalAgregarSigno(false);
    setEstatus({loading:false,leer:false});
  }

  const handleAbrirModalRegistro=async()=>{
    setEstatus({loading:true,leer:false});    
    let dateActual = new Date();
    let sdate = dayjs(dateActual).format("YYYY-MM-DD")
    let sfecha = dayjs(dateActual).format("HH:mm");
    setPeso({idPersona:parametros.paciente.idPersona,estatus:1,fecha:sdate});
    setEstatura({idPersona:parametros.paciente.idPersona,estatus:1,fecha:sdate});
    setTemperatura({idPersona:parametros.paciente.idPersona,estatus:1,fecha:sdate});
    setOxigeno({idPersona:parametros.paciente.idPersona,estatus:1,fecha:sdate});
    setGlucosa({idPersona:parametros.paciente.idPersona,estatus:1,fecha:sdate});
    setPresion({idPersona:parametros.paciente.idPersona,estatus:1,fecha:sdate});
    setHoraTemp(sfecha);
    setModalAgregarSigno(true);    
    setEstatus({loading:false,leer:false});
  }

  const actualizaPeso = (valor:any)=>{
    setPeso({...peso,...valor});
  }

  const actualizaEstatura = (valor:any)=>{
    setEstatura({...estatura,...valor});
  }

  const actualizaTemperatura = (valor:any)=>{
    setTemperatura({...temperatura,...valor});
  }

  const actualizaOxigeno = (valor:any)=>{
    setOxigeno({...oxigeno,...valor});
  }

  const actualizaGlucosa = (valor:any)=>{
    setGlucosa({...glucosa,...valor});
  }

  const actualizaPresion = (valor:any)=>{
    setPresion({...presion,...valor});
  }

  const obtenerDatosPeso=async () =>{    
    leerPesos(user,parametros.paciente.idPersona).then((lpesos)=>{
      let lgp:Array<DTOGraphValues>=[];
      lpesos.forEach((val)=>{
        lgp.push({label:val.label,valor:val.peso})
      });
      lgp.reverse();  
      setLgPesos(lgp);
      setListaPesos(lpesos);              
    });
  }

  const obtenerDatosEstatura = async () =>{
    leerEstaturas(user,parametros.paciente.idPersona).then((lestatura)=>{
      let lg:Array<DTOGraphValues>=[];
      lestatura.forEach((val)=>{
        lg.push({label:val.label,valor:val.estatura})
      }); 
      lg.reverse();
      setLgEstatura(lg);
      setListaEstaturas(lestatura);
    });
  }

  const obtenerDatosTemperatura = async () =>{
    leerTemperatura(user,parametros.paciente.idPersona).then((ltemperatura)=>{
      let lg:Array<DTOGraphValues>=[];
      ltemperatura.forEach((val)=>{
        lg.push({label:val.label,valor:val.temperatura})
      }); 
      lg.reverse();
      setLgTemperatura(lg)
      setListaTemperaturas(ltemperatura);
    });
  }

  const obtenerDatosOxigenacion = async () =>{
    leerOxigenoss(user,parametros.paciente.idPersona).then((loxigeno)=>{
      let lg:Array<DTOGraphValues>=[];
      loxigeno.forEach((val)=>{
        lg.push({label:val.label,valor:val.oxigeno})
      }); 
      lg.reverse();
      setLgOxigeno(lg);
      setListaOxigeno(loxigeno);
    });
  }

  const obtenerDatosGlucosa = async () =>{
    leerGlucosas(user,parametros.paciente.idPersona).then((lglucosa)=>{
      let lg:Array<DTOGraphValues>=[];
      lglucosa.forEach((val)=>{
        lg.push({label:val.label,valor:val.glucosa})
      }); 
      lg.reverse();
      setLgGlucosa(lg);
      setListaGlucosa(lglucosa);
    });
  }

  const obtenerDatosPresion = async () =>{
    leerPresionArterials(user,parametros.paciente.idPersona).then((lpresion)=>{
      let lg:Array<DTOGraphValues>=[];
      lpresion.forEach((val)=>{
        lg.push({label:val.label,valor:val.maxDistolica,valor2:val.maxSistolica})
      }); 
      lg.reverse();
      setLgPresion(lg);
      setListaPresion(lpresion);
    });
  }

  const leerDatos = async ()=>{
    setEstatus({loading:true,leer:false});
    await obtenerDatosPeso();
    await obtenerDatosEstatura();
    await obtenerDatosTemperatura();
    await obtenerDatosOxigenacion();
    await obtenerDatosGlucosa();
    await obtenerDatosPresion();
    setEstatus({loading:false,leer:false});
  }


  const slideCambio = () =>{
    signosSlide.current.getActiveIndex().then((valorIndex)=>{
      setIndexSlide(valorIndex);
      console.log("Index:",valorIndex);
    });
  }

  const convertirHoraFecha =(t:string):string=>{  
    let d = t+":00"
    return d;
  }

  const limpiarDatos= async ()=>{
    setPeso({idPersona:parametros.paciente.idPersona,estatus:1});
    setEstatura({idPersona:parametros.paciente.idPersona,estatus:1});
    setTemperatura({idPersona:parametros.paciente.idPersona,estatus:1});
    setOxigeno({idPersona:parametros.paciente.idPersona,estatus:1});
    setGlucosa({idPersona:parametros.paciente.idPersona,estatus:1});
    setPresion({idPersona:parametros.paciente.idPersona,estatus:1});
    setHoraTemp("");
  }

  const validaPresionCarita=(lpresion:DTOSignoPresionArterial):string =>{
    let carita ="";
    if(lpresion.maxSistolica<90){
      carita = "./assets/Sad.png";
    }else if(90<=lpresion.maxSistolica && lpresion.maxSistolica<=120){
      carita = "./assets/Smiling.png";
    }else if(120<lpresion.maxSistolica && lpresion.maxSistolica<140){
      carita = "./assets/Sad.png";
    }else if(140<=lpresion.maxSistolica && lpresion.maxSistolica<180 ){
      carita = "./assets/Neutral.png";
    }else if(180<=lpresion.maxSistolica){
      carita = "./assets/Disappointed.png";
    }
    return carita;
  }
  
  
  useEffect(() =>{
    if(parametros){
      setPaciente(parametros.paciente);
      limpiarDatos();
      setEstatus({loading:false,leer:false});
    }
  },[parametros]);



  useEffect(()=>{
    if(parametros){
      leerDatos();      
    }
  },[]);

  // const CustomizedLabel: FunctionComponent<any> = (props: any) => {
  //   const { x, y, stroke, value } = props;
  
  //   return (
  //     <text x={x} y={y} dy={-4} fill={stroke} fontSize={7} textAnchor="middle">
  //       {value}
  //     </text>
  //   );
  // };
  

  console.log("valor paciente: ",paciente);

  if(!parametros){
    return <Redirect to="/allivia/alliviaHome" />
  }

  if(!paciente){
    return (<IonLoading isOpen message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>);
  }
  
  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
    <IonPage>          
      <IonHeader>
      <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton text=""  color='primary' defaultHref="/" />
          </IonButtons>
          <IonTitle className="title_header">Monitoreo de Signos Vitales</IonTitle>
          <IonButtons slot='end'>
          <IonButton color='primary' onClick={()=>{descargarArchivoSignosVitales()}} >            
            <IonIcon size='large' icon={downloadOutline} ></IonIcon>
          </IonButton>
        </IonButtons>
        </IonToolbar>       
      </IonHeader>
      <IonContent className="signos-paciente">
      <IonList id="title_sick" lines='none' className='pleca-tratamientos'>
        <IonListHeader>
           <div id="contenedor_datos">
            <div className="icon_px">
                <IonImg src="./assets/px.png"/>
            </div>
            <div className="datos_px">
                 <div id='title_paciente'>
                   <span className='header-nombre'>{paciente?paciente.nombreCompleto:null}</span>
                 </div>
                 <div id="title_parentesco">
                  <span className='header-parentesco'>{paciente?paciente.parentesco:null}</span>
                 </div>
            </div>
          </div>      
        </IonListHeader>            
      </IonList>
      <IonSlides id="slider_signos" ref={signosSlide} pager={true}   options={slideOpts} onIonSlideDidChange={(event)=>{slideCambio()}} >
      <IonSlide  >
        <div className='info-panel'>
          <div className='signo-titulo' >
            <IonImg src='./assets/monitorPeso.png'/>
            <p> Monitoreo de Peso</p>
          </div>          
          <div>
            {listaPesos && listaPesos.length>0?
            <div className="signos-chart">
              <ResponsiveContainer  width="100%" height={200}>
                <LineChart data={lgPesos}>
                <CartesianGrid vertical={false} strokeDasharray="3 3"/>
                  <XAxis dataKey="label" name='fecha' />
                  <YAxis/>
                  <Tooltip></Tooltip>                                                
                  <Line type="monotone" dot={true} dataKey="valor" name='Peso' >
                    {/* <LabelList content={<CustomizedLabel />} /> */}
                  </Line>
                </LineChart>
              </ResponsiveContainer>
            </div>
            :<></>
            }
          </div>
          <div className='signos-datos' >
            <table>
              <thead>
                <tr className="header_datos">
                <th>Fecha</th>
                <th>Horario</th>
                <th>Peso (Kg)</th>
                <th>Editar</th>
                </tr>                
              </thead>
              <tbody>
                {
                  listaPesos?listaPesos.map((lpesos)=>{
                    return <>
                    <tr key={lpesos.idPeso}>
                      <td  >{convertirFecha(lpesos.fecha)}</td>
                      <td  >{convertirHora(lpesos.fecha+lpesos.hora)}</td>
                      <td  >{lpesos.peso}</td>
                      <td className='panel-acciones'>
                      <IonButton fill='clear' size='small' onClick={()=>{handleEditarPeso(lpesos)}} ><IonIcon color="rosa" icon={pencil}></IonIcon></IonButton>
                      <IonButton fill='clear' size='small' onClick={()=>{handleEliminarPeso(lpesos)}}><IonIcon color="rosa" icon={trash}></IonIcon></IonButton>
                      </td>
                    </tr>  
                    </>
                  }):null
                }                            
              </tbody>
            </table>
          </div>
        </div>
      </IonSlide>
      <IonSlide>
      <div className='info-panel'>
          <div className='signo-titulo' >
            <IonImg src='./assets/monitorEstatura.png'/>
              <p>Monitoreo de Estatura </p>
          </div>
          <div className="signos-chart">
          {listaEstaturas && listaEstaturas.length>0?
            <ResponsiveContainer className="signos-chart" width="100%" height={200}>
              <LineChart data={lgEstatura}>
                <YAxis></YAxis>
                <Tooltip></Tooltip>
                <XAxis dataKey="label"  />
                <CartesianGrid vertical={false} strokeDasharray="3 3"/>                
                <Line type="natural" dot={true} dataKey="valor" name='Estatura'/>              
              </LineChart>
            </ResponsiveContainer>:null
            }
          </div>
          <div className='signos-datos' >
            <table>
              <thead>
                <tr className="header_datos">
                <th>Fecha</th>
                <th>Horario</th>
                <th>Estatura (m)</th>
                <th>Editar</th>
                </tr>                
              </thead>
              <tbody>
              {
                  listaEstaturas?listaEstaturas.map((lestatura)=>{
                    return <>
                    <tr key={lestatura.idEstatura}>
                      <td  >{convertirFecha(lestatura.fecha)}</td>
                      <td  >{convertirHora(lestatura.fecha+lestatura.hora)}</td>
                      <td  >{lestatura.estatura}</td>
                      <td className='panel-acciones'>
                        <IonButton fill='clear' size='small' onClick={()=>{handleEditarEstatura(lestatura)}} ><IonIcon color="rosa" icon={pencil}></IonIcon></IonButton>
                        <IonButton fill='clear' size='small' onClick={()=>{handleEliminarEstatura(lestatura)}}><IonIcon color="rosa" icon={trash}></IonIcon></IonButton>
                      </td>
                    </tr>  
                    </>
                  }):null
                } 
              </tbody>
            </table>
          </div>
        </div>
      </IonSlide>
      <IonSlide>
      <div className='info-panel'>
          <div className='signo-titulo' >
            <IonImg src='./assets/monitorTemperatura.png'/>
            <p> Monitoreo de Temperatura </p> 
          </div>
          <div className="signos-chart">
          {listaTemperaturas && listaTemperaturas.length>0?
            <ResponsiveContainer className="signos-chart" width="100%" height={200}>
              <LineChart data={lgTemperatura}>
                <YAxis></YAxis>
                <Tooltip ></Tooltip>
                <XAxis dataKey="label"  />
                <CartesianGrid vertical={false} strokeDasharray="3 3"/>                
                <Line type="natural" dot={true} dataKey="valor" name='Temperatura'/>              
              </LineChart>
            </ResponsiveContainer>:null
            }
          </div>
          <div className='signos-datos' >
            <table>
              <thead>
                <tr className="header_datos">
                <th>Fecha</th>
                <th>Horario</th>
                <th>Temperatura</th>
                <th>Editar</th>
                </tr>                
              </thead>
              <tbody>
              {
                  listaTemperaturas?listaTemperaturas.map((ltempratura)=>{
                    return <>
                    <tr key={ltempratura.idTemperatura}>
                      <td  >{convertirFecha(ltempratura.fecha)}</td>
                      <td  >{convertirHora(ltempratura.fecha+ltempratura.hora)}</td>
                      <td  >{ltempratura.temperatura}</td>
                      <td className='panel-acciones'>
                        <IonButton fill='clear' size='small' onClick={()=>{handleEditarTemperatura(ltempratura)}} ><IonIcon color="rosa" icon={pencil}></IonIcon></IonButton>
                        <IonButton fill='clear' size='small' onClick={()=>{handleEliminarTemperatura(ltempratura)}}><IonIcon color="rosa" icon={trash}></IonIcon></IonButton>
                      </td>
                    </tr>  
                    </>
                  }):null
                } 
              </tbody>
            </table>
          </div>
        </div>
      </IonSlide>
      <IonSlide>
      <div className='info-panel'>
          <div className='signo-titulo' >
            <IonImg src='./assets/monitorOxigenacion.png'/>
              <p> Monitoreo de Oxigenación </p>
          </div>
          <div className="signos-chart">
          {listaOxigeno && listaOxigeno.length>0?
            <ResponsiveContainer className="signos-chart" width="100%" height={200}>
              <LineChart data={lgOxigeno}>
                <YAxis></YAxis>
                <Tooltip></Tooltip>
                <XAxis dataKey="label"  />
                <CartesianGrid vertical={false} strokeDasharray="3 3"/>                
                <Line type="natural" dot={true} dataKey="valor" name='Oxigenación'/>              
              </LineChart>
            </ResponsiveContainer>:null
            }
          </div>
          <div className='signos-datos' >
            <table>
              <thead>
                <tr className="header_datos">
                  <th>Fecha</th>
                  <th>Horario</th>
                  <th>Oxigenación</th>               
                  <th>Editar</th>
                </tr>                
              </thead>
              <tbody>
              {
                  listaOxigeno?listaOxigeno.map((loxigeno)=>{
                    return <>
                    <tr key={loxigeno.idOxigeno}>
                      <td  >{convertirFecha(loxigeno.fecha)}</td>
                      <td  >{convertirHora(loxigeno.fecha+loxigeno.hora)}</td>
                      <td  >{loxigeno.oxigeno}%</td>
                      <td className='panel-acciones'>
                        <IonButton fill='clear' size='small' onClick={()=>{handleEditarOxigeno(loxigeno)}} ><IonIcon color="rosa" icon={pencil}></IonIcon></IonButton>
                        <IonButton fill='clear' size='small' onClick={()=>{handleEliminarOxigeno(loxigeno)}}><IonIcon color="rosa" icon={trash}></IonIcon></IonButton>
                      </td>
                    </tr>  
                    </>
                  }):null
                } 
              </tbody>
            </table>
            <div className="clasi_oxi">
              <div className="categoriaox">
                <div className="datos_clas">
                    <div className="icon">
                        <IonImg src="./assets/Smiling.png" />
                    </div>
                    <div className="txt_clas">
                      <ul>
                        <li>99 - 95 %</li>
                        <li>Normal</li>
                      </ul>
                    </div>
                </div>
                <div className="datos_clas">
                    <div className="icon">
                        <IonImg src="./assets/Sad.png" />
                    </div>
                    <div className="txt_clas">
                      <ul>
                        <li>90 - 86 %</li>
                        <li>Hipoxia Moderada</li>
                      </ul>
                    </div>
                </div>
              </div>
              <div className="categoriaox">
                <div className="datos_clas">
                    <div className="icon">
                        <IonImg src="./assets/Neutral.png" />
                    </div>
                    <div className="txt_clas">
                      <ul>
                        <li>94 - 91 %</li>
                        <li>Hipoxia leve</li>
                      </ul>
                    </div>
                </div>
                <div className="datos_clas">
                    <div className="icon">
                        <IonImg src="./assets/Disappointed.png" />
                    </div>
                    <div className="txt_clas">
                      <ul>
                        <li> &lt;86% </li>
                        <li>Hipoxia severa</li>
                      </ul>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonSlide>
      <IonSlide>
      <div className='info-panel'>
          <div className='signo-titulo' >
            <IonImg src='./assets/monitorGlucosa.png'/>
              <p>Monitoreo de Glucosa</p>
          </div>
          <div className="signos-chart">
          {listaGlucosa && listaGlucosa.length>0?
            <ResponsiveContainer className="signos-chart" width="100%" height={200}>
              <LineChart data={lgGlucosa}>
                <YAxis></YAxis>
                <Tooltip></Tooltip>
                <XAxis dataKey="label"  />
                <CartesianGrid vertical={false} strokeDasharray="3 3"/>                
                <Line type="natural" dot={true} dataKey="valor" name='Glucosa'/>              
              </LineChart>
            </ResponsiveContainer>:null
            }
          </div>
          <div className='signos-datos' >
            <table>
              <thead>
                <tr className="header_datos">
                <th>Fecha</th>
                <th>Horario</th>
                <th>Glucosa</th>
                <th>Editar</th>
                </tr>                
              </thead>
              <tbody>
              {
                  listaGlucosa?listaGlucosa.map((lglucosa)=>{
                    return <>
                    <tr key={lglucosa.idGlucosa}>
                      <td  >{convertirFecha(lglucosa.fecha)}</td>
                      <td  >{convertirHora(lglucosa.fecha+lglucosa.hora)}</td>
                      <td  >{lglucosa.glucosa}mg/dl</td>
                      <td className='panel-acciones'>
                        <IonButton fill='clear' size='small' onClick={()=>{handleEditarGlucosa(lglucosa)}} ><IonIcon color="rosa" icon={pencil}></IonIcon></IonButton>
                        <IonButton fill='clear' size='small' onClick={()=>{handleEliminarGlucosa(lglucosa)}}><IonIcon color="rosa" icon={trash}></IonIcon></IonButton>
                      </td>                     
                    </tr>  
                    </>
                  }):null
                } 
              </tbody>
            </table>
          </div>
        </div>
      </IonSlide>
      <IonSlide>
      <div className='info-panel'>
          <div className='signo-titulo' >
            <IonImg src='./assets/monitorPresionArterial.png'/>
              <p>Monitoreo de Presión Arterial </p>
          </div>
          <div className="signos-chart">
          {listaPresion && listaPresion.length>0?
            <ResponsiveContainer className="signos-chart" width="100%" height={200}>
              <LineChart data={lgPresion}>
                <YAxis></YAxis>
                <Tooltip ></Tooltip>
                <XAxis dataKey="label"  />
                <CartesianGrid vertical={false} strokeDasharray="3 3"/>                
                <Line name='Sistólica' type="natural" dot={true} stroke="#005566" dataKey="valor2" />              
                <Line name="Distólica" type="natural" dot={true} dataKey="valor"/>              
              </LineChart>
            </ResponsiveContainer>:null
            }
          </div>
          <div className='signos-datos'>
                  {
                    listaPresion ? listaPresion.map((lpresion) => {
                      return <>
                        <div id="contenedor_arterial">
                          <div key={lpresion.idPresion} id="data_date">
                            <div className="header_row">
                              <div className="fecha">
                                {convertirFecha(lpresion.fecha)}
                              </div>
                              <div className="hour">
                                {convertirHora(lpresion.fecha + lpresion.hora)}
                              </div>
                            </div>
                          </div>
                          <div id="data_pressure">
                            <div className="date_pacie">
                              <div className="left">
                                <div className="icon_arm">
                                  <IonImg src="./assets/left.png" />
                                </div>
                                <div className="sistolica">
                                  <div className="titles_press">
                                     <IonImg src="./assets/flechaprearriba.png" />
                                    <p>Sistólica</p>
                                  </div>
                                  <div className="date_sis">
                                    {lpresion.izqSistolica}
                                  </div>
                                </div>
                                <div className="distolica">
                                  <div className="titles_press">  
                                     <IonImg src="./assets/flechapresion.png" />
                                    <p>Diastólica</p>
                                  </div>
                                  <div className="date_dis">
                                    {lpresion.izqDistolica}
                                  </div>
                                </div>
                              </div>
                              <div className="right">
                                <div className="icon_arm">
                                  <IonImg src="./assets/right.png" />
                                </div>
                                <div className="sistolica">
                                  <div className="titles_press">
                                    
                                    <p>Sistólica</p>
                                  </div>
                                  <div className="date_sis">
                                    {lpresion.derSistolica}
                                  </div>
                                </div>
                                <div className="distolica">
                                  <div className="titles_press">
                                     
                                      <p>Diastólica</p>
                                  </div>
                                  <div className="date_dis">
                                    {lpresion.derDistolica}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="icon_pressure">
                              <div className="icon_clas">                                                                
                                <IonImg className="est_pressure" src={validaPresionCarita(lpresion)} />
                              </div>
                              <div className='panel-acciones'>
                                <IonButton fill='clear' size='small' onClick={() => { handleEditarPresion(lpresion) }} ><IonIcon color="rosa" icon={pencil}></IonIcon></IonButton>
                                <IonButton fill='clear' size='small' onClick={() => { handleEliminarPresion(lpresion) }}><IonIcon color="rosa" icon={trash}></IonIcon></IonButton>
                              </div>
                            </div>
                          </div>
                        </div>
                    </>
                  }):null
                } 
            
            <div className="clasi_oxi">
              <div className="categoriaox">
                  <div className="datos_clas">
                    <div className="icon">
                        <IonImg src="./assets/short.png" />
                    </div>
                    <div className="txt_clas">
                      <p>Baja</p>
                      <p className="range"> &gt;90-120 mmHg sistólica y &gt;60-90 mmHg diastólica</p>
                    </div>
                  </div>
                  <div className="datos_clas">
                    <div className="icon">
                        <IonImg src="./assets/Smiling.png" />
                    </div>
                    <div className="txt_clas">
                      <p>Normal</p> 
                      <p className="range"> &le;120 mmHg sistólica y 80-90 mmHg diastólica </p>
                  </div>
                </div>
              </div>
              <div className="categoriaox">
                <div className="datos_clas">
                    <div className="icon">
                        <IonImg src="./assets/Sad.png" />
                    </div>
                    <div className="txt_clas">
                      <p>Elevada</p>
                      <p className="range"> &gt;120-140 mmHg sistólica y &gt;90-100 mmHg diastólica</p>
                    </div>
                </div>
                <div className="datos_clas">
                    <div className="icon">
                        <IonImg src="./assets/Neutral.png" />
                    </div>
                    <div className="txt_clas">
                     <p>Alto</p>
                     <p className="range">&gt;140-180 mmHg sistólica y &gt;100-120 mmHg diastólica </p>
                    </div>
                </div>

              </div>
              <div className="categoriaox">
                <div className="datos_clas">
                    <div className="icon">
                        <IonImg src="./assets/Disappointed.png" />
                    </div>
                    <div className="txt_clas">
                     <p className="emergenciah"> Emergencia Hipertensiva</p>
                     <p className="range">
                        &gt;180 mmHg sistólica y &gt;120 mmHg diastólica
                     </p>
                     <p className="emergenciah">
                      Puede existir evidencia de daño a órganos, como falla renal, déficit neurologico o insuficiencia cardiaca
                     </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </IonSlide>
      
    </IonSlides>               
      <IonFab hidden={esWeb()} className='allivia-emergencia-call' vertical='bottom' horizontal='center' edge slot='fixed'>
          <IonFabButton color="emergencia">
            <div>
              <IonIcon icon={call}/>
              <IonLabel>911</IonLabel>       
            </div>          
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton color="success" onClick={()=>{emergencyCall();}}><IonIcon icon={call} /></IonFabButton>
          </IonFabList>
        </IonFab>
        <IonModal backdropDismiss={false} isOpen={modalAgregarSigno} className={indexSlide===5?"ion-modal-signos presion":"ion-modal-signos"} >
            <IonContent hidden={indexSlide!=0}>
              <div className='header'>
                <IonImg src='./assets/agregarPeso.png'></IonImg>
                Agregar nuevo valor de Peso
                <IonButton fill='clear' onClick={()=>{setModalAgregarSigno(false);limpiarDatos();}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/agpeso1.png" />
                        <IonLabel>Peso:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='number' value={peso.peso} placeholder="00.00"
                        onIonChange={(event)=>{actualizaPeso({peso:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso2.png" />                     
                        <IonLabel>Fecha:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='date' value={peso.fecha?peso.fecha:null}
                        onIonChange={(event)=>{actualizaPeso({fecha:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso3.png" />
                        <IonLabel>Hora:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='time' value={horaTemp}
                        onIonChange={(event)=>{setHoraTemp(event.detail.value)}}></IonInput>
                      </IonCol>                      
                    </IonRow>
                  </IonGrid>
                  <IonButton  className="guardar_orange"  expand='block' onClick={() => { handleGuardarPeso() }} >Guardar
                   <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>              
            </IonContent>
            <IonContent hidden={indexSlide!=1}>
              <div className='header'>
                <IonImg src='./assets/agregarEstatura.png'></IonImg>
                Agregar nuevo valor de Estatura
                <IonButton fill='clear' onClick={()=>{setModalAgregarSigno(false);limpiarDatos();}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/aestatura.png" />
                        <IonLabel>Estatura:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='number' value={estatura.estatura} placeholder="0.00"
                        onIonChange={(event)=>{actualizaEstatura({estatura:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso2.png" />                     
                        <IonLabel>Fecha:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='date' value={estatura.fecha?estatura.fecha:null}
                        onIonChange={(event)=>{actualizaEstatura({fecha:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso3.png" />
                        <IonLabel>Hora:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='time' value={horaTemp}
                        onIonChange={(event)=>{setHoraTemp(event.detail.value)}}></IonInput>
                      </IonCol>                      
                    </IonRow>
                  </IonGrid>
                  <IonButton  className="guardar_orange"  expand='block' onClick={() => { handleGuardarEstatura() }} >Guardar
                   <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>              
            </IonContent>
            <IonContent hidden={indexSlide!=2}>
              <div className='header'>
                <IonImg src='./assets/agregarTemperatura.png'></IonImg>
                Agregar nuevo valor de Temperatura
                <IonButton fill='clear' onClick={()=>{setModalAgregarSigno(false);limpiarDatos();}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/aThermometer.png" />
                        <IonLabel>Temperatura:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='number' value={temperatura.temperatura} placeholder="00.0"
                        onIonChange={(event)=>{actualizaTemperatura({temperatura:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso2.png" />                     
                        <IonLabel>Fecha:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='date' value={temperatura.fecha?temperatura.fecha:null}
                        onIonChange={(event)=>{actualizaTemperatura({fecha:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso3.png" />
                        <IonLabel>Hora:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='time' value={horaTemp}
                        onIonChange={(event)=>{setHoraTemp(event.detail.value)}}></IonInput>
                      </IonCol>                      
                    </IonRow>
                  </IonGrid>
                  <IonButton  className="guardar_orange"  expand='block' onClick={() => { handleGuardarTemperatura() }} >Guardar
                   <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>              
            </IonContent>
            <IonContent hidden={indexSlide!=3}>
              <div className='header'>
                <IonImg src='./assets/agregarOxigenacion.png'></IonImg>
                Agregar nuevo valor de Oxigenación
                <IonButton fill='clear' onClick={()=>{setModalAgregarSigno(false);limpiarDatos();}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/aoxig.png" />
                        <IonLabel>Oxigenación:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='number' value={oxigeno.oxigeno} placeholder="00.0"
                        onIonChange={(event)=>{actualizaOxigeno({oxigeno:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso2.png" />                     
                        <IonLabel>Fecha:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='date' value={oxigeno.fecha?oxigeno.fecha:null}
                        onIonChange={(event)=>{actualizaOxigeno({fecha:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso3.png" />
                        <IonLabel>Hora:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='time' value={horaTemp}
                        onIonChange={(event)=>{setHoraTemp(event.detail.value)}}></IonInput>
                      </IonCol>                      
                    </IonRow>
                  </IonGrid>
                  <IonButton  className="guardar_orange"  expand='block' onClick={() => { handleGuardarOxigeno() }} >Guardar
                   <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>              
            </IonContent>
            <IonContent hidden={indexSlide!=4}>
              <div className='header'>
                <IonImg src='./assets/agregarGlucosa.png'></IonImg>
                Agregar nuevo valor de Glucosa
                <IonButton fill='clear' onClick={()=>{setModalAgregarSigno(false);limpiarDatos();}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/aGlucometer.png" />
                        <IonLabel>Glucosa:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='number' value={glucosa.glucosa} placeholder="000.0"
                        onIonChange={(event)=>{actualizaGlucosa({glucosa:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso2.png" />                     
                        <IonLabel>Fecha:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='date' value={glucosa.fecha?glucosa.fecha:null} 
                        onIonChange={(event)=>{actualizaGlucosa({fecha:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso3.png" />
                        <IonLabel>Hora:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='time' value={horaTemp}
                        onIonChange={(event)=>{setHoraTemp(event.detail.value)}}></IonInput>
                      </IonCol>                      
                    </IonRow>
                  </IonGrid>
                  <IonButton  className="guardar_orange"  expand='block' onClick={() => { handleGuardarGlucosa() }} >Guardar
                   <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>              
            </IonContent>
            <IonContent hidden={indexSlide!=5}>
              <div className='header'>
                <IonImg src='./assets/agregarPresion.png'></IonImg>
                Agregar nuevo valor de Presión Arterial
                <IonButton fill='clear' onClick={()=>{setModalAgregarSigno(false);limpiarDatos();}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='12'>
                          <IonImg className="iconionpr" src="./assets/right.png" />
                          <IonLabel>Brazo derecho</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/iconospre-08.png" />
                        <IonLabel>Sistólica:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='number' value={presion.derSistolica} placeholder="000"
                        onIonChange={(event)=>{actualizaPresion({derSistolica:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/iconospre-09.png" />
                        <IonLabel>Diastólica:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='number' value={presion.derDistolica} placeholder="000"
                        onIonChange={(event)=>{actualizaPresion({derDistolica:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='12'>
                            <IonImg className="iconionpr" src="./assets/left.png" />
                            <IonLabel>Brazo izquierdo</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/iconospre-08.png" />
                        <IonLabel>Sistólica:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='number' value={presion.izqSistolica} placeholder="000"
                        onIonChange={(event)=>{actualizaPresion({izqSistolica:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/iconospre-09.png" />
                        <IonLabel>Diastólica:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='number' value={presion.izqDistolica} placeholder="000"
                        onIonChange={(event)=>{actualizaPresion({izqDistolica:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso2.png" />                     
                        <IonLabel>Fecha:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='date' value={presion.fecha?presion.fecha:null}
                        onIonChange={(event)=>{actualizaPresion({fecha:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/agPeso3.png" />
                        <IonLabel>Hora:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='time' value={horaTemp}
                        onIonChange={(event)=>{setHoraTemp(event.detail.value)}}></IonInput>
                      </IonCol>                      
                    </IonRow>
                  </IonGrid>
                  <IonButton  className="guardar_orange"  expand='block' onClick={() => { handleGuardarPresion() }} >Guardar
                   <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>              
            </IonContent>
          </IonModal>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
        />
        <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
      </IonContent>      
      <div className='custom-tab-bar'>
          <IonButton fill='clear' routerLink='/'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' routerLink='/allivia/buscar'>
            <div>
              <IonIcon icon={search} />
              <IonLabel>Buscar</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={esWeb()} fill='clear' onClick={() => {compartirArchivo()}}>
            <div>
              <IonIcon icon={shareSocial}  />
              <IonLabel>Compartir</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={!esWeb()} fill='clear' onClick={() => { descargarArchivoSignosVitales() }}>
            <div>
              <IonIcon icon={downloadOutline} />
              <IonLabel>Descargar</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={indexSlide!=0} fill='clear' onClick={() => { handleAbrirModalRegistro() }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Peso</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={indexSlide!=1} fill='clear' onClick={() => { handleAbrirModalRegistro() }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Estatura</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={indexSlide!=2} fill='clear' onClick={() => { handleAbrirModalRegistro() }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Temperatura</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={indexSlide!=3} fill='clear' onClick={() => { handleAbrirModalRegistro() }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Oxigenación</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={indexSlide!=4} fill='clear' onClick={() => { handleAbrirModalRegistro() }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Glucosa</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={indexSlide!=5} fill='clear' onClick={() => { handleAbrirModalRegistro() }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>P. Arterial</IonLabel>
            </div>
          </IonButton>
        </div>
    </IonPage>
    </>
  );
};

export default SignosVitales;
