import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonLabel,
  IonImg,
  IonBackButton,
  IonLoading,
  IonButton,
  IonModal,
  IonItem,
  IonInput,
  IonRadio,
  IonGrid,
  IonCol,
  IonRow,
  IonRadioGroup,
  IonToast,
  IonFabList,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { call, cart, closeOutline,  home, search, shareSocial } from 'ionicons/icons';
import { useLoginContext, useParametros, } from '../hooks/login';
import '../theme/promocionesFarmacia.css';
import { Redirect } from 'react-router';
import {DTOPromocion } from '../services/AlliviaInterfaces';
import {emergencyCall, esWeb } from '../services/utils.service';



const PromocionesFarmacia: React.FC = () => {
  const user = useLoginContext();
  const { parametros } = useParametros();
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [modalAgregarPromocion, setModalAgregarPromocion] = useState(false);
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [promocion, setPromocion] = useState<DTOPromocion>({});
  const [listaPromociones, setListaPromociones] = useState<Array<DTOPromocion>>([]);
  const [notificaPromocion,setNotificaPromocion] = useState("false");

  const actualizarPromocion = (valor: any) => {
    setPromocion({ ...promocion, ...valor });
  }

  const handleGuardarPromocion = async () =>{
    setEstatus({ loading: true, leer: false });
    setPromocion(null);
    setModalAgregarPromocion(false);
    setEstatus({ loading: false, leer: false });
  }

  const compartirPromociones = async () =>{
    setEstatus({ loading: true, leer: false });
    setEstatus({ loading: false, leer: false });
  }


  useEffect(() => {
    setEstatus({ loading: true, leer: false });
    if (parametros) {

    }
    setEstatus({ loading: false, leer: false });
  }, []);

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Promociones de Farmacia(s)</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className='promociones'>
            <div className='header' >
              Promociones de farmacia(s)
            </div>
            <div hidden={listaPromociones.length>0} className='sin-promociones' >
              <div className='mensaje1'>
                Por el momento no cuenta con promociones en farmacia(s)
              </div>
              <div className='mensaje2'>
                Para agregar, dar clic en el botón + Farmacia
              </div>
            </div>
            <div hidden={listaPromociones.length===0} className='con-promociones' >
            </div>
          </div>
          <IonFab hidden={esWeb()} className='allivia-emergencia-call' vertical='bottom' horizontal='center' edge slot='fixed'>
            <IonFabButton color="emergencia" >
              <div>
                <IonIcon icon={call} />
                <IonLabel>911</IonLabel>
              </div>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton color="success" onClick={() => { emergencyCall(); }}><IonIcon icon={call} /></IonFabButton>
            </IonFabList>
          </IonFab>
          <IonModal backdropDismiss={false} isOpen={modalAgregarPromocion} className="ion-modal-cita" >
            <IonContent>
              <div className='header'>
                <IonImg src='./assets/agregarCitaMed.png'></IonImg>
                Agregar promoción de farmacia
                <IonButton fill='clear' onClick={() => { setModalAgregarPromocion(false); }}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/citaMed1.png" />
                        <IonLabel>Farmacia:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='text' inputMode='text' autocapitalize='sentences' value={promocion.farmacia}
                          onIonChange={(event) => { actualizarPromocion({ farmacia: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed2.png" />
                        <IonLabel>Medicamento:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="text" inputMode='text' autocapitalize="sentences" value={promocion.medicamento}
                          onIonChange={(event) => { actualizarPromocion({ medicamento: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed3.png" />
                        <IonLabel>Tratamiento:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="text" inputMode='text' autocapitalize="sentences" value={promocion.tratamiento}
                          onIonChange={(event) => { actualizarPromocion({ tratamiento: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed4.png" />
                        <IonLabel>Costo:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="number" inputMode='numeric'  value={promocion.costo}
                          onIonChange={(event) => { actualizarPromocion({ costo: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed5.png" />
                        <IonLabel>Número de compra para promoción:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type="number" inputMode='numeric' value={promocion.numCompra}
                          onIonChange={(event) => { actualizarPromocion({ numCompra: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed6.png" />
                        <IonLabel>Fecha:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                      <IonInput type='datetime-local' value={promocion.fecha}
                          onIonChange={(event) => { actualizarPromocion({ fecha: event.detail.value }) }}></IonInput>
                      </IonCol>                      
                      <IonCol size='12'>
                        <IonLabel>¿Desea recibir una notificación cuándo pueda canjear su promoción?</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRadioGroup color='success' value={notificaPromocion}
                      onIonChange={(event) => { setNotificaPromocion(event.detail.value) }}>
                      <IonRow>
                        <IonCol size='6' >
                          <IonItem lines='none'>
                            <IonLabel>Si</IonLabel>
                            <IonRadio mode="md"  slot="start" value="true"></IonRadio>
                          </IonItem>
                        </IonCol>
                        <IonCol size='6' >
                          <IonItem lines='none'>
                            <IonLabel>No</IonLabel>
                            <IonRadio mode="md"  slot="start" value="true"></IonRadio>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonRadioGroup>      
                    </IonGrid>              
                  <IonButton expand='block' onClick={() => { handleGuardarPromocion() }} >Guardar</IonButton>
                </div>

              </div>

            </IonContent>
          </IonModal>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        </IonContent>
        <div className='custom-tab-bar'>
          <IonButton fill='clear' routerLink='/'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' routerLink='/allivia/buscar'>
            <div>
              <IonIcon icon={search} />
              <IonLabel>Buscar</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' onClick={() => { compartirPromociones() }}>
            <div>
              <IonIcon icon={shareSocial} />
              <IonLabel>Compartir</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' onClick={() => { setModalAgregarPromocion(true); }}>
            <div>
              <IonIcon icon={cart} />
              <IonLabel>Farmacia</IonLabel>
            </div>
          </IonButton>
        </div>
      </IonPage>
    </>
  );
};

export default PromocionesFarmacia;
