
//Variables globales
export const PARAM_IMG_PUBLICIDAD ="img.publicidad";
export const MENSAJE_CARGA_ARCHIVOS="Archivo cargado, no olvides guardar cambios";
export const PARAM_MEMBRESIA_PAGO_MENSUAL="membresia.allivia.premium";
export const PARAM_MEMBRESIA_PAGO_ANUAL ="membresia.allivia.premium.anual";
export const PARAM_MEMBRESIA_PAGO_MSI="membresia.pago.msi";
export const PARAM_OPENPAY_PLAN_MENSUAL_ID="openpay.plan.mensual.id";
export const PARAM_OPENPAY_PLAN_ANUAL_ID="openpay.plan.anual.id";
export const PARAM_OPENPAY_AMBIENTE_SANDBOX="openpay.ambiente.sandbox";

//===================DATOS OPEN PAY======================================
//c2tfYzM2NDBiYTE4NGFmNDA3Y2E0ZGVhOTBiZGUyOTI3OGI6
export const OPENPAY_SERV_CLIENTES = "customers"
export const OPENPAY_SERV_CARGOS = "charges"
export const OPENPAY_SERV_TARJETAS = "cards"
export const OPENPAY_SERV_SUSCRIPCIONES = "subscriptions"

//Variables para las imagenes

export const IMG_INE=1;
export const IMG_P_SEGURO_VIDA=2;
export const IMG_VAC=3;
export const IMG_AVATAR=4;
export const IMG_PSGMM=5;
export const IMG_CARNET=6;
export const IMG_ESTUDIO_CLINICO=7;
export const IMG_RECETA_MEDICA=8;

export const MAXIMO_COMPARTIR = 3;
export const MAXIMO_PACIENTES_PREMIUM = 3;
export const MAXIMO_PACIENTES_BASICO=2;

export const VALOR_VASO= 0.250;

export const VERSION_WEB = "0.115.0";

export const MSJ_CARGA_DOCUMENTOS= "Recuerda que hacerte un cuidador PREMIUM en Allivia te brinda grandes beneficios, como contar con un respaldo en la nube, y poder consultar tus documentos médicos en cualquier momento.";

export const TAMANIO_IMAGEN_AVATAR = 250;