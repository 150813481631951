import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonMenuToggle,
  IonButton,
  IonLabel,
  IonListHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink,
  IonImg,
  IonAvatar,
  IonFabList,
  IonLoading,
  IonModal,
  IonSlide,
  IonSlides,
  IonToast,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { call,personCircle,home,personAdd,  menu, closeOutline, trendingDown} from 'ionicons/icons';
import { useLoginContext, useParametros, useValidarToken } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/ios.css';
import '../theme/home.css';
import '../theme/buscar.css';
import '../theme/custom-loading.css';
import Menu from './Menu';
import { leerCuidador } from '../services/cuidador.service';
import { Redirect } from 'react-router';
import { DTOCuidador, DTOPaciente} from '../services/AlliviaInterfaces';
import { emergencyCall, esWeb } from '../services/utils.service';
import { leerPerfilUsuario } from '../services/usuario.service';
import { leerParametroByNombre } from '../services/parametro.service';
import { MAXIMO_PACIENTES_BASICO, MAXIMO_PACIENTES_PREMIUM, PARAM_IMG_PUBLICIDAD } from '../constantes';
import { leerSolicitudRecibidaMembresiaPremium } from '../services/membresia.service';
import { eliminarPaciente } from '../services/paciente.service';
import { leerNotificaciones } from '../services/notificaciones.service';
import { AdSizeType, BannerAd, InterstitialAd } from 'admob-plus-cordova';

const HomeAllivia: React.FC = () => {  
  const user = useLoginContext();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const {parametros,setParametros} = useParametros();
  const [cuidador, setCuidador] = useState<DTOCuidador | undefined>(undefined);
  const [irMenuPaciente,setIrMenuPaciente] = useState(false);
  const [bienvenido,setBienvenido] = useState("Bienvenido(a)");
  const [premium,setPremium]=useState(false);
  const [premiumDuo,setPremiumDuo]=useState(false);
  const [numImg,setNumImg]=useState(1);
  const [modalCuidaDeTi,setModalCuidaDeTi] = useState(false);
  const [tienePacientesCompartidos,setTienePacientesCompartidos] = useState(0);
  const publicidadRef = useRef(null);
  const [modalEliminarPaciente,setModalEliminarPaciente] = useState(false);
  const [modalEliminarPacienteCompartido,setModalEliminarPacienteCompartido] = useState(false);
  const [pacienteSeleccionado,setPacienteSeleccionado] = useState<DTOPaciente>();
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };  
  
  useValidarToken();  

  const handleIrMenuPaciente = async(paciente: DTOPaciente) =>{    
    setParametros({...parametros,paciente:paciente,cuidador:cuidador});
    setIrMenuPaciente(true);
  } 

  

  const leerParametros= async ()=>{
      

    
    

  //   AdMob.setDevMode(false);          
  // await AdMob.banner.show({
  //     id: {
  //       android:'ca-app-pub-2488056876804359/9368970040',
  //       ios: 'ca-app-pub-2488056876804359/9368970040'
  //     },
  //   })
  //   .catch((error)=>{
  //     console.log("error banner");
  //     console.log(error);
  //   });

    if(!estatus.loading){
      setEstatus({loading:true,leer:true})
    }
    let temCuidador:DTOCuidador ;
    await leerCuidador(user.username,user).then((valor)=>{
      if(valor.idSexo===1){
        setBienvenido("Bienvenida")
      }else if(valor.idSexo===2){
        setBienvenido("Bienvenido")
      }
      let pacCompartido = 0;
      valor.pacientes.forEach((pac)=>{
        if(pac.idCuidadorPropietario){
          pacCompartido = pacCompartido+1;
        }        
      });   
      setTienePacientesCompartidos(pacCompartido);   
      temCuidador=valor;      
    });    
    let sol = await leerSolicitudRecibidaMembresiaPremium(user);
    let paramTem = {...parametros};
    let varPrem =false;
    let varPremDuo =false;
    await leerPerfilUsuario(user).then((perf)=>{ 
      varPrem =perf.perfil==='PREMIUM';  
      varPremDuo =perf.perfil==='PREMIUM_DUO';  
      paramTem={...parametros,perfil:perf,cuidador:temCuidador,premium:varPrem,premiumDuo:varPremDuo,invitacionPremium:sol}
    }).catch((error)=>{
      console.log("error",error);
    });   
    setPremium(varPrem);
    setPremiumDuo(varPremDuo);
    //========== FUNCIÓN PARA EJECUTAR ADMOB PARA PUBLICIDAD BANNER
    console.log("=====>Param before publicity");
    if(!paramTem.publicidad && !varPrem && !varPremDuo){    
       /*
      ID_BANNER_ANDROID:ca-app-pub-2612144276627456/1354371359
      ID_BANNER_IOS:ca-app-pub-2612144276627456/6716672386
      */
      let banner = new BannerAd({
        adUnitId: 'ca-app-pub-3940256099942544/6300978111',   
        size:   AdSizeType.BANNER,
        position: 'bottom'
      });
            
      banner.on('banner.impression',async () => {
        await banner.hide();
      });
      let error = false;
      await banner.show().then(valor=>{console.log("banner");console.log(valor)}).catch((e)=>{console.log("error banner");console.log(e);error=true});
      if(!error){
        paramTem = {...paramTem,publicidad:banner};      
      }
    }else if (paramTem.publicidad && varPrem && varPremDuo){
      let banner = paramTem.publicidad;
      await banner.hide();
    }
    //========== FUNCIÓN PARA EJECUTAR ADMOB PARA PUBLICIDAD BANNER
    /* para implementarlo agregar la libreria npm i admob-plus-cordova-native */
    // //========== FUNCIÓN PARA EJECUTAR ADMOB PARA PUBLICIDAD NativeAd
    // console.log("=====>Param before publicity");
    // if(!paramTem.nativeAd && !varPrem && !varPremDuo){    
    //    /*
    //   ID_BANNER_ANDROID:ca-app-pub-2612144276627456/1354371359
    //   ID_BANNER_IOS:ca-app-pub-2612144276627456/6716672386
    //   */
    //   let nativeAd = new NativeAd({
    //     adUnitId: 'ca-app-pub-3940256099942544/2247696110',      
    //   });
      
    //   let error = false;
    //   await nativeAd.load().catch((e)=>{console.log(e);error=true});

    //   if(!error){
    //     await nativeAd.show({
    //       x: 30,
    //       y: 30,
    //       width: window.screen.width-60,
    //       height: 500,
    //     }).then(valor=>{console.log("banner");console.log(valor)}).catch((e)=>{console.log("error banner");console.log(e);error=true});
    //   }
    //   if(!error){
    //     paramTem = {...paramTem,nativeAd:nativeAd};      
    //   }
    // }else if (paramTem.nativeAd && varPrem && varPremDuo){
    //   let nativeAd = paramTem.nativeAd;
    //   await nativeAd.hide();
    // }
    // //========== FUNCIÓN PARA EJECUTAR ADMOB PARA PUBLICIDAD NativeAd
    //========== FUNCIÓN PARA EJECUTAR ADMOB PARA PUBLICIDAD INTERSTICIAL
    // console.log("=====>Param before publicity");
    // if(!paramTem.pubInterstitial && !varPrem && !varPremDuo){    
    //   /*
    //   ID_INTERSTITIAL_ANDROID: ca-app-pub-2612144276627456/1675851279
    //   ID_INTERSTITIAL_IOS: ca-app-pub-2612144276627456/6333529006
    //    */
    //   let interstitial = new InterstitialAd({
    //     adUnitId: 'ca-app-pub-3940256099942544/1033173712',      
    //   });
    //   let error = false;
    //   await interstitial.load().then(val => console.log("interstitial OK")).catch((e)=>{console.log("error interstitial",e);error=true});
    //   if(!error){
    //     await interstitial.show().then(valor=>{console.log("banner");console.log(valor)}).catch((e)=>{console.log("error banner");console.log(e);error=true});
    //   }
    //   if(!error){
    //     paramTem = {...paramTem,pubInterstitial:interstitial};
    //   }
    // }else if (paramTem.pubInterstitial && varPrem && varPremDuo){
    //   let interstitial = paramTem.pubInterstitial;
    //   await interstitial.hide();
    // }
    //========== FUNCIÓN PARA EJECUTAR ADMOB PARA PUBLICIDAD INTESTICIAL
    
    if(JSON.stringify(cuidador)!=JSON.stringify(temCuidador)){ 
      setCuidador(temCuidador);
    }
    if(JSON.stringify(parametros)!=JSON.stringify(paramTem)){
      setParametros(paramTem);
    }
    await leerParametroByNombre(user,PARAM_IMG_PUBLICIDAD).then((valor)=>{
      if(valor!=null){
        setNumImg(parseInt(valor.valor));
      }
    });
    sincronizaNotificaciones(temCuidador);
    setEstatus({loading:false,leer:true})
  }

  useEffect(() =>{    
    leerParametros();
  },[]);  
  
  useEffect(() =>{    
    console.log("ejecuta");
    if(parametros && parametros.update){
      console.log("ejecuta2");
      leerParametros();
    }
  },[parametros]); 

  const existenPacientes = ()=>{
    if(!cuidador){
      return false;
    }
    return cuidador.pacientes.length>0;
  }

  const maximoPacientes =()=>{
    if(!cuidador){
      return false;
    }
    if(!cuidador.pacientes){
      return false;
    }
    /*cambiar y validar de acuerdo al perfil del usuario
      para un perfil premium, no tiene limite de pacientes.
     */
    let cantidadMax = premium?MAXIMO_PACIENTES_PREMIUM:MAXIMO_PACIENTES_BASICO;
    //contar solo los pacientes propios;
    let contar=0;
    cuidador.pacientes.forEach((pac)=>{
      if(pac.idCuidadorPropietario===null){
        contar++;
      }
    });
    return contar>=cantidadMax;
  }

  const contarPacientesPropios=(cuid:DTOCuidador)=>{
    if(!cuid || !cuid.pacientes){
      return 0;
    }
    let contar=0;
    cuid.pacientes.forEach((pac)=>{
      if(pac.idCuidadorPropietario===null){
        contar++;
      }
    });
    return contar;
  }

  const contarPacientesCompartidos=(cuid:DTOCuidador)=>{
    if(!cuid || !cuid.pacientes){
      return 0;
    }
    let contar=0;
    cuid.pacientes.forEach((pac)=>{
      if(pac.idCuidadorPropietario!=null){
        contar++;
      }
    });
    return contar;
  }

  const esPrimeraVez=()=>{
    if(!cuidador){
      return true;
    }    
    return cuidador.fechaRegistro ===cuidador.fechaModificacion;
  }

  const imagenesPublicidad =()=>{
    let content =[];
    for(let i =0;i<numImg;i++){
      let valor = i+1;
      if(valor === 1 && !(premium || premiumDuo)){
        valor = 0;
      }
      let url = "https://allivia.com.mx/publicidad/img"+valor+".png"
      content.push(
        <>
        <IonSlide>
          <IonRouterLink routerLink='/allivia/alliviaPremium'>
            <IonImg src={url} /> 
          </IonRouterLink>   
        </IonSlide>        
        </>
      )
    }
    return content;
  }

  const handleShowModalEliminar=async(pac:DTOPaciente)=>{
    if(pac.idPersona===cuidador.idPersona){
      return;
    }
    setEstatus({loading:true,leer:false});
    setPacienteSeleccionado(pac);
    setModalEliminarPaciente(true);
    setEstatus({loading:false,leer:false});
  }

  const handleAceptarEliminar=async()=>{
    setEstatus({loading:true,leer:false});
    await eliminarPaciente(pacienteSeleccionado.idPaciente,user)
    .then(()=>{
      setMsgToast({ showToast: true, statusMessage: "El paciente se eliminó correctamente" });
      setCuidador(null);
    })
    .catch((error)=>{
      setMsgToast({ showToast: true, statusMessage: "Ocurrio un error al eliminar el paciente, por favor intenta de nuevo" });
      setEstatus({loading:false,leer:false});
    });    
    setPacienteSeleccionado(null);
    setModalEliminarPaciente(false);
    setEstatus({loading:false,leer:false});
  }
  const handleCancelarEliminar=async()=>{
    setEstatus({loading:true,leer:false});
    setPacienteSeleccionado(null);
    setModalEliminarPaciente(false);
    setEstatus({loading:false,leer:false});
  }

  const handleCerrarAvisoEliminarPacienteCompartido=async()=>{
    setEstatus({loading:true,leer:false});    
    setModalEliminarPacienteCompartido(false);
    setEstatus({loading:false,leer:false});
  }

  const handleShowModalEliminaPacienteCompartido=async()=>{
    setEstatus({loading:true,leer:false});    
    setModalEliminarPacienteCompartido(true);
    setEstatus({loading:false,leer:false});
  }


  const sincronizaNotificaciones=async (cuidador:DTOCuidador)=>{   
    await leerNotificaciones(user,cuidador.idCuidador)
  }

  if(irMenuPaciente){  
    return <Redirect to="/allivia/menuPaciente" />
  }
  
  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  let contador =0;
       
  return (
    <>
    <Menu/>
    <IonPage>      
      <IonHeader>
        <IonToolbar>
      <IonButtons slot="start">
         <IonMenuToggle className='btn-menu'>
            <IonButton >
              <IonIcon size='large' color="primary" icon={menu}></IonIcon>
            </IonButton>
          </IonMenuToggle>          
        </IonButtons>
        <IonTitle className='home-title'>allivia</IonTitle>
        <IonButtons id='avatar' slot='end'>
          <IonButton routerLink='/allivia/perfilCuidador'>
            { cuidador && cuidador.avatar ? 
            <>
              <IonAvatar className='homepage-avatar'>
                <img className='homepage-avatar' src={cuidador.avatar.fileStream} alt=""></img>
              </IonAvatar>              
            </>                          
              :(cuidador?<div className='homepage-iniciales'>{cuidador.iniciales}</div>:<IonIcon size='large' color="rosa" icon={personCircle} ></IonIcon>)
            }                  
          </IonButton>
        </IonButtons>
        </IonToolbar>        
      </IonHeader>
      <IonContent className="homepage">     
        <div className='panel-cuidador'>
          <p className='bienvenida'>¡{bienvenido} hola<span> {cuidador?cuidador.nombre:''}!</span><br/>
          </p>
          <p className='deseas_r'>
            <span>¿Que deseas realizar hoy?</span>
          </p>
        </div>
        <div className='panel-pacientes'>        
          <div hidden={!(!premium && existenPacientes())}>
            <IonGrid>
              <IonRow>
                <IonCol size='4'>
                  <span hidden={esPrimeraVez()} className='cuido-a'>Yo me cuido: </span>
                </IonCol>
                <IonCol size='4'>
                  <span hidden={esPrimeraVez()||!maximoPacientes()} className='cuido-a'>Cuido a:</span>
                  <IonRouterLink className='cuido-a' hidden={esPrimeraVez()||maximoPacientes()} routerLink='/allivia/nuevoPaciente'>Cuido a:</IonRouterLink>
                </IonCol>
              </IonRow>
            </IonGrid>            
            
            <IonImg hidden={!esPrimeraVez()} src='./assets/imgCompletaPerfil.png'/>
            <IonGrid>
              <IonRow className="fila_opciones">
            {cuidador && cuidador.pacientes ? cuidador.pacientes.map((pac)=>
              pac.idCuidadorPropietario==null&&contador++<2?
              <>            
                  <IonCol size={contarPacientesPropios(cuidador)===1?'6':"4"} >
                    <IonRouterLink onClick={()=>{handleIrMenuPaciente(pac)}} onContextMenu={event=>{handleShowModalEliminar(pac)}}>
                      <IonAvatar >
                        <img src={!pac.avatar?"./assets/pacienteAvatar.png":pac.avatar.fileStream} alt='pacienteAvatar'/>
                      </IonAvatar>                                           
                    <div className="titulos_opciones">{pac.nombreCompleto}<br/>{pac.parentesco}</div>
                  </IonRouterLink>
                  </IonCol>
                </> :null    
              ) : null
            }

              <IonCol  size={cuidador!=null&&contarPacientesPropios(cuidador)===1?'6':"4"}>
                <IonRouterLink routerLink='/allivia/miCalendarioCuidador'>
                    <IonAvatar>
                      <img src='./assets/calendarioAllivia.png' alt='pacienteAvatar'/>
                    </IonAvatar>                    
                    <div className="titulos_opciones">Nuestro<br/>Calendario</div>
                </IonRouterLink>
                </IonCol>                
            </IonRow>
            </IonGrid>
          </div>
          <div hidden={!premium}>
            <IonGrid>
              <IonRow className="fila_opciones">
                <IonCol size="12">
                  <IonRouterLink routerLink='/allivia/miCalendarioCuidador'>
                    <IonAvatar>
                      <img src='./assets/calendarioAllivia.png' alt='pacienteAvatar'/>
                    </IonAvatar>                    
                    <div className="titulos_opciones">Nuestro<br/>Calendario</div>
                  </IonRouterLink>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="4">
                  <IonRouterLink routerLink='/allivia/estadoEmocional'>
                    <IonAvatar>
                      <img src='./assets/emocional.png' alt='pacienteAvatar'/>
                    </IonAvatar>                    
                    <div className="titulos_opciones">Emocional</div>
                  </IonRouterLink>
                </IonCol>
                <IonCol size="4">
                  <IonRouterLink routerLink='/allivia/estadoNutricional'>
                    <IonAvatar>
                      <img src='./assets/nutricional.png' alt='pacienteAvatar'/>
                    </IonAvatar>                    
                    <div className="titulos_opciones">Nutricional</div>
                  </IonRouterLink>
                </IonCol>
                <IonCol size="4">
                  <IonRouterLink onClick={()=>{setModalCuidaDeTi(true)}}>
                    <IonAvatar>
                      <img src='./assets/cuidaTi.png' alt='pacienteAvatar'/>
                    </IonAvatar>                    
                    <div className="titulos_opciones">Cuida de ti</div>
                  </IonRouterLink>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>            
        </div>
                
                                      
        <div className="cuido-a">
        <IonListHeader>
          <IonLabel  hidden={!premium} id="txt_cuido">Cuido a:</IonLabel>
          <IonLabel hidden={premium} id="txt_cuido">Beneficios de ser Premium</IonLabel>
        </IonListHeader>        
        <div hidden={!(premium && existenPacientes())}>
          <IonGrid>
            <IonRow className="fila_opciones">
          {cuidador && cuidador.pacientes ? cuidador.pacientes.map((pac)=>
            !pac.idCuidadorPropietario?
            <>
              <IonCol key={pac.idPaciente} size={contarPacientesPropios(cuidador)===1?'12':(contarPacientesPropios(cuidador)===2?'6':'4')} >
                <IonRouterLink onClick={()=>{handleIrMenuPaciente(pac)}} onContextMenu={event=>{handleShowModalEliminar(pac)}}>
                  <IonAvatar>
                    <img src={!pac.avatar?"./assets/pacienteAvatar.png":pac.avatar.fileStream} alt='pacienteAvatar'/>
                  </IonAvatar>                    
              <div className="paciente_parentesco" key={pac.idPaciente}>{pac.nombreCompleto}<br/>{pac.parentesco}</div>
              </IonRouterLink>
              </IonCol>
             </>:<></>) : null
          }
          </IonRow>
          </IonGrid>
        </div>
        <div hidden={tienePacientesCompartidos===0} id="pacientes_compartidos">
          <h6>Mis pacientes Compartidos</h6>
          <IonGrid>
            <IonRow className="fila_opciones">
            {cuidador && cuidador.pacientes ? cuidador.pacientes.map((pac)=>
            pac.idCuidadorPropietario?
            <>
              <IonCol key={pac.idPaciente} size={contarPacientesCompartidos(cuidador)===1?'12':(contarPacientesCompartidos(cuidador)===2?'6':'4')} >
                <IonRouterLink onClick={()=>{handleIrMenuPaciente(pac)}} onContextMenu={handleShowModalEliminaPacienteCompartido} >
                  <IonAvatar>
                    <img src={!pac.avatar?"./assets/pacienteAvatar.png":pac.avatar.fileStream} alt='pacienteAvatar'/>
                  </IonAvatar>                    
              <div className="paciente_compar" key={pac.idPaciente}>{pac.nombreCompleto}<br/>{pac.parentesco}</div>
              </IonRouterLink>              
              </IonCol>
             </>:<></>) : null
          }
          </IonRow>
          </IonGrid>
        </div>
        <div id="txt_premium">
           <IonGrid>
            <IonRow className="fila_opciones">
              <IonCol size="11" >
                <h1 hidden={!premium} className="apremium"> Soy allivia <span className="premiumm">Premium</span> </h1>                
                <p className='intro_allivia'>
                  <span className="apremium">allivia  </span>es una app que quiere acompañarte en cualquier situación médica, de rutina o de emergencia. 
                </p>
                <p className='intro_allivia'>
                  <span className="capital">A</span>ctualízala, tu paciente, tu médico y tú, lo agradecerán. 
                </p>
              </IonCol>
            </IonRow>
            </IonGrid>
        </div>
        <div className='premium'>
        <IonSlides ref={publicidadRef}   options={slideOpts} >
          {            
            imagenesPublicidad()
          }                                        
        </IonSlides>                  
        </div>                     
      </div>
      <IonModal backdropDismiss={false} isOpen={modalCuidaDeTi} className="ion-modal-cuida-de-ti" >
            <IonContent>
              <div className='header'>                
                <IonButton fill='clear' size='large' onClick={()=>{setModalCuidaDeTi(false);}}>
                  <IonIcon icon={closeOutline}></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonButton fill='clear' onClick={()=>{setModalCuidaDeTi(false)}} routerLink='/allivia/respiracionMsg' >
                          <div className='cuida-de-ti-imagen'>
                            <IonImg className='img-icon' src="./assets/respiracion.png" />
                            <IonLabel>Respiración</IonLabel>
                          </div>                          
                        </IonButton>
                      </IonCol>
                      <IonCol size='6' >
                        <IonButton fill='clear' onClick={()=>{setModalCuidaDeTi(false)}} routerLink='/allivia/hidratacion'>
                          <div className='cuida-de-ti-imagen'>
                            <IonImg className='img-icon' src="./assets/hidratacion.png" />
                            <IonLabel>Hidratación</IonLabel>
                          </div>
                        </IonButton>
                      </IonCol>
                    </IonRow>                   
                  </IonGrid>
                </div>
              </div>              
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={modalEliminarPaciente} className="ion-modal-eliminar-paciente">
            <IonContent>              
              <div className='contenido'>
                ¿Esta seguro que desea eliminar el paciente: <span className='nombre'>{pacienteSeleccionado?pacienteSeleccionado.nombreCompleto:""}</span>?
              </div>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonButton expand='block' onClick={handleCancelarEliminar} >No</IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton expand='block' onClick={handleAceptarEliminar} >Sí</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={modalEliminarPacienteCompartido} className="ion-modal-eliminar-paciente-compartido">
            <IonContent>              
              <div className='contenido'>
                Si deseas eliminar el acceso compartido de tus pacientes, elimina a tu invitado Premium
              </div>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonButton expand='block' onClick={handleCerrarAvisoEliminarPacienteCompartido}>Aceptar</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
          <IonToast color='rosa'
          isOpen={msgToast.showToast}
          onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
          message={msgToast.statusMessage}
          duration={3000}
        />
      <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
      <IonFab hidden={esWeb()} className='allivia-emergencia-call'  vertical='bottom' horizontal='center' edge slot='fixed'>
          <IonFabButton color="emergencia">
            <div id="911">
              <IonIcon icon={call}/>
              <IonLabel>911</IonLabel>       
            </div>                 
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton color="success" onClick={()=>{emergencyCall();}}><IonIcon icon={call} /></IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>      
      {/* <IonTabBar slot="bottom" color='primary'>
            <IonTabButton tab='home' href='/allivia/alliviaHome' >
              <IonIcon icon={home}/>
              <IonLabel>Inicio</IonLabel>
            </IonTabButton>
            <IonTabButton disabled={maximoPacientes()} href ='/allivia/nuevoPaciente' >
              <IonIcon icon={personAdd}/>
              <IonLabel>Persona a cuidar</IonLabel>
            </IonTabButton>                  
      </IonTabBar> */}
      <div className='custom-tab-bar'>
          <IonButton fill='clear' routerLink='/'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' disabled={maximoPacientes()} routerLink='/allivia/nuevoPaciente'>
            <div>
              <IonIcon icon={personAdd} />
              <IonLabel>Persona a cuidar</IonLabel>
            </div>
          </IonButton>
        </div>
    </IonPage>
    </>
  );
};

export default HomeAllivia;
