import React, { useContext, useEffect } from "react";
import { UsuarioLogin } from "../services/AlliviaInterfaces";
import { cerrarSesion, validarToken } from "../services/login.service";
import { types } from "../types/types";



// interface AuthInit{
//   loading: boolean;
//   auth?: UsuarioLogin;
// }

export const LoginContext = React.createContext<any>(undefined);

/**
 * Regresa el contex de usuario Login
 * @returns usuarioLogin
 */
export function useLoginContext()  : UsuarioLogin{
  const {user} = useContext(LoginContext); 
  return user;
}

export function usePremium() :boolean{
  const {parametros} = useContext(LoginContext);
  return parametros!=null && parametros.perfil!=null && parametros.perfil.perfil==='PREMIUM';
}

export function useParametros() {
  const {parametros,setParametros} = useContext(LoginContext);
  return {parametros,setParametros};
}

export function useValidarToken(){
  const {user,dispatch} = useContext(LoginContext);

  

  useEffect(()=>{    
    console.log("useValidaToken valor token:",user.token);
    if(user.token){
      validarToken(user.token).then((token)=>{        
        console.log("valor token:",token);
        try{
          if(user.token!=token.token){
            console.log("token diferente");
            const action = {
              type: types.login,
              payload: {loggedIn:'true',token:token.token,username:token.username,idUsuario:token.idUsuario}
            }
            console.log("funciona",user,dispatch);
            dispatch(action);
          }
        }catch(e){
          console.log("error",e);
        }

        if(!token){
          cerrarSesion();
          let temAction = {type: types.logout,};
          dispatch(temAction); 
        }
      }).catch((e)=>{
        console.log("error",e);
      });    
    }
  },[]); 
}


