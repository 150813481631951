import { SERVICE_ALLIVIA} from "../properties";
import {  DTOEvento, UsuarioLogin } from "./AlliviaInterfaces";


export const leerEventoById = async (usuario:UsuarioLogin,idEvento:number):Promise<DTOEvento> =>{
  const url = SERVICE_ALLIVIA+"/evento/id/"+idEvento;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerEventos = async (usuario:UsuarioLogin,idCuidador:number):Promise<Array<DTOEvento>> =>{
  const url = SERVICE_ALLIVIA+"/evento/"+idCuidador;  
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("eventosCalendario",JSON.stringify(json));
    console.log(json);    
    return json;
  }catch(evt){
    console.log("error",evt);
    let evtos :Array<DTOEvento>=[];
    if(localStorage.eventosCalendario){
      evtos=JSON.parse(localStorage.eventosCalendario);        
    }
    return evtos
  }
}

export const guardarEvento =async (usuario:UsuarioLogin,estudio:DTOEvento):Promise<DTOEvento> => {
  const url = SERVICE_ALLIVIA+"/evento";
  const response = await fetch(url,{
    body: JSON.stringify(estudio),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el evento: "+response.statusText);
  }
  const json = await response.json();    
  return json;
}

export const leerEventosNotificacion = async (usuario:UsuarioLogin,idCuidador:number):Promise<Array<DTOEvento>> =>{
  const url = SERVICE_ALLIVIA+"/evento/notificaciones/"+idCuidador;  
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    return json;
  }catch(err){
    console.log("error",err);
    return [];
  }
}

export const ultimaActualizacionNotificacion = async (usuario:UsuarioLogin,idCuidador:number) =>{
  
  let urllu = SERVICE_ALLIVIA+"/evento/ultimaActualizacion/notificaciones/"+idCuidador;  
  try{
    const response = await fetch(urllu,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });    
    if(!response.ok){
        return null;
    }
    
    const fecha = await response.json();
    return fecha;          
  }catch(error){
    console.log("error",error)
    return null;
  } 
}
