import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonImg,
  IonButton,
  IonIcon,
  IonFab,
  IonFabButton,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/hidratacion.css';
import { Redirect } from 'react-router';
import { DTOHidratacion, DTOHidratacionRegistro } from '../services/AlliviaInterfaces';
import { guardarHidratacion, leerHidratacionHoy } from '../services/hidratacion.service';
import dayjs from 'dayjs';
import { } from "dayjs/locale/es";
import { add, chevronDown, chevronUp, remove } from 'ionicons/icons';
import { VALOR_VASO } from '../constantes';



const ConsumoAgua: React.FC = () => {
  const user = useLoginContext();
  const { parametros } = useParametros();
  const [estatus, setEstatus] = useState({ loading: true, leer: true,mensaje:null });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [hidratacion, setHidratacion] = useState<DTOHidratacion>();
  const [logro, setLogro] = useState(0);
  const [hoy, setHoy] = useState("");
  const [nivel, setNivel] = useState(0.0);

  const leerInicio = async () => {
    setEstatus({ loading: true, leer: false,mensaje:null });
    // Leer datos de hidratacion
    await leerHidratacionHoy(user, parametros.cuidador.idCuidador).then((hdri) => {
      console.log(hdri);
      let contLogro = 0;
      hdri.hidratacionRegistros.map((regH) => {
        contLogro = contLogro + regH.cantidad;
      });

      let meta = hdri.cantidadAgua;
      let nvl = contLogro / meta;
      nvl = 1 - nvl;
      console.log(nvl);
      nvl = 85 * nvl;
      if (nvl > 85) {
        nvl = 85;
      }
      setNivel(nvl);
      setLogro(contLogro);
      setHidratacion(hdri);
    });

    let date = new Date();
    dayjs.locale("es");
    setHoy(dayjs(date).format("dddd DD [de] MMMM [de] YYYY"));

    setEstatus({ loading: false, leer: false,mensaje:null });
  }

  const agregarConsumoAgua = async()=>{
    setEstatus({loading:true,leer:false,mensaje:"¡Bien hecho, te estás hidratando!"});
    let registro:DTOHidratacionRegistro = {
      idHidratacion:hidratacion.idHidratacion,
      cantidad:VALOR_VASO,
    }
    hidratacion.hidratacionRegistros.push(registro);
    let resultado = await guardarHidratacion(user,hidratacion);
    if(resultado.idHidratacion!=null){        
      await leerHidratacionHoy(user,parametros.cuidador.idCuidador).then((hdri)=>{
        setHidratacion(hdri);
      });      
    }
    await leerInicio();
    setEstatus({loading:false,leer:false,mensaje:null});
  }

  const quitarConsumoAgua = async()=>{
    console.log("nivel: ",nivel);
    if(logro<=0){
      return
    }
    setEstatus({loading:true,leer:false,mensaje:"¡Ups! Recuerda, debes hidratarte"});
    let registro:DTOHidratacionRegistro = {
      idHidratacion:hidratacion.idHidratacion,
      cantidad:-VALOR_VASO,
    }
    hidratacion.hidratacionRegistros.push(registro);
    let resultado = await guardarHidratacion(user,hidratacion);
    if(resultado.idHidratacion!=null){        
      await leerHidratacionHoy(user,parametros.cuidador.idCuidador).then((hdri)=>{
        setHidratacion(hdri);
      });      
    }
    await leerInicio();
    setEstatus({loading:false,leer:false,mensaje:null});
  }

  const valorLogro = (logr:number)=>{
    let val = "0ml";
    if(logr<1){
      val = (logr*1000)+"ml"
    }else{
      val = logr+"L";
    }
    return val;
  }


  useEffect(() => {
    if (parametros) {
      leerInicio();
    }
  }, []);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  if (!hidratacion) {
    return (<IonLoading isOpen message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />);
  }

  



  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text="" color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Cuida de ti</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">

          <div className='hidratacion'>
            <div className='header' >
              <div className='titulo'>
                <h1>Mi Consumo de agua</h1>
              </div>
            </div>

            <div className='contenido'>
              <div className='fechahidratacion'>
                {hoy}
              </div>
              <div className='consumo'>
                <div className='meta'>
                  Meta:
                  <br />
                  {hidratacion.cantidadAgua}l
                </div>
                <div className='logro'>
                  Logro registrado:
                  <br />
                  {valorLogro(logro)}
                </div>
              </div>

              <div className='fondo-consumo-botella'>
                <div >
                  <IonImg className='consumo-botella' style={{ "--consumo-nivel": `${nivel}%` }} src='./assets/botella.png'>
                  </IonImg>
                </div>
              </div>
            </div>
          </div>
          <IonFab className='control-consumo-agua'  vertical="center" horizontal="end" title='Guardar' slot='fixed'>
            <IonFabButton onClick={quitarConsumoAgua} >
              <IonIcon icon={chevronUp}></IonIcon>   
            </IonFabButton>
            <IonFabButton onClick={agregarConsumoAgua}>
              <IonIcon icon={chevronDown}></IonIcon>
            </IonFabButton>
          </IonFab>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message={estatus.mensaje!=null?estatus.mensaje:"Por favor espera..."} spinner='circular' cssClass='allivia-loading' />
        </IonContent>
      </IonPage>
    </>
  );
};

export default ConsumoAgua;
