/* descomentar para android*/
import { LocalNotifications } from "@capacitor/local-notifications";
/* descomentar para ios */
//import { LocalNotifications } from "@awesome-cordova-plugins/local-notifications";
import dayjs from "dayjs";
import { DTOEvento, UsuarioLogin } from "./AlliviaInterfaces";
import { leerEventosNotificacion, ultimaActualizacionNotificacion } from "./evento.service";

export const leerNotificacionesElimina=async (user:UsuarioLogin,idCuidador:number)=>{  
  localStorage.removeItem("ultimaFechaNotificacion");
  await leerNotificaciones(user,idCuidador);
}

export const leerNotificaciones=async (user:UsuarioLogin,idCuidador:number)=>{
  let ultStorageFechaNotifi = null;
  let ultStorageUltimaNotif = null;
  if(localStorage.ultimaFechaNotificacion){
    ultStorageFechaNotifi=localStorage.ultimaFechaNotificacion;            
  }

  if(localStorage.ultimaNotificacion){
    ultStorageUltimaNotif=localStorage.ultimaNotificacion;         
  }


  let ultFechaNotificacion = await ultimaActualizacionNotificacion(user,idCuidador);
  console.log("FECHA_UPDATE_EVENTO",ultStorageFechaNotifi,ultFechaNotificacion,ultStorageUltimaNotif);
  if(!ultStorageFechaNotifi || !ultStorageUltimaNotif || ultStorageFechaNotifi!=ultFechaNotificacion || 
     ultFechaNotificacion>ultStorageUltimaNotif){
    let events = await leerEventosNotificacion(user,idCuidador);   
     
    /* descomentar para android */
    /* android */
    let notPending = await LocalNotifications.getPending();    
    console.log("NOTIFICACIONES_PENDIENTES:",notPending.notifications.length);
    if(notPending && notPending.notifications && notPending.notifications.length>0){
      console.log("Elimina notificaciones");
      await LocalNotifications.cancel(notPending);
    }
    /* android */


    /* descomentar para ios */
    /* ios */
    // await LocalNotifications.cancelAll();
    // await LocalNotifications.clearAll();      
    /* ios */


    let contar=0;       
    events.forEach((ev)=>{      
      crearNotificaciones(ev);                 
      ultStorageUltimaNotif = new Date(ev.fecha+"T"+ev.hora);
      if(ev.nombre==='Cita médica'||ev.nombre==="Estudio clínico"){
        let evTem = JSON.parse(JSON.stringify(ev));
        let date = dayjs(evTem.fecha).subtract(1,'day').format("YYYY-MM-DD");
        if(new Date(date+"T"+evTem.hora)>new Date()){        
          contar++;
          evTem = {...evTem,fecha:date,idEvento:contar};
          crearNotificaciones(evTem);
        }
      }
    });
    localStorage.setItem("ultimaFechaNotificacion",ultFechaNotificacion);
    localStorage.setItem("ultimaNotificacion",ultStorageUltimaNotif);
  }
}

/* Descomentar para android */

const crearNotificaciones = async (event:DTOEvento)=>{
  await LocalNotifications.schedule({notifications:[
    {
      title: event.nombre,
      body: event.descripcion,
      id: event.idEvento,  
      schedule: {
        at: new Date(event.fecha+"T"+event.hora),
      }  
    }
  ]     
  });
}

/* Descomentar para ios */
// const crearNotificaciones = async (event:DTOEvento)=>{
//   await LocalNotifications.schedule(
//     {
//       title: event.nombre,
//       text: event.descripcion,
//       id: event.idEvento,  
//       trigger: {
//         at: new Date(event.fecha+"T"+event.hora),
//       }  
//     }
//   );
// }