import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext } from '../hooks/login';
import '../theme/alliviaPremium.css';
import { Redirect } from 'react-router';
import dayjs from 'dayjs';



const AlliviaPremium: React.FC = () => {
  const user = useLoginContext();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});

  const convertirFecha=(fecha:string):string=>{  
    return dayjs(fecha).format("YYYY-MM-DD");;
  }

  const convertirHora=(fecha:string):string=>{  
    return dayjs(fecha).format("HH:mm:ss");;
  }

  const convertirHoraFecha =(t:string):string=>{  
    let d = ""+t+":00"
    return d;
  }

  const formatDinero =(value: number) =>{
   return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'USD'
    }).format(value);
  }

  const formatFecha =(fecha:string) =>{
    return dayjs(fecha).format("DD/MM/YYYY");
  }
  
  useEffect(()=>{    
    setEstatus({loading:false,leer:false});
  },[]);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Suscríbete </IonTitle>
            <IonTitle className="apremium">allivia <span className="premiumm">premium</span></IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div>
            <h5>Beneficios exclusivos:</h5>
              <ul className="beneficios">
                <li>1. Extensión a 4 miembros para llevar un historial clínico</li>
                <li>2. Plan Invitado PREMIUM para compartir información en dos dispositivos móviles</li>
                <li>3. Plan de alimentación personalizado para el cuidador</li>
                <li>4. Test de diagnóstico emocional para el cuidador</li>
                <li>5. Tu información estará respaldada en la nube</li>
              </ul>
           </div>
          <div className='premium_oferta'>
            <div className="membresias">
                  <div id="mensual">
                      <p>
                         Plan mensual
                      </p>
                      <p>
                         $69.90
                      </p>
                  </div>
            </div>
            <div className="membresias">
                <div id="anual">
                    <p>
                      Plan anual
                    </p>
                    <p>
                      $699.00
                    </p>
                </div>
            </div>
          </div>
          <div id="textos_membresia">
            <p className="frasemembresia">
              ¡Empieza a disfrutar con tu familia de todos estos beneficios!
            </p>
            <p className="descubre">
              Descubre más haciendo click
            </p>
          </div>
          <IonButton className="sus_premium" expand="block" routerLink='/allivia/PagoAlliviaPremium' >Suscríbete ahora</IonButton>                        
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
          />
        <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
        </IonContent>        
      </IonPage>
    </>
  );
};
export default AlliviaPremium;
