import {
   IonPage, IonRouterOutlet
} from '@ionic/react';
import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import SettingsPage from './pages/SettingsPage';
import {useLoginContext } from './hooks/login';
import AcercaDe from './pages/AcercaDe';
import DerechosCuidador from './pages/DerechosCuidador';
import NuevoPaciente from './pages/NuevoPaciente';
import PerfilCuidador from './pages/PerfilCuidador';
import MenuPaciente from './pages/MenuPaciente';
import InformacionPaciente from './pages/InformacionPaciente';
import SignosVitales from './pages/SignosVitales';
import EstudiosClinicos from './pages/EstudiosClinicos';
import Tratamientos from './pages/Tratamientos';
import CitaMedica from './pages/CitaMedica';
import Medicamentos from './pages/Medicamentos';
import MiCalendarioCuidador from './pages/MiCalendarioCuidador';
import BuzonSugerencias from './pages/BuzonSugerencias';
import AvisoPrivacidad from './pages/AvisoPrivacidad';
import Configuracion from './pages/Configuracion';
import Contacto from './pages/Contacto';
import PreguntasFrecuentes from './pages/PreguntasFrecuentes';
import TerminosCondiciones from './pages/TerminosCondiciones';
import AlliviaPremium from './pages/AlliviaPremium';
import PagoAlliviaPremium from './pages/PagoAlliviaPremium';
import MetodoPago from './pages/MetodoPago';
import MisPacientes from './pages/MisPacientes';
import AccesoCompartido from './pages/AccesoCompartido';
import SoyAlliviaPremium from './pages/SoyAlliviaPremium';
import EstadoEmocional from './pages/EstadoEmocional';
import CuestionarioUno from './pages/CuestionarioUno';
import CuestionarioDos from './pages/CuestionarioDos';
import CuestionarioTres from './pages/CuestionarioTres';
import CuestionarioCuatro from './pages/CuestionarioCuatro';
import EstadoNutricional from './pages/EstadoNutricional';
import Respiracion from './pages/Respiracion';
import Hidratacion from './pages/Hidratacion';
import ConsumoAgua from './pages/ConsumoAgua';
import RespiracionMsg from './pages/RespiracionMsg';
import PromocionesFarmacia from './pages/PromocionesFarmacia';
import BlogAllivia from './pages/BlogAllivia';
import MiInvitacionPremium from './pages/MiInvitacionPremium';
import AdminDev from './pages/AdminDev';
import Buscar from './pages/Buscar';
import NotFoundPage from './pages/NotFoundPage';
import CuentaUsuario from './pages/CuentaUsuario';
import HomeAllivia from './pages/HomeAllivia';


const AppTabs: React.FC = () => {
  const { loggedIn } = useLoginContext();
  if(!loggedIn){
    return <Redirect to="/login" />
  }
  return (      
        <IonPage>          
          {/*Its to map the route pages*/}          
          <IonRouterOutlet>             
            <Route exact path="/allivia/alliviaHome" >
              <HomeAllivia />
            </Route>                                             
            <Route exact path="/allivia/settings" >
              <SettingsPage />
            </Route>
            <Route exact path="/allivia/acercaDe" >
              <AcercaDe />
            </Route>
            <Route exact path="/allivia/nuevoPaciente" >
              <NuevoPaciente />
            </Route>
            <Route exact path="/allivia/menuPaciente" >
              <MenuPaciente />
            </Route>
            <Route exact path="/allivia/informacionPaciente" >
              <InformacionPaciente />
            </Route>
            <Route exact path="/allivia/signosVitales" >
              <SignosVitales />
            </Route>
            <Route exact path="/allivia/estudiosClinicos" >
              <EstudiosClinicos />
            </Route>
            <Route exact path="/allivia/tratamientos" >
              <Tratamientos />
            </Route>
            <Route exact path="/allivia/medicamentos" >
              <Medicamentos />
            </Route>
            <Route exact path="/allivia/miCalendarioCuidador" >
              <MiCalendarioCuidador />
            </Route>
            <Route exact path="/allivia/citaMedica" >
              <CitaMedica />
            </Route>
            <Route exact path="/allivia/perfilCuidador" >
              <PerfilCuidador />
            </Route>
            <Route exact path="/allivia/derechosCuidador" >
              <DerechosCuidador />
            </Route>  
            <Route exact path="/allivia/buzonSugerencias" >
              <BuzonSugerencias />
            </Route>
            <Route exact path="/allivia/avisoPrivacidad" >
              <AvisoPrivacidad />
            </Route>
            <Route exact path="/allivia/configuracion" >
              <Configuracion />
            </Route>
            <Route exact path="/allivia/contacto" >
              <Contacto />
            </Route>
            <Route exact path="/allivia/preguntasFrecuentes" >
              <PreguntasFrecuentes />
            </Route>
            <Route exact path="/allivia/terminosCondiciones" >
              <TerminosCondiciones />
            </Route>
            <Route exact path="/allivia/alliviaPremium" >
              <AlliviaPremium />
            </Route>
            <Route exact path="/allivia/metodoPago" >
              <MetodoPago />
            </Route>
            <Route exact path="/allivia/pagoAlliviaPremium" >
              <PagoAlliviaPremium />
            </Route>
            <Route exact path="/allivia/misPacientes" >
              <MisPacientes />
            </Route>
            <Route exact path="/allivia/accesoCompartido" >
              <AccesoCompartido />
            </Route>
            <Route exact path="/allivia/soyAlliviaPremium" >
              <SoyAlliviaPremium />
            </Route>        
            <Route exact path="/allivia/estadoEmocional" >
              <EstadoEmocional />
            </Route>      
            <Route exact path="/allivia/estadoNutricional" >
              <EstadoNutricional />
            </Route> 
            <Route exact path="/allivia/cuestionarioUno" >
              <CuestionarioUno />
            </Route>
            <Route exact path="/allivia/cuestionarioDos" >
              <CuestionarioDos />
            </Route> 
            <Route exact path="/allivia/cuestionarioTres" >
              <CuestionarioTres />
            </Route> 
            <Route exact path="/allivia/cuestionarioCuatro" >
              <CuestionarioCuatro />
            </Route> 
            <Route exact path="/allivia/respiracion" >
              <Respiracion />
            </Route>
            <Route exact path="/allivia/hidratacion" >
              <Hidratacion />
            </Route>         
            <Route exact path="/allivia/consumoAgua" >
              <ConsumoAgua />
            </Route>
            <Route exact path="/allivia/respiracionMsg" >
              <RespiracionMsg />
            </Route>
            <Route exact path="/allivia/promocionesFarmacia" >
              <PromocionesFarmacia />
            </Route>
            <Route exact path="/allivia/blogAllivia" >
              <BlogAllivia />
            </Route>
            <Route exact path="/allivia/miInvitacionPremium" >
              <MiInvitacionPremium />
            </Route>  
            <Route exact path="/allivia/cuentaUsuario" >
              <CuentaUsuario />
            </Route>  
            <Route exact path="/allivia/admin/dev" >
              <AdminDev />
            </Route>           
            <Route exact path="/allivia/buscar" >
              <Buscar />
            </Route>
            <Redirect exact path='/' to="/allivia/alliviaHome" />
            <Route>
              <NotFoundPage />
            </Route>
          </IonRouterOutlet>          
        </IonPage>        
  );
};

export default AppTabs;
