import { SERVICE_ALLIVIA} from "../properties";
import { DTOParametro, UsuarioLogin } from "./AlliviaInterfaces";


export const leerParametroByNombre = async (usuario:UsuarioLogin,nombre:string):Promise<DTOParametro> =>{
  const url = SERVICE_ALLIVIA+"/parametro/nombre/"+nombre;
  try{  
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }  
    const json = await response.json(); 
    return json;
  }catch(evt){
    console.log("error",evt);
    return null
  }
}


export const guardarParametro =async (usuario:UsuarioLogin,parametro:DTOParametro):Promise<DTOParametro> => {
  const url = SERVICE_ALLIVIA+"/parametro";
  const response = await fetch(url,{
    body: JSON.stringify(parametro),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el estudio medico: "+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

