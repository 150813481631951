import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonImg,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext } from '../hooks/login';
import '../theme/metodoPago.css';
import { Redirect } from 'react-router';
import dayjs from 'dayjs';



const MetodoPago: React.FC = () => {
  const user = useLoginContext();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});

  const convertirFecha=(fecha:string):string=>{  
    return dayjs(fecha).format("YYYY-MM-DD");;
  }

  const convertirHora=(fecha:string):string=>{  
    return dayjs(fecha).format("HH:mm:ss");;
  }

  const convertirHoraFecha =(t:string):string=>{  
    let d = ""+t+":00"
    return d;
  }

  const formatDinero =(value: number) =>{
   return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'USD'
    }).format(value);
  }

  const formatFecha =(fecha:string) =>{
    return dayjs(fecha).format("DD/MM/YYYY");
  }


  // useEffect(() => {
    
  //   setEstatus({loading:false,leer:false});
  // }, [parametros]);
  
  useEffect(()=>{    
    setEstatus({loading:false,leer:false});
  },[]);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Método de pago</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {newFunction()}
          <IonList className='metodo-pago'>
            <IonItem routerLink='/allivia/pagoAlliviaPremium' >
              <IonImg src="./assets/wallet.png"></IonImg>
              <IonLabel>Agregar método de pago</IonLabel>
            </IonItem>
          </IonList>                               
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
          />
        <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
        </IonContent>        
      </IonPage>
    </>
  );
};

export default MetodoPago;
function newFunction() {
  return <div id="titulo_pago">
    <h1>Métodos de pago</h1>

  </div>;
}

