import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonRadioGroup,
  IonRadio,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/estadoNutricional.css';
import { Redirect } from 'react-router';
import { DTOCuestionario, DTOCuidador, DTOSignoEstatura, DTOSignoPeso, DTOTestCuidador, DTOTestCuidadorRespuesta } from '../services/AlliviaInterfaces';
import {  guardarTestNutricion, leerCuestionarios, leerTestCuidador } from '../services/cuestionario.service';
import { leerEstaturaUltima, leerPesoUltimo } from '../services/signosVitales.service';



const EstadoNutricional: React.FC = () => {
  const user = useLoginContext();
  const { parametros } = useParametros();
  const idTest = 5;
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [cuestionario, setCuestionario] = useState<Array<DTOCuestionario>>([]);
  const [preguntaPendiente, setPreguntaPendiente] = useState(true);
  const [cuidador, setCuidador] = useState<DTOCuidador | undefined>(undefined);
  const [peso,setPeso]= useState<DTOSignoPeso>(undefined);
  const [estatura,setEstatura]= useState<DTOSignoEstatura>(undefined);  
  const [testFinalizado,setTestFinalizado] = useState(false);

  const leerDatosCuestionario = async () => {
    setEstatus({ loading: true, leer: false });
    let temCuest:Array<DTOCuestionario>=[];
    let temCuestF:Array<DTOCuestionario>=[];
    await leerCuestionarios(idTest, user).then((cuest) => {
      temCuest=cuest;      
    });    
    console.log("parametros cuidador",parametros);
    if(parametros && parametros.cuidador){
      await leerPesoUltimo(user,parametros.cuidador.idPersona).then((peso)=>{
        setPeso(peso);
      });
      await leerEstaturaUltima(user,parametros.cuidador.idPersona).then((estat)=>{
        setEstatura(estat);
      });
      setCuidador(parametros.cuidador);
      await leerTestCuidador(parametros.cuidador.idCuidador,user)
      .then((testTem)=>{
        testTem.map((tt)=>{       
          if(tt.idTest==5){
            setTestFinalizado(true);
            tt.testCuidadorRespuestas.map((tr)=>{
              temCuest.map(tc=>{
                if(tr.idCuestionario=tc.idCuestionario){
                  let tcc = {...tc,valorRespuesta:tr.valorRespuesta};
                  temCuestF.push(tcc);
                  console.log("cc",tc,tr.valorRespuesta);
                }
              });
            });            
          }
        });      
      });
      if(temCuestF.length>0){
        setCuestionario(temCuestF);
      }else{
        setCuestionario(temCuest);
      }
      
    }
    
    setEstatus({ loading: false, leer: false });
  }

  const guardarCuestionario = async () => {
    setEstatus({ loading: true, leer: false });
    let date = new Date();
    let respuestas: Array<DTOTestCuidadorRespuesta> = [];
    cuestionario.map((question) => {
      let resp: DTOTestCuidadorRespuesta = {
        idCuestionario: question.idCuestionario,
        valorRespuesta: question.valorRespuesta,
      }
      respuestas.push(resp);
    });

    let testCuidador:DTOTestCuidador = {
      idTest : idTest,
      idCuidador:cuidador.idCuidador,      
      fechaEvaluacion: date.toISOString(),
      testCuidadorRespuestas : respuestas   
    }

    await guardarTestNutricion(testCuidador,user).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El test se ha enviado al nutriólogo"});
      setTestFinalizado(true);
    }).catch((error)=>{
      console.log("Error al guardar el test",error);
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el test por favor intente de nuevo"});

    });    
    setEstatus({ loading: false, leer: false });
  }

  const evaluarRespuestas = async () => {
    setEstatus({ loading: true, leer: false });
    let puntos = 0;
    let pendiente:boolean = false;
    console.log("puntos:", pendiente,"parametres: ",parametros);
    cuestionario.map((resp) => {
      console.log(resp.numPregunta + "==" + resp.valorRespuesta);
      if (resp.valorRespuesta!=null) {
        console.log("Entra==" + resp.valorRespuesta);
        puntos = puntos + resp.valorRespuesta;
      } else {
        console.log("Etrna true: ",resp.numPregunta);
        pendiente = true;
      }
    });
    console.log("puntos:",pendiente);    

    setPreguntaPendiente(pendiente);
    setEstatus({ loading: false, leer: false });
  }

  useEffect(() => {
    leerDatosCuestionario().then((cuest) => {
      console.log("Funcionando");
    });
  }, []);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if(!parametros){
    return <Redirect to="/allivia/alliviaHome" />
  }

  console.log("Cuidador",cuidador)
  if(!cuidador){
    return <IonLoading isOpen message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Estado nutricional</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" >
          <div className='estado-nutricional'>
            <div className='header' >
              <div className='imagen'>
                <IonImg src='./assets/iconSituacionEmocional.png'/>
                <span>Nutrición</span>
              </div>
              <div className='titulo'>
                <h1>Plan de nutrición</h1>
              </div>
            </div>
            <div className="textos_intro">
                <p>
                  Como miembro premium tienes derecho a recibir un plan nutricional personalizado, llena el formulario y envíalo.
                </p>
                <p>
                  <span className="notanutricion">Nota:</span>
                  <ul>
                    <li>
                      Si envías el formulario los días lunes, martes o miércoles, recibirás tu plan alimenticio el siguiente sábado.
                  </li>
                  <li>
                      Si envías tus datos los días jueves, viernes, sábado o domingo, tú plan alimenticio será enviado el domingo de la siguiente semana.
                  </li>

                  </ul>

                </p>
            </div> 
            <div className='datos-generales'>
              <div className='dg-header'>
                  <span>Datos generales</span>
              </div>
              <div className='contenido'>
                <IonGrid>
                  <IonRow>
                    <IonCol size='6'>
                      <IonLabel>Nombre:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{cuidador.nombreCompleto}</IonText>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel>Edad:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{cuidador.edad}</IonText>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel>Sexo:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{cuidador.sexo}</IonText>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel>Peso:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{peso?peso.peso+" Kg":""}</IonText>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel>Estatura:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText>{estatura?estatura.estatura+" m":""}</IonText>
                    </IonCol>
                    <IonCol size='6'>
                      <IonLabel>Tipo de sangre:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                    <IonText>{cuidador.tipoSangre}</IonText>
                    </IonCol>                    
                  </IonRow>
                  <IonRow hidden={!cuidador.alergias}>
                    <IonCol size='6'>
                      <IonLabel>Alergias:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      {
                        cuidador.alergias?cuidador.alergias.map((alerg)=>
                          <>
                            <IonText key={alerg.idAlergia} >{alerg.alergia}</IonText><br/>
                          </>
                        ):<></>
                      }
                    </IonCol>
                  </IonRow>
                  <IonRow hidden={!cuidador.diagnosticos}>
                    <IonCol size='6'>
                      <IonLabel>Diagnósticos médicos:</IonLabel>
                    </IonCol>
                    <IonCol size='6'>
                      {
                        cuidador.diagnosticos?cuidador.diagnosticos.map((diag)=>
                          <>
                            <IonText key={diag.idDiagnostico} >{diag.descripcion}</IonText><br/>
                          </>
                        ):<></>
                      }
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              </div>
            <div className='contenido'>                            
                {cuestionario ? cuestionario.map((preg) =>
                  <>
                  <div className='cuestionario'>
                    <div className='pregunta'>
                      <span hidden={preg.numPregunta===1} className='categoria'>{preg.categoria}&nbsp;</span>                      
                      <span hidden={!(preg.numPregunta===1)} className='categoria'>Nivel de actividad física</span> 
                    </div>
                    <IonRadioGroup value={preg.valorRespuesta} onIonChange={(event) => { preg.valorRespuesta = event.detail.value; evaluarRespuestas() }}>
                      <div hidden={!(preg.numPregunta===1)} className='categoria-preg'>{preg.categoria}</div>
                      <div className='respuestas'>
                        <div className='resp-radio'>
                          <div className='rdio'>
                            <IonRadio mode="md"  value={0} />                          
                          </div>                          
                          <div className='descripcion'>
                            {JSON.parse(preg.pregunta).a1}
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <div className='rdio'>
                            <IonRadio mode="md"  value={1} />                          
                          </div>
                          <div className='descripcion'>
                          {JSON.parse(preg.pregunta).a2}
                          </div>
                        </div>
                        <div hidden={!JSON.parse(preg.pregunta).a3} className='resp-radio'>
                          <div className='rdio'>
                            <IonRadio mode="md"  value={2} />
                          </div>                          
                          <div className='descripcion'>
                          {JSON.parse(preg.pregunta).a3}
                          </div>
                        </div>
                        <div hidden={!JSON.parse(preg.pregunta).a4} className='resp-radio'>
                          <div className='rdio'>
                            <IonRadio mode="md"  value={3} />                          
                          </div>
                          <div className='descripcion'>
                            {JSON.parse(preg.pregunta).a4}
                          </div>
                        </div>                        
                      </div>
                    </IonRadioGroup>
                    </div>
                  </>
                ) : null
                }                              
            </div>            
          </div>
          <IonButton disabled={preguntaPendiente||testFinalizado} expand="block"
                  onClick={() => { guardarCuestionario() }}>Enviar datos al nutriólogo</IonButton>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        </IonContent>
      </IonPage>
    </>
  );
};

export default EstadoNutricional;
