import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonLabel,
  IonListHeader,
  IonList,
  IonImg,
  IonBackButton,
  IonLoading,
  IonButton,
  IonModal,
  IonInput,
  IonGrid,
  IonCol,
  IonRow,
  IonToast,
  IonFabList,
  IonRadioGroup,
  IonItem,
  IonRadio,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { addCircle, call, closeOutline, downloadOutline, pencil, trash, home, search, shareSocial } from 'ionicons/icons';
import { useLoginContext, useParametros, } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/tratamientos.css';
import { Redirect } from 'react-router';
import { DTODiagnostico, DTODocumento, DTOPaciente, DTOTratamiento } from '../services/AlliviaInterfaces';
import { eliminarTratamiento, guardarTratamiento, leerTratamientos } from '../services/tratamiento.service';
import { abrirArchivoBrowser, emergencyCall, esWeb, guardarDocumentos } from '../services/utils.service';
import { URL_ALLIVIA_DOCS } from '../properties';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { generarReporteTratamiento } from '../services/reportes.service';
import { Share } from '@capacitor/share';
// import Buscar from './Buscar';



const Tratamientos: React.FC = () => {
  const user = useLoginContext();
  const { parametros, setParametros } = useParametros();
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [modalAgregarTratamiento, setModalAgregarTratamiento] = useState(false);
  const [paciente, setPaciente] = useState<DTOPaciente | undefined>();
  const [tratamiento, setTratamiento] = useState<DTOTratamiento>({});
  const [listaTratamiento, setListaTratamiento] = useState<Array<DTOTratamiento>>();
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [modalEliminarTratamiento, setModalEliminarTratamiento] = useState(false);
  const [catDiagnosticos, setCatDiagnosticos] = useState<Array<DTODiagnostico>>([]);
  const [modalDiagnostico, setModalDiagnostico] = useState(false);
  const [selectDiagnostico,setSelectDiagnostico] = useState("");
  const [modalBusar,setModalBuscar] = useState(false);

  // const abrirBuscar = async()=>{
  //   setEstatus({ loading: true, leer: false });    
  //   setModalBuscar(true);
  //   setEstatus({ loading: false, leer: false });
  // }

  // const cerrarBuscar = async()=>{
  //   setEstatus({ loading: true, leer: false });    
  //   setModalBuscar(false);
  //   setEstatus({ loading: false, leer: false });
  // }

  const descargarTratamiento = async () => {
    setEstatus({ loading: true, leer: false });
    console.log("Iniciando descarga");
    let docu = await generarReporteTratamiento(user, paciente.idPersona,paciente.idCuidador);
    if(esWeb()){
      console.log(docu.fileStream);      
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='" +docu.fileStream+ "'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }
    setEstatus({loading:false,leer:false});
    await guardarDocumentos(docu).then(()=>{
      docu.urlDocumento = URL_ALLIVIA_DOCS + "/" + docu.urlDocumento;
      abrirArchivo(docu);
    }).catch((e)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un erro al descargar el reporte, por favor intenta de nuevo"});
    });
  }


  const compartirArchivo = async () => {
    setEstatus({ loading: true, leer: false });
    let docu = await generarReporteTratamiento(user, paciente.idPersona,paciente.idCuidador);
    console.log(docu.fileStream);

    // let fileTransfer: FileTransferObject = FileTransfer.create();
    //Se obtiene la Uri del dispositivo
    let uriSave = await Filesystem.getUri({
      path: URL_ALLIVIA_DOCS,
      directory: Directory.Documents
    }
    );
    console.log("save uri archivo uri:", uriSave);
    //Se descarga el archivo
    let existFile = false;    
    await guardarDocumentos(docu).then(()=>{
      existFile=true;
    }).catch((e)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un erro al descargar el reporte, por favor intenta de nuevo"});
    });
  

    let compartir = await Share.canShare();
    console.log("hola",compartir);
    if(existFile && compartir){      
      await Share.share({
        title: docu.descripcion,
        text: 'Compartir',
        url: "file://" + uriSave.uri + "/" + docu.urlDocumento,
        dialogTitle: 'Compartir',
      }).catch((error)=>{
        console.log(error);
      });
    }

    setEstatus({ loading: false, leer: false });
  }

  const abrirArchivo = async (doc: DTODocumento) => {
    setEstatus({ loading: true, leer: false });
    let uri = await Filesystem.getUri({
      path: doc.urlDocumento,
      directory: Directory.Documents
    }
    );
    console.log("abir archivo uri:", uri);
    FileOpener.showOpenWithDialog(uri.uri, doc.mimeType).then().catch((e) => {
      console.log("error", e);
      abrirArchivoBrowser(doc).catch((e) => {
        setMsgToast({ showToast: true, statusMessage: "El documento no se encuentra en este dispositivo" });
      });
    });
    setEstatus({ loading: false, leer: false });
  }

  const handleGuardarTratamiento = () => {
    setEstatus({ loading: true, leer: false });
    console.log("Guardar tratamiento: ", tratamiento);
    guardarTratamiento(user, tratamiento).then(() => {
      setMsgToast({ showToast: true, statusMessage: "El registro se guardo correctamente" });
      leerTratamientos(user, parametros.paciente.idPersona).then((ltratamientos) => {
        setListaTratamiento(ltratamientos);
      }).catch((error) => {
        setMsgToast({ showToast: true, statusMessage: "Ocurrió un error al tratar de eliminar el tratamiento, por favor intenta de nuevo" });
      }).finally(() => {
        setTratamiento({ idPersona: paciente.idPersona });
      });
      ;
    });
    setModalAgregarTratamiento(false);
    setEstatus({ loading: false, leer: false });
  }

  const handleEditarTratamiento = (tratam: DTOTratamiento) => {
    setEstatus({ loading: true, leer: false });
    console.log("Modificar tratamiento", tratamiento);
    setTratamiento(tratam);
    setModalAgregarTratamiento(true);
    setEstatus({ loading: false, leer: false });
  }

  const handleMostrarHandleEliminarTratamiento = (tratam: DTOTratamiento) => {
    setEstatus({ loading: true, leer: false });
    console.log("Modificar tratamiento", tratamiento);
    setTratamiento(tratam);
    setModalEliminarTratamiento(true);
    setEstatus({ loading: false, leer: false });
  }

  const handleEliminarTratamiento = async () => {
    setEstatus({ loading: true, leer: false });
    console.log("Modificar tratamiento", tratamiento);
    await eliminarTratamiento(user, tratamiento.idTratamiento).then(() => {
      setMsgToast({ showToast: true, statusMessage: "El tratamiento se elimino correctamente" });
    }).catch((error) => {
      setMsgToast({ showToast: true, statusMessage: "Ocurrió un error al eliminar el tratamiento, por favor intenta de nuevo" });
    });
    await leerDatosTratamiento();
    setTratamiento({});
    setModalEliminarTratamiento(false);
    setEstatus({ loading: false, leer: false });
  }

  const handleCancelarEliminarTratamiento = () => {
    setEstatus({ loading: true, leer: false });
    console.log("Modificar tratamiento", tratamiento);
    setTratamiento({});
    setModalEliminarTratamiento(false);
    setEstatus({ loading: false, leer: false });
  }

  const handleIrMedicamento = (tratam: DTOTratamiento) => {
    setParametros({ ...parametros, tratamiento: tratam });
  }

  const limpiarDatos = () => {
    setTratamiento({ idPersona: paciente.idPersona });
  }

  const actualizaTratamiento = (valor: any) => {
    setTratamiento({ ...tratamiento, ...valor });
  }


  const leerDatosTratamiento = async () => {
    setEstatus({ loading: true, leer: false });
    await leerTratamientos(user, parametros.paciente.idPersona).then((ltratamientos) => {
      setListaTratamiento(ltratamientos);
    });
    setEstatus({ loading: false, leer: false });
  }

  const openModalDiagnostico=async()=>{
    setEstatus({ loading: true, leer: false });
    setModalAgregarTratamiento(false);
    setModalDiagnostico(true);
    setSelectDiagnostico("");
    setEstatus({ loading: false, leer: false });
  }

  const cancelarDiagnostico=async()=>{
    setEstatus({ loading: true, leer: false });
    setModalAgregarTratamiento(true);
    setModalDiagnostico(false);
    setSelectDiagnostico("");
    setEstatus({ loading: false, leer: false });
  }

  const aceptarDiagnostico=async()=>{
    setEstatus({ loading: true, leer: false });
    setModalAgregarTratamiento(true);
    setModalDiagnostico(false);
    setTratamiento({...tratamiento,diagnostico:selectDiagnostico});
    setSelectDiagnostico("");
    setEstatus({ loading: false, leer: false });
  }


  useEffect(() => {
    console.log("Tratamientos inicio");
    if (parametros) {
      setEstatus({ loading: true, leer: false });
      if(parametros.paciente.diagnosticos){
        console.log(parametros.paciente.diagnosticos);
        setCatDiagnosticos(parametros.paciente.diagnosticos);
      }
      setPaciente(parametros.paciente);
      setTratamiento({ idPersona: parametros.paciente.idPersona });
      leerDatosTratamiento();
    }
    setEstatus({ loading: false, leer: false });
  }, [parametros]);

  useEffect(() => {
    if (parametros) {      
      leerDatosTratamiento();
    }
  }, []);


  console.log("valor paciente: ", paciente);

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  if (!paciente) {
    return (<IonLoading isOpen />);
  }

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle className="title_header">Tratamientos</IonTitle>
            <IonButtons slot='end'>
              <IonButton color='primary' onClick={() => { descargarTratamiento() }}>
                <IonIcon size='large' icon={downloadOutline} ></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="tratamientos">
          <IonList lines='none' className='pleca-tratamientos'>
            <IonListHeader>
               <div id="contenedor_datos">
                  <div className="icon_px">
                        <IonImg src="./assets/px.png"/>
                  </div>
                  <div className="datos_px">
                      <div id='title_paciente'>
                        <span className='header-nombre'>{paciente?paciente.nombreCompleto:null}</span>
                      </div>
                      <div id="title_parentesco">
                        <span className='header-parentesco'>{paciente?paciente.parentesco:null}</span>
                      </div>
                 </div>
              </div>
            </IonListHeader>
          </IonList>
          <div className='tratamiento-panel'>
            <div className='tratamiento-titulo' >
              <IonImg src='/assets/tratamientoPill.png' onClick={() => { setModalAgregarTratamiento(true); }} />
              <p>Tratamientos </p>
            </div>
            <IonGrid>
              <IonRow>
                {
                  listaTratamiento ? listaTratamiento.map((ltrat) => {
                    return <>
                      <IonCol size='4'>
                        <div className='pastilla-tratamiento'>
                          <div id="ver_tratamiento">
                            <IonButton fill='clear' routerLink='/allivia/medicamentos' onClick={(event) => { handleIrMedicamento(ltrat) }} >
                              <IonImg src="/assets/tratamientos.png">
                              </IonImg>
                              <p className="name_trat" key={ltrat.idTratamiento}>
                                {ltrat.diagnostico}
                              </p>
                            </IonButton>
                            <div id="acciones_tratamientos">
                              <div className="edit">
                                <IonButton fill='clear' onClick={() => handleEditarTratamiento(ltrat)}>
                                  <IonIcon color="rosa" icon={pencil}></IonIcon>
                                </IonButton>
                              </div>
                              <div className="trash">
                                <IonButton fill='clear' onClick={() => handleMostrarHandleEliminarTratamiento(ltrat)}>
                                  <IonIcon color="rosa" icon={trash}></IonIcon>
                                </IonButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </IonCol>
                    </>
                  }) : null
                }
              </IonRow>
            </IonGrid>
          </div>
          <IonFab hidden={esWeb()} className='allivia-emergencia-call' vertical='bottom' horizontal='center' edge slot='fixed'>
            <IonFabButton color="emergencia" routerLink='/my/alliviaHome/add'>
              <div>
                <IonIcon icon={call} />
                <IonLabel>911</IonLabel>
              </div>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton color="success" onClick={() => { emergencyCall(); }}><IonIcon icon={call} /></IonFabButton>
            </IonFabList>
          </IonFab>
          <IonModal backdropDismiss={false} isOpen={modalAgregarTratamiento} className="ion-modal-tratamiento" >
            <IonContent>
              <div className='header'>
                <IonImg src='./assets/estudiosAgregar.png'></IonImg>
                Agregar Tratamiento
                <IonButton fill='clear' onClick={() => { setModalAgregarTratamiento(false); limpiarDatos(); }}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/med1.png" />
                        <IonLabel>Diagnóstico médico (DX):</IonLabel>
                      </IonCol>
                      <IonCol className='diag-input' size='5' >
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={tratamiento.diagnostico}
                          onIonChange={(event) => { actualizaTratamiento({ diagnostico: event.detail.value }) }}   ></IonInput>
                      </IonCol>
                      <IonCol className='add-col' size='1' >
                        <IonButton className='add-diagnostico' fill='clear' onClick={openModalDiagnostico}>
                          <IonIcon icon={addCircle} ></IonIcon>
                        </IonButton>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/med2.png" />
                        <IonLabel>Médico:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={tratamiento.medico}
                          onIonChange={(event) => { actualizaTratamiento({ medico: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/med3.png" />
                        <IonLabel>Especialidad:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={tratamiento.especialidad}
                          onIonChange={(event) => { actualizaTratamiento({ especialidad: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/med4.png" />
                        <IonLabel>Teléfono del médico:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='tel' inputMode='tel' value={tratamiento.telefonoMedico}
                          onIonChange={(event) => { actualizaTratamiento({ telefonoMedico: event.detail.value }) }}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/med5.png" />
                        <IonLabel>Clínica u hospital:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={tratamiento.clinica}
                          onIonChange={(event) => { actualizaTratamiento({ clinica: event.detail.value }) }}></IonInput>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonButton className="guardar_orange" expand='block' onClick={() => { handleGuardarTratamiento() }} >Guardar
                    <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={modalEliminarTratamiento} className="ion-modal-eliminar-tratamiento" >
            <IonContent>
              <div className='header'>
                <IonImg src='./assets/estudiosAgregar.png'></IonImg>
                Eliminar tratamiento
                <IonButton fill='clear' onClick={() => { setModalEliminarTratamiento(false); limpiarDatos(); }}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='12' >
                        <IonLabel>¿Deseas eliminar el tratamiento médico?</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonButton onClick={() => { handleEliminarTratamiento() }} >Sí</IonButton>
                      </IonCol>
                      <IonCol size='6' >
                        <IonButton onClick={() => { handleCancelarEliminarTratamiento() }} >No</IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </div>
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={modalDiagnostico} className="ion-modal-diagnostico" >
            <IonContent>
              <div className='header'>
                <IonImg src='./assets/estudiosAgregar.png'></IonImg>
                Diagnosticos                
              </div>
              <div className='contenido'>
                <div className='panel-formulario'>
                  <IonList lines='none'>
                    <IonRadioGroup value={selectDiagnostico} onIonChange={event=>{setSelectDiagnostico(event.detail.value)}} >
                      {
                        catDiagnosticos.map((diag)=>
                          <IonItem>
                            <IonLabel key={diag.idDiagnostico}>{diag.descripcion}</IonLabel>
                            <IonRadio mode="md"  slot="start" value={diag.descripcion} />
                          </IonItem>
                        )
                      }                                          
                    </IonRadioGroup>
                  </IonList>
                </div>
              </div>
              <div>
                <IonGrid >
                  <IonRow >
                    <IonCol size='6' >
                      <IonButton expand='block' onClick={cancelarDiagnostico}>Cancelar</IonButton>
                    </IonCol>
                    <IonCol size='6' >
                      <IonButton disabled={selectDiagnostico===""} expand='block' onClick={aceptarDiagnostico}>Aceptar</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonContent>
          </IonModal>
          {/* <IonModal backdropDismiss={false} isOpen={modalBusar} className="ion-modal-buscar" >
            <Buscar/>
            <IonContent>                           
                <IonGrid >
                  <IonRow >
                    <IonCol size='6' >
                      <IonButton expand='block' onClick={cerrarBuscar}>Cancelar</IonButton>
                    </IonCol>                    
                  </IonRow>
                </IonGrid>
            </IonContent>
          </IonModal> */}
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        </IonContent>
        <div className='custom-tab-bar'>
          <IonButton fill='clear' routerLink='/'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' routerLink='/allivia/buscar'>
            <div>
              <IonIcon icon={search} />
              <IonLabel>Buscar</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={esWeb()} fill='clear' onClick={() => { compartirArchivo() }}>
            <div>
              <IonIcon icon={shareSocial} />
              <IonLabel>Compartir</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={!esWeb()} fill='clear' onClick={() => { descargarTratamiento() }}>
            <div>
              <IonIcon icon={downloadOutline} />
              <IonLabel>Descargar</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' onClick={() => { setModalAgregarTratamiento(true); }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Tratamiento</IonLabel>
            </div>
          </IonButton>
        </div>
      </IonPage>
    </>
  );
};

export default Tratamientos;
