import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonImg,
  IonInput,
  IonIcon,
  IonSearchbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext } from '../hooks/login';
import '../theme/metodoPago.css';
import { Redirect } from 'react-router';
import { search } from 'ionicons/icons';
import { DTOResultadoBusqueda } from '../services/AlliviaInterfaces';
import { funcionBuscar } from '../services/busqueda.service';



const Buscar: React.FC = () => {
  const user = useLoginContext();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [resultados,setResultados] = useState<Array<DTOResultadoBusqueda>>([]);
  const [query,setQuery]= useState("");
  
  useEffect(()=>{    
    setEstatus({loading:false,leer:false});
  },[]);

  const buscar=async(query:string)=>{
    if(!query || query===null || query===''){    
      setResultados([]);
    }else{
      setResultados(await funcionBuscar(query));
    }
    setQuery(query);
  }

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>            
            <IonTitle>Buscar</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className="buscar-padding">
          <IonSearchbar  value={query} onIonChange={(e)=>buscar(e.detail.value)} placeholder="Buscar"></IonSearchbar>
          <IonList>
            {
              resultados.map(result=>
                <>
                  <IonItem key={result.resultado} routerLink={result.url} >
                    <IonLabel>{result.resultado}</IonLabel>
                  </IonItem>
                </>
                
              )
            }           
          </IonList>                             
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
        </IonContent>        
      </IonPage>
    </>
  );
};

export default Buscar;
