import { SERVICE_ALLIVIA} from "../properties";
import { DTOSignoEstatura, DTOSignoGlucosa, DTOSignoOxigeno, DTOSignoPeso, DTOSignoPresionArterial, DTOSignoTemperatura, UsuarioLogin } from "./AlliviaInterfaces";


export const leerPesoById = async (usuario:UsuarioLogin,idPeso:number):Promise<DTOSignoPeso> =>{
  const url = SERVICE_ALLIVIA+"/peso/id/"+idPeso;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerPesoUltimo = async (usuario:UsuarioLogin,idPersona:number):Promise<DTOSignoPeso> =>{
  const url = SERVICE_ALLIVIA+"/peso/ultimo/"+idPersona;
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    const json = await response.json();
    throw Error("Error:"+json.message);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerPesos = async (usuario:UsuarioLogin,idPersona:number):Promise<Array<DTOSignoPeso>> =>{
  const url = SERVICE_ALLIVIA+"/peso/"+idPersona;
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    
    const json = await response.json();
    localStorage.setItem("speso",JSON.stringify(json));
    console.log(json); 
    return json;
  }catch(evt){
    console.log("error",evt);
    let sigPeso :Array<DTOSignoPeso>=[];
    if(localStorage.speso){
      sigPeso=JSON.parse(localStorage.speso);        
    }
    return sigPeso
  }
}

export const guardarPeso =async (usuario,peso:DTOSignoPeso):Promise<DTOSignoPeso> => {
  const url = SERVICE_ALLIVIA+"/peso";
  const response = await fetch(url,{
    body: JSON.stringify(peso),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el signo peso:"+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
  
}

export const leerEstaturaById = async (usuario:UsuarioLogin,idEstatura:number):Promise<DTOSignoEstatura> =>{
  const url = SERVICE_ALLIVIA+"/estatura/id/"+idEstatura;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerEstaturaUltima = async (usuario:UsuarioLogin,idPersona:number):Promise<DTOSignoEstatura> =>{
  const url = SERVICE_ALLIVIA+"/estatura/ultima/"+idPersona;
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    const json = await response.json();
    throw Error("Error:"+json.message);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerEstaturas = async (usuario:UsuarioLogin,idPersona:number):Promise<Array<DTOSignoEstatura>> =>{
  const url = SERVICE_ALLIVIA+"/estatura/"+idPersona;  
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
  
    const json = await response.json(); 
    localStorage.setItem("sestatura",JSON.stringify(json));
    return json;
  }catch(evt){
    console.log("error",evt);
    let sigEstatura :Array<DTOSignoEstatura>=[];
    if(localStorage.sestatura){
      sigEstatura=JSON.parse(localStorage.sestatura);        
    }
    return sigEstatura
  }
}

export const guardarEstatura =async (usuario,estatura:DTOSignoEstatura):Promise<DTOSignoEstatura> => {
  const url = SERVICE_ALLIVIA+"/estatura";
  const response = await fetch(url,{
    body: JSON.stringify(estatura),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el signo estatura:"+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerTemperaturaById = async (usuario:UsuarioLogin,idTemperatura:number):Promise<DTOSignoTemperatura> =>{
  const url = SERVICE_ALLIVIA+"/temperatura/id/"+idTemperatura;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerTemperatura = async (usuario:UsuarioLogin,idPersona:number):Promise<Array<DTOSignoTemperatura>> =>{
  const url = SERVICE_ALLIVIA+"/temperatura/"+idPersona;
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("stemperatura",JSON.stringify(json));
    console.log(json);    
    return json;
  }catch(evt){
    console.log("error",evt);
    let sigTemperatura :Array<DTOSignoTemperatura>=[];
    if(localStorage.stemperatura){
      sigTemperatura=JSON.parse(localStorage.stemperatura);        
    }
    return sigTemperatura
  }
}

export const guardarTemperatura =async (usuario,temperatura:DTOSignoTemperatura):Promise<DTOSignoTemperatura> => {
  const url = SERVICE_ALLIVIA+"/temperatura";
  const response = await fetch(url,{
    body: JSON.stringify(temperatura),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el signo temperatura:"+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerOxigenoById = async (usuario:UsuarioLogin,idOxigeno:number):Promise<DTOSignoOxigeno> =>{
  const url = SERVICE_ALLIVIA+"/oxigeno/id/"+idOxigeno;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerOxigenoss = async (usuario:UsuarioLogin,idPersona:number):Promise<Array<DTOSignoOxigeno>> =>{
  const url = SERVICE_ALLIVIA+"/oxigeno/"+idPersona; 
  try{ 
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("soxigeno",JSON.stringify(json));
    console.log(json);    
    return json;
  }catch(evt){
    console.log("error",evt);
    let sigOxigen :Array<DTOSignoOxigeno>=[];
    if(localStorage.soxigeno){
      sigOxigen=JSON.parse(localStorage.soxigeno);        
    }
    return sigOxigen
  }
}

export const guardarOxigeno =async (usuario,oxigeno:DTOSignoOxigeno):Promise<DTOSignoOxigeno> => {
  const url = SERVICE_ALLIVIA+"/oxigeno";
  const response = await fetch(url,{
    body: JSON.stringify(oxigeno),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el signo oxigeno:"+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerGlucosaById = async (usuario:UsuarioLogin,idGlucosa:number):Promise<DTOSignoGlucosa> =>{
  const url = SERVICE_ALLIVIA+"/glucosa/id/"+idGlucosa;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerGlucosas = async (usuario:UsuarioLogin,idPersona:number):Promise<Array<DTOSignoGlucosa>> =>{
  const url = SERVICE_ALLIVIA+"/glucosa/"+idPersona;
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("sglucosa",JSON.stringify(json));
    console.log(json);    
    return json;
  }catch(evt){
    console.log("error",evt);
    let sigGlucosa :Array<DTOSignoGlucosa>=[];
    if(localStorage.sglucosa){
      sigGlucosa=JSON.parse(localStorage.sglucosa);        
    }
    return sigGlucosa
  }
}

export const guardarGlucosa =async (usuario,peso:DTOSignoGlucosa):Promise<DTOSignoGlucosa> => {
  const url = SERVICE_ALLIVIA+"/glucosa";
  const response = await fetch(url,{
    body: JSON.stringify(peso),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el signo glucosa:"+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const leerPresionArterialById = async (usuario:UsuarioLogin,idPresion:number):Promise<DTOSignoPresionArterial> =>{
  const url = SERVICE_ALLIVIA+"/presionArterial/id/"+idPresion;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerPresionArterials = async (usuario:UsuarioLogin,idPersona:number):Promise<Array<DTOSignoPresionArterial>> =>{
  const url = SERVICE_ALLIVIA+"/presionArterial/"+idPersona;  
  try{
    const response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    localStorage.setItem("spresion",JSON.stringify(json));
    console.log(json);    
    return json;
  }catch(evt){
    console.log("error",evt);
    let sigPresion :Array<DTOSignoPresionArterial>=[];
    if(localStorage.spresion){
      sigPresion=JSON.parse(localStorage.spresion);        
    }
    return sigPresion
  }
}

export const guardarPresionArterial =async (usuario,presionArterial:DTOSignoPresionArterial):Promise<DTOSignoPresionArterial> => {
  const url = SERVICE_ALLIVIA+"/presionArterial";
  const response = await fetch(url,{
    body: JSON.stringify(presionArterial),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el signo presionArterial:"+response.statusText);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}