import { SERVICE_ALLIVIA} from "../properties";
import { DTOHidratacion, UsuarioLogin } from "./AlliviaInterfaces";




export const leerHidratacionHoy = async (usuario:UsuarioLogin,idCuidador:number):Promise<DTOHidratacion> =>{
  const url = SERVICE_ALLIVIA+"/hidratacion/hoy/"+idCuidador;
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    const json = await response.json();
    throw Error("Error:"+json);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}


export const guardarHidratacion =async (usuario,hidratacion:DTOHidratacion):Promise<DTOHidratacion> => {
  const url = SERVICE_ALLIVIA+"/hidratacion";
  const response = await fetch(url,{
    body: JSON.stringify(hidratacion),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){    
    //const json = 
    await response.json();
    throw Error("Error al guardar hidratación:");
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
  
}
