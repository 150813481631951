import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router';
import { useLoginContext } from '../hooks/login';
import '../theme/derechosCuidador.css';



const Configuracion: React.FC = () => {
  const { loggedIn } = useLoginContext(); 

  //console.log(`rendering from loginPage with loggedIn=${loggedIn}`);
  if (!loggedIn) {
    return <Redirect to="/allivia/alliviaHome" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton text=""  color='primary' defaultHref="/"/>
          </IonButtons>
          <IonTitle>Configuración</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">        
      </IonContent>
    </IonPage>
  );
};

export default Configuracion;
