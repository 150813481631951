import { SERVICE_ALLIVIA} from "../properties";
import { DTOActualizarPassword, UsuarioLogin } from "./AlliviaInterfaces";


interface DTOUsuario{
  idUsuario ?: number;
  nombre: string;
  apPaterno: string;
  apMaterno: string;
  email: string;
  password: string;
}

export const registrarUsuario= async (usuario: DTOUsuario) =>{
  const url = SERVICE_ALLIVIA+"/registrarUsuario";
  //const data = { username: username, password: password};
  const response = await fetch(url,{
    body: JSON.stringify(usuario),
    headers:{
      "Content-Type":"application/json",
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error 1"+response.statusText);
  }
  const json = await response.json();    
  return json;
}

export const leerPerfilUsuario= async (usuario:UsuarioLogin)=>{
  try{
    let us={email:usuario.username}
    const url = SERVICE_ALLIVIA+"/usuario/perfil";
    const response = await fetch(url,{
      body: JSON.stringify(us),
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "POST"
    });
    if(!response.ok){
      throw Error("error al guardar el tratamiento: "+response.statusText);
    }
    const json = await response.json();
    localStorage.setItem("perfilUsuario",JSON.stringify(json))
    return json;
  }catch(err){
    console.log("error",err);
    let temPerfil :UsuarioLogin;
    if(localStorage.perfilUsuario){
      temPerfil=JSON.parse(localStorage.perfilUsuario);        
    }
    return temPerfil
  }
}

export const generarCodigoVerificacion= async (usuario:UsuarioLogin)=>{
  let us={username:usuario.username}
  const url = SERVICE_ALLIVIA+"/generaCodigoSeguridad";
  const response = await fetch(url,{
    body: JSON.stringify(us),
    headers:{
      "Content-Type":"application/json",      
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error al guardar el tratamiento: "+response.statusText);
  }
  const json = await response.json();    
  return json;
}

export const restablecerPassword= async (reset:DTOActualizarPassword)=>{  
  const url = SERVICE_ALLIVIA+"/restablecerPassword";
  const response = await fetch(url,{
    body: JSON.stringify(reset),
    headers:{
      "Content-Type":"application/json",      
    },
    method: "PUT"
  });  
  if(!response.ok){
    const error = await response.json();
    if(error.codigoError!=null){
      throw Error(error.message);
    }else{
      throw Error("Error al actualizar la contraseña: "+response.statusText);
    }
  }
  const json = await response.json();    
  return json.response;
}