import { SERVICE_ALLIVIA} from "../properties";
import {DTODocumento, UsuarioLogin } from "./AlliviaInterfaces";


export const guardarDocumentosGluster = async (usuario:UsuarioLogin,documento:DTODocumento):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/gluster";
  const response = await fetch(url,{
    body: JSON.stringify(documento),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    let err = await response.json();
    console.log(err);
    throw Error("Error "+err.message);
  }  
  const json = await response.json();  
  return json;
}

export const leerDocumentosGluster = async (usuario:UsuarioLogin,documento:DTODocumento):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/gluster/"+documento.idDocumento;
  const response = await fetch(url,{
    
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){
    let err = await response.json();
    console.log(err);
    throw Error("Error "+err.message);
  }  
  const json = await response.json();  
  return json;
}

export const eliminarDocumentosGluster = async (usuario:UsuarioLogin,documento:DTODocumento):Promise<DTODocumento> =>{
  const url = SERVICE_ALLIVIA+"/gluster/"+documento.idDocumento;
  const response = await fetch(url,{
    
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "DELETE"
  });
  if(!response.ok){
    let err = await response.json();
    console.log(err);
    throw Error("Error "+err.message);
  }  
  const json = await response.json();  
  return json;
}



