import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonImg,
  IonAvatar,
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonCheckbox,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/accesoCompartido.css';
import { Redirect } from 'react-router';
import { DTOCuidador, DTOPaciente, DTOSolicitudCompartir } from '../services/AlliviaInterfaces';
import { enviarInvitacionAllivia, leerCuidador, leerCuidadorCompartir, leerCuidadorInvitado } from '../services/cuidador.service';
import { closeOutline } from 'ionicons/icons';
import { EMAIL_VALID } from '../properties';
import { leerPacientesCompartidos, leerPacientesPropietario, solicitaCompartirPaciente, solicitudEliminarPacienteCompartido } from '../services/paciente.service';
import { MAXIMO_COMPARTIR } from '../constantes';



const AccesoCompartido: React.FC = () => {
  const user = useLoginContext();
  const { parametros } = useParametros();
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [cuidador, setCuidador] = useState<DTOCuidador | undefined>(undefined);
  const [cuidadorSeleccionado, setCuidadorSeleccionado] = useState<DTOCuidador | undefined>(undefined);
  const [cuidadorInvitado, setCuidadorInvitado] = useState<Array<DTOCuidador> | undefined>([]);
  const [pacientesCompartidos, setPacientesCompartidos] = useState<Array<DTOPaciente> | undefined>([]);
  const [pacientesPropietario, setPacientesPropietario] = useState<Array<DTOPaciente> | undefined>([]);
  const [cuidadorCompartir, setCuidadorCompartir] = useState<DTOCuidador | undefined>(undefined);
  const [verInvitar, setVerInvitar] = useState(0);
  const [verModalCorreo, setVerModalCorreo] = useState(false);
  const [verModalConfirmacion, setVerModalConfirmacion] = useState(false);
  const [verModalSeleccionarPaciente, setVerModalSeleccionarPaciente] = useState(false);
  const [correo, setCorreo] = useState<string>();
  const [nombre, setNombre] = useState<string>();
  const [tienePacientesCompartidos, setTienePacientesCompartidos] = useState(false);
  const [auxiliar, setAuxiliar] = useState("");
  const [verModalAccesoPacientes, setVerModalAccesoPacientes] = useState(false);
  const [esPremiumDuo, setEsPremiumDuo] = useState(false);
  const [totalCompartido,setTotalCompartido] = useState(0);
  const [totalSeleccionado,setTotalSeleccionado] = useState(0);
  


  const leerPacPropietario = async (cuidadorSelec: DTOCuidador) => {
    let idCuidadorCompartido = cuidadorSelec.idCuidador
    setEstatus({ loading: true, leer: true });
    await leerPacientesPropietario(cuidador.idCuidador, idCuidadorCompartido, user).then((invit) => {
      setPacientesPropietario(invit);
    }
    );
    //solo es la confirmación del envió del paciente
    setCuidadorSeleccionado(cuidadorSelec);
    setVerModalAccesoPacientes(true);
    setEstatus({ loading: false, leer: true });
  }

  const leerPacCompartidos = async () => {
    setEstatus({ loading: true, leer: true });
    await leerPacientesCompartidos(cuidador.idCuidador, user).then((invit) => {
      setPacientesCompartidos(invit);
    }
    );
    //solo es la confirmación del envió del paciente
    setVerModalConfirmacion(false);
    setEstatus({ loading: false, leer: true });
  }

  const modalPasoUno = async () => {
    // Valida formato correo
    let regEmail = EMAIL_VALID.test(correo);
    if (!regEmail) {
      setMsgToast({ showToast: true, statusMessage: "Formato de correo electrónico incorrecto" });
      return;
    }
    // valida que el correo no sea el mismo del usuario actual
    if (cuidador.email === correo) {
      setMsgToast({ showToast: true, statusMessage: "El correo no puede ser el mismo que el tuyo" });
      return;
    }

    if(!nombre || nombre === null || nombre ===''){
      setMsgToast({ showToast: true, statusMessage: "Ingresa un nombre" });
      return;
    }

    //elimina espacios de correo y del nombre
    

    // Valida existe paciente
    let cuidCompartir = await leerCuidadorCompartir(correo, user);
    if (!cuidCompartir) {
      setMsgToast({ showToast: true, statusMessage: "El usuario " + nombre.trim() + " aun no tiene cuenta en allivia, se ha enviado una invitación por correo" });
      await enviarInvitacionAllivia(cuidador.idCuidador,correo,nombre.trim(),user).catch((error)=>{
        console.log(error);
      });
      return;
    }
    // Valida si es paciente premium o premiumDouo.
    if (!(cuidCompartir.premium || cuidCompartir.premiumDuo)) {
      setMsgToast({ showToast: true, statusMessage: "El usuario " + nombre.trim() + " no tiene un perfil allivia premium" });
      return;
    }
    setCuidadorCompartir(cuidCompartir);
    //Cierra modal correo
    setVerModalCorreo(false);
    //Abre modal selección de pacientes
    setVerModalSeleccionarPaciente(true);
  }

  const eliminarPacientesCompartidos = async () => {
    setEstatus({ loading: true, leer: true });
    let solicitudEliminarPacientes: Array<number> = [];
    pacientesPropietario.forEach((pac) => {
      if (pac.pacienteSeleccionado) {
        solicitudEliminarPacientes.push(pac.idPaciente);
      }
    }
    );
    if (solicitudEliminarPacientes.length > 0) {
      await solicitudEliminarPacienteCompartido(solicitudEliminarPacientes, user)
        .then(() => {
          setMsgToast({ showToast: true, statusMessage: "Se ha revocado la invitación de tu paciente" });
        })
        .catch((e) => {
          setMsgToast({ showToast: true, statusMessage: "Ocurrió un erro al quitar los permisos, por favor intente de nuevo" });
        });
    }
    await leerCuidadorInvitado(cuidador.idCuidador, user).then((invit) => {
      setCuidadorInvitado(invit);
    }
    );
    await leerCuidador(user.username, user).then((cuidtem) => {
      contarPacientes(cuidtem);
      setCuidador(cuidtem);
    })
    await leerPacientesPropietario(cuidador.idCuidador, cuidadorSeleccionado.idCuidador, user).then((invit) => {
      setPacientesPropietario(invit);
    }
    );
    setEstatus({ loading: false, leer: true });
  }

  const modalPasoDos = async () => {
    setEstatus({ loading: true, leer: true });
    //valida seleccion mayor a 1 
    if (!validaSeleccion()) {
      setMsgToast({ showToast: true, statusMessage: "Debes seleccionar por lo menos un paciente para compartir" });
      setEstatus({ loading: false, leer: true });
      return;
    }
    let pacientesSeleccionados: Array<number> = [];
    cuidador.pacientes.forEach((pac) => {
      if (pac.pacienteSeleccionado) {
        pacientesSeleccionados.push(pac.idPaciente);
      }
    });
    let solicitudCompartir: DTOSolicitudCompartir = {
      idCuidadorCompartido: cuidadorCompartir.idCuidador,
      idCuidadorPropietario: cuidador.idCuidador,
      permisoCompartir: true,
      aceptaPaciente: 0,
      pacientes: pacientesSeleccionados
    }
    try {
      let solicitudEnvio = await solicitaCompartirPaciente(user, solicitudCompartir);
      setMsgToast({ showToast: true, statusMessage: "La solicitud de compartir paciente se creo correctamente" });
    } catch (error) {
      setMsgToast({ showToast: true, statusMessage: "Ocurrió un error al compartir el paciente, por favor intenta de nuevo" });
    }
    await leerCuidador(user.username, user).then((cuidtem) => {
      contarPacientes(cuidtem);
      setCuidador(cuidtem);
    })
    setVerModalSeleccionarPaciente(false);
    setVerModalConfirmacion(true);
    setEstatus({ loading: false, leer: true });
  }

  const modalPasoTres = async () => {
    setEstatus({ loading: true, leer: true });
    await leerCuidadorInvitado(cuidador.idCuidador, user).then((invit) => {
      setCuidadorInvitado(invit);
    }
    );
    //solo es la confirmación del envió del paciente
    setVerModalConfirmacion(false);
    setEstatus({ loading: false, leer: true });
  }

  const cancelarCompartirPaciente = async () => {
    setEstatus({ loading: true, leer: true });
    setVerModalConfirmacion(false);
    setVerModalCorreo(false);
    setVerModalSeleccionarPaciente(false);
    await leerCuidador(user.username, user).then((valor) => {
      setCuidador(valor);
    }).finally(() => {
      setEstatus({ loading: false, leer: true })
    });
  }

  const cancelarAccesoPaciente = async () => {
    setEstatus({ loading: true, leer: true });
    setVerModalAccesoPacientes(false)
    await leerCuidador(user.username, user).then((valor) => {
      setCuidador(valor);
    }).finally(() => {
      setEstatus({ loading: false, leer: true })
    });
  }

  const validaSeleccion = (): boolean => {
    let cuentaSeleccionados = 0;
    if (cuidador && cuidador.pacientes) {
      cuidador.pacientes.forEach((pac) => {
        if (pac.pacienteSeleccionado) {
          cuentaSeleccionados++;
        }
      });
    }
    return cuentaSeleccionados > 0;
  }

  const validaSeleccionPacientesPropietario = (): boolean => {
    let cuentaSeleccionados = 0;
    if (pacientesPropietario) {
      pacientesPropietario.forEach((pac) => {
        if (pac.pacienteSeleccionado) {
          cuentaSeleccionados++;
        }
      });
    }
    return cuentaSeleccionados > 0;
  }

  const agregarMasPcientesAInvitado =async(cuidComptir:DTOCuidador)=>{
    setEstatus({ loading: true, leer: true });
    cuidComptir = await leerCuidadorCompartir(cuidComptir.email, user);
    setCuidadorCompartir(cuidComptir);
    setVerModalAccesoPacientes(false)    
    //Abre modal selección de pacientes
    setVerModalSeleccionarPaciente(true);
    setEstatus({ loading: false, leer: true });
  }

  const contarPacientes =(valor :DTOCuidador)=>{
    let count = 0;
      valor.pacientes.forEach((pcit) => {
        if (pcit.compartido) {
          count = count + 1;
        }
      });
      setTotalCompartido(count);
      setTotalSeleccionado(count);
      setTienePacientesCompartidos(count > 0);
  }

  const leerDatos = async () => {
    setEstatus({ loading: true, leer: true });
    if (parametros != null) {
      let valor = await leerCuidador(user.username, user);
      let cuidinv = await leerCuidadorInvitado(valor.idCuidador, user)
      contarPacientes(valor);      
      setEsPremiumDuo(parametros.premiumDuo);      
      setCuidadorInvitado(cuidinv);
      setCuidador(valor);
    }
    setEstatus({ loading: false, leer: true });
  }

  const contarPacientesCompartidos=async()=>{
    let contar=0;
    cuidador.pacientes.forEach((pac)=>{

      if(pac.pacienteSeleccionado||pac.compartido){
        contar++;
      }
    });
    setTotalSeleccionado(contar);
  }

  useEffect(() => {
    leerDatos();
  }, []);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Acceso Compartido</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div hidden={verInvitar != 0}>
            <div className='acceso-compartido'>
              <div hidden={cuidadorInvitado.length > 0 || esPremiumDuo} className='mensaje-compartir'>
                <h1>AÚN NO HAS INVITADO A NADIE</h1>
                <p>Las personas que designes como invitados podrán visualizar a tu(s) paciente(s) y editar datos a través de la aplicación
                  <span className='allivia'> allivia</span><span className='premium'> PREMIUM</span></p>
              </div>
            </div>
            <IonList id="invitaciones" hidden={cuidadorInvitado.length === 0} className='lista-invitados' lines="none">
              <p>Haz invitado a:</p>
              {cuidadorInvitado.map((cuid) =>
                <>
                  <IonItem onClick={() => { leerPacPropietario(cuid) }} >
                    <IonImg src={!cuid.avatar ? "./assets/ac.png" : cuid.avatar.fileStream}></IonImg>
                    <div className='paciente'>
                      <div className='paciente-datos'>
                        <div className='nombre-completo'>
                          <IonLabel>{cuid.nombreCompleto}</IonLabel>
                        </div>
                        <div className='paciente-pacientes'>
                          <IonLabel>{cuid.numeroPacientesCompartidos}</IonLabel>
                        </div>
                        <div className='paciente-aceptar'>
                          <IonLabel>{cuid.pendienteAceptar ? "Pendiente de aceptar" : ""}</IonLabel>
                        </div>
                      </div>
                    </div>
                  </IonItem>
                </>
              )
              }
            </IonList>
            <IonList hidden={!(tienePacientesCompartidos || esPremiumDuo)} className='lista-mispacientes' lines="none">
              <IonTitle className="title_contigo">Compartieron contigo a:</IonTitle>
              {cuidador ? cuidador.pacientes.map((pac) =>
                pac.idCuidadorPropietario != null ?
                  <>
                    <IonItem >
                      <IonAvatar>
                        <IonImg src={!pac.avatar ? "./assets/pacienteAvatar.png" : pac.avatar.fileStream}></IonImg>
                      </IonAvatar>
                      <div className='paciente'>
                        <div className='paciente-datos'>
                          <div className='nombre-completo'>
                            <IonLabel>{pac.nombreCompleto} |</IonLabel>
                          </div>
                          <div className='edad'>
                            <IonLabel> {pac.edad}</IonLabel>
                          </div>
                        </div>
                        <div className='parentesco'>
                          <IonLabel>{pac.parentesco}</IonLabel>
                        </div>
                      </div>
                    </IonItem>
                  </>
                  : null
              ) : null
              }
            </IonList>
            <div id="invitar_mas">
              <IonButton hidden={esPremiumDuo} disabled={totalCompartido>=MAXIMO_COMPARTIR} expand='block' onClick={() => setVerModalCorreo(true)}>Invitar
              </IonButton>
            </div>

          </div>

          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
          <IonModal backdropDismiss={false} isOpen={verModalCorreo} className="ion-modal-correo" >
            <IonContent>
              <div className='contenido'>
                <IonGrid>
                  <IonRow>
                    <IonCol size='10'>
                      <h4>Correo de invitación</h4>
                    </IonCol>
                    <IonCol size='2'>
                      <IonButton fill='clear' onClick={() => { cancelarCompartirPaciente() }}>
                        <IonIcon icon={closeOutline}  ></IonIcon>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow>
                    <IonCol size='12'>
                      <p>Ingresa el nombre y correo electrónico de tu invitado.</p>
                      <p>
                        <span className="naranjanew">
                          IMPORTANTE: 
                        </span> Si tu invitado ya está registrado en la app allivia o es miembro PREMIUM, ingresa el correo asociado a su cuenta.</p>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid >
                  <IonRow>
                    <IonCol className="fila_invitacion">
                      <IonLabel position="stacked">Nombre(s)*:</IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonInput  type='text' inputMode='text' autocapitalize="words" value={nombre}
                        onIonChange={(event) => setNombre(event.detail.value)} ></IonInput>
                    </IonCol>
                    <IonCol className="fila_invitacion" >
                      <IonLabel position="stacked">Correo electrónico:*</IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonInput type='email' inputMode='email' value={correo} autoCapitalize="off"
                        onIonChange={(event) => setCorreo(event.detail.value)}></IonInput>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonButton expand='block' disabled={!nombre || !correo}
                  onClick={() => { modalPasoUno() }} >Continuar</IonButton>
              </div>
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={verModalSeleccionarPaciente} className="ion-modal-compartir-paciente" >
            <IonContent>
              <div className='contenido'>
                <IonGrid>
                  <IonRow>
                    <IonCol size='10'>
                      <h4>Compartir paciente</h4>
                    </IonCol>
                    <IonCol size='2'>
                      <IonButton fill='clear' onClick={() => { cancelarCompartirPaciente(); }}>
                        <IonIcon icon={closeOutline}  ></IonIcon>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow>
                    <IonCol size='12'>
                      <p>Darás acceso a tu invitado, recuerda que podrá ver la información personal, editar datos de registro como: citas médicas, signos vitales y estudios clínicos. </p>
                      <p>
                        <span className="recuerda">Recuerda: </span>
                        Cada paciente se puede compartir una sola vez.
                      </p>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                {cuidador ? cuidador.pacientes.map((pac) =>
                  !pac.compartido && pac.idCuidadorPropietario==null?
                  <>
                    <div className='card-paciente'>
                      <div className='avatar'>
                        <div className='izq'>
                          <IonAvatar>
                            <IonImg src={!pac.avatar ? "./assets/pacienteAvatar.png" : pac.avatar.fileStream}></IonImg>
                          </IonAvatar>
                        </div>
                        <div className='der'>
                          <IonCheckbox disabled={totalSeleccionado>=MAXIMO_COMPARTIR && !pac.pacienteSeleccionado} checked={pac.pacienteSeleccionado} onIonChange={(event) => { pac.pacienteSeleccionado = event.detail.checked; contarPacientesCompartidos() }}></IonCheckbox>
                        </div>
                      </div>
                      <div className='paciente'>
                        <div className='paciente-datos'>
                          <div className='nombre-completo'>
                            <IonLabel>{pac.nombreCompleto} |</IonLabel>
                          </div>
                          <div className='edad'>
                            <IonLabel> {pac.edad}&nbsp;&nbsp;</IonLabel>
                          </div>
                        </div>
                        <div className='parentesco'>
                          <IonLabel>{pac.parentesco}</IonLabel>
                        </div>
                      </div>
                    </div>
                  </>:<></>
                ) : null

                }
                <IonButton expand='block' disabled={!validaSeleccion()}
                  onClick={() => { modalPasoDos() }} >Aceptar {validaSeleccion()}</IonButton>
              </div>
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={verModalConfirmacion} className="ion-modal-confirmacion" >
            <IonContent>
              <div className='contenido'>
                <IonGrid>
                  <IonRow className='titulo'>
                    <IonCol size='12'>
                      <IonImg src="./assets/emailIcon.png"></IonImg>
                    </IonCol>
                    <IonCol size='10'>
                      <h4>Invitación Enviada</h4>
                    </IonCol>
                    <IonCol size='2'>
                      <IonButton fill='clear' onClick={() => { cancelarCompartirPaciente(); }}>
                        <IonIcon icon={closeOutline}  ></IonIcon>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow>
                    <IonCol size='12'>
                      <p>¡Listo! Tu invitación ha sido enviada a
                        <b> {correo}</b>, en breve recibirá un correo para
                        poder visualizar a tu(s) paciente(s).</p>
                      <p>Podrás agregar y eliminar invitados cuándo lo desees.</p>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonButton expand='block' onClick={() => { modalPasoTres() }} >Aceptar</IonButton>
              </div>
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={verModalAccesoPacientes} className="ion-modal-acceso-paciente" >
            <IonContent>
              <div className='contenido'>
                <IonGrid>
                  <IonRow>
                    <IonCol size='10'>
                      <h4>Acceso a pacientes</h4>
                    </IonCol>
                    <IonCol size='2'>
                      <IonButton fill='clear' onClick={() => { cancelarAccesoPaciente(); }}>
                        <IonIcon icon={closeOutline}  ></IonIcon>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonList className='lista-invitados' lines="none">
                  <IonItem >
                    <IonImg src={cuidadorSeleccionado && !cuidadorSeleccionado.avatar ? "./assets/ac.png" : cuidadorSeleccionado ? cuidadorSeleccionado.avatar.fileStream : ""}></IonImg>
                    <div className='paciente'>
                      <div className='paciente-datos'>
                        <div className='nombre-completo'>
                          <IonLabel>{cuidadorSeleccionado ? cuidadorSeleccionado.nombreCompleto : ""}</IonLabel>
                        </div>
                        <div className='paciente-pacientes'>
                          <IonLabel>{cuidadorSeleccionado ? cuidadorSeleccionado.numeroPacientesCompartidos : ""}</IonLabel>
                        </div>
                        <div className='paciente-aceptar'>
                          <IonLabel>{cuidadorSeleccionado && cuidadorSeleccionado.pendienteAceptar ? "Pendiente de aceptar" : ""}</IonLabel>
                        </div>
                      </div>
                    </div>
                  </IonItem>
                </IonList>
                {pacientesPropietario ? pacientesPropietario.map((pac) =>
                  <>
                    <div className='card-paciente'>
                      <div className='avatar'>
                        <div className='izq'>
                          <IonAvatar>
                            <IonImg src={!pac.avatar ? "./assets/pacienteAvatar.png" : pac.avatar.fileStream}></IonImg>
                          </IonAvatar>
                        </div>
                        <div className='der'>
                          <IonCheckbox checked={pac.pacienteSeleccionado} onIonChange={(event) => { pac.pacienteSeleccionado = event.detail.checked; setAuxiliar(pac.idPaciente + "" + event.detail.checked) }}></IonCheckbox>
                        </div>
                      </div>
                      <div className='paciente'>
                        <div className='paciente-datos'>
                          <div className='nombre-completo'>
                            <IonLabel>{pac.nombreCompleto} |</IonLabel>
                          </div>
                          <div className='edad'>
                            <IonLabel> {pac.edad}&nbsp;&nbsp;</IonLabel>
                          </div>
                          <div className='estatus-invitacion'>
                            <IonLabel>{pac.aceptaPaciente? "Aceptado" : "Pendiente"}</IonLabel>
                          </div>
                        </div>
                        <div className='parentesco'>
                          <IonLabel>{pac.parentesco}</IonLabel>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null

                }                
                <IonButton expand='block' disabled={!validaSeleccionPacientesPropietario()}
                  onClick={() => { eliminarPacientesCompartidos(); }} >Eliminar paciente Invitado</IonButton>
                <IonButton hidden={esPremiumDuo} disabled={totalCompartido>MAXIMO_COMPARTIR} expand='block' onClick={() => agregarMasPcientesAInvitado(cuidadorSeleccionado)}>Invitar
              </IonButton>
              </div>
            </IonContent>
          </IonModal>
        </IonContent>
      </IonPage>
    </>
  );
};

export default AccesoCompartido;
