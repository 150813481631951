import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router';
import { useLoginContext } from '../hooks/login';
import '../theme/sugerenciascontacto.css';



const Contacto: React.FC = () => {
  const { loggedIn } = useLoginContext(); 

  //console.log(`rendering from loginPage with loggedIn=${loggedIn}`);
  if (!loggedIn) {
    return <Redirect to="/allivia/alliviaHome" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton text=""  color='primary' defaultHref="/"/>
          </IonButtons>
          <IonTitle>Contacto y Soporte Técnico</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">     
       <div id="contenedor_sugerencias">
              <div id="titulo">
                  <div className="titular">
                    <div id="icon">
                      <img src="./assets/contacto.png" />
                    </div>
                    <div id="txtsuger">
                     <h2>Contacto y Soporte Técnico</h2>
                    </div>
                  </div>
              </div> 
              <div id="datos_cont">
                  <div className="contenedor_iconos">
                    <div className="iconos">
                      <span className="icon-iconos-03">
                      </span>
                    </div>
                    <div className="txt">
                      <a href="tel:5564845014">
                        556484 . 5014
                      </a>
                    </div>
                  </div>
                  <div className="contenedor_iconos">
                     <div className="iconos">
                         <span className="icon-iconos-04"></span>
                     </div>
                     <div className="txt">
                         <a href="https://wa.link/faaxi6" target="_blank">
                          556484 . 5014
                        </a>
                     </div>
                  </div>
                  <div className="contenedor_iconos">
                     <div className="iconos">
                         <span className="icon-iconos-01"></span>
                     </div>
                     <div className="txt">
                         <a href="https://www.facebook.com/Allivia-108566888375631" target="_blank">
                        https://www.facebook.com/Allivia
                      </a>
                     </div>
                  </div>
                 <div className="contenedor_iconos">
                    <div className="iconos">
                      <span className="icon-iconos-05"></span>
                    </div>
                    <div className="txt">
                         <a href="https://www.instagram.com/allivia.mx/" target={"_blank"}>
                         https://www.instagram.com/allivia
                         </a>
                     </div>
                 </div>
                 <div className="contenedor_iconos">
                    <div className="iconos">
                        <span className="icon-iconos-06"><span className="path1"></span><span className="path2"></span></span>
                    </div>
                     <div className="txt">
                         <a href="https://www.youtube.com/channel/UC9kqh8TIe6vbObe_r7ZMlHg" target={"_blank"}>
                     https://www.youtube.com/allivia
                         </a>
                     </div>
                 </div>
                 <div className="contenedor_iconos">
                    <div className="iconos">
                      <span className="icon-iconos-02"></span>
                    </div>
                    <div className="txt">
                       Municipio Libre 111, Ampliación Sinatel, Iztapalapa,
                        Ciudad de México, C. P. 09470
                    </div>
                  </div>
              </div>
            </div> 
      </IonContent>
    </IonPage>
  );
};

export default Contacto;
