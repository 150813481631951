import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/cuestionarioDos.css';
import { Redirect } from 'react-router';
import { DTOCuestionario, DTOCuestionarioEvaluacion, DTOTestCuidador, DTOTestCuidadorRespuesta } from '../services/AlliviaInterfaces';
import { guardarTestCuidador, leerCuestionarioEvaluacion, leerCuestionarios } from '../services/cuestionario.service';
import { timeout } from 'rxjs';



const CuestionarioDos: React.FC = () => {
  const user = useLoginContext();
  const { parametros,setParametros } = useParametros();
  const idTest = 2;
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [cuestionario, setCuestionario] = useState<Array<DTOCuestionario>>([]);
  const [cuestionarioEvaluacion, setCuestionarioEvaluacion] = useState<Array<DTOCuestionarioEvaluacion>>([]);
  const [preguntaPendiente, setPreguntaPendiente] = useState(true);
  const [puedeAvanzar, setPuedeAvanzar] = useState(false);
  const [evaluacion, setEvaluacion] = useState({ puntos: null, descripcion: null,idCuestEvaluacion:null });
  const [idPanel,setIdPanel] = useState(1);
  const [testFinalizado,setTestFinalizado] =useState(false);
  const [regresar,setRegresar] =useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  
  const scrollToTop= () => {
      contentRef.current && contentRef.current.scrollToTop();
  };

  const leerDatosCuestionario = async () => {
    setEstatus({ loading: true, leer: false });
    // await leerCuidador(user.username, user).then((valor) => {
    //   setCuidador(valor);
    // }).finally(() => {
    // });
    await leerCuestionarios(idTest, user).then((cuest) => {
      //crear structura de respuestas        
      setCuestionario(cuest);
    });
    await leerCuestionarioEvaluacion(idTest, user).then((evalu) => {
      setCuestionarioEvaluacion(evalu);
    })
    setEstatus({ loading: false, leer: false });
  }

  const regresarPanel=async()=>{
    if(idPanel===2 ){
      setIdPanel(1);
    }
    if(idPanel===3){
      setIdPanel(2);
    }
    evaluarRespuestas(idPanel-1);
    scrollToTop();
  }

  const avanzarPanel=async () => {
    evaluarRespuestas(idPanel+1);
    let contarAnsw = 0;
    let total = 0;
    cuestionario.forEach((resp) => {
      console.log(resp.numPregunta + "==" + resp.valorRespuesta);
      if(idPanel===resp.idPanel){
        if(resp.idPanel<=idPanel){
          total = total+1;
        }
        if (resp.valorRespuesta!=null) {
          contarAnsw=contarAnsw+1;
        }    
      }  
    });
    if(idPanel===1 && contarAnsw===total){
      setIdPanel(2);
    }
    if(idPanel===2 && contarAnsw===total){
      setIdPanel(3);
    }
    scrollToTop();
  }

  const guardarCuestionario = async () => {    
    setEstatus({ loading: true, leer: false });
    let date = new Date();
    let respuestas: Array<DTOTestCuidadorRespuesta> = [];
    cuestionario.map((question) => {
      let resp: DTOTestCuidadorRespuesta = {
        idCuestionario: question.idCuestionario,
        valorRespuesta: question.valorRespuesta,
      }
      respuestas.push(resp);
    });

    let testCuidador:DTOTestCuidador = {
      idTest : idTest,
      idCuidador: parametros.cuidador.idCuidador,
      puntos: evaluacion.puntos,
      idCuestEvaluacion: evaluacion.idCuestEvaluacion,   
      fechaEvaluacion: date.toISOString(),
      testCuidadorRespuestas : respuestas   
    }

    await guardarTestCuidador(testCuidador,user).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El test se guardo correctamente"});
      setParametros({...parametros,test2:testCuidador});
      setTestFinalizado(true);
    }).catch((error)=>{
      console.log("Error al guardar el test",error);
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el test por favor intente de nuevo"});
    });    
    setEstatus({ loading: false, leer: false });
  }

  const afterMessage = async ()=>{
    setMsgToast({showToast:false,statusMessage:''});
    if(testFinalizado){
      await timeout(1000);
      setRegresar(true);
    }
  }

  const evaluarRespuestas = async (idPanelValor:number) => {
    setEstatus({ loading: true, leer: false });
    let puntos = 0;
    let pendiente:boolean = false;
    let contaAnsw =0;
    let total =0;
    let temPuedeAvanzar:boolean=false;
    console.log("puntos:", pendiente,"parametres: ",parametros);
    cuestionario.map((resp) => {
      console.log("respueta:",resp,idPanelValor);
      if(resp.idPanel<=idPanelValor){
        total = total+1;
      }
      console.log(resp.numPregunta + "==" + resp.valorRespuesta);
      if (resp.valorRespuesta!=null) {
        console.log("Entra==" + resp.valorRespuesta);
        puntos = puntos + resp.valorRespuesta;
        contaAnsw=contaAnsw+1;
      } else {
        console.log("Etrna true: ",resp.numPregunta);
        pendiente = true;
      }
    });
    console.log("puntos:",pendiente,total,contaAnsw);
    if(idPanelValor<3 && contaAnsw>=total){
      temPuedeAvanzar=true;
    }
   
    setPuedeAvanzar(temPuedeAvanzar);  

    cuestionarioEvaluacion.map((evalu) => {
      console.log(evalu.minimo + "<=" + puntos + "&&" + puntos + "<=" + evalu.maximo);
      if (evalu.minimo <= puntos && puntos <= evalu.maximo) {
        setEvaluacion({ puntos: puntos, descripcion: evalu.descripcion,idCuestEvaluacion:evalu.idCuestEvaluacion });
      }
    });

    setPreguntaPendiente(pendiente);
    setEstatus({ loading: false, leer: false });
  }

  useEffect(() => {
    leerDatosCuestionario().then(() => {
      console.log("Funcionando");
    });
  }, []);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if(!parametros){
    return <Redirect to="/allivia/alliviaHome" />
  }

  if(regresar){
    return <Redirect to="/allivia/estadoEmocional" />
  }


  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Autovalore su situación</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent ref={contentRef} scrollEvents={true} >
          <div className='cuestionario-dos'>
            <div className='header' >
              <div className='numero'>
                <span>2</span>
              </div>
              <div className='titulo'>
                <span>¿Hasta qué punto el cuidado me ha sobrecargado?</span>
              </div>
            </div>
            <div className='contenido'>
              <div className='subtitulo'>
                Le dará información sobre el grado en que la atención a su familiar dependiente altera su bienestar físico, psicológico, económico y social del cuidador, esto es, hasta qué punto como persona cuidadora se ve sobrecargada, sobrepasada, superada por el cuidado y todo lo que implica.
              </div>
              <div className='instrucciones'>
                <span>Instrucción:</span> A continuación se presenta una lista de frases que reflejan cómo se sienten algunas personas cuando cuidan a otra persona. Después de leer cada frase, seleccione con qué frecuencia se siente usted de esa manera, escogiendo entre NUNCA (1), CASI NUNCA (2), A VECES (3), FRECUENTEMENTE (4) y CASI SIEMPRE (5).
              </div>
              <div className='cuestionario'>
                {cuestionario ? cuestionario.map((preg) =>
                  preg.idPanel===idPanel?
                  <>
                    <div className='pregunta'>
                      <span className='categoria'>{preg.categoria}&nbsp;</span>
                      {preg.pregunta}
                    </div>
                    <IonRadioGroup value={preg.valorRespuesta} onIonChange={(event) => { preg.valorRespuesta = event.detail.value; evaluarRespuestas(idPanel) }}>
                      <div className='respuestas'>                        
                        <div className='resp-radio'>
                          <span>1</span> 
                          <IonRadio mode="md"  value={1} />
                        </div>
                        <div className='resp-radio'>
                          <span>2</span>
                          <IonRadio mode="md"  value={2} />                          
                        </div>
                        <div className='resp-radio'>
                          <span>3</span>
                          <IonRadio mode="md"  value={3} />
                        </div>
                        <div className='resp-radio'>                          
                          <span>4</span>
                          <IonRadio mode="md"  value={4} />
                        </div>
                        <div className='resp-radio'>
                          <span>5</span>
                          <IonRadio mode="md"  value={5} />
                        </div>
                      </div>
                    </IonRadioGroup>
                  </>:null
                ) : null
                }
              </div>
              <div hidden={idPanel<3} className='resultado'>
                <div className='panel-mensaje'>
                  <div className='puntaje'>
                    <span className='texto'>Tu puntuación fue:</span>
                    <span className='numero'>{evaluacion.puntos}</span>
                  </div>
                  <div className='nota'>
                    {evaluacion.descripcion}
                  </div>
                </div>
              </div>
              <div className='boton-siguiente'>
                <IonButton expand='block' color="rosa" disabled={idPanel===1} onClick={regresarPanel}>Anterior</IonButton>
                <IonButton expand='block' color="rosa" hidden={idPanel===3} disabled={!puedeAvanzar} 
                  onClick={() => { avanzarPanel() }}>Preguntas siguientes</IonButton>
                <IonButton expand='block' hidden={idPanel<3||testFinalizado} color="rosa" disabled={preguntaPendiente}
                  onClick={() => { guardarCuestionario() }}>Siguiente test</IonButton>
              </div>
            </div>
          </div>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => afterMessage()}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        </IonContent>
      </IonPage>
    </>
  );
};

export default CuestionarioDos;
