import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonButton,
  IonRouterLink,
  IonLabel,  
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/estadoEmocional.css';
import { Redirect } from 'react-router';
import { leerTestCuidador } from '../services/cuestionario.service';
import { DTODocumento, DTOTestCuidador } from '../services/AlliviaInterfaces';
import { generarReporteTestCuidador } from '../services/reportes.service';
import { abrirArchivoBrowser, esWeb, guardarDocumentos, leerArchivoDescarga } from '../services/utils.service';
import { URL_ALLIVIA_DOCS } from '../properties';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';



const EstadoEmocional: React.FC = () => {
  const user = useLoginContext();
  const { parametros } = useParametros();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [test,setTest] = useState<Array<DTOTestCuidador>>();  
  const [testEstatus,setTestEstatus] = useState({t1D:false,t1F:false,t2D:false,t2F:false,t3D:false,t3F:false,t4D:false,t4F:false,tr1:null,
    tr2:null,
    tr3:null,
    tr4:null}); 
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [modalSabiasQue,setModalSabiasQue] = useState(false);
  const leerDatos = async()=>{
    setEstatus({loading:true,leer:false});
    let testEstatusTemporal = {t1D:false,t1F:false,t2D:true,t2F:false,t3D:true,t3F:false,t4D:true,t4F:false,
      tr1:null,
      tr2:null,
      tr3:null,
      tr4:null};
    await leerTestCuidador(parametros.cuidador.idCuidador,user)
    .then((testTem)=>{
      testTem.map((tt)=>{
        if(tt.idTest==1){
          testEstatusTemporal ={...testEstatusTemporal,t1F:true,t2D:false,tr1:tt}
        }
        if(tt.idTest==2){
          testEstatusTemporal ={...testEstatusTemporal,t2F:true,t3D:false,tr2:tt}
        }
        if(tt.idTest==3){
          testEstatusTemporal ={...testEstatusTemporal,t3F:true,t4D:false,tr3:tt}
        }
        if(tt.idTest==4){
          testEstatusTemporal ={...testEstatusTemporal,t4F:true,tr4:tt}
        }
      });
      setTest(testTem);
    });
    setTestEstatus(testEstatusTemporal);
    setEstatus({loading:false,leer:false});
  }

  const descargarReporteTestCuidador= async () => {
    setEstatus({ loading: true, leer: false });
    console.log("Iniciando descarga");
    let docu = await generarReporteTestCuidador(user, parametros.cuidador.idCuidador);
    if(esWeb()){
      console.log(docu.fileStream);      
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='" +docu.fileStream+ "'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }

    // let fileTransfer: FileTransferObject = FileTransfer.create();
    //Se obtiene la Uri del dispositivo
    // let uriSave = await Filesystem.getUri({
    //   path: URL_ALLIVIA_DOCS,
    //   directory: Directory.Documents
    // }
    // );
    // console.log("save uri archivo uri:", uriSave);
    //Se descarga el archivo
    // await fileTransfer.download(docu.fileStream, uriSave.uri + "/" + docu.urlDocumento).then(() => {
    //   docu.urlDocumento = URL_ALLIVIA_DOCS + "/" + docu.urlDocumento;
    //   abrirArchivo(docu);
    // }).catch((e) => { console.log("Error:", e) });
    setEstatus({loading:false,leer:false});
    await guardarDocumentos(docu).then(()=>{
      docu.urlDocumento = URL_ALLIVIA_DOCS + "/" + docu.urlDocumento;
      abrirArchivo(docu);
    }).catch((e)=>{
      setMsgToast({showToast:true,statusMessage:"Ocurrio un erro al descargar el reporte, por favor intenta de nuevo"});
    });
  }

  const abrirArchivo = async (doc: DTODocumento) => {

    if(esWeb()){         
      let docTemp = await leerArchivoDescarga(doc);
      console.log(docTemp);
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='"+docTemp+"'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }

    let uri = await Filesystem.getUri({
      path: doc.urlDocumento,
      directory: Directory.Documents
    }
    );
    console.log("abir archivo uri:", uri);
    await FileOpener.showOpenWithDialog(uri.uri, doc.mimeType).then().catch((e) => {
      console.log("error", e);
      abrirArchivoBrowser(doc).catch((e) => {
        setMsgToast({ showToast: true, statusMessage: "El documento no se encuentra en este dispositivo" });
      });
    });
  }
  
  useEffect(()=>{        
    leerDatos();
  },[parametros]);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if(!parametros){
    return <Redirect to="/allivia/alliviaHome" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Estado Emocional</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className='estado-emocional'>
            <div className='header' >
              <div className='imagen'>
                <IonImg src='./assets/iconSituacionEmocional.png'/>
                <span>Emocional</span>
              </div>
              <div className='titulo'>
                <h1>Autovalore su situación</h1>
              </div>
            </div>
            <div id="intro_mas">
              <div className='nota'>
                Los cuidadores de pacientes llegan a experimentar emociones y sentimientos que afectan su salud mental, derivado de un cuidado intenso y prolongado.
              </div>
            </div>
            <div hidden={!test || test.length<4} className='felicitaciones'>
              ¡Felicidades completaste todos los Test!
            </div>
            <div className='contenido-preguntas'>
              <p>
                ¿Te has preguntado lo siguiente?
              </p>
              <ol>
                <li> ¿Cómo ha afectado el cuidado a su vida cotidiana?</li>
                <li> ¿Hasta qué punto el cuidado le sobrecarga, sobrepasa o supera?</li>
                <li> ¿Cuál es su estado de ánimo? </li>
                <li> ¿Cuál es su nivel de tensión? </li>
              </ol>
              <div className='nota'>
               En <span className="apremium">allivia</span>, ponemos a tu disposición una serie de pruebas, que te darán una guía para entender si hay algo que te esté afectando.
              </div>              
              <div id="sabias_que">
                  <IonButton fill='clear' id="trigger-button" onClick={()=>setModalSabiasQue(true)}> Completa tus test de manera efectiva. Da clic aquí 
                  </IonButton>                  
              </div>
            </div>
            <div className='contenido-autovaloracion'>
              <div className='titulo'>
                <span>Autovaloración:</span>
              </div>
              <IonGrid className='lista-cuestionarios'>
                <IonRow>
                  <IonCol size='6'>
                    <IonRouterLink className={testEstatus.t1F?"finalizado":""} routerLink='/allivia/cuestionarioUno'>
                    <div className='panel-cuestionario'>
                      <div className='header'>
                        <div className='imagen'>
                          <span className='numero'>1</span>
                          <IonImg hidden={!testEstatus.t1F} className='img-header' src='./assets/cuestionario1.png'></IonImg>
                        </div>
                        <div hidden={testEstatus.t1F} className='titulo'>
                          ¿Como afecta el cuidado a mi vida cotidiana?
                        </div>
                      </div>
                      <div hidden={testEstatus.t1F} className='descripcion'>
                        Permitirá conocer en qué medida el cuidado influye en su vida 
                      </div>
                      <div hidden={!testEstatus.t1F} className='resultado'>
                        Tu puntuación fue<br/>{testEstatus.tr1?testEstatus.tr1.puntos:""}
                      </div>
                      <div hidden={!testEstatus.t1F} className='resultado-descripcion'>
                        {testEstatus.tr1?testEstatus.tr1.descripcion:""}
                      </div>
                    </div>
                    </IonRouterLink>
                  </IonCol>
                  <IonCol size='6'>
                    <IonRouterLink className={!testEstatus.t1F?"disabled-router-link":(testEstatus.t2F?"finalizado":"")} routerLink='/allivia/cuestionarioDos'>
                    <div className='panel-cuestionario'>
                      <div className='header'>
                        <div className='imagen'>
                          <span className='numero'>2</span>
                          <IonImg hidden={!testEstatus.t2F} className='img-header' src='./assets/cuestionario2.png'></IonImg>
                        </div>
                        <div hidden={testEstatus.t2F} className='titulo'>
                          ¿Hasta qué punto el cuidado me ha sobrecargado?
                        </div>
                      </div>
                      <div hidden={testEstatus.t2F} className='descripcion'>
                        Le dará información sobre hasta qué punto como persona cuidadora se ve sobrecargada, sobrepasada, superada por el cuidado y todo lo que implica.
                      </div>
                      <div hidden={!testEstatus.t2F} className='resultado'>
                        Tu puntuación fue<br/>{testEstatus.tr2?testEstatus.tr2.puntos:""}
                      </div>
                      <div hidden={!testEstatus.t2F} className='resultado-descripcion'>
                        {testEstatus.tr2?testEstatus.tr2.descripcion:""}
                      </div>
                    </div>
                    </IonRouterLink>
                  </IonCol>
                  <IonCol size='6'>
                    <IonRouterLink className={!testEstatus.t2F?"disabled-router-link":(testEstatus.t3F?"finalizado":"")} routerLink='/allivia/cuestionarioTres'>
                    <div className='panel-cuestionario'>
                      <div className='header'>
                        <div className='imagen'>
                          <span className='numero'>3</span>
                          <IonImg hidden={!testEstatus.t3F} className='img-header' src='./assets/cuestionario3.png'></IonImg>
                        </div>
                        <div hidden={testEstatus.t3F} className='titulo'>
                          ¿Cuál es mi estado de ánimo?
                        </div>
                      </div>
                      <div hidden={testEstatus.t3F} className='descripcion'>
                      Le dará información sobre si su estado de ánimo se ve afectado por su situación, y en especial si se siente triste, desanimado, desesperanzado…
                      </div>
                      <div hidden={!testEstatus.t3F} className='resultado'>
                        Tu puntuación fue<br/>{testEstatus.tr3?testEstatus.tr3.puntos:""}
                      </div>
                      <div hidden={!testEstatus.t3F} className='resultado-descripcion'>
                        {testEstatus.tr3?testEstatus.tr3.descripcion:""}
                      </div>
                    </div>
                    </IonRouterLink>
                  </IonCol>
                  <IonCol size='6'>
                    <IonRouterLink className={!testEstatus.t3F?"disabled-router-link":(testEstatus.t4F?"finalizado":"")} routerLink='/allivia/cuestionarioCuatro'>
                    <div className='panel-cuestionario'>
                      <div className='header'>
                        <div className='imagen'>
                          <span className='numero'>4</span>
                          <IonImg hidden={!testEstatus.t4F} className='img-header' src='./assets/cuestionario4.png'></IonImg>
                        </div>
                        <div hidden={testEstatus.t4F} className='titulo'>
                          ¿Cuál es mi nivel de tensión?
                        </div>
                      </div>
                      <div hidden={testEstatus.t4F} className='descripcion'>
                      Le dará información sobre el grado de tensión y ansiedad que está experimentando en su vida.
                      </div>
                      <div hidden={!testEstatus.t4F} className='resultado'>
                        Tu puntuación fue<br/>{testEstatus.tr4?testEstatus.tr4.puntos:""}
                      </div>
                      <div hidden={!testEstatus.t4F} className='resultado-descripcion'>
                        {testEstatus.tr4?testEstatus.tr4.descripcion:""}
                      </div>
                    </div>
                    </IonRouterLink>
                  </IonCol>             
                </IonRow>
              </IonGrid>              
            </div>
            <IonButton hidden={!testEstatus.t4F} expand='block' onClick={()=>{descargarReporteTestCuidador()}}>
                <IonLabel>Reporte Test</IonLabel>
              </IonButton>
          </div>                              
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>

<IonModal backdropDismiss={false}  isOpen={modalSabiasQue} className="ion-modal-sabiasque">
            <IonContent>
               <p>
                Realiza cada prueba en un momento de tranquilidad para ti y mantén tu enfoque en ello, estamos seguros de que te ayudarán a entender mejor si debes acudir con un especialista. 
              </p>
              <p></p>
              <p>
                Si bien, esto no es un diagnóstico definitivo y tampoco pretende brindar una solución, sí son herramientas que podrás imprimir y compartir con tu médico, juntos podrán tener una solución específica para ti y el momento que vives.  
              </p>
              <IonButton expand='block' onClick={()=>{setModalSabiasQue(false)}}>
                Cerrar
              </IonButton>
            </IonContent>
          </IonModal> 


        </IonContent>       
         
      </IonPage>
    </>
  );
};

export default EstadoEmocional;
