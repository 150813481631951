
//Variables globales
// export const SERVICE_URL ="http://192.168.1.65";
// export const SERVICE_PORT ="8080";
// export const SERVICE_ALLIVIA = SERVICE_URL+":"+SERVICE_PORT+"/allivia";


//Descomentar para producción
export const SERVICE_URL ="https://tomcat.allivia.com.mx";
export const SERVICE_ALLIVIA = SERVICE_URL+"/allivia";


export const URL_CURP = "https://www.gob.mx/curp/";

export const URL_ALLIVIA_DOCS = "allivia/documentos"

export const EMAIL_VALID = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//export const EMAIL_VALID = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
//export const EMAIL_VALID =  ^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$
//export const EMAIL_VALID = [a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}



//======================================================================