import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext } from '../hooks/login';
import '../theme/respiracion.css';
import { Redirect } from 'react-router';



const Respiracion: React.FC = () => {
  const user = useLoginContext();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  
  useEffect(()=>{    
    setEstatus({loading:false,leer:false});
  },[]);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar className='respira-header'>
            <IonButtons slot='start'>
              <IonBackButton text=""  defaultHref="/" />
            </IonButtons>
            <IonTitle>Respiración</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className='video-padding'>
          <div>
            <video className='video-respiracion' loop={true} autoPlay={true}>
              <source src="./assets/respiravideo.mp4" type="video/mp4"/>
            </video>
          </div>                              
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
        </IonContent>        
      </IonPage>
    </>
  );
};

export default Respiracion;
