import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/cuestionarioUno.css';
import { Redirect } from 'react-router';
import { DTOCuestionario, DTOCuestionarioEvaluacion, DTOTestCuidador, DTOTestCuidadorRespuesta } from '../services/AlliviaInterfaces';
import { guardarTestCuidador, leerCuestionarioEvaluacion, leerCuestionarios } from '../services/cuestionario.service';
import { timeout } from 'rxjs';



const CuestionarioUno: React.FC = () => {
  const user = useLoginContext();
  const { parametros,setParametros } = useParametros();
  const idTest = 1;
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [cuestionario, setCuestionario] = useState<Array<DTOCuestionario>>([]);
  const [cuestionarioEvaluacion, setCuestionarioEvaluacion] = useState<Array<DTOCuestionarioEvaluacion>>([]);
  const [preguntaPendiente, setPreguntaPendiente] = useState(true);
  const [evaluacion, setEvaluacion] = useState({ puntos: null, descripcion: null,idCuestEvaluacion:null });
  const [testFinalizado,setTestFinalizado] =useState(false);
  const [regresar,setRegresar] =useState(false);

  const leerDatosCuestionario = async () => {
    setEstatus({ loading: true, leer: false });    
    await leerCuestionarios(idTest, user).then((cuest) => {
      //crear structura de respuestas        
      setCuestionario(cuest);
    });
    await leerCuestionarioEvaluacion(idTest, user).then((evalu) => {
      setCuestionarioEvaluacion(evalu);
    })
    setEstatus({ loading: false, leer: false });
  }

  const guardarCuestionario = async () => {
    setEstatus({ loading: true, leer: false });
    let date = new Date();
    let respuestas: Array<DTOTestCuidadorRespuesta> = [];
    cuestionario.map((question) => {
      let resp: DTOTestCuidadorRespuesta = {
        idCuestionario: question.idCuestionario,
        valorRespuesta: question.valorRespuesta,
      }
      respuestas.push(resp);
    });

    let testCuidador:DTOTestCuidador = {
      idTest : idTest,
      idCuidador: parametros.cuidador.idCuidador,
      puntos: evaluacion.puntos,
      idCuestEvaluacion: evaluacion.idCuestEvaluacion,   
      fechaEvaluacion: date.toISOString(),
      testCuidadorRespuestas : respuestas   
    }

    await guardarTestCuidador(testCuidador,user).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El test se guardo correctamente"}); 
      setParametros({...parametros,test1:testCuidador});
      setTestFinalizado(true);
    }).catch((error)=>{
      console.log("Error al guardar el test",error);
      setMsgToast({showToast:true,statusMessage:"Ocurrio un error al guardar el test por favor intente de nuevo"});

    });    
    setEstatus({ loading: false, leer: false });    
  }

  const afterMessage = async ()=>{
    setMsgToast({showToast:false,statusMessage:''});
    if(testFinalizado){
      await timeout(1000);
      setRegresar(true);
    }
  }


  const evaluarRespuestas = async () => {
    setEstatus({ loading: true, leer: false });
    let puntos = 0;
    let pendiente:boolean = false;
    console.log("puntos:", pendiente,"parametres: ",parametros);
    cuestionario.map((resp) => {
      console.log(resp.numPregunta + "==" + resp.valorRespuesta);
      if (resp.valorRespuesta!=null) {
        console.log("Entra==" + resp.valorRespuesta);
        puntos = puntos + resp.valorRespuesta;
      } else {
        console.log("Etrna true: ",resp.numPregunta);
        pendiente = true;
      }
    });
    console.log("puntos:",pendiente);

    cuestionarioEvaluacion.map((evalu) => {
      console.log(evalu.minimo + "<=" + puntos + "&&" + puntos + "<=" + evalu.maximo);
      if (evalu.minimo <= puntos && puntos <= evalu.maximo) {
        setEvaluacion({ puntos: puntos, descripcion: evalu.descripcion,idCuestEvaluacion:evalu.idCuestEvaluacion });
      }
    });

    setPreguntaPendiente(pendiente);
    setEstatus({ loading: false, leer: false });
  }

  useEffect(() => {
    leerDatosCuestionario().then(() => {
      console.log("Funcionando");
    });
  }, []);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if(!parametros){
    return <Redirect to="/allivia/alliviaHome" />
  }

  if(regresar){
    return <Redirect to="/allivia/estadoEmocional" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Autovalore su situación</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent >
          <div className='cuestionario-uno'>
            <div className='header' >
              <div className='numero'>
                <span>1</span>
              </div>
              <div className='titulo'>
                <span>¿Cómo afecta el cuidado a mi vida cotidiana?</span>
              </div>
            </div>
            <div className='contenido'>
              <div className='subtitulo'>
                Permitirá conocer en qué medida el cuidado influye en su vida
              </div>
              <div className='instrucciones'>
                <span>Instrucción:</span> Seleccione el número que mejor describa su situación actual acerca de los aspectos de la vida cotidiana que se señalan a continuación.
              </div>
              <div className='cuestionario'>
                {cuestionario ? cuestionario.map((preg) =>
                  <>
                    <div className='pregunta'>
                      <span className='categoria'>{preg.categoria}&nbsp;</span>
                      {preg.pregunta}
                    </div>
                    <IonRadioGroup value={preg.valorRespuesta} onIonChange={(event) => { preg.valorRespuesta = event.detail.value; evaluarRespuestas() }}>
                      <div className='respuestas'>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={0} />
                          <span>0</span>
                          <div className='descripcion'>
                            N/A
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={1} />
                          <span>1</span>
                          <div className='descripcion'>
                            Casí nada
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={2} />
                          <span>2</span>
                          <div className='descripcion'>
                            Poco
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={3} />
                          <span>3</span>
                          <div className='descripcion'>
                            Bastante
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={4} />
                          <span>4</span>
                          <div className='descripcion'>
                            Mucho
                          </div>
                        </div>
                        <div className='resp-radio'>
                          <IonRadio mode="md"  value={5} />
                          <span>5</span>
                          <div className='descripcion'>
                            Exceso
                          </div>
                        </div>
                      </div>
                    </IonRadioGroup>
                  </>
                ) : null
                }
              </div>
              <div className='resultado'>
                <div className='panel-mensaje'>
                  <div className='puntaje'>
                    <span className='texto'>Tu puntuación fue:</span>
                    <span className='numero'>{evaluacion.puntos}</span>
                  </div>
                  <div className='nota'>
                    {evaluacion.descripcion}
                  </div>
                </div>
              </div>
              <div className='boton-siguiente'>
                <IonButton className='bts' hidden={testFinalizado} disabled={preguntaPendiente} fill='clear'
                  onClick={() => { guardarCuestionario() }}>Siguiente test</IonButton>
              </div>
            </div>
          </div>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => afterMessage()}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        </IonContent>
      </IonPage>
    </>
  );
};

export default CuestionarioUno;
