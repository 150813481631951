import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonImg,
  IonInput,
  IonIcon,
  IonModal,
  IonGrid,
  IonCol,
  IonRow,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { LoginContext, useLoginContext } from '../hooks/login';
import '../theme/cuentaUsuario.css';
import { Redirect } from 'react-router';
import { trash } from 'ionicons/icons';
import { cerrarSesion, eliminaUserWithPassword } from '../services/login.service';
import { timeout } from 'rxjs';
import { types } from '../types/types';



const CuentaUsuario: React.FC = () => {
  const user = useLoginContext();
  const {dispatch} = useContext(LoginContext);
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '',mensajeEliminar:false});
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [modalConfEliminar, setModalConfEliminar] = useState(false);
  const [modalConfPass, setModalConfPass] = useState(false);
  const [password,setPassword] = useState("");
  const [cuentaEliminada,setCuentaEliminada] = useState(false);
  const [irLogin,setIrLogin]=useState(false);

  useEffect(() => {
    setNombreUsuario(user.username);    
    setEstatus({ loading: false, leer: false });
  }, []);

  const siEliminar = async()=>{
    setEstatus({loading:true,leer:false});
    setModalConfPass(true)
    setModalConfEliminar(false);
    setEstatus({loading:false,leer:false});
  }

  const noEliminar = async()=>{
    setEstatus({loading:true,leer:false});
    setModalConfEliminar(false);
    setEstatus({loading:false,leer:false});
  }

  const aceptarEliminar = async()=>{
    setEstatus({loading:true,leer:false});
    await eliminarCuentaUsuario();
    setPassword("");
    setModalConfPass(false);
    setEstatus({loading:false,leer:false});
  }

  const cancelarEliminar = async()=>{
    setEstatus({loading:true,leer:false});
    setPassword("");
    setModalConfPass(false);
    setEstatus({loading:false,leer:false});
  }

  const eliminarCuentaUsuario = async() =>{
    console.log(password);
    if(!password || password ===null || password ===''){
      setMsgToast({showToast:true,statusMessage:"Por favor, ingresa una contraseña válida",mensajeEliminar:false});
    }

    await eliminaUserWithPassword(user,password).then(()=>{
      console.log("El usuario se elimino correctamente");
      setCuentaEliminada(true);
      setMsgToast({showToast:true,statusMessage:"Lamentamos que dejes allivia, pero cuenta con que estaremos siempre para ayudarte. Debido a que conocemos la importancia de tu información, la misma será eliminada de nuestra plataforma en un plazo de 30 días. Si deseas revertir la eliminación, puedes recuperarla simplemente ingresando nuevamente a allivia",mensajeEliminar:true});
    }).catch((error)=>{
      console.log("Ocurrió un error al eliminar la cuenta, por favor intenta de nuevo");
      setMsgToast({showToast:true,statusMessage:"Por favor ingresa una contraseña valida",mensajeEliminar:false});
    });
  }

  const afterMessage = async ()=>{
    setMsgToast({showToast:false,statusMessage:'',mensajeEliminar:false});
    if(cuentaEliminada){
      await timeout(1000);
      await handleCerrarSesion();
    }
  }

  const handleCerrarSesion = async () => {
    try{
      cerrarSesion();
      const action = {
        type: types.logout,
        payload: {loggedIn:'false'}
      }
      dispatch(action);
    }catch(error){
      console.log("Error al cerar la sesión: ",error);
    }    
  }  

  

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  if (irLogin) {
    return <Redirect to="/login" />
  }
  

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text="" color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Cuenta usuario</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div>
            <IonList>
              <IonItem >
                <IonLabel position='fixed'>Usuario:</IonLabel>
                <IonInput disabled={true}>{nombreUsuario}</IonInput>
              </IonItem>
            </IonList>
            <IonButton expand='block' onClick={()=>setModalConfEliminar(true)}>
              <label>Eliminar cuenta</label>
              <IonIcon icon={trash}></IonIcon>
            </IonButton>
          </div>
          <IonModal backdropDismiss={false} isOpen={modalConfEliminar} className="ion-modal-confirmacion-eliminar" >
            <IonContent>
              <div className='header'>
                Confirmación
              </div>
              <div className='contenido'>
                <div className='panel-confirmacion'>
                  <p>
                    Lamentamos que dejes allivia, pero cuenta con que estaremos siempre para ayudarte.
                  </p>
                  <p>
                    <b>¿Deseas eliminar tu cuenta permanentemente?</b>
                  </p>
                </div>
                <IonGrid>
                  <IonRow>
                    <IonCol size='6'>
                      <IonButton expand='block' onClick={()=>{siEliminar()}}>Sí</IonButton>
                    </IonCol>
                    <IonCol size='6'>
                      <IonButton expand='block' onClick={()=>{noEliminar()}}>No</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonContent>
          </IonModal>
          <IonModal backdropDismiss={false} isOpen={modalConfPass} className="ion-modal-confirmacion-eliminar" >
            <IonContent>
              <div className='header'>
                Confirmar
              </div>
              <div className='contenido'>
                <div className='panel-confirmacion'>
                  Para confirmar, por favor ingresa tu contraseña
                  <IonInput value={password} onIonChange={(val)=>setPassword(val.detail.value)} type='password' ></IonInput>
                </div>
                <IonGrid>                
                  <IonRow>
                    <IonCol size='6'>
                      <IonButton expand='block' onClick={()=>{aceptarEliminar()}}>Aceptar</IonButton>
                    </IonCol>
                    <IonCol size='6'>
                      <IonButton expand='block' onClick={()=>{cancelarEliminar()}}>Cancelar</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonContent>
          </IonModal>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => afterMessage()}
            message={msgToast.statusMessage}
            duration={msgToast.mensajeEliminar?15000:3000}
            position={'middle'}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
        </IonContent>
      </IonPage>
    </>
  );
};

export default CuentaUsuario;
