import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonLabel,
  IonListHeader,
  IonList,
  IonImg,
  IonBackButton,
  IonLoading,
  IonButton,
  IonModal,
  IonItem,
  IonInput,
  IonRadio,
  IonGrid,
  IonCol,
  IonRow,
  IonRadioGroup,
  IonToast,
  IonFabList,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { addCircle, call, closeOutline, downloadOutline, home, pencil, search, shareSocial, trash } from 'ionicons/icons';
import { useLoginContext, useParametros, } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/citaMedica.css';
import { Redirect } from 'react-router';
import { DTOCitaMedica, DTODocumento, DTOEvento, DTOPaciente } from '../services/AlliviaInterfaces';
import { guardarCitaMedica, leerCitaMedicas } from '../services/citaMedica.service';
import dayjs from 'dayjs';
import { abrirArchivoBrowser, emergencyCall, esWeb, guardarDocumentos } from '../services/utils.service';
import { Share } from '@capacitor/share';
import { generarReporteCitaMedica } from '../services/reportes.service';
import { URL_ALLIVIA_DOCS } from '../properties';
import { Filesystem,Directory} from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { leerNotificaciones, leerNotificacionesElimina } from '../services/notificaciones.service';



const CitaMedica: React.FC = () => {
  const user = useLoginContext();
  const { parametros } = useParametros();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [modalAgregarCitaMedica, setModalAgregarCitaMedica] = useState(false);
  const [paciente, setPaciente] = useState<DTOPaciente | undefined>();
  const [citaMedica, setCitaMedica] = useState<DTOCitaMedica>({});
  const [listaCitaMedica,setListaCitaMedica]= useState<Array<DTOCitaMedica>>();
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [verEvento, setVerEvento] = useState("no");
  const [verRecordatorio, setVerRecordatorio] = useState("no");

  const descargarCitaMedica = async ()=>{
    setEstatus({loading:true,leer:false});
    console.log("Iniciando descarga");
    let docu = await generarReporteCitaMedica(user,paciente.idPersona,paciente.idCuidador);
    if(esWeb()){
      console.log(docu.fileStream);      
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='" +docu.fileStream+ "'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }
    setEstatus({loading:false,leer:false});
    await guardarDocumentos(docu).then(()=>{
      docu.urlDocumento = URL_ALLIVIA_DOCS + "/" + docu.urlDocumento;
      abrirArchivo(docu);
    });
    
  }
  

  const compartirArchivo = async()=>{
    setEstatus({loading:true,leer:false});
    let docu = await generarReporteCitaMedica(user,paciente.idPersona,paciente.idCuidador);

    console.log(docu.fileStream);

    // let fileTransfer:FileTransferObject = FileTransfer.create();
    //Se obtiene la Uri del dispositivo
    let uriSave = await Filesystem.getUri({
      path: URL_ALLIVIA_DOCS,
      directory: Directory.Documents}      
    );
    console.log("save uri archivo uri:",uriSave);
    await guardarDocumentos(docu).then(()=>{      
    });
    setEstatus({loading:false,leer:false});
    await Share.share({
      title: docu.descripcion,
      text: 'Compartir',
      url: "file://"+uriSave.uri+"/"+docu.urlDocumento,
      dialogTitle: 'Compartir',
    }).catch((error)=>{
      console.log(error);
    });
  }

  const abrirArchivo = async(doc:DTODocumento)=>{
    let uri = await Filesystem.getUri({
      path: doc.urlDocumento,
      directory: Directory.Documents}      
    );
    console.log("abir archivo uri:",uri);    
    FileOpener.showOpenWithDialog(uri.uri, doc.mimeType).then().catch((e)=>{
      console.log("error",e);
      abrirArchivoBrowser(doc).catch((e)=>{
        setMsgToast({showToast:true,statusMessage:"El documento no se encuentra en este dispositivo"});
      });
    });
  }

  const handleEditarCita=(cita:DTOCitaMedica)=>{
    setCitaMedica(cita);
    setVerEvento(cita.evento!=null?"si":"no");
    setVerRecordatorio(cita.evento!=null && cita.evento.recordatorio?"si":"no");
    setModalAgregarCitaMedica(true);
  }

  const handleEliminarCita= async(cita:DTOCitaMedica)=>{
    setEstatus({loading:true,leer:false});
    cita={...cita,estatus:0}
    console.log("Eliminar cita Medica: ",cita); 
    await guardarCitaMedica(user,cita).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se elimino correctamente"});
      leerCitaMedicas(user,parametros.paciente.idPersona).then((lcitas)=>{       
        setListaCitaMedica(lcitas);        
      }).finally(()=>{
        setCitaMedica({idPersona:paciente.idPersona});
        setVerEvento("no");
        setVerRecordatorio("no");
      });
    });
    await sincronizaNotificacionesElimina();
    setEstatus({loading:false,leer:false});
  }

  const handleGuardarCitaMedica = async ()=>{
    setEstatus({loading:true,leer:false});
    console.log(citaMedica)
    if(!citaMedica|| !citaMedica.fecha || citaMedica.fecha===null){
      setEstatus({loading:false,leer:false});
      setMsgToast({showToast:true,statusMessage:"Por favor ingresa una fecha"});
      return;
    }
    console.log("Guardar cita medica: ",citaMedica); 
    let tevento:DTOEvento=null;
    if(verEvento==="si"){
      let temp:DTOEvento = citaMedica.evento;
      tevento={                
        idEvento:temp?temp.idEvento:null,
        idPersona:citaMedica.idPersona,
        nombre:"Cita médica",
        descripcion:citaMedica.seguimiento,
        fecha: convertirFecha(citaMedica.fecha),
        hora: convertirHora(citaMedica.fecha),
        mostrar: true,
        recordatorio: verRecordatorio==="si",        
      }
    }
    let pcitMed ={...citaMedica,evento:tevento,fecha:convertirHoraFecha(citaMedica.fecha)};            
    await guardarCitaMedica(user,pcitMed).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se guardo correctamente"});
      leerCitaMedicas(user,parametros.paciente.idPersona).then((lcitas)=>{       
        setListaCitaMedica(lcitas);        
      }).finally(()=>{
        setCitaMedica({idPersona:paciente.idPersona,estatus:1});
        setVerEvento("no");
        setVerRecordatorio("no");
      });
      sincronizaNotificaciones();
    });
    setModalAgregarCitaMedica(false);
    setEstatus({loading:false,leer:false});
  }

  const sincronizaNotificacionesElimina=async ()=>{    
    await leerNotificacionesElimina(user,parametros.cuidador.idCuidador)
  }

  const sincronizaNotificaciones=async ()=>{    
    await leerNotificaciones(user,parametros.cuidador.idCuidador)
  }

  const convertirFecha=(fecha:string):string=>{  
    return dayjs(fecha).format("YYYY-MM-DD");;
  }
  const convertirHora=(fecha:string):string=>{
    return dayjs(fecha).format("HH:mm:ss");;
  }

  const formatFecha =(fecha:string) =>{
    return dayjs(fecha).format("DD/MM/YY");
  }
  const convertirHoraFecha =(t:string):string=>{  
    let d = ""+t+":00"
    return d;
  }

  const actualizaCitaMedica = (valor:any)=>{
    setCitaMedica({...citaMedica,...valor});
  }

  useEffect(() => {
    console.log("Entrando a useefect menuPaciente: ");
    if (parametros) {
      setPaciente(parametros.paciente);
      setCitaMedica({idPersona:parametros.paciente.idPersona,estatus:1});
      setEstatus({loading:false,leer:false});
    }
  }, [parametros]);

  useEffect(()=>{
    if(parametros){
      leerCitaMedicas(user,parametros.paciente.idPersona).then((lcita)=>{       
        setListaCitaMedica(lcita);              
      });
      setEstatus({loading:false,leer:false});   
    }
  },[]);


  console.log("valor paciente: ", paciente);

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  if (!paciente) {
    return (<IonLoading isOpen />);
  }

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle className="title_header">Cita Médica</IonTitle>
            <IonButtons slot='end'>
              <IonButton color='primary' onClick={()=>{descargarCitaMedica()}} >
                <IonIcon size='large' icon={downloadOutline} ></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="citaMedica">
          <IonList lines='none' className='pleca-tratamientos'>
            <IonListHeader>
               <div id="contenedor_datos">
                  <div className="icon_px">
                      <IonImg src="./assets/px.png"/>
                  </div>
                  <div className="datos_px">
                      <div id='title_paciente'>
                        <span className='header-nombre'>{paciente?paciente.nombreCompleto:null}</span>
                      </div>
                      <div id="title_parentesco">
                        <span className='header-parentesco'>{paciente?paciente.parentesco:null}</span>
                      </div>
                  </div>
              </div>
            </IonListHeader>
          </IonList>
          <div className='cita-panel'>
            <div className='cita-titulo' >
              <IonImg src='./assets/citaMed.png' />
              <p>Cita Médica</p>
            </div>
            <div className='cita-datos' >
              <table>
                <thead>
                  <tr className="titulos_cita">
                    <th>Fecha</th>
                    <th>Médico</th>
                    <th>Clínica u Hospital</th>
                    <th>Dx</th>
                    <th>Editar</th>
                  </tr>
                </thead>
                <tbody>
                {
                    listaCitaMedica?listaCitaMedica.map((lcita)=>{
                      return <>
                      <tr key={lcita.idCita}>
                        <td  >{formatFecha(lcita.fecha)}</td>
                        <td className="capitalizar" >{lcita.medico}</td>
                        <td className="capitalizar" >{lcita.clinica}</td>
                        <td className="capitalizar">{lcita.seguimiento}</td>
                        <td className='panel-acciones'>
                          <IonButton fill='clear' size='small' onClick={()=>{handleEditarCita(lcita)}} ><IonIcon color="rosa" icon={pencil}></IonIcon></IonButton>
                          <IonButton fill='clear' size='small' onClick={()=>{handleEliminarCita(lcita)}}><IonIcon color="rosa" icon={trash}></IonIcon></IonButton>
                        </td>
                      </tr>  
                      </>
                    }):null
                  }    
                </tbody>
              </table>
            </div>
          </div>
          <IonFab hidden={esWeb()} className='allivia-emergencia-call' vertical='bottom' horizontal='center' edge slot='fixed'>
            <IonFabButton color="emergencia" >
              <div>
                <IonIcon icon={call} />
                <IonLabel>911</IonLabel>
              </div>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton color="success" onClick={()=>{emergencyCall();}}><IonIcon icon={call} /></IonFabButton>
            </IonFabList>
          </IonFab>
          <IonModal backdropDismiss={false} isOpen={modalAgregarCitaMedica} className="ion-modal-cita" >
            <IonContent>
              <div className='header'>
                <IonImg src='./assets/agregarCitaMed.png'></IonImg>
                Agregar Cita Médica
                <IonButton fill='clear' onClick={()=>{setModalAgregarCitaMedica(false);}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/citaMed1.png" />
                        <IonLabel>Fecha:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput type='datetime-local' value={citaMedica.fecha} 
                        onIonChange={(event)=>{actualizaCitaMedica({fecha:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed2.png" />                      
                        <IonLabel>Médico:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={citaMedica.medico} 
                        onIonChange={(event)=>{actualizaCitaMedica({medico:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed3.png" />
                        <IonLabel>Clínica u hospital:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={citaMedica.clinica} 
                        onIonChange={(event)=>{actualizaCitaMedica({clinica:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed4.png" />
                        <IonLabel>Consultorio:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={citaMedica.consultorio} 
                        onIonChange={(event)=>{actualizaCitaMedica({consultorio:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed5.png" />
                        <IonLabel>Teléfono clínica / consultorio:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='tel' value={citaMedica.telefonoClinica} 
                        onIonChange={(event)=>{actualizaCitaMedica({telefonoClinica:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed6.png" />
                        <IonLabel>Teléfono del médico:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='tel' value={citaMedica.telefonoMedico} 
                        onIonChange={(event)=>{actualizaCitaMedica({telefonoMedico:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/citaMed7.png" />
                        <IonLabel>Seguimiento:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={citaMedica.seguimiento} 
                        onIonChange={(event)=>{actualizaCitaMedica({seguimiento:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='12'>
                        <IonLabel>Deseas agregar este evento al calendario:</IonLabel>                                               
                      </IonCol> 
                    </IonRow>
                    <IonRadioGroup color='success' value={verEvento} 
                      onIonChange={(event)=>{setVerEvento(event.detail.value)}}>
                      <IonRow>                                           
                        <IonCol size='6' >
                          <IonItem lines='none'>
                          <IonLabel>Si</IonLabel>
                            <IonRadio mode="md"  slot="start" value="si"></IonRadio>
                          </IonItem>
                        </IonCol>
                        <IonCol size='6' >
                          <IonItem lines='none'>
                            <IonLabel>No</IonLabel>
                            <IonRadio mode="md"  slot="start" value="no"></IonRadio>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonRadioGroup>
                    <IonRow>
                      <IonCol size='12'>
                        <IonLabel>Deseas recibir un recordatorio de este evento:</IonLabel>                                               
                      </IonCol> 
                    </IonRow>
                    <IonRadioGroup value={verRecordatorio}
                      onIonChange={(event)=>{setVerRecordatorio(event.detail.value)}}>
                      <IonRow>                                           
                        <IonCol size='6' >
                          <IonItem lines='none'>
                          <IonLabel>Si</IonLabel>
                            <IonRadio mode="md"  slot="start" value="si"></IonRadio>
                          </IonItem>
                        </IonCol>
                        <IonCol size='6' >
                          <IonItem lines='none'>
                            <IonLabel>No</IonLabel>
                            <IonRadio mode="md"  slot="start" value="no"></IonRadio>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonRadioGroup>
                  </IonGrid>
                  <IonButton  className="guardar_orange" expand='block' onClick={() => { handleGuardarCitaMedica() }} >Guardar
                   <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
                
              </div>
              
            </IonContent>
          </IonModal>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
        />
        <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
        </IonContent>
        <div className='custom-tab-bar'>
          <IonButton fill='clear' routerLink='/'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' routerLink='/allivia/buscar'>
            <div>
              <IonIcon icon={search} />
              <IonLabel>Buscar</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={esWeb()} fill='clear' onClick={() => { compartirArchivo() }}>
            <div>
              <IonIcon icon={shareSocial} />
              <IonLabel>Compartir</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={!esWeb()} fill='clear' onClick={() => { descargarCitaMedica() }}>
            <div>
              <IonIcon icon={downloadOutline} />
              <IonLabel>Descargar</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' onClick={() => { setModalAgregarCitaMedica(true); console.log("entrando") }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Cita</IonLabel>
            </div>
          </IonButton>
        </div>
      </IonPage>
    </>
  );
};

export default CitaMedica;
