import { SERVICE_ALLIVIA} from "../properties";
import { DTOCuidador, UsuarioLogin } from "./AlliviaInterfaces";

export const leerCuidador = async (email:string, usuario:UsuarioLogin):Promise<DTOCuidador> =>{
  let temCuidador :DTOCuidador;
  if(localStorage.cuidador){
    temCuidador=JSON.parse(localStorage.cuidador);    
    if(temCuidador.email!=email){
      localStorage.removeItem("cuidador");
    }
  }
  
  if(temCuidador){
    let urllu = SERVICE_ALLIVIA+"/cuidador/fechaUpdate/"+email;  
    let response=null;
    await fetch(urllu,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    }).then(resp=>{
      response=resp;
    }).catch(err=>{
      console.log("error",err);
    });
    if(response==null){
      return temCuidador
    }

    if(!response.ok){
        return temCuidador
    }
    const fecha = await response.json();
    if(fecha===temCuidador.fechaModificacion){
      return temCuidador;
    }
  }
  let url = SERVICE_ALLIVIA+"/cuidador/"+email;  
  try{
    let response = await fetch(url,{
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer "+usuario.token,
      },
      method: "GET"
    });
    if(!response.ok){    
      throw Error("Error:"+response.status);
    }
    const json = await response.json();
    console.log(json);
    localStorage.setItem("cuidador",JSON.stringify(json));
    return json;
  }catch(err){
    console.log(err);
    return null;
  }
}

export const leerCuidadorInvitado = async (idCuidador:number, usuario:UsuarioLogin):Promise<Array<DTOCuidador>> =>{
  const url = SERVICE_ALLIVIA+"/cuidador/invitado/"+idCuidador;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  const json = await response.json();
  console.log(json);    
  return json;
}

export const leerCuidadorCompartir = async (email:string, usuario:UsuarioLogin):Promise<DTOCuidador> =>{  
  const url = SERVICE_ALLIVIA+"/cuidador/compartir/"+email;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}

export const guardarCuidador =async (usuario,cuidador:DTOCuidador):Promise<DTOCuidador> => {
  const url = SERVICE_ALLIVIA+"/cuidador";
  limpiarDocsAntesGuardar(cuidador);
  const response = await fetch(url,{
    body: JSON.stringify(cuidador),
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "POST"
  });
  if(!response.ok){
    throw Error("error 2"+response.statusText);
  }  
  const json = await response.json();  
  return json;
}

const limpiarDocsAntesGuardar = (cuid :DTOCuidador) =>{
  if(cuid.docIne){
    cuid.docIne.fileStream=null;
  }
  if(cuid.docVacuna){
    cuid.docVacuna.fileStream=null;
  }
  if(cuid.docCarnet){
    cuid.docCarnet.fileStream=null;
  }
  if(cuid.docSeguroGMM){
    cuid.docSeguroGMM.fileStream=null;
  }
  if(cuid.docSeguroVida){
    cuid.docSeguroVida.fileStream=null;
  }
}

export const enviarInvitacionAllivia = async (idCuidador:number,email:string,nombre:string, usuario:UsuarioLogin):Promise<DTOCuidador> =>{
  const url = SERVICE_ALLIVIA+"/cuidador/enviar/invitacion/"+idCuidador+"/"+email+"/"+nombre;  
  const response = await fetch(url,{
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer "+usuario.token,
    },
    method: "GET"
  });
  if(!response.ok){    
    throw Error("Error:"+response.status);
  }
  try{
    const json = await response.json(); 
    return json;
  }catch(evt){
    return null
  }
}