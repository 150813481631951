import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButtons,
  IonLabel,
  IonListHeader,
  IonList,
  IonImg,
  IonBackButton,
  IonLoading,
  IonButton,
  IonModal,
  IonItem,
  IonInput,
  IonRadio,
  IonGrid,
  IonCol,
  IonRow,
  IonRadioGroup,
  IonToast,
  IonFabList,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { addCircle, attach, call, camera, closeOutline, cloudDownload, cloudUpload, document, downloadOutline, home, pencil, search, shareSocial, sync, trash } from 'ionicons/icons';
import { useLoginContext, useParametros, } from '../hooks/login';
import '../theme/webcss.css';
import '../theme/estudiosClinicos.css';
import { Redirect } from 'react-router';
import { DTODocumento, DTOEstudio, DTOEvento, DTOPaciente, FileTemporalDoc } from '../services/AlliviaInterfaces';
import { guardarEstudio, leerEstudios } from '../services/estudio.service';
import dayjs from 'dayjs';
import { Filesystem,Directory} from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { abrirArchivoBrowser, cargarPreview, eliminarDocumento, emergencyCall, esWeb, guardarDocumentos, leerArchivoDescarga } from '../services/utils.service';
import { Camera, CameraResultType } from '@capacitor/camera';
import { URL_ALLIVIA_DOCS } from '../properties';
// import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer';
import { Share } from '@capacitor/share';
import { generarReporteEstudioClinico } from '../services/reportes.service';
import { MENSAJE_CARGA_ARCHIVOS, MSJ_CARGA_DOCUMENTOS } from '../constantes';
import { leerNotificaciones, leerNotificacionesElimina } from '../services/notificaciones.service';
import { eliminarDocumentosGluster, guardarDocumentosGluster } from '../services/gluster.service';



const EstudiosClinicos: React.FC = () => {
  const user = useLoginContext();
  const { parametros } = useParametros();
  const [estatus,setEstatus] = useState({loading:true,leer:true});
  const [modalAgregarEstudio, setModalAgregarEstudio] = useState(false);
  const [paciente, setPaciente] = useState<DTOPaciente | undefined>();
  const [estudio, setEstudio] = useState<DTOEstudio>({});
  const [listaEstudios,setListaEstudios]= useState<Array<DTOEstudio>>();
  const [msgToast,setMsgToast] = useState({showToast:false,statusMessage:''});
  const [verEvento, setVerEvento] = useState("no");
  const [verRecordatorio, setVerRecordatorio] = useState("no");
  const fileInputDocEMRef = useRef<HTMLInputElement>();
  const [fileEM,setFileEM] = useState<FileTemporalDoc>();
  const [eliminarDocEM,setEliminarDocEM] = useState(false);
  const [subirEM,setSubirEM]=useState(false);
  const [imagenOptions]=useState({
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.Uri,
    promptLabelHeader:"Foto",
    promptLabelCancel:"Cancelar",
    promptLabelPhoto:"Galeria",
    promptLabelPicture:"Cámara"
  });

  const descargarEstudioClinico = async ()=>{
    setEstatus({loading:true,leer:false});
    console.log("Iniciando descarga");
    let docu = await generarReporteEstudioClinico(user,paciente.idPersona,paciente.idCuidador);
    if(esWeb()){
      console.log(docu.fileStream);      
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='" +docu.fileStream+ "'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }
    setEstatus({loading:false,leer:false});
    await guardarDocumentos(docu).then(()=>{
      docu.urlDocumento = URL_ALLIVIA_DOCS + "/" + docu.urlDocumento;
      abrirArchivo(docu);
    });
    
  }
  

  const compartirArchivo = async()=>{
    setEstatus({loading:true,leer:false});
    let docu = await generarReporteEstudioClinico(user,paciente.idPersona,paciente.idCuidador);

    console.log(docu.fileStream);

    // let fileTransfer:FileTransferObject = FileTransfer.create();
    //Se obtiene la Uri del dispositivo
    let uriSave = await Filesystem.getUri({
      path: URL_ALLIVIA_DOCS,
      directory: Directory.Documents}      
    );
    console.log("save uri archivo uri:",uriSave);
    
    await guardarDocumentos(docu).then(()=>{     
    });
    setEstatus({loading:false,leer:false});
    await Share.share({
      title: docu.descripcion,
      text: 'Compartir',
      url: "file://"+uriSave.uri+"/"+docu.urlDocumento,
      dialogTitle: 'Compartir',
    }).catch((error)=>{
      console.log(error);
    });
  }

  const takePictureEM = async () => {
    const image = await Camera.getPhoto(imagenOptions);
    let date = dayjs(new Date()).format("YYYYMMDDHHmmss")
    var imageUrl = image.webPath;      
    console.log("imgEM",imageUrl);
    setFileEM({url:imageUrl,fileName:"EstudioMedico"+date+"."+image.format,mimeType:"image/"+image.format});
    setSubirEM(false);
    setMsgToast({showToast:true,statusMessage:MENSAJE_CARGA_ARCHIVOS});
  };

  const handleEliminarDocEM = (doc:DTODocumento)=>{
    setEstatus({loading:true,leer:false});
    if(fileEM!=null){
      URL.revokeObjectURL(fileEM.url);
      setFileEM(null);      
    }else{
      //doc.estatus=0;
      setEliminarDocEM(true);
    }
    console.log("eliminar doc ine",paciente);    
    setEstatus({loading:false,leer:false});
  }

  const handleSeleccionarDocEM=async(event: React.ChangeEvent<HTMLInputElement>)=>{    
    setFileEM(await leerArchivo(event));
    setSubirEM(false);
    setMsgToast({showToast:true,statusMessage:MENSAJE_CARGA_ARCHIVOS});
  }

  const leerArchivo= async(event: React.ChangeEvent<HTMLInputElement>):Promise<FileTemporalDoc>=>{
    if(event.target.files.length>0){
      const file = event.target.files.item(0);
      const temUrl = {url:URL.createObjectURL(file),fileName:file.name,mimeType:file.type};      
      console.log("created URL: ",temUrl);
      return temUrl;
    }
  }

  const abrirArchivo = async(doc:DTODocumento)=>{
    if(esWeb()){         
      let docTemp = await leerArchivoDescarga(doc);
      console.log(docTemp);
      let pdfWindow = window.open("",'_blank')
      pdfWindow.document.write(
      "<iframe  width='100%' height='100%' frameBorder='0' src='"+docTemp+"'></iframe>");
      setEstatus({loading:false,leer:false});
      return;
    }

    let uri = await Filesystem.getUri({
      path: doc.urlDocumento,
      directory: Directory.Documents}      
    );
    console.log("abir archivo uri:",uri);    
    FileOpener.showOpenWithDialog(uri.uri, doc.mimeType).then().catch((e)=>{
      console.log("error",e);
      abrirArchivoBrowser(doc).catch((e)=>{
        setMsgToast({showToast:true,statusMessage:"El documento no se encuentra en este dispositivo"});
      });
    });
  }

  const handleEditarEstudio=async(setudio :DTOEstudio)=>{    
    setEstudio(setudio);
    console.log("estudio:"+estudio);
    setVerEvento(setudio.evento!=null?"si":"no");
    setVerRecordatorio(setudio.evento!=null && setudio.evento.recordatorio?"si":"no");
    setModalAgregarEstudio(true);    
  };

  const handleEliminarEstudio=async(sestudio:DTOEstudio)=>{
    setEstatus({loading:true,leer:false});
    console.log("Eliminar estudio: ",sestudio); 
    sestudio = {...sestudio,estatus:0}        
    await guardarEstudio(user,sestudio).then(()=>{
      setMsgToast({showToast:true,statusMessage:"El registro se elimino correctamente"});
      leerEstudios(user,parametros.paciente.idPersona).then((lestudios)=>{       
      setListaEstudios(lestudios);
    })});
    await sincronizaNotificacionesElimina();
    setEstatus({loading:false,leer:false});
  }

  const handleGuardarEstudio = async()=>{
    setEstatus({loading:true,leer:false});   
    console.log("Guardar estudio: ",estudio); 
    let tevento:DTOEvento=null;
    if(verEvento==="si"){
      tevento={
        idPersona:estudio.idPersona,
        nombre:"Estudio clínico",
        descripcion:estudio.tipoEstudio,
        fecha: convertirFecha(estudio.fechaEstudio),
        hora: convertirHora(estudio.fechaEstudio),
        mostrar: true,
        recordatorio: verRecordatorio==="si"&&!validarFechaRecordatorio(estudio.fechaEstudio),        
      }
    }

    let estudioTemp ={...estudio,evento:tevento};
    console.log("Guardar documento Estudio Clinico",estudioTemp);
      if(fileEM!=null){               
        let temDocEM:DTODocumento=estudioTemp.docEstudioClinico;
        if(!temDocEM){
          temDocEM={
            idPersona:paciente.idPersona,
            descripcion:"Estudio clínico",
            urlDocumento: URL_ALLIVIA_DOCS+"/"+fileEM.fileName,
            nombre:fileEM.fileName,
            catTipoDocumento:{idTipoDoc:7},
            estatus : 1,
            remoto:false,
            mimeType:fileEM.mimeType,
            gluster:0,
          }
        }else{
          temDocEM={...temDocEM,urlDocumento:URL_ALLIVIA_DOCS+"/"+fileEM.fileName,mimeType:fileEM.mimeType}
        }                
        await guardarDocumentos(fileEM);
        if(parametros&&(parametros.premium||parametros.premiumDuo)){
          //syncronizar imagen en el servidor
          temDocEM ={...temDocEM,fileStream:fileEM.fileStream,gluster:1};
          await guardarDocumentosGluster(user,temDocEM);
          temDocEM.fileStream=null;
        }      
        estudioTemp = {...estudioTemp,docEstudioClinico:temDocEM};
        URL.revokeObjectURL(fileEM.url);
        setFileEM(null);
      }  
      if(eliminarDocEM){        
        await eliminarDocumento(estudio.docEstudioClinico);
        try{
          await eliminarDocumentosGluster(user,estudio.docEstudioClinico);
        }catch(err){      
          console.log("error al eliminar los documentos del gluster,",err);
        }
         let temDocEM:DTODocumento={...estudioTemp.docEstudioClinico,estatus:0};
         estudioTemp = {...estudioTemp,docEstudioClinico:temDocEM}
      }         
      estudioTemp = {...estudioTemp,fechaEstudio:convertirHoraFecha(estudioTemp.fechaEstudio)}
      console.log("Guardar estudio: ",estudioTemp);         
      guardarEstudio(user,estudioTemp).then(()=>{
        setMsgToast({showToast:true,statusMessage:"El registro se guardo correctamente"});
        leerEstudios(user,parametros.paciente.idPersona).then((lestudios)=>{       
          setListaEstudios(lestudios);        
        }).finally(()=>{
          setEstudio({idPersona:paciente.idPersona,estatus:1});
          setVerEvento("no");
          setVerRecordatorio("no");
          setEliminarDocEM(false);
        });
        sincronizaNotificaciones();
      });
    setModalAgregarEstudio(false);
    setEstatus({loading:false,leer:false});   
  }

  const limpiarDatos =()=>{
    setEstudio({idPersona:paciente.idPersona,estatus:1});
    setVerEvento("no");
    setVerRecordatorio("no");
    setEliminarDocEM(false);
  }


  const convertirFecha=(fecha:string):string=>{  
    return dayjs(fecha).format("YYYY-MM-DD");;
  }

  const convertirHora=(fecha:string):string=>{  
    return dayjs(fecha).format("HH:mm:ss");;
  }

  const convertirHoraFecha =(t:string):string=>{  
    let d = ""+t+":00"
    return d;
  }

  const formatDinero =(value: number) =>{
   return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
    }).format(value);
  }

  const formatFecha =(fecha:string) =>{
    return dayjs(fecha).format("DD/MM/YY");
  }

  const validarFechaRecordatorio= (date:string)=>{
    if(!date){
      return false;
    }
    let destudio =dayjs(date).toDate();
    let dhoy = new Date();
    return destudio<dhoy
  }

  const actualizaEstudio = (valor:any)=>{
    console.log("valor: ",valor);
    setEstudio({...estudio,...valor});
  }

  useEffect(() => {
    console.log("Entrando a useefect menuPaciente: ");
    if (parametros) {
      setPaciente(parametros.paciente);
      setEstudio({idPersona:parametros.paciente.idPersona,estatus:1});
      setEstatus({loading:false,leer:false});   
    }
  }, [parametros]);
  
  useEffect(()=>{
    if(parametros){
      leerEstudios(user,parametros.paciente.idPersona).then((lestudios)=>{              
        lestudios.forEach((estud)=>{
          cargarPreview(user,estud.docEstudioClinico,parametros);
        })
        setListaEstudios(lestudios);        
      });
      setEstatus({loading:false,leer:false});       
    }
  },[]);

  const sincronizaNotificacionesElimina=async ()=>{    
    await leerNotificacionesElimina(user,parametros.cuidador.idCuidador)
  }

  const sincronizaNotificaciones=async ()=>{    
    await leerNotificaciones(user,parametros.cuidador.idCuidador)
  }

  console.log("valor paciente: ", paciente);

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  if (!paciente) {
    return (<IonLoading isOpen />);
  }

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle className="title_header">Estudios Clínicos</IonTitle>
            <IonButtons slot='end'>
              <IonButton color='primary' onClick={()=>{descargarEstudioClinico()}} >
                <IonIcon size='large' icon={downloadOutline} ></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="estudios-paciente">
          <IonList lines='none' className='pleca-tratamientos'>
            <IonListHeader>
               <div id="contenedor_datos">
            <div className="icon_px">
                <IonImg src="./assets/px.png"/>
            </div>
            <div className="datos_px">
                 <div id='title_paciente'>
                   <span className='header-nombre'>{paciente?paciente.nombreCompleto:null}</span>
                 </div>
                 <div id="title_parentesco">
                  <span className='header-parentesco'>{paciente?paciente.parentesco:null}</span>
                 </div>
            </div>
          </div>
            </IonListHeader>
          </IonList>
          <div className='estudios-panel'>
            <div className='estudios-titulo' >
              <IonImg src='./assets/estudios.png' />
              <p> Estudios Clínicos </p>
            </div>
            <div className='estudios-datos' >
              <table>
                <thead>
                  <tr>
                    <th>Estudio</th>
                    <th>Fecha</th>
                    <th>Descargar</th>
                    <th>Costo</th>
                    <th>Editar</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    listaEstudios?listaEstudios.map((letudios)=>{
                      return <>
                      <tr key={letudios.idEstudio}>
                        <td  >{letudios.tipoEstudio}</td>
                        <td  >{formatFecha(letudios.fechaEstudio)}</td>
                        <td  >
                          <IonButton hidden={!letudios.docEstudioClinico} fill='clear' size='small' onClick={()=>{abrirArchivo(letudios.docEstudioClinico)}} >
                            <IonIcon color="rosa" icon={attach}></IonIcon>
                          </IonButton>
                        </td>
                        <td  >{formatDinero(letudios.costo)}</td>
                        <td className='panel-acciones'>
                        <IonButton className="editar" fill='clear' size='small' onClick={()=>{handleEditarEstudio(letudios)}} ><IonIcon color="rosa" icon={pencil}></IonIcon></IonButton>
                        <IonButton fill='clear' size='small' onClick={()=>{handleEliminarEstudio(letudios)}}><IonIcon color="rosa" icon={trash}></IonIcon></IonButton>
                      </td> 
                      </tr>  
                      </>
                    }):null
                  }                   
                </tbody>
              </table>
            </div>
          </div>
          <IonFab hidden={esWeb()} className='allivia-emergencia-call' vertical='bottom' horizontal='center' edge slot='fixed'>
            <IonFabButton color="emergencia" >
              <div>
                <IonIcon icon={call} />
                <IonLabel>911</IonLabel>
              </div>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton color="success" onClick={()=>{emergencyCall();}}><IonIcon icon={call} /></IonFabButton>
            </IonFabList>
          </IonFab>
          <IonModal backdropDismiss={false} isOpen={modalAgregarEstudio} className="ion-modal-estudios" >
            <IonContent>
              <div className='header'>
                <IonImg src='./assets/estudiosAgregar.png'></IonImg>
                Agregar Estudio Clínico
                <IonButton fill='clear' onClick={()=>{setModalAgregarEstudio(false);limpiarDatos();}}>
                  <IonIcon icon={closeOutline}  ></IonIcon>
                </IonButton>
              </div>
              <div className='contenido'>                
                <div className='panel-formulario'>
                  <IonGrid >
                    <IonRow >
                      <IonCol size='6' >
                        <IonImg className='img-icon' src="./assets/labItem.png" />
                        <IonLabel>Tipo de Estudio:</IonLabel>
                      </IonCol>
                      <IonCol size='6' >
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={estudio.tipoEstudio} 
                        onIonChange={(event)=>{actualizaEstudio({tipoEstudio:event.detail.value})}}  ></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/labstore.png" />                      
                        <IonLabel>Laboratorio:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput className="capitalizar" type="text" inputMode='text' autocapitalize="words" value={estudio.laboratorio} 
                        onIonChange={(event)=>{actualizaEstudio({laboratorio:event.detail.value})}}  ></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/labmoney.png" />
                        <IonLabel>Costo:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='number' value={estudio.costo} 
                        onIonChange={(event)=>{actualizaEstudio({costo:event.detail.value})}} ></IonInput>
                      </IonCol>
                      <IonCol size='6'>
                        <IonImg className='img-icon' src="./assets/labcalendar.png" />
                        <IonLabel>Fecha de estudio:</IonLabel>
                      </IonCol>
                      <IonCol size='6'>
                        <IonInput type='datetime-local' value={estudio.fechaEstudio} 
                        onIonChange={(event)=>{actualizaEstudio({fechaEstudio:event.detail.value})}}></IonInput>
                      </IonCol>
                      <IonCol size='12'>
                        <IonLabel>Deseas agregar este evento al calendario:</IonLabel>                                               
                      </IonCol> 
                    </IonRow>
                    <IonRadioGroup color='success' value={verEvento} 
                      onIonChange={(event)=>{setVerEvento(event.detail.value)}}>
                      <IonRow>                                           
                        <IonCol size='6' >
                          <IonItem lines='none'>
                          <IonLabel>Si</IonLabel>
                            <IonRadio mode="md"  slot="start" value="si"></IonRadio>
                          </IonItem>
                        </IonCol>
                        <IonCol size='6' >
                          <IonItem lines='none'>
                            <IonLabel>No</IonLabel>
                            <IonRadio mode="md"  slot="start" value="no"></IonRadio>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonRadioGroup>
                    <IonRow>
                      <IonCol size='12'>
                        <IonLabel>Deseas recibir un recordatorio de este evento:</IonLabel>                                               
                      </IonCol> 
                    </IonRow>
                    <IonRadioGroup value={verRecordatorio} hidden={validarFechaRecordatorio(estudio.fechaEstudio)}
                      onIonChange={(event)=>{setVerRecordatorio(event.detail.value)}} >
                      <IonRow>                                           
                        <IonCol size='6' >
                          <IonItem lines='none'>
                          <IonLabel>Si</IonLabel>
                            <IonRadio mode="md"  slot="start" value="si"></IonRadio>
                          </IonItem>
                        </IonCol>
                        <IonCol size='6' >
                          <IonItem lines='none'>
                            <IonLabel>No</IonLabel>
                            <IonRadio mode="md"  slot="start" value="no"></IonRadio>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonRadioGroup>
                  </IonGrid>
                </div>
                <div className='panel-documentos'>
                <div hidden={esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo)}>
                  <div hidden={eliminarDocEM} className='upload-panel'>
                  <div className='upload-panel-buttons'>                          
                      <IonButton hidden={subirEM || estudio.docEstudioClinico!=null || fileEM!=null} shape='round' color='rosa' onClick={()=> setSubirEM(true)}>
                        <IonIcon icon={cloudUpload}/>                
                      </IonButton>
                      <IonButton hidden={!subirEM || fileEM!=null} shape='round' color='rosa' onClick={()=> takePictureEM()}>
                        <IonIcon icon={camera}/>                
                      </IonButton>                  
                      <IonButton hidden={!subirEM || fileEM!=null} shape='round' color='rosa' onClick={()=> fileInputDocEMRef.current.click()}>
                        <IonIcon icon={document}/>                
                      </IonButton>
                      <IonButton hidden={!estudio.docEstudioClinico} shape='round' color='rosa' onClick={()=>{abrirArchivo(estudio.docEstudioClinico)}}>
                        <IonIcon icon={cloudDownload}/>                
                      </IonButton>
                      <IonButton hidden={!estudio.docEstudioClinico &&  !fileEM} shape='round' color='rosa' onClick={()=>{handleEliminarDocEM(estudio.docEstudioClinico)}}>
                        <IonIcon icon={trash}/>                
                      </IonButton>                          
                    </div>
                    <IonLabel>Estudio Clínico</IonLabel>
                    <input type='file' accept='application/pdf,image/*' hidden onChange={handleSeleccionarDocEM} ref={fileInputDocEMRef}></input>
                  </div>
                  </div>
                  <div className='msj-doc-noPremium' hidden={!(esWeb()&&parametros&&!(parametros.premium||parametros.premiumDuo))}>
                          <span>{MSJ_CARGA_DOCUMENTOS}</span>
                        </div>
                  <IonButton className="guardar_orange" expand='block' onClick={() => {handleGuardarEstudio();}} >
                    Guardar
                    <IonImg src="../assets/saveIcon.png" />
                  </IonButton>
                </div>
              </div>
              
            </IonContent>
          </IonModal>
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({showToast:false,statusMessage:''})}
            message= {msgToast.statusMessage}
            duration={3000}
        />
        <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading'/>
        </IonContent>
        <div className='custom-tab-bar'>
          <IonButton fill='clear' routerLink='/'>
            <div>
              <IonIcon icon={home} />
              <IonLabel>Inicio</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' routerLink='/allivia/buscar'>
            <div>
              <IonIcon icon={search} />
              <IonLabel>Buscar</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={esWeb()} fill='clear' onClick={() => {compartirArchivo()}}>
            <div>
              <IonIcon icon={shareSocial} />
              <IonLabel>Compartir</IonLabel>
            </div>
          </IonButton>
          <IonButton hidden={!esWeb()} fill='clear' onClick={() => { descargarEstudioClinico() }}>
            <div>
              <IonIcon icon={downloadOutline} />
              <IonLabel>Descargar</IonLabel>
            </div>
          </IonButton>
          <IonButton fill='clear' onClick={() => { setModalAgregarEstudio(true); console.log("entrando") }}>
            <div>
              <IonIcon icon={addCircle} />
              <IonLabel>Estudio</IonLabel>
            </div>
          </IonButton>
        </div>
      </IonPage>
    </>
  );
};

export default EstudiosClinicos;
