import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonToast,
  IonButton,
  IonIcon,
  IonModal,
  IonSlides,
  IonSlide,
  IonImg,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useLoginContext, useParametros } from '../hooks/login';
import '../theme/miInvitacionPremium.css';
import { Redirect } from 'react-router';
import { DTOSolicitudCompartirMembresia } from '../services/AlliviaInterfaces';
import { aceptarSolicitudRecibidaMembresiaPremium, leerSolicitudRecibidaMembresiaPremium, rechazarSolicitudRecibidaMembresiaPremium } from '../services/membresia.service';
import { trash } from 'ionicons/icons';



const MiInvitacionPremium: React.FC = () => {
  const user = useLoginContext();
  const { parametros,setParametros } = useParametros();
  const [estatus, setEstatus] = useState({ loading: true, leer: true });
  const [msgToast, setMsgToast] = useState({ showToast: false, statusMessage: '' });
  const [solicitud,setSolicitud] = useState<DTOSolicitudCompartirMembresia>(null);
  const [modalAceptar ,setModalAceptar] = useState(false);
  const [modalEstatusInvitacion ,setModalEstatusInvitacion] = useState(false);
  const publicidadRef = useRef(null);

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  const leerDatos=async()=>{
    setEstatus({loading:true,leer:false});
    if(parametros!=null){
      let sol = await leerSolicitudRecibidaMembresiaPremium(user);
      setSolicitud(sol);
    }    
    setEstatus({loading:false,leer:false});
  }

  const aceptarInvitacion=async()=>{
    setEstatus({loading:true,leer:false});
    let sol = await aceptarSolicitudRecibidaMembresiaPremium(user);
    setSolicitud(sol);
    setMsgToast({showToast:true,statusMessage:"Has aceptado recibir los beneficios premium"});
    setModalAceptar(false);
    setParametros({...parametros,invitacionPremium:sol});
    setEstatus({loading:false,leer:false});
  }

  const rechazarInvitacion=async()=>{
    setEstatus({loading:true,leer:false});
    let sol = await rechazarSolicitudRecibidaMembresiaPremium(user);
    setSolicitud(sol);
    setMsgToast({showToast:true,statusMessage:"Has rechazado recibir los beneficios premium"});
    setModalAceptar(false);
    setParametros({...parametros,invitacionPremium:sol});
    setEstatus({loading:false,leer:false});
  }

  const handleVerModal=()=>{
    if(solicitud.idEstatus===0){
      setModalAceptar(true);
    }else{
      setModalEstatusInvitacion(true);
    }
  }

  useEffect(() => {
    setEstatus({ loading: true, leer: false });
    leerDatos();
    setEstatus({ loading: false, leer: false });
  }, []);

  if (!user.loggedIn) {
    return <Redirect to="/login" />
  } 

  if (!parametros) {
    return <Redirect to="/allivia/alliviaHome" />
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton text=""  color='primary' defaultHref="/" />
            </IonButtons>
            <IonTitle>Mi Invtación PREMIUM</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className='minv-premium'>
            <div className='header'>
              Mi invitación<br></br>premium
            </div>
            <div hidden={solicitud===null} className='invitado'  >
                <div className='im-inv'>
                  AC
                </div>
                <div className='datos-inv' onClick={()=>handleVerModal()}>
                  <div>{solicitud?solicitud.nombreCompletoPremium:""}</div>
                  <div>COMPARTE SU BENEFICIO DE</div>
                  <div className='datos-estatus'>
                    <div className='estatus'>INVITADO <span className='status-premium'>PREMIUM</span><span hidden={solicitud&&solicitud.idEstatus!=0} >, ¿ACEPTAS?</span></div>                    
                  </div>
                </div>
                <div className='accion'>
                  <IonButton fill='clear' onClick={rechazarInvitacion} ><IonIcon icon={trash}></IonIcon></IonButton>
                </div>
              </div>
              <div  hidden={solicitud!=null} className="sin-invitacion">
                Sin invitaciones
              </div>
            <div className='mensaje'>
             <span className="apremium"> allivia </span> es una app que quiere acompañarte en cualquier situación médica, de rutina o de emergencia. Actualízala, tu paciente, tu médico y tú lo agradecerán.
            </div>            
            
          </div>
          <div className='publicidad-img-premium'>
          <IonSlides ref={publicidadRef}   options={slideOpts} >
            <IonSlide>
              <IonImg  src={solicitud&&solicitud.idEstatus===1?"https://allivia.com.mx/publicidad/imgMIP2.png":"https://allivia.com.mx/publicidad/imgMIP1.png"}/>           
            </IonSlide> 
                                               
          </IonSlides>                  
        </div> 
          
          <IonToast color="rosa"
            isOpen={msgToast.showToast}
            onDidDismiss={() => setMsgToast({ showToast: false, statusMessage: '' })}
            message={msgToast.statusMessage}
            duration={3000}
          />
          <IonLoading isOpen={estatus.loading} message="Por favor espera..." spinner='circular' cssClass='allivia-loading' />
          <IonModal backdropDismiss={false} isOpen={modalAceptar} className="modal-aceptar-invitado" >
            <IonContent >              
              <div className='contenido'>                  
                <div className='mensaje1'>
                  Aceptar recibir beneficios premium
                </div>
                <div className='mensaje2'>
                  ¿Deseas aceptar recibir los beneficios premium que {solicitud!=null?solicitud.nombreCompletoPremium:""} comparte contigo?
                </div>                               
                <div className='botones'>
                  <div className='dl'>
                    <IonButton onClick={rechazarInvitacion} fill='clear'>Rechazar</IonButton>
                  </div>
                  <div className='dr'>
                    <IonButton onClick={aceptarInvitacion} fill='clear'>Aceptar</IonButton>
                  </div>                  
                </div>
              </div>              
            </IonContent>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={modalEstatusInvitacion} className="modal-estatus-invitado" >
            <IonContent >              
              <div className='contenido'>                  
                <div className='mensaje1'>
                  Solicitud {solicitud!=null?(solicitud.idEstatus===1?"aceptada":(solicitud.idEstatus===2?"rechazada":"")):""}
                </div>
                <div className='acciones' >
                  <IonButton expand='block' onClick={()=>setModalEstatusInvitacion(false)}>Cerrar</IonButton>
                </div>                                               
              </div>              
            </IonContent>
            </IonModal>
        </IonContent>
      </IonPage>
    </>
  );
};

export default MiInvitacionPremium;
