import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router';
import { useLoginContext } from '../hooks/login';
import '../theme/derechosCuidador.css';



const DerechosCuidador: React.FC = () => {
  const { loggedIn } = useLoginContext(); 

  //console.log(`rendering from loginPage with loggedIn=${loggedIn}`);
  if (!loggedIn) {
    return <Redirect to="/allivia/alliviaHome" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton text=""  color='primary' defaultHref="/"/>
          </IonButtons>
          <IonTitle>Derechos del cuidador</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className='cuidador'>
          <h2 className='titulo'>Derechos del cuidador</h2>
          <h2 className='subtitulo'>Los cuidadores tienen derechos conócelos porque tú, también importas</h2>
          <p>Según la Guía Básica de Autocuidado para Personas
Cuidadoras estas personas que dedican gran parte de su vida
a cuidar enfermos que son familiares, además de los derechos
que tenemos todos los ciudadanos, cuentan con una serie de
derechos específicos que derivan de su propia situación y son
llamados los Derechos de cuidador:
</p>
<ol className="lista_derechos">
<li>A dedicar tiempo a ellos mismos sin tener sentimientos de
culpa.</li>

<li>A experimentar sentimientos negativos por ver a su
familiar enfermo.</li>

<li>A solicitar información sobre todo aquello que no
comprendan.</li>

<li>A buscar soluciones a problemas que se ajustan a sus
necesidades y a las de sus familiares.</li>

<li>A ser tratados con respeto por aquellos a quienes se les
solicita ayuda.</li>

<li>A equivocarse.</li>

<li>A ser reconocidos como miembros valiosos de la familia.</li>

<li>A ser reconocidos como miembros valiosos de la
sociedad.</li>
<li>A aprender y a disfrutar del tiempo para aprender.</li>
<li>A expresar sus sentimientos.</li>
<li>A poner límites a las demandas excesivas.</li>
<li>A cuidar de su futuro.</li>
</ol>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default DerechosCuidador;
