import {
  IonButton,
  IonContent,
  IonImg,
  IonPage,
} from '@ionic/react';
import '../theme/404.css';
import React from 'react';


const NotFoundPage: React.FC = () => {
  return (
    <IonPage>      
      <IonContent className="ion-padding">
          <div id="title_404">
              <p id="upss">
                  ¡UPSS!
              </p>
              <p>
                <span className="error">Error</span>
                <span className="aqua404">404</span>
              </p>
              <p>
                No encontramos el contenido que buscabas
              </p>
          </div>
          <div id="image">
            <IonImg src="./assets/404.png" />
          </div>
          <div>
            <p>
             Lamentamos las molestias que esto pueda ocasionarte
            </p>
            <IonButton href='allivia/alliviaHome'>Inicio</IonButton>
          </div>
      </IonContent>
    </IonPage>
  );
};

export default NotFoundPage;
